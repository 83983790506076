import { useTheme } from 'styled-components'

import { ILoaderPointsProps } from './types'

export const useLoaderPointsColors = (colors: ILoaderPointsProps['colors']) => {
  const theme = useTheme()

  return (
    colors || {
      start: theme.colors.custom.primary[9],
      end: theme.colors.default.primary[90]
    }
  )
}
