import React, { FC } from 'react'

import { Auth } from 'widgets/Auth/Auth'
import { RightBasketContainer } from 'containers/BasketContainer'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { SportsMenu } from 'widgets/sports-menu'
import { Header } from 'widgets/header'
import { FooterOfPage } from 'widgets/footer-of-page'
import { LineHeader } from 'pages/page-live-events/Line/components/ui'

import { LayoutProps } from '../../Layout.types'

import { StyledContent, StyledLayout } from './Sport.styled'

export const LayoutSport: FC<LayoutProps> = ({
  children,
  refContent,
  typeHeader
}) => (
  <StyledLayout>
    <Auth />

    <Header />

    {!!typeHeader && <LineHeader typeHeader={typeHeader} />}

    <SportsMenu />

    <NotificationsPanel />

    <StyledContent ref={refContent}>
      {children}
      <FooterOfPage />
    </StyledContent>

    <RightBasketContainer />
  </StyledLayout>
)
