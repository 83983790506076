import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconEmblem: FC<Omit<IconProps, 'colorProps'>> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.254 5.399a1.164 1.164 0 0 0-.586 1.015v9.921c0 4.359 1.873 8.257 4.801 10.628.966.782 2.562 1.532 3.804 2.058.643.272.998.438 1.66.661.678.23 1.423.235 2.104.011l.033-.01c.662-.221 1.016-.39 1.66-.662 1.242-.526 2.838-1.276 3.803-2.058 2.929-2.371 4.802-6.269 4.802-10.628V6.414c0-.422-.225-.811-.586-1.015l-3.4-1.92a1.117 1.117 0 0 0-1.464.332c-.907 1.275-1.738 1.991-2.608 2.41-.877.422-1.898.591-3.276.591-2.55 0-4.59-1.181-5.883-3a1.117 1.117 0 0 0-1.464-.334l-3.4 1.92Z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="a"
          x1={27.996}
          x2={4.097}
          y1={4.307}
          y2={29.62}
        >
          <stop stopColor="#fff" stopOpacity={0.64} />
          <stop offset={1} stopColor="#fff" stopOpacity={0.32} />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
