import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { StyledTag } from 'shared/ui/Tag'
import { customMedia } from 'shared/lib/styled'

export const StyledEventTeams = styled.div`
  display: flex;
  grid-area: teams;
  ${customMedia.medium} {
    flex-direction: column;
  }

  ${StyledTag} {
    margin-right: 4px;
    ${customMedia.medium} {
      margin-right: 0;
    }
  }
`

export const StyledEventTeamsNames = styled(Link)`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.default.primary[90]};
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    gap: 4px;
    line-height: 16px;
  `}
`

export const StyledCompetitor = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;
`

export const StyledCurrentServerFlag = styled.div`
  background-color: ${(p) => p.theme.colors.default.green[50]};
  border-radius: 1px;
  height: 12px;
  width: 2px;
`
