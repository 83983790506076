import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconAddCircleFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M7.99935 15.3327C12.0494 15.3327 15.3327 12.0494 15.3327 7.99935C15.3327 3.94926 12.0494 0.666016 7.99935 0.666016C3.94926 0.666016 0.666016 3.94926 0.666016 7.99935C0.666016 12.0494 3.94926 15.3327 7.99935 15.3327ZM6.99939 4.99936C6.99939 4.44707 7.4471 3.99936 7.99939 3.99936C8.55167 3.99936 8.99939 4.44707 8.99939 4.99936V6.99936H10.9994C11.5517 6.99936 11.9994 7.44707 11.9994 7.99936C11.9994 8.55164 11.5517 8.99936 10.9994 8.99936H8.99939V10.9994C8.99939 11.5516 8.55167 11.9994 7.99939 11.9994C7.4471 11.9994 6.99939 11.5516 6.99939 10.9994V8.99936H4.99939C4.4471 8.99936 3.99939 8.55164 3.99939 7.99936C3.99939 7.44707 4.4471 6.99936 4.99939 6.99936H6.99939V4.99936Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
