import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'
import {
  selectBetsHistoryBasketFetchData,
  selectBetsHistoryBasketHasMore,
  selectBetsHistoryBasketsPagination
} from 'containers/BetsHistoryBasketContainer/selectors'
import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS = {
  setPaginationAction: betsHistoryBasketContainerActions.setPagination,
  selectorHasMore: selectBetsHistoryBasketHasMore,
  selectorPagination: selectBetsHistoryBasketsPagination,
  selectorRequestStatus: selectBetsHistoryBasketFetchData
} as DataListWithReduxPaginationProps['pagination']
