import React, { FC, memo, useCallback, useContext, useRef } from 'react'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectCurrencyMinBet } from 'astra-core/containers/CommonDataProvider'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { useUpdateQuickBet } from 'og-web-core/providers/basket/quick-bet/hooks/useUpdateQuickBet'
import { useQuickBetForm } from 'og-web-core/providers/basket/quick-bet/hooks/useQuickBetForm'
import { formatString } from 'og-web-core/providers/basket/quick-bet/basket-quick-bet.utils'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { CurrencyDynamicInput } from 'shared/ui/DynamicInput'

import { BasketQuickBetsAmount } from '../../../BasketCommon/components/BasketQuickBetsAmount/BasketQuickBetsAmount'
import { BasketQuickBetError } from '../BasketQuickBetError/BasketQuickBetError'
import { EBasketQuickBetError } from '../BasketQuickBetError/BasketQuickBetError.types'
import { BasketQuickBetSubmitButton } from '../BasketQuickBetSubmitButton/BasketQuickBetSubmitButton'
import {
  StyledBetCurrency,
  StyledBetInputWrapper
} from '../../../BasketCommon/components/BasketInput/BasketInput.styled'

import {
  StyledQuickBetForm,
  StyledQuickBetInput,
  StyledQuickBetInputDescription,
  StyledQuickBetInputError,
  StyledQuickBetInputLabel,
  StyledQuickBetInputWrapper
} from './BasketQuickBetForm.styled'
import { BasketQuickBetFormProps } from './BasketQuickBetForm.types'

const errorsKeys = {
  [EBasketQuickBetError.minBetError]: 'minimum amount'
}
export const BasketQuickBetForm: FC<BasketQuickBetFormProps> = memo(() => {
  const minBet = useSelector(selectCurrencyMinBet)
  const inputRef = useRef<HTMLInputElement>(null)

  const focusOnInput = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const {
    isQuickBetActive,
    setIsQuickBetActive,
    lowerBalanceModalVisible,
    setLowerBalanceModalVisible,
    modal,
    setModal
  } = useContext(BasketQuickBetContext)

  const { inputValue, handleChangeInput, handleSelectAmount, error } =
    useQuickBetForm()

  const currencyIcon = useGetCurrencyIcon()
  const { t } = useTranslation()
  const isBetsAmountEnabled = useFeatureFlag(EFeatureFlags.BETS_AMOUNT_ENABLED)

  const onSuccess = useCallback(() => {
    if (lowerBalanceModalVisible) {
      setLowerBalanceModalVisible(false)
    }

    if (modal) {
      setModal(null)
    }

    if (!isQuickBetActive) {
      setIsQuickBetActive(true)
    }
  }, [
    isQuickBetActive,
    lowerBalanceModalVisible,
    modal,
    setIsQuickBetActive,
    setLowerBalanceModalVisible,
    setModal
  ])

  const { isLoading, updateQuickBetSum } = useUpdateQuickBet({
    isQuickBetActive,
    quickBetValue: formatString(inputValue) ?? minBet,
    onError: (err) => {
      console.error(err)
    },
    onSuccess
  })

  return (
    <>
      <StyledQuickBetForm>
        <StyledQuickBetInputWrapper>
          <StyledQuickBetInputLabel>
            {t('bets amount')}
          </StyledQuickBetInputLabel>
          <StyledQuickBetInput error={error} onClick={focusOnInput}>
            <StyledBetInputWrapper>
              <CurrencyDynamicInput
                inputValue={inputValue}
                placeholder="0"
                ref={inputRef}
                onAmoutChange={handleChangeInput}
              />
              <StyledBetCurrency>{currencyIcon}</StyledBetCurrency>
            </StyledBetInputWrapper>
          </StyledQuickBetInput>
          {error ? (
            <StyledQuickBetInputError>
              {t(errorsKeys[error], {
                amount: minBet,
                currency: currencyIcon
              })}
            </StyledQuickBetInputError>
          ) : (
            <StyledQuickBetInputDescription>
              {t('minimum bet amount', {
                amount: minBet,
                currency: currencyIcon
              })}
            </StyledQuickBetInputDescription>
          )}
        </StyledQuickBetInputWrapper>

        {isBetsAmountEnabled && (
          <BasketQuickBetsAmount onChange={handleSelectAmount} />
        )}
        {!!error && <BasketQuickBetError error={error} />}
      </StyledQuickBetForm>
      <BasketQuickBetSubmitButton
        disabled={!!error}
        handleSubmit={updateQuickBetSum}
        isLoading={isLoading}
        title="save"
      />
    </>
  )
})
