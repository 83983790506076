import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'
import { StyledIcon } from 'shared/ui/Icon'
import { IconSetting } from 'shared/ui/Icon/General/IconSetting'
import { ThemeContext } from 'shared/types/theme'
import { Button } from 'shared/ui/Button'

const COLOR_HOVER = (props: { theme: ThemeContext }) =>
  props.theme.colors.fixed.primary[0]

export const StyledAdditional = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  ${columnGapPrefixes('16px')}
  justify-content: flex-end;
`

export const StyledSupport = styled.div`
  align-items: center;
  background: transparent;
  ${columnGapPrefixes('8px')}
  border: none;
  color: ${(props) => props.theme.colors.custom.primary[6]};
  cursor: pointer;
  display: flex;
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding: 0;
`

export const StyledLink = styled(Link)`
  ${(props) => css`
    align-items: center;
    background: transparent;
    ${columnGapPrefixes('8px')}
    border: none;
    color: ${props.theme.colors.custom.primary[6]};
    cursor: pointer;
    display: flex;
    font-weight: ${props.theme.fonts.weight.normal};
    padding: 0;

    &:hover {
      color: ${COLOR_HOVER(props)};

      ${StyledIcon} {
        color: ${COLOR_HOVER(props)} !important;
      }
    }
  `}
`

export const StyledFlagButton = styled(Button)``

export const StyledTime = styled.li`
  color: ${(props) => props.theme.colors.custom.primary[43]};
  font-size: ${({ theme }) => theme.fonts.size.m};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
`

export const StyledIconSetting = styled(IconSetting)`
  &:hover {
    color: ${(props) => COLOR_HOVER(props)} !important;
  }
`
