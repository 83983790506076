import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  accountProviderActions,
  TTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import { EAuthPagesAndModal } from 'astra-core/constants/auth'
import {
  useGetPostAuthServiceMessage,
  EPostMessageIdEvent
} from 'astra-core/containers/AuthMessageProvider'

import { EButtonSizes, EButtonViews } from 'shared/ui/Button'
import { useHashParam } from 'hooks/useHashParams'
import { useAuthTypeModal } from 'hooks/useAuthTypeModal'
import { ETestData } from 'shared/lib/testData'

import {
  StyledButton,
  StyledComponentOfUnauthorized,
  StyledSignUp
} from './ComponentOfUnauthorized.styled'
import { ComponentOfUnauthorizedProps } from './types'
import { BUTTON_NAME_LIST, EButtonNameList } from './constants'

export const ComponentOfUnauthorized: FC<ComponentOfUnauthorizedProps> = memo(
  ({ profile }) => {
    const dispatch = useDispatch()
    const [t] = useTranslation()
    const setAuthTypeModal = useAuthTypeModal()
    const buttonNames = profile
      ? BUTTON_NAME_LIST[EButtonNameList.PROFILE]
      : BUTTON_NAME_LIST[EButtonNameList.DEFAULT]

    const openAuthModal = useCallback(
      (types: TTypeAuthModal) => () => {
        dispatch(accountProviderActions.setTypeAuthModal(types))
        setAuthTypeModal(!!types)
      },
      [dispatch, setAuthTypeModal]
    )

    const { exists: regmeParamExists } = useHashParam('regme')

    useEffect(() => {
      if (regmeParamExists) {
        openAuthModal(EAuthPagesAndModal.SignIn)()
      }
    }, [openAuthModal, regmeParamExists])

    useGetPostAuthServiceMessage(
      EPostMessageIdEvent.open,
      openAuthModal(EAuthPagesAndModal.SignIn)
    )

    useGetPostAuthServiceMessage(
      EPostMessageIdEvent.signUp,
      openAuthModal(EAuthPagesAndModal.FastSignUp)
    )

    useGetPostAuthServiceMessage(
      EPostMessageIdEvent.fullSignUp,
      openAuthModal(EAuthPagesAndModal.FullSignUp)
    )

    return (
      <StyledComponentOfUnauthorized profile={profile}>
        <StyledSignUp
          data-test-id={ETestData.TestButtonSignUp}
          profile={profile}
          size={EButtonSizes.S}
          view={EButtonViews.SECONDARY}
          onClick={openAuthModal(EAuthPagesAndModal.FastSignUp)}
        >
          {t(buttonNames.signUp)}
        </StyledSignUp>
        <StyledButton
          data-test-id={ETestData.TestButtonSignIn}
          profile={profile}
          size={EButtonSizes.S}
          onClick={openAuthModal(EAuthPagesAndModal.SignIn)}
        >
          {t(buttonNames.signIn)}
        </StyledButton>
      </StyledComponentOfUnauthorized>
    )
  }
)
