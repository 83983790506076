import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'

import { Modal, ViewComponentType } from 'shared/ui/modal'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'

import { BasketQuickBetForm } from '../right-container/Basket/BasketQuickBet/components/BasketQuickBetForm/BasketQuickBetForm'

import { LowerBalanceModalProps } from './lower-balance-modal.types'
import {
  StyledLink,
  StyledLowerBalanceModalDescription,
  StyledLowerBalanceModalDescriptionWrapper,
  StyledLowerBalanceModalTitle,
  StyledLowerBalanceModalTitleWrapper,
  StyledLowerBalanceModalWrapper
} from './lower-balance-modal.styled'

export const LowerBalanceModal: FC<LowerBalanceModalProps> = () => {
  const { t } = useTranslation()
  const balance = useSelector(selectAccountBalance)
  const currency = useGetCurrencyIcon()

  const { lowerBalanceModalVisible, setLowerBalanceModalVisible } = useContext(
    BasketQuickBetContext
  )

  const onClickClose = () => {
    setLowerBalanceModalVisible(false)
  }

  return (
    <Modal isOpen={lowerBalanceModalVisible} view={ViewComponentType.FIRST}>
      <StyledLowerBalanceModalWrapper>
        <StyledLowerBalanceModalTitleWrapper>
          <StyledLowerBalanceModalTitle>
            {t('low balance')}
          </StyledLowerBalanceModalTitle>
          <IconClose
            colorProps={{ name: EColorsNames.Primary, value: 30 }}
            size={10}
            onClick={onClickClose}
          />
        </StyledLowerBalanceModalTitleWrapper>
        <StyledLowerBalanceModalDescriptionWrapper>
          <StyledLowerBalanceModalDescription>
            {t('amount on your balance is lower')} <br />
            {t('change your bet')}
          </StyledLowerBalanceModalDescription>
          <StyledLowerBalanceModalDescription>
            {t('your balance', {
              balance,
              currency
            })}
            <StyledLink to={ERoutes.ProfileBalance} onClick={onClickClose}>
              {t('up balance')}
            </StyledLink>
          </StyledLowerBalanceModalDescription>
        </StyledLowerBalanceModalDescriptionWrapper>

        <BasketQuickBetForm />
      </StyledLowerBalanceModalWrapper>
    </Modal>
  )
}
