import styled from 'styled-components'

import { customMedia } from 'shared/lib/styled'

export const StyledHeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: flex;
  justify-content: space-between;
  padding: 0 16px 8px;
  width: 100%;

  ${customMedia.small} {
    flex-direction: column;
    justify-content: start;
  }
`

export const StyledTitleWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: flex;
  justify-content: space-between;

  ${customMedia.small} {
    margin-bottom: 4px;
  }
`

export const StyledTitleTextWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const StyledTitle = styled.h2`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.xl};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 28px;
  margin-left: 8px;
`
