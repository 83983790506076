import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballShotOnGoal: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg
        fill="none"
        height={16}
        width={17}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M1.304 13.58a1 1 0 1 0 1.932.518l.84-3.14 8.584 2.3c.593.159 1.189-.145 1.332-.678l.854-3.188h.001l.854-3.188a1 1 0 0 0-.707-1.224L5.846 2.529a1 1 0 0 0-1.695.426l-.02.077a1.018 1.018 0 0 0-.021.067l-1.968 7.34.003.002-.841 3.139Zm7.15-3.52L9.05 7.84 5.19 6.805l.595-2.222 3.862 1.035-.595 2.221 3.863 1.035-.596 2.222-3.864-1.035Z"
          fill="#fff"
          fillOpacity={0.5}
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}
