import React, { useMemo } from 'react'

import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { IconEventInfo } from 'shared/ui/Icon/Event'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { StyledMatchActionEventComment } from './show-action-comment.styled'

export const ShowActionComment = ({ comment }) => {
  const hintAttr = useMemo(() => getHintAttrNameFormatted(comment), [comment])

  return comment ? (
    <StyledMatchActionEventComment {...hintAttr}>
      <IconEventInfo
        colorProps={{
          name: EColorsNames.Primary,
          type: EColorsTypes.CUSTOM,
          value: 9
        }}
        size={16}
      />
    </StyledMatchActionEventComment>
  ) : null
}
