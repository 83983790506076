import React, { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { IconLine } from 'shared/ui/Icon/General/IconLine'
import { IconLive } from 'shared/ui/Icon/General/IconLive'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'

import { ISportsMenuTabProps } from './SportsMenuTabs.types'

export const SportsMenuTabs: FC<ISportsMenuTabProps> = memo(
  ({ currentTabMenu, setCurrentTabMenu }) => {
    const [t] = useTranslation()

    const handleTabChange = useCallback(
      (type: ESportsMenuTabTypes) => {
        setCurrentTabMenu(type)
      },
      [setCurrentTabMenu]
    )

    const { OnboardingAttrWrapper } = useOnboardingAttr(
      EWelcomeOnboardingDataAttrs.SPORTS_MENU_TABS,
      EOnboardingType.MAIN_PAGE_WELCOME
    )

    return (
      <OnboardingAttrWrapper>
        <Tabs variant={4}>
          <TabsItem
            active={currentTabMenu === ESportsMenuTabTypes.LINE}
            handleTabChange={handleTabChange}
            icon={IconLine}
            testData={ETestData.TestSportsMenuTabLine}
            type={ESportsMenuTabTypes.LINE}
          >
            {t('line')}
          </TabsItem>
          <TabsItem
            active={currentTabMenu === ESportsMenuTabTypes.LIVE}
            handleTabChange={handleTabChange}
            icon={IconLive}
            testData={ETestData.TestSportsMenuTabLive}
            type={ESportsMenuTabTypes.LIVE}
          >
            {t('live')}
          </TabsItem>
        </Tabs>
      </OnboardingAttrWrapper>
    )
  }
)
