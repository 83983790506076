import styled, { css } from 'styled-components'
import {
  ESportsCodes,
  MainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'

import { Button } from 'shared/ui/Button'
import { StyledTableRow } from 'widgets/event-row'

import { ETableHeadVariant } from './TableBetsHead.types'

export const StyledLineTableHead = styled(StyledTableRow)<{
  variant: ETableHeadVariant
}>`
  background-color: ${({ theme }) => theme.colors.custom.primary[2]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.custom.primary[4]};
  display: flex;
  justify-content: center;
  position: sticky;
  top: -1px;
  z-index: 5;
  ${(props) => css`
    ${props.variant === ETableHeadVariant.ONE &&
    css`
      background-color: ${({ theme }) => theme.colors.custom.primary[2]};
    `};

    ${props.variant === ETableHeadVariant.TWO &&
    css`
      background-color: ${({ theme }) => theme.colors.custom.primary[65]};
    `};
  `}
`

export const StyledTableHeadColumn = styled.div<{
  align?: string
  hide?: boolean
  viewType?: MainOutcomeTypes | 'total'
  sportCode?: ESportsCodes
}>`
  ${({ viewType, sportCode, ...props }) => {
    const baseWidth = '40px'

    return css`
      align-items: center;
      color: ${props.theme.colors.custom.primary[9]};
      display: flex;
      flex-grow: 0;
      font-size: ${props.theme.fonts.size.xxs};
      font-weight: ${props.theme.fonts.weight.normal};
      justify-content: ${props.align ?? 'center'};
      line-height: 16px;
      padding: 4px 4px 4px 8px;
      visibility: ${props.hide && 'hidden'};
      width: ${baseWidth};

      ${(viewType === MainOutcomeTypes.HC1 ||
        viewType === MainOutcomeTypes.HC2 ||
        viewType === MainOutcomeTypes.HC1O ||
        viewType === MainOutcomeTypes.HC2O) &&
      css`
        width: 80px;
      `}

      ${sportCode === ESportsCodes.BASKETBALL &&
      css`
        ${viewType === 'total' &&
        css`
          width: 62px;
        `}
      `}
    `
  }}
`

export const StyledTableHeadColumnTitle = styled(StyledTableHeadColumn)`
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: flex;
  flex-grow: 1;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  gap: 4px;
  justify-content: flex-start;
  min-width: 300px;
  position: relative;
`

export const StyledTableHeadDoubleColumn = styled(StyledTableHeadColumn)`
  min-width: 80px;
`

export const StyledSportIcon = styled.span`
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
  width: 16px;
`

export const StyledLineTableHeadChevron = styled.div`
  align-items: center;
  display: flex;
  width: 70px;
`

export const StyledLineTableHeadChevronButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.custom.primary[8]};
  color: ${({ theme }) => theme.colors.default.primary[70]};
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: 16px;
  line-height: 16px;
  margin: auto 13px auto auto;
`
