import { useStorageProvider } from 'astra-core/containers/StorageProvider'

import { useInjectSaga } from 'shared/lib/@reduxjs'

import { localStorageProviderSaga } from './saga'

export const useLocalStorageProvider = () => {
  useStorageProvider()
  useInjectSaga({ key: 'localStorageProvider', saga: localStorageProviderSaga })
}
