import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'

export const useRouteRedirect = () => {
  const history = useHistory()

  const redirect = useCallback(
    ({ route, isReplace }: { route: ERoutes; isReplace?: boolean }) => {
      if (isReplace) {
        history.replace(route)
      } else {
        history.push(route)
      }
    },
    [history]
  )

  return redirect
}
