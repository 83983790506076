import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'

import * as S from './DynamicInput.styled'
import { DynamicInputProps } from './DynamicInput.types'
import { DynamicInputContext } from './DynamicInputContext'

export const DynamicInput = forwardRef(
  (
    { placeholder, onChange, inputMode, inputValue }: DynamicInputProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const spanRef = useRef<HTMLSpanElement>(null)

    const { testData } = useContext(DynamicInputContext)

    useImperativeHandle(
      ref,
      () => {
        return {
          focus() {
            inputRef.current?.focus()
          }
        }
      },
      []
    )

    const adjustWidth = useCallback(() => {
      if (spanRef.current && inputRef.current) {
        spanRef.current.textContent = inputValue || placeholder
        inputRef.current.style.width = `${spanRef.current.offsetWidth}px`
      }
    }, [placeholder, inputValue])

    useEffect(() => {
      adjustWidth()
    }, [adjustWidth])

    return (
      <S.StyledDynamicInputWrapper>
        <S.StyledHiddenSpan ref={spanRef} />
        <S.StyledDynamicInput
          data-test-id={testData}
          inputMode={inputMode}
          placeholder={placeholder}
          ref={inputRef}
          style={{ width: '4px' }}
          type="text"
          value={inputValue}
          onChange={onChange}
        />
      </S.StyledDynamicInputWrapper>
    )
  }
)
