import styled, { css } from 'styled-components'

export const StyledToastNotificationItem = styled.div`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.custom.primary[0]};
    display: flex;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
    gap: 11px;
    justify-content: space-between;
    line-height: 20px;
    width: fit-content;
  `}
`
