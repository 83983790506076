import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { LayoutTheme } from 'betweb-openapi-axios'

import { ThemeContext } from 'shared/types/theme'

import { FONTS_STYLES } from './fonts'
import { LIGHT_THEME_COLORS } from './colors-light'
import { DARK_THEME_COLORS } from './colors-dark'
import { FIXED_COLORS } from './colors-fixed'

export const useSelectDefaultTheme = () => {
  const layoutTheme = useSelector(selectUserSettingLayoutTheme)

  const defaultTheme: ThemeContext = useMemo(
    () => ({
      colors: {
        ...(layoutTheme === LayoutTheme.Light
          ? LIGHT_THEME_COLORS
          : DARK_THEME_COLORS),
        fixed: FIXED_COLORS
      },
      fonts: FONTS_STYLES,
      typeLayoutTheme: layoutTheme
    }),
    [layoutTheme]
  )

  return [defaultTheme]
}
