import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventH2H: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 3H5L8 7L5 11H2V3Z" fill="currentColor" />
      <path d="M14 13L11 13L8 9L11 5L14 5L14 13Z" fill="currentColor" />
    </svg>
  </Icon>
)
