import styled from 'styled-components'

export const StyledToastifyContent = styled.div`
  align-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: flex;
`

export const StyledToastifyContentRight = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  padding-left: 5px;
`

export const StyledToastifyContentLeft = styled.div`
  align-items: center;
  display: flex;
  padding-right: 5px;
`

export const StyledToastifyContentMain = styled.div`
  flex-grow: 1;
  width: 0;
`
