import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconChevronDown: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg fill="none" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="currentColor" />
      </svg>
    </Icon>
  )
}
