import { TStepGeneratorValue } from './stepGenerator.types'

export function* stepGenerator<TArrayOnboarding extends { length: number }>(
  onboardingList: TArrayOnboarding
): Generator<
  TStepGeneratorValue<TArrayOnboarding>,
  TStepGeneratorValue<TArrayOnboarding>
> {
  const onboardingListLength = onboardingList.length
  const onboardingItemLastIndex = onboardingList.length - 1

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= onboardingListLength; i++) {
    yield {
      item: onboardingList[i],
      indexNum: i
    }
  }

  return {
    item: onboardingList[onboardingItemLastIndex],
    indexNum: onboardingItemLastIndex
  }
}
