import styled, { css } from 'styled-components'

import { CheckboxProps } from './types'

export const StyledCheckboxWrapper = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`

export const StyledCheckbox = styled.div<CheckboxProps>`
  border-radius: 2px;
  height: 14px;
  padding: 3px 1px;
  width: 14px;
  ${({ theme, disabled, checked }) => css`
    background-color: ${checked
      ? disabled
        ? theme.colors.custom.primary[10]
        : theme.colors.default.primary[90]
      : theme.colors.custom.primary[10]};
    border: 1px solid
      ${checked
        ? theme.colors.default.primary[90]
        : theme.colors.default.primary[30]};
    color: ${disabled && checked
      ? theme.colors.default.primary[30]
      : theme.colors.default.primary[0]};

    svg {
      display: ${disabled || checked ? 'block' : 'none'};
    }
  `};
`

export const StyledCheckboxInput = styled.input`
  display: none;
`

export const StyledCheckboxLabel = styled.div`
  color: ${({ theme }) => theme.colors.default.primary[50]};
  font-size: ${({ theme }) => theme.fonts.size.s};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 14px;
  margin-left: 8px;
`
