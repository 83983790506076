export enum ESelectOrder {
  Popularity = 'popularity',
  Alphabet = 'alphabet'
}

export enum ESelectTime {
  ByDay = 'by day',
  ByTwoDays = 'byTwoDays',
  ByThreeDays = 'byThreeDays',
  ByWeek = 'byWeek'
}

export interface IStyledFilterStream {
  $isActive?: boolean
}
