import styled from 'styled-components'

import {
  StyledBasketQuickBetButtonProps,
  StyledBasketQuickBetWrapperProps
} from './BasketQuickBet.types'

export const StyledBasketQuickBetWrapper = styled.div<StyledBasketQuickBetWrapperProps>`
  border: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  border-radius: 4px;
  margin: 16px 16px 8px 16px;
  &:hover {
    border-color: ${({ theme, active }) =>
      !active && theme.colors.custom.blue[27]};
  }
`

export const StyledBasketQuickBetButton = styled.div<StyledBasketQuickBetButtonProps>`
  align-items: center;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.custom.blue[12]
      : props.theme.colors.default.primary[0]};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: inherit;
`

export const StyledQuickBetFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`

export const StyledLoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`
