import React, { FC } from 'react'
import {
  selectAccountUser,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useAuth, useCompleted } from 'hooks'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { ELayoutsList, Layout } from 'widgets/Layout'
import { ERoutes } from 'shared/types/routes'

import { AppRoutesProps, CustomRouteProps } from './AppRoute.types'
import { LoaderPointsWrapperStyled } from './AppRoute.styled'

export const AppRoutes: FC<AppRoutesProps> = ({ routes }) => {
  useAuth()

  const user = useSelector(selectAccountUser)
  const { loading } = useSelector(selectFetchUserRequest)
  const authRequestCompleted = useCompleted(!!loading)

  return (
    <>
      {routes.map((route) => {
        return (
          <CustomRoute
            authRequestCompleted={authRequestCompleted}
            isAuthorized={!!user}
            key={route.name}
            route={route}
          />
        )
      })}
    </>
  )
}

const CustomRoute: FC<CustomRouteProps> = ({
  route,
  isAuthorized,
  authRequestCompleted
}) => {
  return route.private && !isAuthorized ? (
    <Route
      render={({ location }) =>
        authRequestCompleted ? (
          <Redirect
            to={{
              pathname: ERoutes.Root,
              state: { from: location, showAuthModal: true }
            }}
          />
        ) : (
          <Layout
            pageTitle={{ text: 'profile of user' }}
            type={ELayoutsList.PROFILE}
          >
            <LoaderPointsWrapperStyled>
              <LoaderPoints />
            </LoaderPointsWrapperStyled>
          </Layout>
        )
      }
      exact={route.exact}
      path={route.path}
    />
  ) : (
    <Route {...route} />
  )
}
