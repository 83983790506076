import { CSSProperties } from 'react'

export enum ViewComponentType {
  FIRST = 'first',
  CUSTOM = 'custom',
  FULL = 'full',
  NOTIFICATION = 'notification',
  PAYMENT = 'payment'
}

export interface ModalProps {
  view?: ViewComponentType
  isOpen: boolean
  onModalClose?: () => void
  showFooter?: boolean
  styleContainer?: CSSProperties
}

export interface StyledModalContainerProps {
  view: ViewComponentType
}

export interface StyledModalProps {
  showFooter?: boolean
}
