import styled from 'styled-components'

export const StyledScoreByPeriods = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  margin-left: 16px;
`

export const StyledSection = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
`

export const StyledNamePart = styled.span`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  height: 24px;
  justify-content: center;
  margin-bottom: 8px;
  opacity: 0.6;
  text-transform: uppercase;
  width: 100%;
`

/* PERIOD SCORE */
export const StyledPeriodScoreColumns = styled.div<{ isLine?: boolean }>`
  display: flex;
`

export const StyledPeriodScoreItem = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  padding: 6px 8px;
  text-align: center;
`

export const StyledPeriodScore = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  line-height: 20px;
  opacity: 0.5;
  text-align: center;
`

export const StyledPeriodScoreColumn = styled.div<{ isLive?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 1px;
  }

  &:first-child ${StyledPeriodScoreItem} {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-child ${StyledPeriodScoreItem} {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;

    & ${StyledPeriodScore} {
      opacity: ${({ isLive }) => (isLive ? 1 : 0.5)};
    }
  }
`

export const StyledPeriodScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
// TODO: Move to own component
/* MAIN SCORE */
export const StyledMainScoreColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`

export const StyledMainScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`
export const StyledMainScoreItem = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.fixed.primary[0]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 6px 8px;
`
export const StyledMainScore = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[100]};
  line-height: 20px;
`

// TODO: Move to own component
/* GAME SCORE */
export const StyledGameScoreColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`

export const StyledGameScoreWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`

export const StyledGameScoreItem = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 6px 8px;
  text-align: center;
  width: 100%;
`
export const StyledGameScore = styled.span`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  line-height: 20px;
`
