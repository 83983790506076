import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { EBetTypes } from 'astra-core/containers/BasketProvider'
import { LayoutTheme } from 'betweb-openapi-axios'

import { HINT_ATTR_DATA } from 'constants/styles/components/hintAttr/constants'
import { Button } from 'shared/ui/Button'
import { IconArrow } from 'shared/ui/Icon/General/IconArrow'
import { Input } from 'shared/ui/Input'
import { OddModes } from 'pages/page-live-events/Line/Line.types'
import { RIGHT_CONTAINER_WIDTH } from 'widgets/right-container/right-container.styled'

import {
  StyledCleanBasketWrapperProps,
  StyledButtonProps,
  IStyledBetCardOddWrapperProps,
  IStyledBetCardLeftSideProps,
  IStyledBetCardCouponWrapperProps,
  StyledBasketInputProps,
  StyledButtonTextProps,
  IStyledCommonBlockClearAllWrapper,
  IStyledButtonWrapper
} from './Common.types'
import { BASKET_INPUT_WIDTH } from './constants'

const BASKET_INPUT_STYLE_VALUES = {
  [EBetTypes.Ordinar]: {
    height: '24px'
  },
  [EBetTypes.Express]: {
    height: '32px'
  },
  [EBetTypes.System]: {
    height: '32px'
  }
}

export const StyledBasketInput = styled(Input)<StyledBasketInputProps>`
  ${({ theme, betTypeTab, isAppliedCoupon }) => css`
    height: ${BASKET_INPUT_STYLE_VALUES[betTypeTab].height};

    ${isAppliedCoupon
      ? css`
          background-color: ${theme.colors.custom.blue[16]};

          &:disabled {
            background-color: ${theme.colors.custom.blue[16]};
            border-color: ${theme.colors.custom.blue[22]};
          }
        `
      : css`
          background-color: ${theme.colors.default.primary[0]};
        `}
  `}

  caret-color: transparent;
  text-align: right;
  width: ${BASKET_INPUT_WIDTH}px;
`

export const StyledCleanBasketWrapper = styled.div<StyledCleanBasketWrapperProps>`
  ${(props) => css`
    align-items: center;
    border-bottom: ${props.wideBorder ? '4px' : '1px'} solid
      ${props.theme.colors.custom.primary[8]};
    display: flex;
    justify-content: space-between;
    padding: ${props.wideBorder ? '16px' : '4px 16px'};
    width: 100%;
  `}
`

export const StyledButton = styled.span<StyledButtonProps>`
  ${({ theme }) => `background-color: ${theme.colors.custom.primary[59]};`}
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  min-width: 97px;
  ${({ oneRightButton }) => oneRightButton && `margin-left: auto;`}
`

export const StyledText = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[7]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    padding-right: 4px;
    text-align: center;
  `}
`

export const StyledBetCardCouponWrapper = styled.div<IStyledBetCardCouponWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 59px;
  padding: 4px 16px;
  position: relative;
  ${({ theme, isDependedHighlightedOutcome }) =>
    `
      background-color: ${
        isDependedHighlightedOutcome
          ? theme.colors.custom.yellow[8]
          : theme.colors.default.primary[0]
      };
    `}

  ${HINT_ATTR_DATA.body} {
    max-width: ${RIGHT_CONTAINER_WIDTH - 25}px;
    white-space: normal;
  }
`

export const StyledBetCardHistoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 59px;
  padding: 4px 8px;
`

export const StyledBetCardLeftSide = styled.div<IStyledBetCardLeftSideProps>`
  ${({ theme, isEventEnded, isEventPending }) =>
    css`
      ${(isEventEnded || isEventPending) &&
      css`
        background-color: ${theme.colors.default.primary[0]};
        opacity: 0.1;
      `}
    `}
`

export const StyledBetCardHistoryLeftSide = styled.div``

export const StyledBetCardRightSide = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: end;
  line-height: 16px;
  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.semiBold};
    `}
  &:last-child {
    align-items: flex-start;
    flex-direction: row;
  }
`

export const StyledBetCardEventUnavailableText = styled.div`
  border-radius: 24px;
  left: 50%;
  line-height: 20px;
  padding: 2px 8px;
  position: absolute;
  top: 29px;
  transform: translate(-50%, -50%);
  width: max-content;
  ${({ theme }) =>
    `
      background-color: ${theme.colors.default.primary[5]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
      color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledBetCardEventPending = styled.div`
  left: 250px;
  position: absolute;
  top: 42px;
  transform: translate(-50%, -50%);
`

export const StyledBetCardWrapper = styled.div`
  display: flex;
`

export const StyledBetCardOddWrapper = styled.div<IStyledBetCardOddWrapperProps>`
  display: flex;
  flex-direction: column;

  ${({ theme, isEventEnded, isEventPending }) =>
    (isEventEnded || isEventPending) &&
    `
      opacity: 0.1;
      background-color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBetCardOdd = styled.div<{ $mode?: OddModes }>`
  ${(props) => css`
    color: ${(props) => props.theme.colors.default.primary[90]};

    ${props.$mode === OddModes.Increase &&
    css`
      color: ${props.theme.colors.fixed.green[2]};
      transition: none;
    `}

    ${props.$mode === OddModes.Decrease &&
    css`
      color: ${props.theme.colors.default.red[100]};
      transition: none;
    `}
  `}
`

export const StyledBetPrevOdd = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    `
      color: ${theme.colors.default.primary[30]};
      font-weight: ${theme.fonts.weight.semiBold};
      font-size: ${theme.fonts.size.s};
    `}
`

export const StyledBetCardIcon = styled.div`
  cursor: pointer;
  margin-left: 8px;
`

export const StyledTeamName = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  line-height: 16px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledEventTypeWrapper = styled.div`
  display: flex;
  gap: 3px;
`

export const StyledEventType = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.fixed.primary[50]};
    `};
`

export const StyledPossibleWinningSumWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
`

export const StyledPossibleWinningSumText = styled.span`
  line-height: 16px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledPossibleWinningSum = styled.span`
  display: inline;
  line-height: 16px;
  margin-right: 9px;
  overflow: hidden;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledButtonWrapper = styled.button<IStyledButtonWrapper>`
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: auto;

  ${({ isAddedExtraSpace }) => css`
    ${isAddedExtraSpace &&
    css`
      margin-top: 8px;
    `}
  `}

  padding: 6px 0;
  width: 248px;
  ${({ theme, isAppliedCoupon }) => css`
    ${isAppliedCoupon
      ? css`
          background-color: ${theme.colors.custom.blue[25]};
        `
      : css`
          background-color: ${theme.colors.default.primary[90]};
        `}

    &:disabled {
      background-color: ${theme.colors.custom.primary[24]};
    }
  `}
`

export const StyledBasketButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 53px;
`

export const StyledButtonText = styled.span<StyledButtonTextProps>`
  line-height: 20px;

  ${({ disabled, theme, isAppliedCoupon }) => css`
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};

    ${isAppliedCoupon
      ? css`
          color: ${disabled
            ? theme.colors.custom.primary[25]
            : theme.colors.custom.primary[43]};
        `
      : css`
          color: ${disabled
            ? theme.colors.custom.primary[25]
            : theme.colors.custom.primary[20]};
        `}
  `}
`

export const StyledTotalRateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

export const StyledTotalRateBonusWrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;

  ${({ theme }) =>
    `
      background-color: ${theme.colors.default.yellow[31]};
    `}
`

export const StyledTotalRateBonusSum = styled.div`
  line-height: 16px;
  padding: 1px 3px;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.xxxxs};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.custom.primary[52]};
    `}
`

export const StyledTotalRate = styled.div`
  height: 16px;
  line-height: 16px;
  margin-left: 16px;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.semiBold};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledBetCardElementWrapper = styled.div`
  position: relative;
`

export const StyledBetCardElementBorder = styled.div`
  ${({ theme }) =>
    `border-bottom: 1px solid ${theme.colors.custom.primary[4]};`}
`

export const StyledHoverTextWrapper = styled.div`
  height: 40px;
  padding: 4px 8px;
  position: absolute;
  right: 15px;
  top: 36px;
  width: 248px;
  ${({ theme }) =>
    `
      background-color: ${theme.colors.default.primary[70]};
    `}
`

export const StyledHoverText = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.xs};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[0]};
    `}
`

export const StyledBasketErrorWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
  margin-left: 16px;
  margin-top: 4px;
  padding: 4px 8px 4px 5px;
  width: 248px;
  ${({ theme }) =>
    `
      background-color: ${theme.colors.custom.yellow[5]};
    `}
`

export const StyledIconWrapper = styled.div``

export const StyledLink = styled(Link)`
  text-decoration: underline;
  ${({ theme }) =>
    `
      color: ${theme.colors.default.primary[80]};
    `}
`

export const StyledBasketErrorAction = styled.span`
  cursor: pointer;
  text-decoration: underline;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[80]};
    `}
`

export const StyledRemoveText = styled.span`
  cursor: pointer;
  ${({ theme }) =>
    `
      color: ${theme.colors.default.primary[80]};
    `}

  &:hover, &:active {
    text-decoration: underline;
  }
`

export const StyledBasketErrorText = styled.div`
  line-height: 16px;
  margin-left: 5px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[70]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledInputMaxBet = styled.div`
  bottom: 0px;
  left: 0px;
  line-height: 16px;
  position: absolute;
  z-index: 1111;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.xxs};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.custom.primary[26]};
    `}
`

export const StyledMaxBetHoverTextWrapper = styled.div`
  border-radius: 4px;
  bottom: -13px;
  left: 0;
  margin-left: 25px;
  padding: 3px 5px;
  position: absolute;
  z-index: 5;
  ${({ theme }) =>
    `
      background-color: ${theme.colors.default.primary[70]};
    `}
`

export const StyledMaxBetHoverTriangle = styled.div`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  height: 0;
  left: 5px;
  position: absolute;
  top: -5px;
  width: 0;
  ${({ theme }) =>
    `
      border-bottom: 5px solid ${theme.colors.default.primary[70]};
    `}
`

export const StyledMaxBetHoverText = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[10]};
    `}
`

export const StyledLoaderEventPendingWrapper = styled.div`
  position: relative;
`

export const StyledLoaderEventPendingContainer = styled.div`
  align-items: center;
  border-radius: 24px;
  display: flex;

  height: 16px;
  justify-content: center;
  width: 29px;

  ${({ theme }) =>
    `
      background-color: ${theme.colors.default.primary[5]}
    `}
`

export const StyledLoaderEventPending = styled.div`
  -webkit-animation: loaderCircle 0.9s 0.35s ease alternate infinite;
  animation: loaderCircle 0.9s 0.1s ease alternate infinite;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin: -20px auto 0;
  position: absolute;
  top: 70%;
  width: 4px;

  &:nth-child(1) {
    animation-delay: 0.2s;
    left: 23%;
  }
  &:nth-child(2) {
    animation-delay: 0.4s;
    left: calc(23% + 6px);
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
    left: calc(23% + 12px);
  }

  @keyframes loaderCircle {
    0% {
      box-shadow: 0 15px 0 -15px transparent;
      opacity: 0;
    }
    50% {
      opacity: 0.5;
      box-shadow: 0 15px 0 -15px transparent;
    }
    100% {
      box-shadow: 0 15px 0 ${({ theme }) => theme.colors.default.primary[80]};
      opacity: 1;
    }
  }
`

export const StyledIconDependentOutcomes = styled.div`
  cursor: pointer;
`

export const StyledWinSum = styled.div<{
  isAppliedCoupon: boolean
}>`
  align-items: center;
  border-radius: 2px;
  display: flex;
  margin-left: 8px;
  padding-right: 3px;
  white-space: nowrap;

  ${({ theme, isAppliedCoupon }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};

      ${isAppliedCoupon
        ? css`
            background-color: ${theme.colors.custom.blue[20]};
            color: ${theme.colors.custom.blue[17]};
          `
        : css`
            background-color: ${theme.colors.default.green[11]};
          `}
    `}
`

export const StyledIconArrow = styled(IconArrow)``

export const StyledIconExtraHintWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 5px;
`

export const StyledHoverMaxBetTextWrapper = styled.div`
  border-radius: 4px;
  left: 27px;
  padding: 2px 4px;
  position: absolute;
  top: 30px;
  z-index: 2;

  ${({ theme }) => css`
    background-color: ${theme.colors.custom.primary[41]};

    ${theme.typeLayoutTheme === LayoutTheme.Dark &&
    css`
      box-shadow: 0px 2px 4px ${theme.colors.default.shadow[0]};
    `}
  `}
`

export const StyledHoverMaxBetText = styled.div`
  line-height: 16px;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.custom.primary[51]};
    `}
`

export const StyledHoverMaxBetTextTriangle = styled.div`
  bottom: 19px;
  height: 0;
  left: 3px;
  position: absolute;
  width: 0;
  z-index: 100;

  ${({ theme }) =>
    `
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 6px solid ${theme.colors.custom.primary[41]};
    `}
`

export const StyledBasketButtonSingleBetSize = styled(Button)`
  height: 24px;
  line-height: 16px;
  padding: 4px 6px;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `};
`

export const StyledCommonBlockClearAllWrapper = styled.div<IStyledCommonBlockClearAllWrapper>`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  padding: 8px 16px;

  ${({ isShownSingleBetPanel }) => css`
    ${isShownSingleBetPanel &&
    css`
      padding: 8px 16px 0px 16px;
    `}
  `}
`

export const StyledBasketButtonClearAll = styled(Button)`
  height: 24px;
  line-height: 16px;
  margin-right: 6px;
  padding: 4px 6px;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledCommonBlockClearAllButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const SingleBetAmountWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
`

export const StyledInfoIconWrapper = styled.div`
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`

export const StyledTime = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.blue[7]};
    font-size: ${props.theme.fonts.size.xs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
  `}
`
