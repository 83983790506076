import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballCorner: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 17 16"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M2.375 3.34375C2.375 3.1539 2.52424 3 2.70833 3H4.04167C4.22576 3 4.375 3.1539 4.375 3.34375V7.08144C4.58818 7.0396 4.80453 7.01375 5.02197 7.0042C5.33872 6.99029 5.65794 7.01088 5.9734 7.0668C6.85547 7.22315 7.66097 7.64732 8.26984 8.27607C8.87872 8.90482 9.25836 9.7045 9.35218 10.5559C9.38573 10.8604 9.38208 11.1657 9.34258 11.4659C9.31886 11.6462 9.2822 11.8246 9.23291 12H14C14.2071 12 14.375 12.1492 14.375 12.3333V13.6667C14.375 13.8508 14.2071 14 14 14H2.70833C2.56678 14 2.44584 13.909 2.3975 13.7807C2.38294 13.7451 2.375 13.7067 2.375 13.6667V3.34375ZM7.02672 12L6.99694 11.9831C7.22525 11.6164 7.32376 11.1884 7.27701 10.7641C7.23023 10.3396 7.04094 9.94087 6.73734 9.62736C6.43375 9.31386 6.03212 9.10236 5.59231 9.0244C5.18085 8.95147 4.7566 8.99906 4.375 9.15988V12H7.02672Z"
          fill="white"
          fillOpacity="0.5"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}
