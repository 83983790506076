import styled from 'styled-components'

export const StyledMainScoreWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  gap: 2px;
  line-height: 16px;
`

export const StyledMainScore = styled.div`
  color: ${(props) => props.theme.colors.custom.blue[9]};
`

export const StyledScoreDivider = styled.div`
  background-color: ${(p) => p.theme.colors.custom.blue[12]};
  height: 10px;
  width: 1px;
`
