import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

type UseHoverCaptureParamsType = {
  onHoverCaptured: () => void
  isHoverAvailable: boolean
  captureDuration: number
}

type UseHoverCaptureReturnType = {
  handleMouseEnter: MouseEventHandler
  handleMouseLeave: MouseEventHandler
}

/*
  Hook to handle hover capture:
  an event when the pointer is
  positioned above an element
  during the given duration.
 */
export function useHoverCapture({
  onHoverCaptured,
  isHoverAvailable,
  captureDuration
}: UseHoverCaptureParamsType): UseHoverCaptureReturnType {
  const durationTimeout = useRef<NodeJS.Timeout | null>(null)
  const [hoverCaptured, setHoverCaptured] = useState(false)

  const clearDurationTimeout = useCallback(() => {
    if (durationTimeout.current) {
      clearTimeout(durationTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (hoverCaptured) {
      setHoverCaptured(false) // reset hoverCaptured
      clearDurationTimeout() // clear timeout
      if (isHoverAvailable) {
        onHoverCaptured()
      }
    }
  }, [hoverCaptured, clearDurationTimeout, isHoverAvailable, onHoverCaptured])

  const handleMouseEnter = useCallback(() => {
    if (isHoverAvailable) {
      durationTimeout.current = setTimeout(
        () => setHoverCaptured(true),
        captureDuration
      )
    }
  }, [isHoverAvailable, captureDuration])

  const handleMouseLeave = useCallback(() => {
    /*
    Clear timeout on mouse leave
     */
    clearDurationTimeout()
  }, [clearDurationTimeout])

  useEffect(() => {
    /*
    Clear timeout, if isHoverAvailable = false
     */
    if (!isHoverAvailable) {
      clearDurationTimeout()
    }
  }, [isHoverAvailable, clearDurationTimeout])

  useEffect(() => {
    /*
    Clear timeout on unmount
     */
    return () => {
      clearDurationTimeout()
    }
  }, [clearDurationTimeout])

  return {
    handleMouseEnter,
    handleMouseLeave
  }
}
