import React, { FC } from 'react'

import { useFetchSportsCounters } from './hooks/useFetchSportsCounters'
import { useCurrentTabMenu } from './hooks/useCurrentTabMenu'
import { SportsMenuList, SportsMenuTabs } from './components'
import { SportsMenuProps } from './sports-menu.types'
import {
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './sports-menu.styled'

export const SportsMenu: FC<SportsMenuProps> = () => {
  const [currentTabMenu, setCurrentTabMenu] = useCurrentTabMenu()

  useFetchSportsCounters(currentTabMenu)

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <SportsMenuTabs
            currentTabMenu={currentTabMenu}
            setCurrentTabMenu={setCurrentTabMenu}
          />
        </StyledTabsWrapper>

        <SportsMenuList currentTabMenu={currentTabMenu} />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
