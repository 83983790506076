import styled from 'styled-components'

export const StyledEventStatisticBlockLiveTime = styled.div`
  align-items: center;
  background-color: ${(p) => p.theme.colors.custom.blue[5]};
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 16px;
  padding: 0 4px;
`
