import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isBrowser } from 'astra-core'

import { getOnboardingIsDisplay } from 'containers/LayoutContainer/selectors'

import { TOnboardingMaskProps } from './components/OnboardingMask/OnboardingMask.types'
import { getFormattedOnboardingAttr } from './utils'
import { IGetFormattedOnboardingAttr } from './Onboarding.types'
import { SCROLL_OFFSET } from './constants'

function getScrollParent(node: HTMLElement | null) {
  if (node === null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentElement)
  }
}

export const useGetOnboardingNodeItemClientRect = () => {
  return useCallback(
    (
      attrData?: IGetFormattedOnboardingAttr,
      toScroll?: boolean
    ): TOnboardingMaskProps[] | undefined => {
      if (isBrowser && attrData) {
        const attrs = [
          getFormattedOnboardingAttr({
            attrType: attrData.attrType,
            onboardingType: attrData.onboardingType
          }),
          attrData.additionalAttrType &&
            getFormattedOnboardingAttr({
              attrType: attrData.additionalAttrType,
              onboardingType: attrData.onboardingType
            })
        ].filter(Boolean)

        const nodes = attrs
          .map((attr) => document.querySelector<HTMLDivElement>(`*[${attr}]`))
          .filter((node): node is HTMLDivElement => node !== null)

        if (nodes.length) {
          return nodes.map((itemNode) => {
            if (toScroll) {
              const scrollParent = getScrollParent(itemNode)
              if (scrollParent) {
                const previousTop = itemNode.getBoundingClientRect().top
                if (previousTop > SCROLL_OFFSET) {
                  scrollParent.scrollTo({
                    top: previousTop - SCROLL_OFFSET
                  })
                }
              }
            }
            const { left, top, width, height } =
              itemNode.getBoundingClientRect()
            return {
              leftTopX: left,
              leftTopY: top,
              width,
              height
            }
          })
        }
      }

      return undefined
    },
    []
  )
}

export const useOnboardingAttr = (
  attrTypeOrObj:
    | IGetFormattedOnboardingAttr['attrType']
    | IGetFormattedOnboardingAttr,
  onboardingType: IGetFormattedOnboardingAttr['onboardingType']
) => {
  let attrType: IGetFormattedOnboardingAttr['attrType']

  if (typeof attrTypeOrObj === 'object') {
    attrType = attrTypeOrObj.attrType || attrTypeOrObj.additionalAttrType
    onboardingType = attrTypeOrObj.onboardingType
  } else {
    attrType = attrTypeOrObj
  }

  const result = getFormattedOnboardingAttr({ attrType, onboardingType })
  const attr = { [result]: true }
  const isDisplayOnboarding = useSelector(getOnboardingIsDisplay)

  return isDisplayOnboarding
    ? {
        onboardingAttr: attr,
        OnboardingAttrWrapper: ({ children, ...props }) => (
          <span {...attr} {...props}>
            {children}
          </span>
        )
      }
    : {
        OnboardingAttrWrapper: ({ children }) => children
      }
}
