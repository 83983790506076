import styled from 'styled-components'

export const StyledOnboarding = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
`
