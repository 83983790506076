import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { LayoutEventContext } from 'widgets/Layout/components/Event/context'
import { matchTrackerModel } from 'entities/match-tracker'
import { matchBroadcastModel } from 'entities/match-broadcast'

import { EventContext, IEventContext } from '../../Event.context'

import { EventBannerLargeFeatureFlag } from './components/EventBannerLargeFeatureFlag'
import { EventBannerMiniFeatureFlag } from './components/EventBannerMiniFeatureFlag/EventBannerMini'

export const EventBanner: FC = () => {
  const dispatch = useDispatch()

  const { event } = useContext(EventContext) as IEventContext

  const { isEventBannerScrolled } = useContext(LayoutEventContext) || {}

  const [isShowDropDownMenuScores, setIsShowDropDownMenuScores] =
    useState(false)

  useEffect(() => {
    dispatch(matchTrackerModel.liveMatchTrackerActions.clearCurrentMatch())
    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.clearCurrentBroadcast()
    )
  }, [dispatch, event.id])

  return isEventBannerScrolled ? (
    <EventBannerMiniFeatureFlag
      isShowDropDownMenuScores={isShowDropDownMenuScores}
      setIsShowDropDownMenuScores={setIsShowDropDownMenuScores}
    />
  ) : (
    <EventBannerLargeFeatureFlag />
  )
}
