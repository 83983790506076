import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, EButtonViews } from 'shared/ui/Button'
import { EColorsNames } from 'shared/types/theme'
import { IconSupport } from 'shared/ui/Icon/General/IconSupport'
import { StyledFooterTopHelpSupport } from 'entities/footer-of-page'

export const OpenSupport = () => {
  const [t] = useTranslation()

  const handleButtonClick = () => window.LC_API.open_chat_window()

  return (
    <Button view={EButtonViews.SECONDARY} onClick={handleButtonClick}>
      <StyledFooterTopHelpSupport>
        <IconSupport colorProps={{ name: EColorsNames.Primary, value: 100 }} />

        {t('support')}
      </StyledFooterTopHelpSupport>
    </Button>
  )
}
