import styled from 'styled-components'

import { ClearResultSearchProps } from './ClearResultSearch.types'

export const StyledClearResultSearch = styled.div<ClearResultSearchProps>`
  align-items: center;
  background-color: ${({ isMainPage }) =>
    isMainPage
      ? `${({ theme }) => theme.colors.custom.primary[10]}`
      : `${({ theme }) => theme.colors.default.primary[0]}`};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 25px 0;
`
export const StyledClearResultSearchIcon = styled.div`
  margin-bottom: 16px;
`
export const StyledClearResultSearchTextNotResults = styled.div`
  margin-bottom: 8px;
  ${({ theme }) =>
    `
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    line-height: ${theme.fonts.size.l};
  `}
`
export const StyledClearResultSearchText = styled.div`
  text-align: center;
  ${({ theme }) =>
    `
    color: ${theme.colors.custom.primary[56]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    line-height: ${theme.fonts.size.l};
  `}
`
