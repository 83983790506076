import React, { FC, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectSport } from 'astra-core/containers/CommonDataProvider'
import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'

import { STATS_SPORTS } from './constants'

export const Stats: FC<{ eventId: Event['id'] }> = (props) => {
  const { event } = useContext(EventContext) as IEventContext

  const { code: sportCode } =
    useSelector((state: RootState) =>
      selectSport(state, +event.tournament.sportId)
    ) || {}

  const SportStats = useMemo(
    () => !!sportCode && STATS_SPORTS[sportCode],
    [sportCode]
  )

  return SportStats ? <SportStats {...props} /> : null
}
