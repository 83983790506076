import { Link } from 'react-router-dom'
import { EPaymentModalTypes } from 'astra-core/containers/BalanceProvider'
import styled from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'

export const StyledCommonContainer = styled.div`
  height: 100%;
  padding: 32px 20px;
`

export const StyledCloseButton = styled.div``

export const StyledCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledCircleChecked = styled.div<{ type: EPaymentModalTypes }>`
  align-items: center;
  background: ${(props) =>
    props.type === EPaymentModalTypes.Success
      ? props.theme.colors.default.green[1]
      : props.theme.colors.default.yellow[3]};
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: 16px;
  width: 40px;
`
export const StyledContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const StyledModalContentTitle = styled.h3`
  line-height: 20px;
  padding: 4px 0;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledModalContentSubtitle = styled.h3`
  line-height: 20px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.fixed.primary[50]};
  `}
`

export const StyledContinueTitle = styled.h3`
  cursor: pointer;
  line-height: 20px;
  padding-top: 4px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[40]};
  `}
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding-top: 4px;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledIconCloset = styled(IconClose)`
  cursor: pointer;
`
