import styled from 'styled-components'

import { IconPerson } from 'shared/ui/Icon/General/IconPerson'

export const StyledIconPersonal = styled(IconPerson)`
  &:hover {
    color: ${(props) => props.theme.colors.default.primary[70]} !important;
  }

  &:active {
    color: ${(props) => props.theme.colors.default.primary[50]} !important;
  }
`
