import styled, { css } from 'styled-components'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { EColorsTypes } from 'shared/types/theme'
import { OddModes } from 'pages/page-live-events/Line/Line.types'

export const StyledOddWrapper = styled.div<{
  $mode?: OddModes
  isPlain?: boolean
  labelInButton?: boolean
}>`
  ${(props) => css`
    align-items: center;
    border: 1px solid ${props.theme.colors.custom.primary[8]};
    color: ${props.theme.colors.default.primary[80]};
    cursor: pointer;
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    gap: 2px;
    height: ${props.isPlain ? '100%' : '32px'};
    justify-content: ${props.labelInButton ? 'space-between' : 'center'};
    line-height: 16px;
    padding: 0 8px;

    width: ${props.isPlain || props.labelInButton ? '100%' : '60px'};

    ${!props.isPlain &&
    css`
      background-color: ${({ theme }) => theme.colors.default.primary[5]};
      border-radius: 4px;
    `}

    ${props.$mode === OddModes.Disabled &&
    css`
      background-color: ${props.theme.colors.custom.primary[10]};
    `}

    ${props.$mode === OddModes.Increase &&
    css`
      background-color: ${props.theme.colors.default.green[5]};
      transition: none;
      transition: 0.5s ease background-color, color;
    `}

    ${props.$mode === OddModes.Decrease &&
    css`
      background-color: ${props.theme.colors.default.red[5]};
      transition: none;
      transition: 0.5s ease background-color, color;
    `}

    ${props.$mode === OddModes.Active &&
    css`
      background-color: ${props.theme.colors.custom.primary[48]};
      color: ${props.theme.colors.custom.primary[43]};
      transition: none;
      transition: 0.5s ease background-color, color;
      ${StyledEventOdd} {
        color: ${props.theme.colors.custom.primary[43]};
      }
      ${StyledEventOddInfo} {
        background-color: ${props.theme.colors.custom.primary[47]};
        color: ${props.theme.colors.custom.primary[6]};
      }

      &:hover {
        background-color: ${props.theme.colors.custom.primary[48]};
        color: ${props.theme.colors.custom.primary[6]};
        ${StyledEventOdd} {
          color: ${props.theme.colors.custom.primary[43]};
        }
        ${StyledEventOddInfo} {
          background-color: ${props.theme.colors[EColorsTypes.CUSTOM]
            .primary[47]};
        }
      }
    `}

    &:hover {
      ${props.isPlain
        ? css`
            background-color: ${props.theme.colors.custom.primary[8]};
          `
        : css`
            background-color: ${props.theme.colors.default.primary[10]};
          `}

      ${props.$mode === OddModes.Disabled &&
      css`
        background-color: ${props.theme.colors.custom.primary[10]};
        cursor: default;
      `}

      ${props.$mode === OddModes.Increase &&
      css`
        background-color: ${props.theme.colors.fixed.green[0]};
      `}

      ${props.$mode === OddModes.Decrease &&
      css`
        background-color: ${props.theme.colors.fixed.red[1]};
      `}

      ${props.$mode === OddModes.Active &&
      css`
        background-color: ${props.theme.colors.custom.primary[48]};
      `}
    }
  `}
`

export const StyledEventOdd = styled.div<{
  $mode?: OddModes
}>`
  color: ${({ theme, $mode }) =>
    $mode !== OddModes.Active
      ? theme.colors.default.primary[90]
      : theme.colors.custom.primary[46]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  line-height: 16px;
`

export const StyledEventOddLabel = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`

export const StyledEventOddTitle = styled.span`
  color: ${(props) => props.theme.colors.fixed.primary[50]};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledEventOddInfo = styled.div<{ $mode?: OddModes }>`
  align-self: center;
  background-color: ${(props) => props.theme.colors.default.primary[5]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.default.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  padding: 4px;

  & + ${StyledEventOdd} {
    margin-left: 8px;
  }
  ${(props) => css`
    ${props.$mode === OddModes.Active &&
    css`
      background-color: ${props.theme.colors.custom.primary[45]};
      color: ${(props) => props.theme.colors.default.primary[50]};
      transition: none;
    `}
  `}
`

export const StyledTableBets = styled.div``

export const StyledTableEventBets = styled.div`
  display: grid;
`

export const StyledTableEventDate = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.default.primary[5]};
  color: ${(props) => props.theme.colors.default.primary[50]};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xs};
  line-height: ${(props) => props.theme.fonts.size.l};
  padding: 0 16px;
  position: sticky;
  top: 23px;
  z-index: 1;

  span {
    margin-right: 4px;

    &:empty {
      margin: 0;
    }
  }
`
export const StyledTableEvents = styled.div``
export const StyledTableEventsItems = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => `${!isShow ? 'none' : 'block'}`};
`

export const CoefficientTableCellButtonExpandStyled = styled(IconChevronDown)`
  height: 4px;
  margin-inline-start: auto;
  width: 8px;
`
