import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryFiltersDates,
  selectBetsHistorySelect,
  selectBetsHistorySelectedDatePeriod
} from 'astra-core/containers/BetsHistoryProvider'

import { ETestData } from 'shared/lib/testData'
import { EButtonSizes, EButtonViews } from 'shared/ui/Button'
import { DatePickerRange } from 'shared/ui/DatePicker/DatePicker'

import { BETS_HISTORY_PERIODS, CURRENT_DATE } from './constants'
import { StyledFilterButton } from './PickerPeriod.styled'

export const PickerPeriod = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const dates = useSelector(selectBetsHistoryFiltersDates)
  const selectValue = useSelector(selectBetsHistorySelect)
  const selectedDatePeriod = useSelector(selectBetsHistorySelectedDatePeriod)

  const onChangeDatePicker = useCallback(
    (dateRangeArray) =>
      dispatch(
        betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
      ),
    [dispatch]
  )

  const onChangeInputDatePicker = useCallback(
    (data) =>
      dispatch(betsHistoryProviderActions.setDatePickerRangeInputValue(data)),
    [dispatch]
  )

  const onChangeSelect = useCallback(
    (dateRangeArray, value) => {
      dispatch(
        betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
      )

      dispatch(betsHistoryProviderActions.setDatePickerSelectDatePeriod(value))
    },
    [dispatch]
  )

  const onCalendarClose = useCallback(
    (value) => dispatch(betsHistoryProviderActions.handleCalendarClose(value)),
    [dispatch]
  )

  const onClickButtonPeriod = useCallback(
    (periodName) => () =>
      dispatch(betsHistoryProviderActions.setDatePeriod(periodName)),
    [dispatch]
  )

  return (
    <>
      <DatePickerRange
        maxDate={CURRENT_DATE}
        rangeCalendarDates={dates}
        selectValue={selectValue}
        // @ts-ignore
        onCalendarClose={onCalendarClose}
        onChange={onChangeDatePicker}
        onChangeInputRange={onChangeInputDatePicker}
        onChangeSelect={onChangeSelect}
      />

      {BETS_HISTORY_PERIODS.map((betsHistoryPeriod) => (
        <StyledFilterButton
          data-test-id={`${ETestData.TestBetsHistoryFilterButton}-${betsHistoryPeriod.key}`}
          isActive={selectedDatePeriod === betsHistoryPeriod.key}
          key={betsHistoryPeriod.key}
          size={EButtonSizes.S}
          view={EButtonViews.SECONDARY}
          onClick={onClickButtonPeriod(betsHistoryPeriod.key)}
        >
          {t(betsHistoryPeriod.label)}
        </StyledFilterButton>
      ))}
    </>
  )
}
