import React, { FC } from 'react'

import { IconStar } from 'shared/ui/Icon/General/IconStar'
import { EColorsNames } from 'shared/types/theme'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconStarWithoutBackground: FC<IconProps> = ({
  isActive,
  ...props
}) =>
  isActive ? (
    <IconStar
      {...props}
      colorProps={{ name: EColorsNames.Yellow, value: 50 }}
    />
  ) : (
    <Icon {...props}>
      <svg fill="none" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0.642578L10.0572 4.81102L14.6574 5.47946L11.3287 8.72414L12.1145 13.3057L8 11.1426L3.8855 13.3057L4.6713 8.72414L1.3426 5.47946L5.94275 4.81102L8 0.642578Z"
          stroke="currentColor"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  )
