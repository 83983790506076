import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconStreamFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13 0C13.5523 0 14 0.447716 14 1V9C14 9.55229 13.5523 10 13 10L1 10C0.447715 10 0 9.55229 0 9V1C0 0.447716 0.447715 0 1 0H13ZM5 2V8L11 5L5 2Z"
        fill="#E03A3A"
        fillRule="evenodd"
      />
      <path
        d="M2 13C2 12.4477 2.44772 12 3 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H3C2.44772 14 2 13.5523 2 13Z"
        fill="#E03A3A"
      />
    </svg>
  </Icon>
)
