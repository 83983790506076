import {
  EOnboardingType,
  IGetFormattedOnboardingAttr,
  TOnboardingDataAttrsEnums
} from './Onboarding.types'

export const getFormattedOnboardingAttr = ({
  attrType,
  onboardingType
}: IGetFormattedOnboardingAttr): `data-${EOnboardingType}-${TOnboardingDataAttrsEnums}` => {
  return `data-${onboardingType}-${attrType}`
}
