import { LayoutTheme } from 'betweb-openapi-axios'

export const getSettingIcon = (
  folderName: `${string}.${string}`,
  theme: LayoutTheme
) => {
  const foldersThemesMap = {
    [LayoutTheme.Dark]: 'darkTheme',
    [LayoutTheme.Light]: 'lightTheme'
  }

  return require(`assets/images/settings/${
    foldersThemesMap[theme] || foldersThemesMap[LayoutTheme.Light]
  }/${folderName}`)
}
