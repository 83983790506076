import React, { FC } from 'react'

import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'

import { ButtonBackProps } from './ButtonBack.types'
import { StyledButtonBack } from './ButtonBack.styled'

export const ButtonBack: FC<ButtonBackProps> = ({
  children,
  link,
  testData,
  onButtonClickAction
}) => {
  if (!link) {
    return <> {children} </>
  }

  return (
    <StyledButtonBack
      data-test-id={testData}
      to={link}
      onClick={onButtonClickAction}
    >
      {children && (
        <>
          <IconChevronLeft size={16} />

          {children}
        </>
      )}
    </StyledButtonBack>
  )
}
