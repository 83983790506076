import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBalance: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM7.81129 2.90909C7.81129 2.40701 7.41936 2 6.93589 2C6.45242 2 6.06049 2.40701 6.06049 2.90909V2.98794C5.63989 3.06907 5.25604 3.21513 4.93082 3.43694C4.36122 3.82541 4.00022 4.43534 4.00022 5.17044C4.00022 6.06629 4.50678 6.67931 5.02973 7.06315C5.53836 7.43649 6.16303 7.67436 6.67638 7.84001C7.26749 8.03076 7.71009 8.23017 7.98959 8.4348C8.26074 8.63331 8.24956 8.73472 8.2492 8.738C8.2492 8.78717 8.23275 8.8837 8.10509 8.98868C7.96582 9.10321 7.63303 9.27273 6.93615 9.27273C6.3489 9.27273 5.90009 9.19778 5.60873 9.12776C5.46296 9.09273 5.35651 9.0589 5.29186 9.03626C5.25954 9.02494 5.2377 9.01643 5.22665 9.01198L5.21881 9.00875C4.77749 8.81335 4.26608 9.02347 4.07351 9.48094C3.87986 9.94098 4.08199 10.4769 4.52498 10.6781L4.7324 10.7591C4.84804 10.7996 5.00946 10.85 5.21398 10.8991C5.44335 10.9543 5.72682 11.0078 6.06049 11.0443V11.0909C6.06049 11.593 6.45242 12 6.93589 12C7.41936 12 7.81129 11.593 7.81129 11.0909V11.0168C8.36433 10.9162 8.82744 10.7139 9.19197 10.4141C9.74643 9.95814 10 9.33276 10 8.738C10 7.90451 9.50858 7.32229 8.99839 6.94877C8.49275 6.57858 7.84111 6.31193 7.19591 6.10373C6.72021 5.95022 6.31462 5.77978 6.04025 5.57839C5.7802 5.38751 5.75102 5.25941 5.75102 5.17044C5.75102 5.11222 5.76091 5.04602 5.8919 4.95669C6.05233 4.84727 6.38076 4.72727 6.93615 4.72727C7.29424 4.72727 7.72879 4.81002 8.10353 4.90692C8.2845 4.95371 8.43935 5.00057 8.54802 5.03541C8.60216 5.05277 8.64432 5.06699 8.67195 5.0765L8.70202 5.08699L8.70883 5.08942C9.16483 5.25513 9.66397 5.00585 9.82397 4.53235C9.98405 4.0586 9.744 3.53977 9.2878 3.37353C9.2878 3.37353 9.49947 3.43762 9.06456 3.29816C8.93164 3.25554 8.74505 3.1991 8.52657 3.14261C8.32104 3.08946 8.07508 3.03299 7.81129 2.9889V2.90909Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
