import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useGroupedEventsDate } from 'astra-core/containers/EventsProvider'

import { ETestData } from 'shared/lib/testData'
import { useToggleState } from 'hooks/useToggleState'
import { ERoutes } from 'shared/types/routes'
import { ETableHeadVariant, TableBetsHead } from 'widgets/event-row-head'
import { EventRow } from 'widgets/event-row'

import { TableBetsContext } from './TableBets.context'
import {
  EventsGroupDateProps,
  TableBetsEventsProps,
  TableBetsProps
} from './TableBets.types'
import {
  CoefficientTableCellButtonExpandStyled,
  StyledTableBets,
  StyledTableEventBets,
  StyledTableEventDate,
  StyledTableEvents,
  StyledTableEventsItems
} from './TableBets.styled'

export const TableBets: FC<TableBetsProps> = ({
  eventsGroups,
  headerEventsProps,
  isLive,
  isLinkHead
}) => {
  const { current: tableBetsContextValue } = useRef({
    ...(headerEventsProps && { tableEventHead: headerEventsProps }),
    isLive,
    isLinkHead
  })

  return (
    <TableBetsContext.Provider value={tableBetsContextValue}>
      <StyledTableBets data-test-id={ETestData.TestTableEventBets}>
        {eventsGroups.map((group) => (
          <TableBetsEvents eventsGroup={group} key={group.id} />
        ))}
      </StyledTableBets>
    </TableBetsContext.Provider>
  )
}

const TableBetsEvents: FC<TableBetsEventsProps> = ({ eventsGroup }) => {
  const eventGroups = useGroupedEventsDate(eventsGroup.events)
  const [isOutcomesOpened, setIsOutcomesOpened] = useState(true)

  return (
    <StyledTableEventBets
      data-test-id={`${ETestData.TestTableEventBetsGroup}-${eventsGroup?.id}`}
    >
      <TableBetsHead
        isOutcomesOpened={isOutcomesOpened}
        isShowCount={eventsGroup.events.length}
        setIsOutcomesOpened={setIsOutcomesOpened}
        tournament={eventsGroup}
        variant={ETableHeadVariant.TWO}
      />

      {!!isOutcomesOpened &&
        eventGroups.map((eventGroup) => {
          return (
            <TableBetsEventsDate
              eventGroup={eventGroup}
              key={eventGroup.date}
            />
          )
        })}
    </StyledTableEventBets>
  )
}

const TableBetsEventsDate: FC<EventsGroupDateProps> = ({ eventGroup }) => {
  const { isLive } = useContext(TableBetsContext)
  const { pathname } = useLocation()
  const [isTableEventsItems, toggleIsTableEventsItems] = useToggleState(true)
  const [t] = useTranslation()

  const checkDate = useCallback(
    (date: string) => {
      if (dayjs(date).isSame(dayjs().add(1, 'day'), 'day')) {
        return t('tomorrow')
      }
      if (dayjs(date).isSame(dayjs(), 'day')) {
        return t('today')
      }
      return ''
    },
    [t]
  )

  const isFavourite = useMemo(
    () => pathname === ERoutes.FavouriteEvents,
    [pathname]
  )

  return (
    <StyledTableEvents key={eventGroup.date}>
      {!isLive && !isFavourite && (
        <StyledTableEventDate onClick={() => toggleIsTableEventsItems()}>
          <span>{checkDate(eventGroup.date)}</span>
          {dayjs(eventGroup.date).format('DD MMMM')}
          <CoefficientTableCellButtonExpandStyled
            twist={isTableEventsItems ? 180 : 0}
          />
        </StyledTableEventDate>
      )}

      <StyledTableEventsItems isShow={isTableEventsItems}>
        {eventGroup.items.map((event) => (
          <EventRow event={event} key={event.id} />
        ))}
      </StyledTableEventsItems>
    </StyledTableEvents>
  )
}
