import React from 'react'
import { useWindowSize } from '@react-hook/window-size'
import {
  useMasonry,
  usePositioner,
  useContainerPosition,
  useScroller,
  useResizeObserver
} from 'masonic'

import { MasonryProps } from './types'

export function Masonry<T>(props: MasonryProps<T>) {
  const containerRef = React.useRef(null)
  const [windowWidth, height] = useWindowSize()
  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    height
  ])
  const { scrollTop, isScrolling } = useScroller(offset)
  const positioner = usePositioner(
    {
      width,
      columnCount: props.columnCount,
      columnGutter: props.columnGutter,
      rowGutter: props.rowGutter
    },
    /**
     * Recalculate positioning on items length update
     */
    [props.items]
  )

  const resizeObserver = useResizeObserver(positioner)

  return useMasonry({
    resizeObserver,
    positioner,
    scrollTop,
    isScrolling,
    height,
    containerRef,
    ...props,
    className: 'masonry'
  })
}
