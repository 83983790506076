import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconUnreadNotification: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="9"
      viewBox="0 0 8 9"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4.00195" fill="#F54D3D" r="4" />
    </svg>
  </Icon>
)
