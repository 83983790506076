export enum EButtonNameList {
  DEFAULT = 'default',
  PROFILE = 'profile'
}

export const BUTTON_NAME_LIST = {
  [EButtonNameList.DEFAULT]: {
    signUp: 'sign up',
    signIn: 'log in'
  },
  [EButtonNameList.PROFILE]: {
    signUp: 'register',
    signIn: 'sign in'
  }
}
