import styled, { css } from 'styled-components'

import { IconBonusCouponHeader } from 'shared/ui/Icon/General/IconBonusCouponHeader'

import { ACTIVE_LOYALTY_LENGTH_POSITION_ON_ICON } from './constants'

export const StyledIconBonusCouponButton = styled(IconBonusCouponHeader)``

export const StyledActiveBonusCouponButtonValue = styled.div<{
  activeLoyaltyLength: number
}>`
  cursor: pointer;
  line-height: 16px;
  position: absolute;
  top: 7px;

  ${({ theme, activeLoyaltyLength }) =>
    css`
      color: ${theme.colors.custom.primary[43]};
      font-size: ${theme.fonts.size.xs};
      font-weight: ${theme.fonts.weight.normal};

      ${activeLoyaltyLength >= ACTIVE_LOYALTY_LENGTH_POSITION_ON_ICON
        ? css`
            left: 18.5px;
          `
        : css`
            left: 21px;
          `}
    `}
`
