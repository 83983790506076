import { useWindowSize } from '@react-hook/window-size'
import { useCallback } from 'react'

export const DISTANCE_TO_CHANGE_VIEW = 50
export const SCROLL_WIDTH = 26
export const ARROW_GAP = 15

type TooltipSize = { tooltipW: number; tooltipH: number }

export type ArrowPositionType = 'top' | 'right' | 'bottom' | 'left'

export type TooltipOptionsType = {
  x: number
  y: number
  arrow: {
    x: number
    y: number
    position: ArrowPositionType
  }
}

export const useTooltipOptions = () => {
  const [width, height] = useWindowSize()

  const initOptions: TooltipOptionsType = {
    x: 0,
    y: 0,
    arrow: {
      x: 0,
      y: -4,
      position: 'top'
    }
  }

  const getOptions = useCallback(
    (
      { tooltipW, tooltipH }: TooltipSize,
      rect: DOMRect
    ): TooltipOptionsType => {
      const saveSpace = width - rect.x
      const isNotBottomOutbounds =
        height - (rect.y + rect.height + ARROW_GAP) > tooltipH

      if (saveSpace < tooltipW && saveSpace > DISTANCE_TO_CHANGE_VIEW) {
        return {
          x: width - tooltipW - SCROLL_WIDTH,
          y: isNotBottomOutbounds
            ? rect.y + rect.height
            : rect.y - tooltipH - 10,
          arrow: {
            position: isNotBottomOutbounds ? 'top' : 'bottom',
            x: rect.x - (width - tooltipW - SCROLL_WIDTH) + 3,
            y: isNotBottomOutbounds ? -4 : tooltipH
          }
        }
      }
      if (saveSpace < DISTANCE_TO_CHANGE_VIEW) {
        return {
          x: rect.x - tooltipW - ARROW_GAP,
          y: isNotBottomOutbounds
            ? rect.y - ARROW_GAP
            : rect.y + rect.height - tooltipH,
          arrow: {
            position: 'right',
            x: tooltipW,
            y: isNotBottomOutbounds ? ARROW_GAP : tooltipH - ARROW_GAP
          }
        }
      }
      return {
        x: rect.x,
        y: isNotBottomOutbounds ? rect.y + rect.height : rect.y - tooltipH - 10,
        arrow: {
          position: isNotBottomOutbounds ? 'top' : 'bottom',
          x: 3,
          y: isNotBottomOutbounds ? -4 : tooltipH
        }
      }
    },
    [height, width]
  )

  return { getOptions, initOptions }
}
