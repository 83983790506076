import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { currentYearConstants } from '../..'

import { IFooterOfPageProps } from './footer-of-page.types'
import {
  StyledFooterBottom,
  StyledFooterBottomLogoList,
  StyledFooterBottomMenuList,
  StyledFooterBottomYear,
  StyledFooterLogoWrapper,
  StyledFooterTop,
  StyledFooterTopSocialNetworkWrapper,
  StyledFooterWrapper
} from './footer-of-page.styled'

export const FooterOfPage: FC<IFooterOfPageProps> = memo(
  ({
    menuItems,
    logoItems,
    logoMain,
    socialNetworksItems,
    helpButtons,
    landingButton
  }) => {
    const [t] = useTranslation()
    const isLanding = useFeatureFlag(EFeatureFlags.LANDING_APP)

    return (
      <StyledFooterWrapper>
        <StyledFooterTop>
          {logoMain}

          {isLanding && landingButton}
          {helpButtons}

          <StyledFooterTopSocialNetworkWrapper>
            {socialNetworksItems}
          </StyledFooterTopSocialNetworkWrapper>
        </StyledFooterTop>

        <StyledFooterBottom>
          <StyledFooterBottomMenuList>{menuItems}</StyledFooterBottomMenuList>

          <StyledFooterLogoWrapper>
            <StyledFooterBottomYear>
              {`${t('footer app name')}, ${currentYearConstants.CURRENT_YEAR}`}
            </StyledFooterBottomYear>

            <StyledFooterBottomLogoList>{logoItems}</StyledFooterBottomLogoList>
          </StyledFooterLogoWrapper>
        </StyledFooterBottom>
      </StyledFooterWrapper>
    )
  }
)
