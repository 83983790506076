import React, { FC } from 'react'

import { LoaderWelcome as LoaderWelcomeEntity } from 'entities/loader-welcome'

import { loaderWelcomeCreatePortalModel } from '../model'

export const LoaderWelcome: FC<{
  isLoading?: boolean
}> = ({ isLoading }) => {
  const { useLoaderWelcomeCreatePortal } = loaderWelcomeCreatePortalModel

  return useLoaderWelcomeCreatePortal({
    isLoading,
    component: <LoaderWelcomeEntity />
  })
}
