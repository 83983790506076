import { useMediaQuery } from 'react-responsive'

export const queryMedia = {
  tablet: 'only screen and (max-width: 768px)',
  small: 'only screen and (max-width: 1024px)',
  smallMedium: 'only screen and (max-width: 1439px)',
  medium: 'only screen and (min-width: 1025px) and (max-width: 1439px)',
  large: 'only screen and (min-width: 1440px) and (max-width: 1920px)',
  biggest: 'only screen and (min-width: 1920px)'
}

export const customMedia = {
  tablet: `@media ${queryMedia.tablet}`,
  small: `@media ${queryMedia.small}`,
  medium: `@media ${queryMedia.smallMedium}`,
  large: `@media ${queryMedia.medium}`,
  extraLarge: `@media ${queryMedia.large}`,
  biggest: `@media ${queryMedia.biggest}`
}

// export const Medium = ({ children }) => {
//   const isMedium = useMediaQuery({ query: queryMedia.medium })
//   return isMedium ? children : null
// }

export const useIsTablet = () => {
  return useMediaQuery({
    query: queryMedia.tablet
  })
}

export const useIsSmall = () => {
  return useMediaQuery({
    query: queryMedia.small
  })
}

export const useIsSmallMedium = () => {
  return useMediaQuery({
    query: queryMedia.smallMedium
  })
}

export const useIsMedium = () => {
  return useMediaQuery({
    query: queryMedia.medium
  })
}

export const useIsLarge = () => {
  return useMediaQuery({
    query: queryMedia.large
  })
}
