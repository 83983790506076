import { useTranslation } from 'react-i18next'

import { filterRoutes } from 'shared/lib/routes'
import { ERoutes } from 'shared/types/routes'

export const useGetMenuList = () => {
  const [t] = useTranslation()
  return filterRoutes({
    routeList: [{ path: ERoutes.About, locale: 'about us' }],
    t
  })
}
