import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledButtonBack = styled(Link)`
  align-items: center;
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: flex;
  overflow: auto;
  width: fit-content;
`
