import { Config } from 'astra-core/containers/ConfigProvider'
import { getCurrentLng } from 'astra-core/utils/lang'

export const useLandingAppUrl = (config: Config) => {
  const lang = getCurrentLng()
  let landingUrl

  if (lang !== 'ru' && lang !== 'kz') {
    landingUrl = config.LANDING_APP_EN
  } else {
    landingUrl = config.LANDING_APP_RU
  }

  return landingUrl
}
