import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

export const CoefficientTableCellButtonExpandStyled = styled(Button)`
  font-weight: ${(props) => props.theme.fonts.weight.normal};

  * {
    color: ${(props) => props.theme.colors.default.primary[50]} !important;
  }
`
