import styled from 'styled-components'

export const StyledMinMaxHint = styled.div`
  line-height: 16px;
  white-space: nowrap;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.xxs};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.custom.primary[26]};
    `}
`
