import React, { ComponentProps, FC } from 'react'

import {
  ButtonProps,
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from './types'
import {
  StyledButton,
  StyledButtonAdditionalText,
  StyledIconWrap
} from './styled'

// TODO: Discard props of StyledButton and replace all type of Button to "FC<ButtonProps>"
export const Button: FC<ComponentProps<typeof StyledButton> & ButtonProps> = ({
  children,
  view = EButtonViews.PRIMARY,
  size = EButtonSizes.M,
  iconProps,
  icon: Icon = null,
  testData,
  additionalText,
  ...props
}) => {
  const iconRight = iconProps?.positionSide === EButtonIconPositionSides.Right

  return (
    <StyledButton
      data-test-id={testData}
      iconProps={iconProps}
      size={size}
      view={view}
      {...props}
    >
      {!!Icon && !iconRight && <Icon {...iconProps} />}

      {!!children && <>{children}</>}

      {!!additionalText && (
        <StyledButtonAdditionalText icon={Icon}>
          {additionalText}
        </StyledButtonAdditionalText>
      )}

      {!!Icon && iconRight && (
        <StyledIconWrap>
          <Icon {...iconProps} />
        </StyledIconWrap>
      )}
    </StyledButton>
  )
}
