import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballShot: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 17 16"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.44984 6.51595C6.12097 8.46344 5.45441 9.4403 7.95761 12.9563C8.97377 14.3836 8.08115 14.9234 6.26504 13.1638C5.122 12.0564 4.05134 11.4054 3.22169 10.9009C2.58105 10.5114 2.08412 10.2093 1.80856 9.8518C1.40244 9.32499 2.26611 8.50998 3.10605 7.71737C3.57408 7.27571 4.03474 6.841 4.26423 6.46699C4.90556 5.42177 5.62055 3.84586 5.84026 2.25377C6.10686 0.321967 9.9372 2.79971 8.14734 5.46188C7.90437 5.84982 7.66817 6.19598 7.44984 6.51595Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M11.4999 14C13.1568 14 14.4999 12.6569 14.4999 11C14.4999 9.34319 13.1568 8.00005 11.4999 8.00005C9.84309 8.00005 8.49995 9.34319 8.49995 11C8.49995 12.6569 9.84309 14 11.4999 14Z"
          fill="white"
          fillOpacity="0.5"
        />
      </svg>
    </Icon>
  )
}
