import React from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { EButtonViews } from 'shared/ui/Button'

import { StyledButton } from '../../Toastify.styled'

export const ToastifyCloseButton = ({ closeToast }) => {
  const [t] = useTranslation()

  return (
    <StyledButton
      data-test-id={ETestData.TestToastifyCancelButton}
      view={EButtonViews.NONE}
      onClick={closeToast}
    >
      {t('сancel')}
    </StyledButton>
  )
}
