import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarContainer } from 'react-datepicker'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import find from 'lodash/find'
import { DATE_PERIOD_BY_TITLE, EDatePeriods } from 'astra-core'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'
import { useDispatch } from 'react-redux'

import { CALENDAR_DATA_FORMAT } from 'shared/ui/DatePicker'

import { ICustomContainerRange } from './CustomContainerRange.types'
import { PERIOD_SECTION_OPTIONS } from './constants'
import {
  StyledContainerBody,
  StyledContainerHeader,
  InputRange,
  StyledWrapper,
  StyledDashWrapper,
  SelectRange
} from './CustomContainerRange.styled'

export const CustomContainerRange: FC<ICustomContainerRange> = memo(
  ({
    children,
    className,
    onChangeInputRange,
    rangeCalendarDates,
    onChangeSelect,
    selectValue
  }) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const rangeDates = useMemo(() => {
      const { startDate, endDate } = rangeCalendarDates || {}

      const formatDate = (value) =>
        value ? dayjs(value).format(CALENDAR_DATA_FORMAT) : ''

      return {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      }
    }, [rangeCalendarDates])

    const selectHandleChange = useCallback(
      (data) => {
        const { value } = data || {}

        if (value) onChangeSelect?.(DATE_PERIOD_BY_TITLE[value], value)
      },
      [onChangeSelect]
    )

    const selectOptions = useMemo(() => PERIOD_SECTION_OPTIONS(t), [t])

    const selectPropValue = useMemo(
      () =>
        selectValue !== EDatePeriods.Default
          ? {
              value: find(selectOptions, ['value', selectValue])
            }
          : {},
      [selectOptions, selectValue]
    )

    const inputHandleChange = useCallback(
      (rangeName) =>
        ({ target: { value } }) => {
          onChangeInputRange?.({ [rangeName]: value })
          dispatch(betsHistoryProviderActions.resetSelectDatePeriod())
        },
      [onChangeInputRange, dispatch]
    )

    return (
      <CalendarContainer className={className}>
        <StyledContainerHeader>
          <StyledWrapper>
            <InputRange
              defaultValue={rangeDates.startDate}
              type="date"
              onChange={inputHandleChange('startDate')}
            />
            <StyledDashWrapper />
            <InputRange
              defaultValue={rangeDates.endDate}
              type="date"
              onChange={inputHandleChange('endDate')}
            />
          </StyledWrapper>

          <StyledWrapper>
            <SelectRange
              className="selectRange"
              options={selectOptions}
              placeholder={t('per period')}
              onChange={selectHandleChange}
              {...selectPropValue}
            />
          </StyledWrapper>
        </StyledContainerHeader>

        <StyledContainerBody>{children}</StyledContainerBody>
      </CalendarContainer>
    )
  }
)
