import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconExit: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H8C8.55228 0 9 0.447715 9 1V5.5L5.4 5.5C4.01929 5.5 2.9 6.61929 2.9 8C2.9 9.38071 4.01929 10.5 5.4 10.5L9 10.5V15C9 15.5523 8.55228 16 8 16H1C0.447715 16 0 15.5523 0 15V14V2V1ZM12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289C10.9024 4.68342 10.9024 5.31658 11.2929 5.70711L12.5858 7H6C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H12.5858L11.2929 10.2929C10.9024 10.6834 10.9024 11.3166 11.2929 11.7071C11.6834 12.0976 12.3166 12.0976 12.7071 11.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L12.7071 4.29289Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
