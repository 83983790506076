import React, { FC } from 'react'
import { findFlagUrlByIso2Code, findFlagUrlByIso3Code } from 'country-flags-svg'
import { getFlagCode } from 'astra-core/utils'

import { StyledFlagImg } from './styled'
import { ICountryCodes } from './types'

export const Flag: FC<ICountryCodes> = ({ country = 'US' }) => {
  // TODO: Must be refactored
  const flagSrc =
    findFlagUrlByIso2Code(getFlagCode(country)) ||
    findFlagUrlByIso3Code(getFlagCode(country))

  return <StyledFlagImg alt={country} src={flagSrc} />
}
