import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSuccess: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#40BF40" height="32" rx="16" width="32" />
      <path
        clipRule="evenodd"
        d="M23.8167 11.2779C24.2156 11.6599 24.2292 12.2929 23.8471 12.6917L16.184 20.6917C15.9954 20.8887 15.7345 21 15.4618 21C15.1892 21 14.9283 20.8887 14.7397 20.6917L9.40286 15.1203C9.02082 14.7215 9.03443 14.0885 9.43326 13.7064C9.83209 13.3244 10.4651 13.338 10.8471 13.7368L15.4618 18.5544L22.4029 11.3083C22.7849 10.9094 23.4179 10.8958 23.8167 11.2779Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
