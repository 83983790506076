import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'

import { Modal, ViewComponentType } from 'shared/ui/modal'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { Button, EButtonViews } from 'shared/ui/Button'
import { useHandleMakeQuickBet } from 'features/event'

import { BasketQuickBetSubmitButton } from '../right-container/Basket/BasketQuickBet/components/BasketQuickBetSubmitButton/BasketQuickBetSubmitButton'

import {
  StyledButtonWrapper,
  StyledCoefficient,
  StyledCoefficientChangedModalDescription,
  StyledCoefficientChangedModalTitle,
  StyledCoefficientChangedModalTitleWrapper,
  StyledCoefficientChangedModalWrapper,
  StyledCoefficientText,
  StyledCoefficientTitle,
  StyledCoefficientWrapper
} from './coefficient-changed-modal.styled'
import { CoefficientChangedModalContentProps } from './coefficient-changed-modal.types'

const CoefficientChangedModalContent: FC<
  CoefficientChangedModalContentProps
> = ({ outcome, onClickClose }) => {
  const { t } = useTranslation()
  const { basketOutcomeName, competitorsNames, currentOdd } = useOutcomeInfo(
    outcome.outcomeCompositeId
  )

  const { handleMakeQuickBet, isLoading } = useHandleMakeQuickBet()

  const handleSubmit = () => {
    handleMakeQuickBet({ ...outcome, coefficient: currentOdd })
  }

  return (
    <>
      <StyledCoefficientWrapper>
        <StyledCoefficient>
          <StyledCoefficientTitle>{t('was')}</StyledCoefficientTitle>
          <StyledCoefficientText>{`${basketOutcomeName} ${outcome.coefficient}`}</StyledCoefficientText>
        </StyledCoefficient>
        <StyledCoefficient>
          <StyledCoefficientTitle>{t('became')}</StyledCoefficientTitle>
          <StyledCoefficientText>{`${basketOutcomeName} ${currentOdd}`}</StyledCoefficientText>
        </StyledCoefficient>
      </StyledCoefficientWrapper>
      <StyledCoefficientChangedModalDescription>
        {`${t('you can make bet')} "${competitorsNames}"`}
      </StyledCoefficientChangedModalDescription>
      <StyledButtonWrapper>
        <BasketQuickBetSubmitButton
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          title={t('make a bet')}
        />
        <Button view={EButtonViews.SECONDARY} onClick={onClickClose}>
          {t('сancel')}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}

export const CoefficientChangedModal: FC = () => {
  const {
    outcome,
    coefficientChangedModalVisible,
    setCoefficientChangedModalVisible
  } = useContext(BasketQuickBetContext)

  const onClickClose = () => {
    setCoefficientChangedModalVisible(false)
  }

  const { t } = useTranslation()

  return (
    <Modal
      isOpen={coefficientChangedModalVisible}
      view={ViewComponentType.FIRST}
    >
      <StyledCoefficientChangedModalWrapper>
        <StyledCoefficientChangedModalTitleWrapper>
          <StyledCoefficientChangedModalTitle>
            {t('coefficient changed')}
          </StyledCoefficientChangedModalTitle>
          <IconClose
            colorProps={{ name: EColorsNames.Primary, value: 30 }}
            size={10}
            onClick={onClickClose}
          />
        </StyledCoefficientChangedModalTitleWrapper>
        {outcome && (
          <CoefficientChangedModalContent
            outcome={outcome}
            onClickClose={onClickClose}
          />
        )}
      </StyledCoefficientChangedModalWrapper>
    </Modal>
  )
}
