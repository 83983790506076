import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { columnGapPrefixes } from 'shared/lib/styled'

export const StyledComponentOfUnauthorized = styled.div<{ profile }>`
  align-items: center;
  display: flex;

  ${(props) =>
    props.profile
      ? css`
          flex-direction: column;
          gap: 4px;
          width: 100%;
        `
      : css`
          ${columnGapPrefixes('8px')}
        `}
`

export const StyledButton = styled(Button)<{ profile }>`
  background: ${(props) => props.theme.colors.custom.primary[4]};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding: 4px 8px;
  ${(props) =>
    props.profile
      ? css`
          background: ${(props) => props.theme.colors.custom.primary[20]};
          border-radius: 2px;

          color: ${(props) => props.theme.colors.custom.primary[80]};
          justify-content: center;
          width: 100%;
        `
      : css`
          background: ${(props) => props.theme.colors.custom.primary[4]};

          color: ${(props) => props.theme.colors.default.primary[90]};
        `}

  &:hover {
    background: ${(props) => props.theme.colors.default.primary[10]};
  }

  &:active {
    background: ${(props) => props.theme.colors.default.primary[15]};
  }
`

export const StyledSignUp = styled(StyledButton)<{ profile }>`
  background: linear-gradient(180deg, #6c7 0%, #40bf55 100%);
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  ${(props) =>
    props.profile &&
    css`
      border-radius: 2px;
      justify-content: center;
      width: 100%;
    `}

  &:hover {
    background: linear-gradient(180deg, #40bf55 0%, #394 100%);
  }

  &:active {
    background: linear-gradient(180deg, #394 0%, #267333 100%);
  }
`
