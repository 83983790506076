import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconArrow: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 4.97461L12 7.97461M12 7.97461L9.5 10.9746M12 7.97461L4 7.97461"
        stroke="currentColor"
      />
    </svg>
  </Icon>
)
