import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBin: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M7 1C6.44772 1 6 1.44772 6 2V3H4C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5H12C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3H10V2C10 1.44772 9.55228 1 9 1H7ZM4 7H12V13C12 14.1046 11.1046 15 10 15H6C4.89543 15 4 14.1046 4 13V7Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
