import styled, { css } from 'styled-components'

import { HINT_ATTR_DATA } from 'constants/styles/components/hintAttr/constants'

export const StyledBasketSuitableBonusCouponsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 16px;
`

export const StyledBasketSuitableBonusCouponWrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  padding: 8px 8px 8px 9px;

  ${HINT_ATTR_DATA.body} {
    margin-bottom: 45px;
    margin-left: -100px;
    white-space: normal;
  }

  ${HINT_ATTR_DATA.arrow} {
    display: none;
  }

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.blue[16]};
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledBasketSuitableBonusCouponTitleWrapper = styled.div`
  display: flex;
  gap: 5px;
`

export const StyledBasketSuitableBonusCouponTitle = styled.p`
  ${({ theme }) =>
    css`
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledConditions = styled.div`
  ${({ theme }) =>
    css`
      &${HINT_ATTR_DATA.body} {
        background-color: ${theme.colors.custom.primary[41]};
        color: ${theme.colors.custom.primary[77]};
        height: fit-content;
        left: -162px;
        margin-bottom: 51px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        right: -8px;
        text-align: left;
        white-space: normal;
        width: 220px;
      }
    `}
`
