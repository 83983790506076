import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconHeadMic: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6.35294C13 3.39659 10.7617 1 8.00052 1C5.30286 1 3.10071 2.72595 3.00104 5.58824C2.9987 5.65559 3.00104 8.64706 3.00104 8.64706C3.00104 11.6034 5.23938 14 8.00052 14"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8L12 6C12 4.89543 12.8954 4 14 4C15.1046 4 16 4.89543 16 6L16 8Z"
        fill="currentColor"
      />
      <path
        d="M4 8C4 9.10457 3.10457 10 2 10C0.895429 10 -3.98556e-07 9.10457 -3.01992e-07 8L1.54178e-06 6C1.63835e-06 4.89543 0.895433 4 2 4C3.10457 4 4 4.89543 4 6L4 8Z"
        fill="currentColor"
      />
      <path
        d="M8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
