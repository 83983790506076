import styled, { css } from 'styled-components'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { StyledIcon } from 'shared/ui/Icon'
import { columnGapPrefixes } from 'shared/lib/styled'

import { SPORT_CERTAIN_DATA } from './constants'

export const StyledBannerScore = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-size: 32px;
`

export const StyledBannerComment = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[50]};
    font-size: ${props.theme.fonts.size.xs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    text-align: center;
  `}
`

export const StyledBannerStats = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledBannerStatsContent = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 8px;
  display: inline-flex;
  grid-column-gap: 1px;
  grid-template-columns: repeat(auto-fill, 48px);
  margin: 0 auto;
  padding: 8px;
`
export const StyledBannerStatsItem = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1px;
  padding: 8px;
  position: relative;
  ${StyledIcon} {
    color: ${({ theme }) => theme.colors.default.primary[30]};
  }

  &::after {
    border: 1px solid ${({ theme }) => theme.colors.default.primary[5]};
    bottom: 0;
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`

export const StyledBannerStatsItemText = styled.div`
  color: ${({ theme }) => theme.colors.default.primary[90]};
  line-height: 16px;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
`

export const StyledBannerCompetitors = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  width: 100%;
  ${() => `
    ${StyledBannerCompetitor}:first-of-type {
      text-align: right;
    }
  
  `}
`

export const StyledBannerCompetitorWrapper = styled.div<{ reverse?: boolean }>`
  display: flex;
  gap: 4px;
  justify-content: ${(p) => (p.reverse ? 'flex-start' : 'flex-end')};
  line-height: 1;
`

export const StyledBannerCompetitor = styled.div.attrs(
  (props: { isLongName?: boolean; sportCode: ESportsCodes }) => props
)`
  font-size: ${(props) => props.theme.fonts.size.l};
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #fff 91.67%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
`

export const CurrentServerFlag = styled.div<{ $isActive?: boolean }>`
  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.fixed.primary[0] : 'transparent'};
  border-radius: 50%;
  height: 8px;
  left: -20px;
  position: absolute;
  width: 8px;
`

export const StyledBannerContent = styled.div`
  grid-row-gap: 12px;
  justify-content: center;
`
export const StyledBannerContentHead = styled.div`
  display: flex;
  justify-content: center;
  ${columnGapPrefixes('8px')}
`

export const StyledEventBannerWrapper = styled.div<{ sportCode: ESportsCodes }>`
  background: ${(p) =>
    p.sportCode
      ? (SPORT_CERTAIN_DATA[p.sportCode] || SPORT_CERTAIN_DATA.default)
          .backgroundColor
      : 'none'};
  border-radius: 8px;
  margin: 0 8px 8px 8px;
  overflow: hidden;
  padding-top: 8px;
`

export const StyledEventBanner = styled.div`
  align-items: center;
  border-radius: 8px;
`

export const StyledEventBannerHeader = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  padding: 8px 16px;

  button {
    span {
      color: ${(props) => props.theme.colors.fixed.primary[0]};
    }
  }

  a {
    margin-left: -4px;
    padding: 0;
  }
`

export const StyledTeamLogo = styled.div`
  height: 32px;
  margin-right: 6px;
  width: 32px;
`

export const StyledNameTeamWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 4px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0px;
  }
`

export const StyledEventEmblemWrapper = styled.div`
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
`

export const StyledStatisticsWrapper = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
`

export const StyledBannerCompetitorsHeader = styled.div`
  display: flex;
  margin-bottom: 8px;
  padding: 2px 0px;
`
export const StyledDateTimeEventWrapepr = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 20px;
  padding: 0 6px 0 6px;
  position: relative;
  width: fit-content;

  &:after {
    background: ${(props) => props.theme.colors.fixed.primary[0]};
    border-radius: 4px;
    content: '';
    display: flex;
    height: 20px;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
  }

  div {
    background: none;
    color: ${(props) => props.theme.colors.fixed.primary[0]};
    padding: 0;
  }
`

export const StyledLiveDotsIndicator = styled.span`
  background: ${(props) => props.theme.colors.fixed.primary[0]};
  border-radius: 50%;
  display: flex;
  height: 5px;
  margin: 0px 3px 0 0px;
  width: 5px;
`

const StyledActionsItemCss = css`
  align-items: center;
  appearance: none;
  background: none;
  ${columnGapPrefixes('4px')}
  border: none;
  cursor: pointer;
  display: flex;
  line-height: 16px;
  padding: 0px;
`

export const StyledActionFavourite = styled.button<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    ${StyledActionsItemCss}
    & > span {
      color: ${theme.colors.fixed.primary[0]} !important;

      & > svg > g {
        opacity: ${$isActive ? 1 : 0.5};
      }

      &:hover > svg > g {
        opacity: ${$isActive ? 1 : 0.7};
      }
    }
  `}
`

export const StyledEventBannerHeaderControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  a {
    color: ${(props) => props.theme.colors.fixed.primary[0]};

    span {
      color: ${(props) => props.theme.colors.fixed.primary[0]} !important;
    }
  }
`

export const StyledEventStatistics = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

export const StyledBannerMainContent = styled.div`
  padding: 0px 16px 16px 16px;
`

export const StyledTeamsSection = styled.div`
  width: 100%;
`

export const StyledActionsWrapper = styled.div``

export const StyledMatchPhase = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  padding-left: 8px;
`

export const StyledCommentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 16px;
  margin-top: 8px;
`

export const StyledComment = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  opacity: 0.5;
  padding-right: 5px;
`
