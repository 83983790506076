import React from 'react'
import { Redirect } from 'react-router-dom'

import { ERoutes, IRoute } from 'shared/types/routes'

import {
  AboutPage,
  CalendarPage,
  LineEventPage,
  LinePage,
  LineTournamentPage,
  LiveEventPage,
  LiveEventsPage,
  LivePage,
  MainPage,
  NotFoundPage,
  RefundPolicyPage,
  BalancePage,
  BetsHistoryPage,
  StatisticsPage,
  ResultsPage,
  GamesBetPage,
  GamesTurboPage,
  GamesTurboGamePage,
  GamesLivePage,
  GamesTwainPage,
  GamesCyberbetPage,
  GamesVsportGamePage,
  GamesVsportPage,
  CasinoPage,
  SportGamesPage,
  FriggPage,
  FavouriteEventsPage,
  ProfilePage,
  NotificationPage,
  PromotionPage,
  PromotionAndBonusesPage,
  ResponsibleGamblingPage,
  PolicyPrivacyPage,
  PolicyAmlPage,
  TermsAndConditionsPage,
  EventCompletedPage,
  AuthSuccessPage,
  AuthSuccessSignUpPage,
  HeadToHeadPage,
  SearchPage
} from './imports'

// This is a static route configuration. It should include all of your top level
// routes, regardless of whether they are going to server render. In fact, you
// can totally point multiple routes to the same component! This is great for
// when you only need to server render a handful of routes and not your entire
// application!

export const routes: Array<IRoute> = [
  {
    path: ERoutes.Root,
    component: MainPage,
    name: 'Root',
    exact: true
  },
  {
    path: ERoutes.Live,
    component: LivePage,
    name: 'Live',
    exact: true
  },
  {
    path: ERoutes.LiveEvents,
    component: LiveEventsPage,
    name: 'LiveEvents',
    exact: true
  },
  {
    path: ERoutes.LiveEvent,
    component: LiveEventPage,
    name: 'LiveEvent',
    exact: true
  },
  {
    path: ERoutes.Line,
    component: LinePage,
    name: 'Line',
    exact: true
  },
  {
    path: ERoutes.LineTournament,
    component: LineTournamentPage,
    name: 'Line Tournament',
    exact: true
  },
  {
    path: ERoutes.LineEvent,
    component: LineEventPage,
    name: 'LineEvent',
    exact: true
  },
  {
    path: ERoutes.LineRedirect,
    render: () => <Redirect to="/line/100" />,
    name: 'Redirect to Line',
    exact: true
  },
  {
    path: ERoutes.Calendar,
    component: CalendarPage,
    name: 'Calendar',
    exact: true
  },
  {
    path: ERoutes.FavouriteEvents,
    component: FavouriteEventsPage,
    name: 'Favourite events',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Statistics,
    component: StatisticsPage,
    name: 'Statistics',
    exact: true
  },
  {
    path: ERoutes.Results,
    component: ResultsPage,
    name: 'Results',
    exact: true
  },
  {
    path: ERoutes.ResultsEvent,
    component: EventCompletedPage,
    name: 'EventCompleted',
    exact: true
  },
  {
    path: ERoutes.Casino,
    component: CasinoPage,
    name: 'Casino',
    exact: true
  },
  {
    path: ERoutes.BetGames,
    component: GamesBetPage,
    name: 'BetGames TV',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGames,
    component: GamesTurboPage,
    name: 'TurboGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TwainGames,
    component: GamesTwainPage,
    name: 'TwainGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGamesGame,
    component: GamesTurboGamePage,
    name: 'TurboGamesGame',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.SportGamesLobby,
    component: SportGamesPage,
    name: 'SportGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.LiveGames,
    component: GamesLivePage,
    name: 'Live Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VirtualGames,
    name: 'Virtual Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.CyberbetGames,
    component: GamesCyberbetPage,
    name: 'Cyberbet Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VsportGames,
    component: GamesVsportPage,
    name: 'Vsport Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VsportGamesGame,
    component: GamesVsportGamePage,
    name: 'Vsport Game',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.Frigg,
    component: FriggPage,
    name: 'Frigg',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.ProfileBalance,
    component: BalancePage,
    name: 'Balance',
    private: true
  },
  {
    path: ERoutes.BetsHistory,
    component: BetsHistoryPage,
    name: 'BetsHistory',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Profile,
    component: ProfilePage,
    name: 'Profile',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Identification,
    name: 'Identification',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Won,
    name: 'Won',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Notifications,
    component: NotificationPage,
    name: 'Notifications',
    exact: true,
    private: true
  },
  {
    path: ERoutes.PromotionBonus,
    component: PromotionPage,
    name: 'PromotionBonus',
    exact: true,
    private: true
  },
  {
    path: ERoutes.PromotionsAndBonuses,
    component: PromotionAndBonusesPage,
    name: 'PromotionsAndBonuses',
    exact: true,
    private: true
  },
  {
    path: ERoutes.About,
    component: AboutPage,
    name: 'About',
    exact: true
  },
  {
    path: ERoutes.RefundPolicy,
    component: RefundPolicyPage,
    name: 'Refund Policy',
    exact: true
  },
  {
    path: ERoutes.ResponsibleGambling,
    component: ResponsibleGamblingPage,
    name: 'Responsible Gambling',
    exact: true
  },
  {
    path: ERoutes.PrivacyPolicy,
    component: PolicyPrivacyPage,
    name: 'Privacy Policy',
    exact: true
  },
  {
    path: ERoutes.AMLPolicy,
    component: PolicyAmlPage,
    name: 'AML Policy',
    exact: true
  },
  {
    path: ERoutes.TermsAndConditions,
    component: TermsAndConditionsPage,
    name: 'Terms and Conditions',
    exact: true
  },
  {
    path: ERoutes.Support,
    name: 'Support',
    exact: true
  },
  {
    path: ERoutes.Rules,
    render: () => <Redirect to={ERoutes.TermsAndConditions} />,
    name: 'Rules',
    exact: true
  },
  {
    path: ERoutes.Training,
    name: 'Training',
    exact: true
  },
  {
    path: ERoutes.SuccessLoginPage,
    component: AuthSuccessPage,
    name: 'Success Page',
    exact: true
  },
  {
    path: ERoutes.SuccessFastSignUp,
    component: AuthSuccessSignUpPage,
    name: 'Success Fast Sign Up',
    exact: true
  },
  {
    path: ERoutes.SuccessFullSignUp,
    component: AuthSuccessSignUpPage,
    name: 'Success Full Sign Up',
    exact: true
  },
  {
    path: ERoutes.HeadToHead,
    component: HeadToHeadPage,
    name: 'Head 2 Head statistics Page',
    exact: true
  },
  {
    path: ERoutes.Search,
    component: SearchPage,
    name: 'Search Page',
    exact: true
  },
  {
    path: ERoutes.Error404,
    component: NotFoundPage,
    name: 'Error 404',
    exact: true
  }
]

export const privateRoutes = routes.reduce((acc, route) => {
  if (route.private) {
    acc.add(route.path)
  }
  return acc
}, new Set())

export const virtualGamesRoutes = routes.reduce<Set<IRoute['path']>>(
  (acc, route) => {
    if (route.virtualGame) {
      acc.add(route.path)
    }
    return acc
  },
  new Set()
)
