import { OptionType } from 'astra-core/utils/line/types'
import { Props as SelectProps } from 'react-select'

import { ETestData } from 'shared/lib/testData'

export interface CustomReactSelectProps extends SelectProps<OptionType> {
  view?: ESelectView
  testData?: ETestData
}

export enum ESelectView {
  Default = 'default',
  Mini = 'mini',
  Basket = 'basket',
  Results = 'results',
  Registration = 'registration'
}
