import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSuccessBonusCoupon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="41"
      viewBox="0 0 40 41"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20.5" fill="#9470FE" r="20" />
      <circle
        cx="19.9991"
        cy="20.4991"
        fill="url(#paint0_linear_32721_101221)"
        r="16.087"
      />
      <path
        clipRule="evenodd"
        d="M27.8158 15.7779C28.2146 16.1599 28.2282 16.7929 27.8462 17.1917L20.183 25.1917C19.9944 25.3887 19.7335 25.5 19.4609 25.5C19.1882 25.5 18.9273 25.3887 18.7387 25.1917L13.4019 19.6203C13.0198 19.2215 13.0335 18.5885 13.4323 18.2064C13.8311 17.8244 14.4641 17.838 14.8462 18.2368L19.4609 23.0544L26.4019 15.8083C26.7839 15.4094 27.4169 15.3958 27.8158 15.7779Z"
        fill="white"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_32721_101221"
          x1="3.91211"
          x2="46.6334"
          y1="1.80666"
          y2="39.6297"
        >
          <stop stopColor="#480DF2" />
          <stop offset="0.0001" stopColor="#875EFF" />
          <stop offset="1" stopColor="#492CC1" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
