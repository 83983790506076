import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLive: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg fill="none" viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M14.2807 10.3053C13.897 10.7026 13.9081 11.3357 14.3053 11.7193C14.7026 12.103 15.3357 12.0919 15.7193 11.6947C18.7602 8.54573 18.7602 3.45428 15.7193 0.305344C15.3357 -0.0919372 14.7026 -0.102988 14.3053 0.280659C13.9081 0.664308 13.897 1.29738 14.2807 1.69466C16.5731 4.06857 16.5731 7.93143 14.2807 10.3053ZM3.69466 0.280661C4.09194 0.66431 4.10299 1.29738 3.71934 1.69466C1.42689 4.06857 1.42689 7.93143 3.71934 10.3053C4.10299 10.7026 4.09194 11.3357 3.69466 11.7193C3.29738 12.103 2.66431 12.0919 2.28066 11.6947C-0.76022 8.54573 -0.76022 3.45428 2.28066 0.305345C2.66431 -0.0919363 3.29738 -0.102988 3.69466 0.280661ZM11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6ZM11.705 2.28066C11.3077 2.66431 11.2966 3.29738 11.6803 3.69466C12.9061 4.964 12.9061 7.036 11.6803 8.30534C11.2966 8.70262 11.3077 9.33569 11.705 9.71934C12.1022 10.103 12.7353 10.0919 13.1189 9.69466C15.0932 7.65029 15.0932 4.34971 13.1189 2.30534C12.7353 1.90806 12.1022 1.89701 11.705 2.28066ZM6.21916 8.30534C6.60281 8.70262 6.59175 9.33569 6.19447 9.71934C5.79719 10.103 5.16412 10.0919 4.78048 9.69466C2.80626 7.65029 2.80626 4.34971 4.78048 2.30534C5.16412 1.90806 5.79719 1.89701 6.19447 2.28066C6.59175 2.66431 6.60281 3.29738 6.21916 3.69466C4.99337 4.964 4.99337 7.036 6.21916 8.30534Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
