import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconCoins: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.25603 3.96998C9.07622 3.55988 9.36256 3.10741 9.3746 2.78265C9.34265 1.58828 7.39631 0.625 5 0.625C2.60369 0.625 0.657349 1.58828 0.625399 2.78265C0.637438 3.10741 0.923783 3.55988 1.74397 3.96998C2.54497 4.37048 3.69528 4.63623 5 4.63623C6.30472 4.63623 7.45503 4.37048 8.25603 3.96998Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.81504 5.08802C9.00884 4.99111 9.19737 4.88223 9.375 4.76163V5.13672C9.375 5.46281 9.09441 5.92628 8.25603 6.34547C7.45503 6.74597 6.30472 7.01172 5 7.01172C3.69528 7.01172 2.54497 6.74597 1.74397 6.34547C0.905593 5.92628 0.625 5.46281 0.625 5.13672V4.76163C0.802635 4.88223 0.991157 4.99111 1.18496 5.08802C2.1936 5.59234 3.5433 5.88623 5 5.88623C6.4567 5.88623 7.8064 5.59234 8.81504 5.08802Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0.625 7.13712V7.1875C0.625 8.39562 2.58375 9.375 5 9.375C7.41625 9.375 9.375 8.39562 9.375 7.1875V7.13712C9.19737 7.25772 9.00884 7.3666 8.81504 7.4635C7.8064 7.96783 6.4567 8.26172 5 8.26172C3.5433 8.26172 2.1936 7.96783 1.18496 7.4635C0.991157 7.3666 0.802635 7.25772 0.625 7.13712Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
