import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBetsHistory: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 16 12"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V4.22228C15.0182 4.22228 14.2218 5.01821 14.2218 6.00005C14.2218 6.98189 15.0177 7.77783 15.9996 7.77783L16 10C16 11.1046 15.1046 12 14 12H2C0.895431 12 0 11.1046 0 10V7.77778C0.98184 7.77778 1.77778 6.98184 1.77778 6C1.77778 5.01816 0.98184 4.22222 0 4.22222V2ZM7 3C7 3.55228 6.55228 4 6 4C5.44772 4 5 3.55228 5 3C5 2.44772 5.44772 2 6 2C6.55228 2 7 2.44772 7 3ZM7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6ZM6 10C6.55228 10 7 9.55229 7 9C7 8.44771 6.55228 8 6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
