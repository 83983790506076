import React, {
  FC,
  memo,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  EBetTypes,
  selectBasketBetTypeTab,
  selectBasketOutcomes,
  selectBasketOutcomesLength,
  selectCoefficientStatusOnChanged,
  selectMakeBetErrorsTop
} from 'astra-core/containers/BasketProvider'
import {
  accountProviderActions,
  selectAccount,
  TTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { EAuthPagesAndModal } from 'astra-core/constants/auth'
import { BetSaveAny } from 'betweb-openapi-axios'

import { ETestData } from 'shared/lib/testData'
import { IconSettingSmall } from 'shared/ui/Icon/General/IconSettingSmall'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { STYLED_HEADER_HEIGHT } from 'widgets/header/Header.styled'
import { Tooltip } from 'shared/ui/Tooltip'
import { useAuthTypeModal } from 'hooks/useAuthTypeModal'
import { IconProfileMenuBanner } from 'shared/ui/Icon/General/IconProfileMenuBanner'
import { EButtonViews } from 'shared/ui/Button'
import { rightBasketContainerState } from 'containers/BasketContainer/slice'

import { BasketError } from '../BasketCommon/Common'
import { StyledIconWrapper } from '../BasketCommon/Common.styled'
import { SettingRates } from '../BasketHeader/BasketHeader'
import { BasketSuitableBonusCoupons } from '../BasketCommon/components/BasketSuitableBonusCoupons'

import { Ordinar } from './Ordinar/Ordinar'
import { Express } from './Express/Express'
import { System } from './System/System'
import {
  StyledBasketBody,
  StyledBasketBodyList,
  StyledBasketBodyWrapper,
  StyledBetTypeItemText,
  StyledBetTypeItemWrapper,
  StyledButton,
  StyledEmptyBasketLoginButton,
  StyledEmptyBasketMakeABetButton,
  StyledEmptyBasketText,
  StyledEmptyBasketTitle,
  StyledEmptyBasketWrapper,
  StyledLink,
  StyledNotAuthorizedBasketText,
  StyledNotAuthorizedBasketTitle,
  StyledNotAuthorizedBasketWrapper,
  StyledThreeBetTypesWrapper
} from './BasketBody.styled'
import {
  BetTypeItemProps,
  BetTypes,
  BetTypesLocale,
  ThreeBetTypesProps
} from './BasketBody.types'

const betTypes = ({ outcomes }): BetTypes => ({
  [EBetTypes.Ordinar]: <Ordinar outcomes={outcomes} />,
  [EBetTypes.Express]: <Express outcomes={outcomes} />,
  [EBetTypes.System]: <System outcomes={outcomes} />
})

export const BasketBody: FC = () => {
  const [isSettingRatesShown, setIsSettingRatesShown] = useState<boolean>(false)

  const outcomes = useSelector(selectBasketOutcomes)
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const errorsTop = useSelector(selectMakeBetErrorsTop)
  const outcomesLength = useSelector(selectBasketOutcomesLength)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const toggleSettingRatesModal = () => setIsSettingRatesShown((prev) => !prev)
  const dispatch = useDispatch()
  const coefficientStatusOnChanged = useSelector(
    selectCoefficientStatusOnChanged
  )

  const changeCoefficientStatusOnChanged = useCallback(
    (status: BetSaveAny) => {
      dispatch(basketProviderActions.setCoefficientStatusOnChanged({ status }))
    },
    [dispatch]
  )

  const wrapperRect = useMemo<{ top: number }>(() => {
    if (wrapperRef.current && outcomesLength && isSettingRatesShown) {
      const rect = wrapperRef.current?.getBoundingClientRect()
      return { top: rect.top + rect.height - STYLED_HEADER_HEIGHT }
    }
    return { top: 0 }
  }, [isSettingRatesShown, outcomesLength])

  return (
    <>
      {!outcomesLength ? (
        <EmptyBasket />
      ) : (
        <>
          <StyledBasketBodyWrapper>
            <StyledBasketBody ref={wrapperRef}>
              <ThreeBetTypes
                toggleSettingRatesModal={toggleSettingRatesModal}
              />

              {errorsTop.map((error) => (
                <BasketError error={error} key={error.code} />
              ))}
            </StyledBasketBody>

            <StyledBasketBodyList data-test-id={ETestData.TestBasketBodyList}>
              {betTypes({ outcomes })[betTypeTab]}
            </StyledBasketBodyList>

            <BasketSuitableBonusCoupons />
          </StyledBasketBodyWrapper>

          {isSettingRatesShown && (
            <SettingRates
              activeStatus={coefficientStatusOnChanged}
              rect={wrapperRect}
              toggleSettingRatesModal={toggleSettingRatesModal}
              onChange={changeCoefficientStatusOnChanged}
            />
          )}
        </>
      )}
    </>
  )
}

const betTypesLocale: BetTypesLocale[] = [
  { name: EBetTypes.Ordinar, locale: 'ordinar' },
  { name: EBetTypes.Express, locale: 'express' },

  { name: EBetTypes.System, locale: 'system' }
]

const ThreeBetTypes: FC<ThreeBetTypesProps> = memo(
  ({ toggleSettingRatesModal }) => {
    return (
      <StyledThreeBetTypesWrapper>
        {betTypesLocale.map(({ name, locale }) => (
          <BetTypeItem key={name} locale={locale} name={name} />
        ))}
        <StyledIconWrapper data-test-id={ETestData.TestBasketIcon}>
          <IconSettingSmall
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 17
            }}
            size={14}
            buttonProps
            onClick={toggleSettingRatesModal}
          />
        </StyledIconWrapper>
      </StyledThreeBetTypesWrapper>
    )
  }
)

const MIN_OUTCOMES_LENGTH = {
  [EBetTypes.Ordinar]: 0,
  [EBetTypes.Express]: 2,
  [EBetTypes.System]: 3
}

const BetTypeItem: FC<BetTypeItemProps> = ({ locale, name }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const outcomesLength = useSelector(selectBasketOutcomesLength)
  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  const hoverText = useMemo(
    () =>
      EBetTypes.Express === name
        ? t('express hover text')
        : EBetTypes.System === name
        ? t('system hover text')
        : '',
    [t, name]
  )

  const isAllow = useMemo(() => {
    return ((outcomesLength as number) || 0) >= MIN_OUTCOMES_LENGTH[name]
  }, [name, outcomesLength])

  const handleBetTypeTabChange = useCallback(() => {
    if (isAllow) {
      dispatch(
        basketProviderActions.handleBetTypeTabChange({ betTypeTab: name })
      )
    }
  }, [dispatch, isAllow, name])

  const isCommon = useMemo(() => {
    return !hoverText || isAllow
  }, [hoverText, isAllow])

  return (
    <StyledBetTypeItemWrapper
      active={name === betTypeTab}
      data-test-id={`${ETestData.TestBasketBetType}-${name}`}
      typeTheme={typeTheme}
      onClick={handleBetTypeTabChange}
    >
      <Tooltip isCommon={isCommon} title={hoverText}>
        <StyledBetTypeItemText active={name === betTypeTab}>
          {t(locale)}
        </StyledBetTypeItemText>
      </Tooltip>
    </StyledBetTypeItemWrapper>
  )
}

const EmptyBasketAuthorized = () => {
  const [t] = useTranslation()

  return (
    <StyledEmptyBasketWrapper>
      <StyledEmptyBasketTitle data-test-id={ETestData.TestCouponEmpty}>
        {t('coupon empty')}
      </StyledEmptyBasketTitle>
      <StyledEmptyBasketText>
        <Trans
          components={[<StyledLink to="/live" />, <StyledLink to="/line" />]}
          i18nKey="add an event by selecting it in live or line"
          t={t}
        />
      </StyledEmptyBasketText>
      <StyledEmptyBasketMakeABetButton disabled>
        {t('make a bet')}
      </StyledEmptyBasketMakeABetButton>
    </StyledEmptyBasketWrapper>
  )
}

export const NotAuthorizedBasket = () => {
  const notAuthorizedBasketWrapperRef = useRef<HTMLDivElement>(null)
  const [isAuthorizedBasket, setNotAuthorizedBasket] = useState(false)
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const setAuthTypeModal = useAuthTypeModal()

  const handleClickButton = useCallback(
    (types: TTypeAuthModal) => () => {
      dispatch(accountProviderActions.setTypeAuthModal(types))
      setAuthTypeModal(!!types)
    },
    [dispatch, setAuthTypeModal]
  )

  const handleMouseUp = useCallback(() => {
    if (isAuthorizedBasket) {
      dispatch(
        rightBasketContainerState.toggleBasketOnSmallWindow({ isShow: false })
      )
    }
  }, [dispatch, isAuthorizedBasket])

  const handleMouseDown = useCallback((e) => {
    setNotAuthorizedBasket(notAuthorizedBasketWrapperRef.current !== e.target)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseDown, handleMouseUp])

  return (
    <StyledNotAuthorizedBasketWrapper ref={notAuthorizedBasketWrapperRef}>
      <IconProfileMenuBanner size={{ height: 32, width: 30 }} />
      <StyledNotAuthorizedBasketTitle data-test-id={ETestData.TestCouponEmpty}>
        {t('you are not authorized')}
      </StyledNotAuthorizedBasketTitle>
      <StyledNotAuthorizedBasketText>
        <Trans
          components={[
            <StyledButton
              data-test-id={ETestData.TestEmptyBasketTextSignIn}
              onClick={handleClickButton(EAuthPagesAndModal.SignIn)}
            />,
            <StyledButton
              data-test-id={ETestData.TestEmptyBasketTextFastSignUp}
              onClick={handleClickButton(EAuthPagesAndModal.FastSignUp)}
            />
          ]}
          i18nKey="to add events login or register"
          t={t}
        />
      </StyledNotAuthorizedBasketText>
      <StyledEmptyBasketLoginButton
        view={EButtonViews.REGISTRATION}
        onClick={handleClickButton(EAuthPagesAndModal.SignIn)}
      >
        {t('sign in')}
      </StyledEmptyBasketLoginButton>
    </StyledNotAuthorizedBasketWrapper>
  )
}

const EmptyBasket = () => {
  const account = useSelector(selectAccount)

  return account ? <EmptyBasketAuthorized /> : <NotAuthorizedBasket />
}
