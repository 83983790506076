import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconMoreRow: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#EDF1F7" fillOpacity={0.16} height={12} rx={2} width={16} />
      <circle cx={4} cy={6} fill="#fff" r={1} />
      <circle cx={8} cy={6} fill="#fff" r={1} />
      <circle cx={12} cy={6} fill="#fff" r={1} />
    </svg>
  </Icon>
)
