import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconWallet: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.25C2 3.00736 3.00736 2 4.25 2H11.75C12.1642 2 12.5 2.33579 12.5 2.75C12.5 3.16421 12.1642 3.5 11.75 3.5H4.25C3.83579 3.5 3.5 3.83579 3.5 4.25C3.5 4.66421 3.83579 5 4.25 5H12.5C13.3284 5 14 5.67157 14 6.5V12.5C14 13.3284 13.3284 14 12.5 14H3.5C2.67157 14 2 13.3284 2 12.5V4.25Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
