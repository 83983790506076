import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { IconUnreadNotification } from 'shared/ui/Icon/General/IconUnreadNotification'

import { IExpandButton, IStyledMessageRead } from './NotificationListItem.types'

export const StyledMessageBlock = styled.div<IStyledMessageRead>`
  ${({ theme }) => `background-color: ${theme.colors.default.blue[71]};`}
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 8px;
  min-width: 260px;
  overflow: hidden;
  padding: 8px;

  &:first-child {
    margin-top: 8px;
  }

  ${(props) => css`
    background-color: ${props.isRead
      ? props.theme.colors.default.primary[0]
      : `background-color: ${props.theme.colors.default.blue[71]}`};
  `}
`

export const StyledMessageTop = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
`

export const StyledMessageTitle = styled.h2<IStyledMessageRead>`
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
  `};

  ${(props) => css`
    font-weight: ${props.isRead
      ? props.theme.fonts.weight.normal
      : props.theme.fonts.weight.bold};
  `}
`

export const StyledIconUnreadMessage = styled(IconUnreadNotification)`
  margin: 5px 0 auto auto;
`

export const StyledMessageDate = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.custom.primary[70]};
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
  `}
`

export const StyledNotificationListItemDescription = styled.div<IStyledMessageRead>`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    padding-bottom: 4px;
    position: relative;
  `}
  ${(props) => css`
    background-color: ${props.isRead
      ? props.theme.colors.default.primary[0]
      : props.theme.colors.default.blue[71]};
  `}

  ${(props) => css`
    color: ${props.isRead
      ? props.theme.colors.default.primary[70]
      : `${props.theme.colors.default.primary[90]}`};
  `}
`

export const StyledMoreButton = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    background-color: ${theme.colors.default.blue[71]};
  `}
  bottom: -30px;
  cursor: pointer;
  left: 0;
  position: absolute;

  &::before {
    content: '';
    height: 40px;
    left: -25px;
    position: absolute;
    width: 25px;
    ${({ theme }) => `
      ${
        theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'background: linear-gradient(270deg, #262626 0%, rgba(38, 38, 38, 0.6) 59.9%, rgba(38, 38, 38, 0) 100%);'
          : 'background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0.6) 59.9%, rgba(246, 246, 246, 0) 100%);'
      }
    `}
  }
`

export const StyledNotificationListItemDescriptionText = styled.p`
  cursor: auto;
  overflow: hidden;
  padding: 4px 0 0;
`

export const StyledToggleExpandButton = styled.div<IExpandButton>`
  ${({ theme }) => css`
    color: ${theme.colors.custom.primary[39]};
    cursor: pointer;
    display: block;
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    margin-top: 4px;
  `}
  ${(props) => css`
    font-weight: ${props.isExpanded
      ? props.theme.fonts.weight.normal
      : props.theme.fonts.weight.regular};
  `}
`
