import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledButtonBalanceCount,
  StyledButtonBonusCount,
  StyledBalanceTitle,
  StyledName,
  StyledWrapper
} from './PersonalData.styled'

export const PersonalData = memo(() => {
  const [t] = useTranslation()

  return (
    <StyledWrapper>
      <StyledName />
      <StyledBalanceTitle>{t('balance')}</StyledBalanceTitle>
      <StyledButtonBalanceCount />
      <StyledBalanceTitle>{t('bonus account')}</StyledBalanceTitle>
      <StyledButtonBonusCount />
    </StyledWrapper>
  )
})
