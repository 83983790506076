import React, { FC } from 'react'

import { ESwitchView, SwitchProps } from './types'
import {
  StyledSwitchPin,
  StyledSwitch,
  StyledSwitchWrapper,
  StyledSwitchLabel
} from './styled'

export const Switch: FC<SwitchProps> = ({
  active,
  onClick,
  label,
  disabled,
  testData,
  view = ESwitchView.PRIMARY
}) => {
  const handleSwitch = disabled ? () => {} : onClick

  return (
    <StyledSwitchWrapper>
      <StyledSwitch
        active={active}
        data-test-id={testData}
        view={view}
        onClick={handleSwitch}
      >
        <StyledSwitchPin active={active} view={view} />
      </StyledSwitch>
      {!!label && <StyledSwitchLabel>{label}</StyledSwitchLabel>}
    </StyledSwitchWrapper>
  )
}
