import { useDispatch } from 'react-redux'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { messagesProviderActions } from 'astra-core/containers/MessagesProvider'

import { SIZE_DESCRIPTION_LONG } from './constants'

export const useNotificationDescriptionToggleExpand = ({
  notificationId,
  isRead
}) => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)

  const onSetReadNotification = useCallback(() => {
    if (!isRead) {
      dispatch(
        messagesProviderActions.setReadMessage({
          messageId: notificationId
        })
      )
    }
  }, [dispatch, notificationId, isRead])

  const onToggleExpandAndSetReadNotification = useCallback(() => {
    onSetReadNotification()

    setIsExpanded((prevState) => !prevState)
  }, [onSetReadNotification])

  return {
    isExpanded,
    onToggleExpandAndSetReadNotification,
    onSetReadNotification
  }
}

export const useHandleTextBlockSizes = <T extends HTMLElement>({
  ref,
  text
}: {
  ref: MutableRefObject<T | null>
  text: string
}) => {
  const [isBlockFullContent, setIsBlockFullContent] = useState<null | boolean>(
    null
  )

  useEffect(() => {
    const node = ref.current

    if (node) {
      if (node.scrollHeight > node.clientHeight) {
        setIsBlockFullContent(true)
      } else {
        setIsBlockFullContent(false)
      }
    }
  }, [ref])

  const conditionalSizes = useMemo(
    () => ({
      isMiddle: isBlockFullContent && text.length < SIZE_DESCRIPTION_LONG,
      isLarge: text.length >= SIZE_DESCRIPTION_LONG
    }),
    [isBlockFullContent, text.length]
  )

  return { conditionalSizes }
}

export const useHandleHeight = ({ ref, isExpanded }) => {
  const [blockHeight, setBlockHeight] = useState('35px')

  useEffect(() => {
    setBlockHeight(
      isExpanded && ref.current ? `${ref.current.scrollHeight}px` : '35px'
    )
  }, [isExpanded, ref])

  const onSetFullBlockHeight = () =>
    ref.current && setBlockHeight(`${ref.current.scrollHeight}px`)

  const onSetDefaultBlockHeight = () => setBlockHeight('35px')

  return { onSetDefaultBlockHeight, onSetFullBlockHeight, blockHeight }
}
