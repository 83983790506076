import React, { FC } from 'react'

import { IEventCoefficientTableProps } from './coefficient-table.types'
import {
  StyledEventOdd,
  StyledEventOddInfo,
  StyledOddWrapper
} from './coefficient-table.styled'

export const EventCoefficientTable: FC<IEventCoefficientTableProps> = ({
  parameterValue,
  outcomeMode,
  value
}) => {
  return (
    <StyledOddWrapper $mode={outcomeMode} isPlain>
      {!!parameterValue && (
        <StyledEventOddInfo $mode={outcomeMode}>
          {parameterValue}
        </StyledEventOddInfo>
      )}
      <StyledEventOdd $mode={outcomeMode}>{value || '-'}</StyledEventOdd>
    </StyledOddWrapper>
  )
}
