import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconEmblemMainPage: FC<IconProps> = (props) => {
  const theme = useTheme()

  const colorsIcon = {
    background: theme.colors.default.primary[85]
  }
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          fill="black"
          height="16"
          id="path-1-outside-1_7209_34657"
          maskUnits="userSpaceOnUse"
          width="14"
          x="1"
          y="0"
        >
          <rect fill="white" height="16" width="14" x="1" />
          <path d="M2 2.10415L4 1C4 1 5.33143 2.82976 7.97723 2.84021H8.02277C10.6686 2.82976 12 1 12 1L14 2.10415V7.80889C14 10.1227 12.983 12.167 11.4286 13.3974C10.4567 14.1667 8 15 8 15C8 15 5.54329 14.1667 4.57143 13.3974C3.01701 12.167 2 10.1227 2 7.80889V2.10415Z" />
        </mask>
        <path
          d="M2 2.10415L4 1C4 1 5.33143 2.82976 7.97723 2.84021H8.02277C10.6686 2.82976 12 1 12 1L14 2.10415V7.80889C14 10.1227 12.983 12.167 11.4286 13.3974C10.4567 14.1667 8 15 8 15C8 15 5.54329 14.1667 4.57143 13.3974C3.01701 12.167 2 10.1227 2 7.80889V2.10415Z"
          fill={colorsIcon.background}
          mask="url(#path-1-outside-1_7209_34657)"
          stroke="#B3B3B3"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M3.76399 2.27066L3 2.76557V7.97845C3 9.93101 3.84751 11.6561 5.14286 12.6944C5.95274 13.3436 8 14.0468 8 14.0468C8 14.0468 10.0473 13.3436 10.8571 12.6944C12.1525 11.6561 13 9.93101 13 7.97845V2.71094L12.2294 2.27846C11.3407 3.08249 10.2272 3.63699 8.99623 3.83326C8.68752 3.88941 8.3696 3.91872 8.04492 3.91872C7.87805 3.91872 7.71297 3.91098 7.55 3.89583C6.09932 3.79273 4.78308 3.19609 3.76399 2.27066Z"
          fill="url(#paint0_linear_7209_34657)"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_7209_34657"
            x1="3"
            x2="13.8373"
            y1="14.0468"
            y2="3.17878"
          >
            <stop stopColor="#B3B3B3" />
            <stop offset="1" stopColor="#E6E6E6" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  )
}
