import { RefObject } from 'react'

import { ELineHeaderTypes } from 'pages/page-live-events/Line/components/ui/LineHeader/LineHeader.types'

export interface LayoutProps {
  type?: ELayoutsList
  pageTitle?: {
    text: string
  }
  refContent?: RefObject<HTMLDivElement>
  typeHeader?: ELineHeaderTypes
}

export enum ELayoutsList {
  DEFAULT = 'default',
  SPORT = 'sport',
  PROFILE = 'profile',
  ABOUT = 'about',
  GAME = 'game',
  RESULTS = 'results',
  CALENDAR = 'calendar',
  EVENT = 'event',
  ERROR = 'error',
  MAIN = 'main'
}
