import { OddModes } from 'pages/page-live-events/Line/Line.types'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

export const iconCoefficientChangeColorProps = {
  [OddModes.Increase]: {
    type: EColorsTypes.CUSTOM,
    name: EColorsNames.Green,
    value: 3
  },
  [OddModes.Decrease]: {
    name: EColorsNames.Red,
    value: 50
  }
}

export const showIconCoefficientChangeList = [
  OddModes.Decrease,
  OddModes.Increase
]

export const BASKET_INPUT_WIDTH = 248
export const BASKET_INPUT_WIDTH_WITHOUT_EMPTY_SPACE = BASKET_INPUT_WIDTH - 27

export const getIconArrowColorProps = (appliedCoupon: boolean) =>
  appliedCoupon
    ? {
        name: EColorsNames.Blue,
        type: EColorsTypes.CUSTOM,
        value: 21
      }
    : {
        name: EColorsNames.Green,
        type: EColorsTypes.CUSTOM,
        value: 7
      }
