import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'
import { EColorsNames } from 'shared/types/theme'

export const IconStatFootballFouls: FC<IconProps> = (props) => {
  const colorProps = { name: EColorsNames.Primary, value: 30 }

  return (
    <Icon colorProps={colorProps} size={16} {...props}>
      <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M4.18361 0.051588C4.70755 -0.12306 5.27387 0.1601 5.44852 0.684044L6.44852 3.68404C6.62316 4.20799 6.34 4.77431 5.81606 4.94895C5.29212 5.1236 4.7258 4.84044 4.55115 4.3165L3.55115 1.3165C3.3765 0.792556 3.65966 0.226236 4.18361 0.051588ZM9.88757 1.16489C9.97836 0.620119 9.61034 0.104892 9.06557 0.0140965C8.5208 -0.0766985 8.00558 0.291321 7.91478 0.836091L7.50367 3.30278C7.41287 3.84755 7.78089 4.36278 8.32566 4.45357C8.87043 4.54437 9.38566 4.17635 9.47645 3.63158L9.88757 1.16489ZM1.92 5.81501C2.37011 6.13503 2.99443 6.02956 3.31445 5.57945C3.63447 5.12933 3.52901 4.50501 3.07889 4.18499L2.07953 3.47447C1.62941 3.15445 1.00509 3.25992 0.68507 3.71003C0.36505 4.16015 0.470515 4.78447 0.920632 5.10449L1.92 5.81501ZM6.08366 6.01738L6.05511 6.02463L6.05519 6.02493C3.42418 6.73066 1.86299 9.43536 2.56806 12.0667C3.27325 14.6985 5.9784 16.2603 8.61019 15.5551C11.242 14.8499 12.8038 12.1448 12.0986 9.51301C12.0391 9.29091 11.9654 9.07644 11.8786 8.87031L15.8232 6.81222L16.0004 3.50022L6.15816 5.99847C6.13332 6.00458 6.10849 6.01088 6.08366 6.01738ZM8.91963 10.3644C9.15469 11.2417 8.63408 12.1434 7.75682 12.3784C6.87956 12.6135 5.97784 12.0929 5.74278 11.2156C5.50772 10.3384 6.02832 9.43665 6.90559 9.20158C7.78285 8.96652 8.68457 9.48713 8.91963 10.3644Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}
