import React, { FC } from 'react'
import { useGetEventLink } from 'og-web-core/utils/events'

import { getEventTestDataId } from 'shared/lib/testData'
import { StyledEventTeamsNames } from 'entities/event'
import { Marquee } from 'shared/ui/Marquee'

import { routesWithoutPrevPath } from '../lib'

import { EventRowOpenEventPageProps } from './open-event-page.types'

export const EventRowOpenEventPage: FC<EventRowOpenEventPageProps> = ({
  children,
  event,
  marqueeId
}) => {
  const link = useGetEventLink({ event, routesWithoutPrevPath })
  const eventTestDataId = getEventTestDataId(event)

  return (
    <Marquee id={marqueeId || event?.tournament?.id} isMarqueeCustomColors>
      <StyledEventTeamsNames data-test-id={eventTestDataId} to={link}>
        {children}
      </StyledEventTeamsNames>
    </Marquee>
  )
}
