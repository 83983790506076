import React, { ElementRef, FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventStatus } from 'betweb-openapi-axios'
import { useGetHotProbsSportMarketOutcomeTypes } from 'astra-core/hooks/useGetHotProbsSportMarketOutcomeTypes'
import { getEventStatusType } from 'astra-core/utils/events'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import {
  ETestData,
  getCreateTestData,
  getEventTestDataId
} from 'shared/lib/testData'
import {
  CoefficientTableCellStyled,
  EventRow as EventRowEntity,
  EventRowActions,
  EventRowExpandButton
} from 'entities/event'
import {
  getFullEventComment,
  OpenActionH2h,
  OpenActionMatchLiveTracker,
  ShowActionComment,
  useEventRowHandleHover,
  useExpandEventRow
} from 'features/event'
import { EventFavouritesStarButton } from 'widgets/event-favourites-star-button'
import {
  StyledOutcomesHead,
  StyledOutcomesHeadRight,
  StyledOutcomesToggleDisplay
} from 'pages/page-live-events/Line/components/Outcomes/Outcomes.styled'
import { OutcomesGroupsTabs } from 'pages/page-live-events/Line/components/Outcomes/Filters/components/OutcomesFilterTabs'
import { InputSearch } from 'pages/page-live-events/Line/components/Event/components'
import { Outcomes } from 'pages/page-live-events/Line/components/Outcomes/Outcomes'
import { OutcomesComponentType } from 'pages/page-live-events/Line/components/Outcomes/Outcomes.types'
import { OutcomesCategories } from 'pages/page-live-events/Line/components/Outcomes/Table'
import { OpenActionMatchLiveBroadcast } from 'features/event/row/open-action-match-live-broadcast'

import { TableBetsRowContext } from '../../lib/context'
import { EventRowTeamsNames } from '../teams-names'
import { EventRowCoefficient } from '../coefficient'
import { EventRowMessage } from '../message'
import { MiniStatistics } from '../mini-statistics'

import { ITableColumnHeaderListProps } from './event-row.types'

export const EventRow: FC<ITableColumnHeaderListProps> = ({
  event,
  isMainPage
}) => {
  const isShowAllEventProbs = useFeatureFlag(EFeatureFlags.SHOW_ALL_EVENT_PROBS)

  const [t] = useTranslation()
  const [areAllOpened, setAllOpened] = useState(true)
  const outcomesRef = useRef<ElementRef<typeof OutcomesCategories>>(null)
  const isSuspended = event.status === EventStatus.Suspended

  const { toggleOutcomesOpen, isOutcomesOpened } = useExpandEventRow({
    isSuspended
  })

  const { handleMouseEnter, handleMouseLeave } = useEventRowHandleHover({
    eventId: event.id,
    isHoverAvailable: !isOutcomesOpened
  })

  const isLoadingOutcomes = isShowAllEventProbs
    ? !event
    : event.status === EventStatus.Suspended

  const openAll = useCallback(() => {
    setAllOpened(true)
    outcomesRef?.current?.openAll()
  }, [outcomesRef])

  const closeAll = useCallback(() => {
    setAllOpened(false)
    outcomesRef?.current?.closeAll()
  }, [outcomesRef])

  const { outcomeTypes } = useGetHotProbsSportMarketOutcomeTypes({
    sportId: event.tournament.sportId
  })
  const eventTestDataId = getEventTestDataId(event)

  const { eventStatusType } = getEventStatusType(event)

  return (
    <TableBetsRowContext.Provider value={{ event }}>
      <EventRowEntity
        coefficientsItems={
          <>
            {outcomeTypes?.map((coefficientData) => (
              <EventRowCoefficient
                {...coefficientData}
                event={event}
                key={coefficientData.outcomeTypesName[0]}
              />
            ))}
          </>
        }
        expandedData={
          isOutcomesOpened && (
            <StyledOutcomesHead>
              <OutcomesGroupsTabs event={event} />

              <StyledOutcomesHeadRight>
                <InputSearch
                  eventId={event.id}
                  testData={ETestData.TestEventRowInputSearch}
                />

                <StyledOutcomesToggleDisplay
                  data-test-id={
                    areAllOpened
                      ? ETestData.TestEventRowFilterCollapseAll
                      : ETestData.TestEventRowFilterExpandAll
                  }
                  onClick={() => (areAllOpened ? closeAll() : openAll())}
                >
                  {t(areAllOpened ? 'collapse all' : 'expand all')}
                </StyledOutcomesToggleDisplay>
              </StyledOutcomesHeadRight>
            </StyledOutcomesHead>
          )
        }
        infoCellComponents={
          <>
            <EventRowTeamsNames event={event} />
            <MiniStatistics event={event} isMainPage={isMainPage} />
            <EventFavouritesStarButton
              data-test-id={getCreateTestData(
                eventTestDataId,
                ETestData.TestEventRowFavorite
              )}
              eventId={event.id}
              size={16}
            />
            <EventRowActions
              lineComponents={
                <>
                  <ShowActionComment
                    comment={getFullEventComment(event.comments)}
                  />
                  <OpenActionH2h event={event} />
                </>
              }
              liveComponents={
                <>
                  <ShowActionComment
                    comment={getFullEventComment(event.comments)}
                  />
                  <OpenActionMatchLiveTracker event={event} />
                  <OpenActionMatchLiveBroadcast event={event} />
                </>
              }
              isLive={event.live}
            />
          </>
        }
        lastCell={
          <CoefficientTableCellStyled withExpandButton>
            <EventRowExpandButton onClick={toggleOutcomesOpen}>
              {event.outcomesCount}
            </EventRowExpandButton>
          </CoefficientTableCellStyled>
        }
        outcomesComponent={
          isOutcomesOpened ? (
            <Outcomes
              eventId={event.id}
              eventStatusType={eventStatusType}
              isLoading={isLoadingOutcomes}
              outcomesRef={outcomesRef}
              type={OutcomesComponentType.EVENTS_LIST}
            />
          ) : null
        }
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        message={!!outcomeTypes?.length && isSuspended && <EventRowMessage />}
      />
    </TableBetsRowContext.Provider>
  )
}
