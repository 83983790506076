import styled, { css } from 'styled-components'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { columnGapPrefixes } from 'shared/lib/styled'
import { StyledIcon } from 'shared/ui/Icon'
import { EColorsNames } from 'shared/types/theme'

import { SPORT_CERTAIN_DATA } from '../../constants'

export const StyledActions = styled.div<{ sportCode: ESportsCodes }>`
  &:empty {
    display: none;
  }
  background: ${(p) =>
    p.sportCode
      ? (SPORT_CERTAIN_DATA[p.sportCode] || SPORT_CERTAIN_DATA.default)
          .fixedColor
      : 'none'};
  ${(props) => css`
    align-items: center;
    display: flex;
    grid-column-gap: 12px;
    height: 32px;
    justify-content: center;

    & > button {
      color: ${(props) => props.theme.colors.fixed.primary[0]};
      font-size: ${props.theme.fonts.size.s};
      font-weight: ${props.theme.fonts.weight.normal};

      span {
        color: ${(props) => props.theme.colors.fixed.primary[0]} !important;
      }
    }
  `}
`

const StyledActionsItemCss = css`
  align-items: center;
  appearance: none;
  background: none;
  ${columnGapPrefixes('4px')}
  border: none;
  cursor: pointer;
  display: flex;
  line-height: 16px;
  padding: 4px;
`

export const StyledActionsItem = styled.button`
  ${() => css`
    ${StyledActionsItemCss}

    &:hover {
      color: ${(props) => props.theme.colors.fixed.primary[0]};
    }
  `}
`

export const StyledActionFavourite = styled.button<{ $isActive?: boolean }>`
  ${(props) => css`
    ${StyledActionsItemCss}
    color: ${(props) => props.theme.colors.fixed.primary[0]};
    ${props.$isActive &&
    css`
      ${StyledIcon} {
        color: ${props.theme.colors.fixed[EColorsNames.Yellow][80]} !important;
      }
    `}

    &:hover {
      color: ${props.theme.colors.default.primary[90]};

      ${!props.$isActive &&
      css`
        ${StyledIcon} {
          color: ${(props) => props.theme.colors.fixed.primary[0]} !important;
        }
      `}
    }
  `}
`
