import { BasicScore, Event } from 'betweb-openapi-axios'

import { EEventStatisticsCodes } from 'widgets/event-statistics'

export const getEventStatisticsByCode = (
  event: Event,
  code: EEventStatisticsCodes
) => event.statistics.find((stat) => stat.code === code)

export const getEventStatisticScoreCurrent = (currentScore?: BasicScore) =>
  currentScore ? `${currentScore.homeScore}:${currentScore.awayScore}` : ''

export const getEventStatisticScoreByPeriod = (
  scoresByPeriod?: BasicScore[]
): TGetEventStatisticScoreByPeriodReturnedType =>
  scoresByPeriod
    ? scoresByPeriod.map((score) => `${score.homeScore}:${score.awayScore}`)
    : []

export type TGetEventStatisticScoreByPeriodReturnedType =
  | Array<`${number}:${number}`>
  | Array<unknown>
