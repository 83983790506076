import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatAmountLocaleString, useGetCurrencyIcon } from 'astra-core'
import {
  betsHistoryProviderActions,
  selectCashoutByBetId,
  selectCashoutError,
  selectCurrentCashout
} from 'astra-core/containers/BetsHistoryProvider'

import { RootState } from 'shared/types/store'

import { DISABLE_HOVER_ON_CHILD } from '../../constants'

import { StyledCurrentCashoutWrapper } from './CurrentCashoutModal.styled'
import { getCurrentCashoutModalContent } from './constants'

export const CurrentCashoutModal = () => {
  const [t] = useTranslation()
  const currentCashout = useSelector(selectCurrentCashout)!
  const cashout = useSelector((state: RootState) =>
    selectCashoutByBetId(state, currentCashout.betId)
  )
  const cashoutError = useSelector(selectCashoutError)

  const currencyIcon = useGetCurrencyIcon()
  const dispatch = useDispatch()

  const { amount } = currentCashout

  const setCurrentCashout = () => {
    dispatch(
      betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
    )
  }

  const cashoutAmount = `${t(
    'confirm bet sale for'
  )} ${formatAmountLocaleString({
    value: amount,
    currency: currencyIcon
  })}`

  const isCashoutAmountHasChanged = amount !== cashout?.amount

  return (
    <StyledCurrentCashoutWrapper className={DISABLE_HOVER_ON_CHILD}>
      {getCurrentCashoutModalContent({
        cashoutError,
        cashout,
        isCashoutAmountHasChanged,
        setCurrentCashout,
        cashoutAmount
      })}
    </StyledCurrentCashoutWrapper>
  )
}
