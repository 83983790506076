import { useSelector } from 'react-redux'
import {
  ClientFilteredProbsMapWithOutcomeIds,
  MainOutcomeTypes,
  selectClientFilteredProbsMap,
  selectMainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'
import { useMemo } from 'react'
import { useProbs } from 'astra-core/hooks/useEvents'
import { Event, ParameterType } from 'betweb-openapi-axios'
import { getOutcomeParameterValue } from 'astra-core/utils/outcomes'

export const useGetTotalValue = ({
  event,
  totalOutcomeType
}: {
  event: Event
  totalOutcomeType: MainOutcomeTypes.TO | MainOutcomeTypes.TOO
}) => {
  const mainOutcomeTypes = useSelector(selectMainOutcomeTypes)
  const clientFilteredProbsMap = useSelector(selectClientFilteredProbsMap) // To filter Handicaps and Totals on client side to show in the events list as "main probs"

  const probs = useProbs(
    event,
    clientFilteredProbsMap as ClientFilteredProbsMapWithOutcomeIds
  )

  return useMemo(
    () =>
      getOutcomeParameterValue({
        eventProbability: probs[mainOutcomeTypes[totalOutcomeType]!],
        parameterType: ParameterType.Value
      }),
    [totalOutcomeType, mainOutcomeTypes, probs]
  )
}
