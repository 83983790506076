import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: auto 1fr;
  height: 100%;
`

export const StyledTabsWrapper = styled.div`
  padding: 0 8px 0 16px;
`

export const StyledSportsMenu = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  border-right: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  grid-area: sports-menu;
  overflow: hidden;
  padding-top: 16px;
`

export const StyledCalendarMenuTitle = styled.div`
  line-height: 20px;
  text-transform: uppercase;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.semiBold};
      color: ${theme.colors.default.primary[90]};
    `}
`
