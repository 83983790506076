import styled, { css } from 'styled-components'
import { BetStatus } from 'betweb-openapi-axios'

import { TThemeColors } from 'shared/types/theme'
import { DataListWithReduxPagination } from 'shared/ui/DataList'

import {
  BasketHistoryItemResultWrapperProps,
  BetsInfoProps,
  BetsStatusProps
} from './BasketHistoryBody.types'

export const StyledBasketHistoryBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  overflow-y: auto;
`

export const StyledBasketHistoryItemWrapper = styled.div`
  padding-bottom: 4px;
  position: relative;
`

export const StyledBasketHistoryItemHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`

export const StyledBasketHistoryItemHeaderBetType = styled.div`
  color: ${(props) => props.theme.colors.custom.primary[16]};
  flex: 1;
  line-height: 20px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.bold};
    `}
`

export const StyledBasketHistoryItemHeaderBetTime = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[50]};
  line-height: 20px;
  margin-left: 8px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledBasketHistoryItemBorder = styled.div`
  ${({ theme }) =>
    `border-bottom: 1px solid ${theme.colors.default.primary[10]};`}
`

// Style TextAndNumber start

export const StyledTextAndNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
`

export const StyledTextLeftSide = styled.div`
  line-height: 14px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledNumberRightSide = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

// Style TextAndNumber end

// TODO check some status RETURNED, RETURNED_COMMITED, CANCELED
const basketHistoryItemResultColor = (
  colors: TThemeColors,
  status: BetStatus
) => {
  const color = {
    CREATED: colors.custom.primary[8],
    CREATED_COMMITED: colors.custom.primary[8],
    [BetStatus.Current]: colors.custom.primary[8],
    [BetStatus.Returned]: colors.custom.yellow[4],
    RETURNED_COMMITED: colors.custom.yellow[4],
    [BetStatus.Won]: colors.custom.green[2],
    WON_COMMITED: colors.custom.green[2],
    [BetStatus.Canceled]: colors.custom.yellow[4],
    [BetStatus.Lost]: colors.custom.red[1],
    [BetStatus.Sold]: colors.custom.blue[27]
  }

  return color[status]
}

export const StyledBasketHistoryItemResultWrapper = styled.div<BasketHistoryItemResultWrapperProps>`
  ${(props) => css`
    background-color: ${basketHistoryItemResultColor(
      props.theme.colors,
      props.status
    )};
    border-radius: 4px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 4px 8px;
  `}
  ${({ isTwoLines }) => `flex-wrap: ${isTwoLines ? 'wrap' : 'nowrap'}`};
  ${({ isTwoLines }) => `flex-direction: ${isTwoLines ? 'column' : 'row'}`};
`

export const StyledWideBorder = styled.div`
  height: 1px;
  margin-top: 4px;
  width: 100%;
  ${(p) =>
    `
      background-color: ${p.theme.colors.default.primary[10]};
      &:last-of-type {
        height: 0;
      }
    `}
`

export const StyledDataList = styled(DataListWithReduxPagination)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

export const StyledBetsInfo = styled.div<BetsInfoProps>`
  align-items: center;
  display: flex;
  gap: 4px;
  ${({ isTwoLines }) => isTwoLines && 'flex: 1;'}
  ${({ isTwoLines }) =>
    `justify-content:${isTwoLines ? 'space-between' : 'flex-start'}`};
  ${({ isLongSumValue }) =>
    `justify-content:${isLongSumValue ? 'flex-start' : 'space-between'}`};
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
      color: ${theme.colors.default.primary[90]};
    `}
`
export const StyledBetsStatus = styled.div<BetsStatusProps>`
  white-space: nowrap;
  ${({ theme }) => `color: ${theme.colors.default.primary[90]};`}
`

export const StyledBetsValue = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
`

export const StyledDataListEmpty = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 76px;
`

export const StyledDataListTitle = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[16]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
  `}
`

export const StyledDataListText = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.fixed.primary[50]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.regular};
    line-height: 20px;
  `}
`

export const StyledBonusWalletIconWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 5px;
`
