import React, { FC } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { Event } from 'betweb-openapi-axios'

import {
  MiniStatistics as MiniStatisticsEntity,
  StyledMiniStatisticsTime
} from 'entities/event'
import { EventStatistics } from 'widgets/event-statistics' // TODO: do EventStatistics as entity
import { TIME_DATA_FORMAT, TIME_DATE_DATA_FORMAT } from 'hooks/useGetNowTime'

export const MiniStatistics: FC<{ event: Event; isMainPage?: boolean }> = ({
  event,
  isMainPage
}) => {
  const isLiveEvent = event.live

  return (
    <MiniStatisticsEntity
      lineStatisticsComponents={
        !isLiveEvent && (
          <StyledMiniStatisticsTime>
            {dayjs(event.eventDate).format(
              isMainPage ? TIME_DATE_DATA_FORMAT : TIME_DATA_FORMAT
            )}
          </StyledMiniStatisticsTime>
        )
      }
      liveStatisticsComponents={
        isLiveEvent && (
          <EventStatistics event={event} adaptiveScoreByPeriods showLiveIcon />
        )
      }
    />
  )
}
