import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLivePointWhite: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
      <circle cx={3} cy={3} fill="#fff" r={2.5} />
    </svg>
  </Icon>
)
