import { selectConfig } from 'astra-core/containers/ConfigProvider/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { IconAndroid } from 'shared/ui/Icon/General/IconAndroid'
import { useLandingAppUrl } from 'features/footer-of-page/utils'

import {
  StyledButtonLink,
  StyledButtonWrapper,
  StyledText
} from './LandingButton.styled'

export const LandingButton = () => {
  const config = useSelector(selectConfig)
  const landingUrl = useLandingAppUrl(config)

  return (
    <StyledButtonWrapper>
      <StyledButtonLink href={landingUrl} rel="noreferrer" target="_blank">
        <IconAndroid />
        <StyledText>Android</StyledText>
      </StyledButtonLink>
    </StyledButtonWrapper>
  )
}
