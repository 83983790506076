import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'

export const StyledBasketButtonSmallWindowWrapper = styled.div<{
  isRouteWithPermanentHiddenBasket: boolean
}>`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: space-between;
  line-height: 20px;
  padding: 0 8px;

  ${({ isRouteWithPermanentHiddenBasket }) =>
    !isRouteWithPermanentHiddenBasket
      ? css`
          display: none;
        `
      : ''}

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.default.primary[90]};
      color: ${theme.colors.custom.primary[20]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.normal};
    `}

  ${customMedia.small} {
    display: flex;
  }
`

export const StyledBasketButtonSmallWindowText = styled.div``
export const StyledBasketButtonSmallWindowSize = styled.div`
  margin-left: 10px;
  margin-right: 4px;
`
export const StyledBasketButtonSmallWindowCoefficient = styled.div`
  border-radius: 2px;
  line-height: 16px;
  padding: 2px 4px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[69]};
      font-size: ${theme.fonts.size.s};
    `}
`
