import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleMakeQuickBet } from 'og-web-core/providers/basket/quick-bet/hooks/useHandleMakeQuickBet'
import { QuickBetOutcome } from 'astra-core/containers/BasketProvider'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { setLocalStorageItem } from 'og-web-core/utils/storage/local-storage'
import { StorageKeys } from 'og-web-core/utils/storage/constants'

import { Button, EButtonViews } from 'shared/ui/Button'
import { Checkbox } from 'shared/ui/checkbox'
import { BasketQuickBetSubmitButton } from 'widgets/right-container/Basket/BasketQuickBet/components/BasketQuickBetSubmitButton/BasketQuickBetSubmitButton'
import { useNotifyMakeQuickBet } from 'hooks/useNotifyMakeQuickBet'

import {
  StyledButtonWrapper,
  StyledConfirmQuickContentDescription
} from './ConfirmQuickContent.styled'

interface ConfirmQuickContentProps {
  quickBetValue: number
  outcome: QuickBetOutcome
  onClickClose: () => void
}

export const ConfirmQuickContent: FC<ConfirmQuickContentProps> = ({
  outcome,
  onClickClose,
  quickBetValue
}) => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const { competitorsNames, basketOutcomeName } = useOutcomeInfo(
    outcome.outcomeCompositeId
  )

  const notifyCallback = useNotifyMakeQuickBet()
  const { handleMakeQuickBet, isLoading } =
    useHandleMakeQuickBet(notifyCallback)

  const [isChecked, setIsChecked] = useState(true)

  const handleCheckBox = () => {
    setIsChecked(!isChecked)
  }

  const handleSubmit = useCallback(() => {
    setLocalStorageItem(StorageKeys.shouldConfirmQuickBet, !isChecked)
    handleMakeQuickBet(outcome)
  }, [handleMakeQuickBet, isChecked, outcome])

  return (
    <>
      <StyledConfirmQuickContentDescription>
        <div>
          Вы действительно хотите сделать ставку
          <strong>{` ${competitorsNames} ${basketOutcomeName} ${outcome.coefficient} `}</strong>
          на сумму <strong>{`${quickBetValue} ${currencyIcon} ?`}</strong>
        </div>
      </StyledConfirmQuickContentDescription>
      <Checkbox
        checked={isChecked}
        label={t('not show message')}
        onChange={handleCheckBox}
      />
      <StyledButtonWrapper>
        <BasketQuickBetSubmitButton
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          title={t('confirm')}
        />
        <Button view={EButtonViews.SECONDARY} onClick={onClickClose}>
          {t('сancel')}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
