import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import React, { FC } from 'react'

import { StyledScore } from '../../EventStatisticsScore.styled'

import { IEventStatisticScoreGameProps } from './EventStatisticScoreGame.types'

export const EventStatisticScoreGame: FC<IEventStatisticScoreGameProps> = ({
  value,
  sportCode,
  ...props
}) => {
  const formattedValue =
    sportCode === ESportsCodes.TENNIS ? value.replace(/50/g, 'A') : value

  return <StyledScore {...props}>{formattedValue}</StyledScore>
}
