import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { EIconButtonShape, EIconButtonSize, IIconPropsButton } from './types'

export const DEFAULT_STYLED_ICON_BUTTON_PROPS: IIconPropsButton = {
  isActiveState: false,
  shape: EIconButtonShape.Default,
  withBackground: true,
  size: EIconButtonSize.M
}

export const DEFAULT_COLOR_PROPS = {
  name: EColorsNames.Primary,
  value: 12,
  type: EColorsTypes.CUSTOM
}
