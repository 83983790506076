import styled, { css } from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

export const StyledEventDateTimeContent = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

export const StyledEventDateTime = styled.div<{ isLive?: boolean }>`
  ${(props) => css`
    align-items: center;
    background-color: ${props.theme.colors.custom[EColorsNames.Primary][20]};
    border-radius: 4px;
    color: ${props.theme.colors.default[EColorsNames.Primary][90]};
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight[
      props.isLive ? 'semiBold' : 'normal'
    ]};
    gap: 8px;
    line-height: 16px;
    padding: 4px 8px;
  `}
`

export const StyledEventDateTimeAdditional = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.custom[EColorsNames.Primary][17]};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.custom[EColorsNames.Primary][21]};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  margin-left: 8px;
  padding: 0 2px;
`

export const StyledAdditionalTime = styled.div`
  background-color: ${(p) => p.theme.colors.default[EColorsNames.Primary][5]};
  border-radius: 2px;
  color: ${(p) => p.theme.colors.default[EColorsNames.Primary][50]};
  font-size: ${(p) => p.theme.fonts.size.xs};
  line-height: 16px;
  padding: 0 2px;
`
