import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconProfileMenuBanner } from 'shared/ui/Icon/General/IconProfileMenuBanner'
import { ComponentOfUnauthorized } from 'features/ComponentOfUnauthorized'

import {
  StyledMenuBannerWrapper,
  StyledMenuBannerText,
  StyledMenuBannerTitle,
  StyledMenuBannerDeskription
} from './ProfileMenuBanner.styled'

export const ProfileMenuBanner = memo(() => {
  const [t] = useTranslation()

  return (
    <StyledMenuBannerWrapper>
      <IconProfileMenuBanner />
      <StyledMenuBannerText>
        <StyledMenuBannerTitle>
          {t('login to your account')}
        </StyledMenuBannerTitle>
        <StyledMenuBannerDeskription>
          {t('place any bets')}
        </StyledMenuBannerDeskription>
      </StyledMenuBannerText>
      <ComponentOfUnauthorized profile />
    </StyledMenuBannerWrapper>
  )
})
