import { css, ThemeProps } from 'styled-components'

import { ThemeContext } from 'shared/types/theme'

import { HINT_ATTR_DATA } from './constants'

export const HINT_ATTR = (props: ThemeProps<ThemeContext>) => css`
  [${HINT_ATTR_DATA.nameAttr}] {
    position: relative;
  }

  ${HINT_ATTR_DATA.body} {
    background: ${props.theme.colors.default.primary[70]};
    border-radius: 4px;
    bottom: -24px;
    color: ${props.theme.colors.default.primary[10]};
    content: attr(${HINT_ATTR_DATA.nameAttr});
    display: inline-block;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    left: 0;
    line-height: 16px;
    overflow: hidden;
    padding: 2px 4px;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    z-index: 1001;
  }

  [${HINT_ATTR_DATA.nameAttr}]:hover::after {
    border: 9px solid transparent;
    border-bottom-color: ${props.theme.colors.default.primary[70]};
    bottom: -8px;
    content: '';
    display: inline-block;
    left: 2px;
    position: absolute;
    z-index: 1000;
  }
`
