import React, { memo } from 'react'

import { SideMenu } from 'shared/ui/SideMenu'

import { PROFILE_MENU_ROUTES_LIST } from './routes'
import { ProfileMenuBanner } from './components/ProfileMenuBanner'

export const UnauthorizedProfileMenu = memo(() => {
  return (
    <>
      <ProfileMenuBanner />
      <SideMenu routeList={PROFILE_MENU_ROUTES_LIST} />
    </>
  )
})
