import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBalanceWithPlus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M13.9994 3.3381C13.9801 3.84054 13.5219 4.54057 12.2096 5.17503C12.1734 5.19255 12.1367 5.20991 12.0996 5.22709C11.5717 5.35572 11.0642 5.53673 10.5832 5.76431C9.53992 6.04157 8.3193 6.20579 7 6.20579C4.91244 6.20579 3.07196 5.79464 1.79036 5.17503C0.478052 4.54057 0.0199011 3.84054 0.000638525 3.3381C0.0517589 1.4903 3.1659 0 7 0C10.8341 0 13.9482 1.4903 13.9994 3.3381ZM7.65441 8.12764C7.438 8.13562 7.21976 8.13966 7 8.13966C4.66928 8.13966 2.50977 7.68498 0.895932 6.90474C0.585851 6.75483 0.284216 6.58638 0 6.3998V6.98009C0 7.48458 0.448949 8.20162 1.79036 8.85015C2.99624 9.43316 4.69693 9.8316 6.63266 9.87665C6.8993 9.24848 7.24393 8.66143 7.65441 8.12764ZM6.09068 11.7915C4.11102 11.6896 2.29765 11.2576 0.895932 10.5799C0.585851 10.43 0.284216 10.2615 0 10.0749V10.1529C0 11.8604 2.61571 13.2726 6.01562 13.5039C6.00526 13.3373 6 13.1693 6 13C6 12.5892 6.03096 12.1856 6.09068 11.7915ZM6.32511 15.2649C4.25435 15.1861 2.3528 14.7471 0.895932 14.0427C0.585851 13.8928 0.284216 13.7244 0 13.5378V13.6157C0 15.4848 3.13401 17 7 17C7.02342 17 7.0468 16.9999 7.07016 16.9998C6.75699 16.4584 6.50544 15.8769 6.32511 15.2649Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14 19C17.3137 19 20 16.3137 20 13C20 9.68629 17.3137 7 14 7C10.6863 7 8 9.68629 8 13C8 16.3137 10.6863 19 14 19ZM13.1819 10.5455C13.1819 10.0936 13.5482 9.72728 14 9.72728C14.4519 9.72728 14.8182 10.0936 14.8182 10.5455V12.1818H16.4546C16.9064 12.1818 17.2728 12.5481 17.2728 13C17.2728 13.4519 16.9064 13.8182 16.4546 13.8182H14.8182V15.4546C14.8182 15.9064 14.4519 16.2727 14 16.2727C13.5482 16.2727 13.1819 15.9064 13.1819 15.4546V13.8182H11.5455C11.0936 13.8182 10.7273 13.4519 10.7273 13C10.7273 12.5481 11.0936 12.1818 11.5455 12.1818H13.1819V10.5455Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
