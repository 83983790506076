import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import {
  EventProbability,
  EventProbabilityTradingStatus,
  ParameterType,
  Event
} from 'betweb-openapi-axios'
import {
  getBasketOutcomeKey,
  selectBasketOutcomeByKey
} from 'astra-core/containers/BasketProvider'
import { EVENT_STATUSES_BETS_ENABLED } from 'astra-core/containers/EventsProvider/utils'

import { usePrevious } from 'hooks'
import { RootState } from 'shared/types/store'
import {
  EventOddProps,
  OddModes,
  UseOddHighlightType
} from 'pages/page-live-events/Line/Line.types'
import { OutcomeOddProps } from 'pages/page-live-events/Line/components/Outcomes/Outcomes.types'

export type TUseProbs = Dictionary<EventProbability>

const ODD_HIGHLIGHT_TIMEOUT = 2000

export function useOddHighlight(
  probability?: EventProbability
): UseOddHighlightType {
  const prevOdd = usePrevious(probability?.odd)
  const [mode, setMode] = useState<OddModes>(OddModes.Default)

  useEffect(() => {
    if (prevOdd && probability && prevOdd !== probability.odd) {
      if (prevOdd! < probability?.odd) {
        setMode(OddModes.Increase)
      } else if (prevOdd! > probability?.odd) {
        setMode(OddModes.Decrease)
      }
    }
  }, [prevOdd, probability])

  useEffect(() => {
    if (mode !== OddModes.Default) {
      const timerId = setTimeout(
        () => setMode(OddModes.Default),
        ODD_HIGHLIGHT_TIMEOUT
      )
      return () => clearTimeout(timerId)
    }
    return () => null
  }, [mode])

  return { mode, prevOdd }
}

// TODO: transfer to entity / core
export function useEventMode({
  eventProbability,
  eventStatus
}: {
  eventProbability: EventOddProps['eventProbability']
  eventStatus?: Event['status']
}): OddModes {
  const { mode } = useOddHighlight(eventProbability)

  const basketOutcomeKey = useMemo(
    () =>
      !!eventProbability &&
      !!eventProbability.outcomeTypeId &&
      !!eventProbability.parameters &&
      !!eventProbability.eventId
        ? getBasketOutcomeKey({
            probability: {
              outcomeTypeId: eventProbability.outcomeTypeId,
              parameters: eventProbability.parameters
            },
            event: { id: eventProbability.eventId }
          })
        : '',
    [eventProbability]
  )

  const isOutcomeInBasket = useSelector((state: RootState) =>
    selectBasketOutcomeByKey(state, basketOutcomeKey)
  )

  const isOutcomeDisabled =
    eventProbability?.tradingStatus !== EventProbabilityTradingStatus.Trading ||
    (!!eventStatus && !EVENT_STATUSES_BETS_ENABLED.includes(eventStatus))

  return useMemo(
    () =>
      isOutcomeDisabled
        ? OddModes.Disabled
        : isOutcomeInBasket
        ? OddModes.Active
        : mode,
    [isOutcomeInBasket, isOutcomeDisabled, mode]
  )
}

export const useTitleMergedColumns = (
  eventProbability: OutcomeOddProps['eventProbability']
) => {
  const pointRange = eventProbability.parameters.find(
    (param) => param.type === ParameterType.PointRange
  )

  if (pointRange) {
    let title = `${pointRange.value}`

    if (eventProbability?.outcomeTypeData.name) {
      title = `${eventProbability?.outcomeTypeData.name} ${title}`
    }

    return title
  }

  const winningMarginObj = eventProbability.parameters.find(
    (param) => param.type === ParameterType.WinningMargin
  )

  if (winningMarginObj) {
    return `${eventProbability.outcomeTypeData.name} ${winningMarginObj.value}`
  }

  const goalsAndTimeParams = eventProbability.parameters.filter(
    (param) =>
      param.type === ParameterType.GoalsRange ||
      param.type === ParameterType.ExactGoals ||
      param.type === ParameterType.TimeRange
  )

  if (eventProbability?.outcomeTypeData.name.trim()) {
    return eventProbability?.outcomeTypeData.name
  }

  if (goalsAndTimeParams.length > 0) {
    return goalsAndTimeParams[0].value
  }

  return ''
}
