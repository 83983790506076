import { ETestData } from 'shared/lib/testData'

export interface SwitchProps {
  active?: boolean
  label?: string
  onClick?: () => void
  disabled?: boolean
  testData?: ETestData
  view?: ESwitchView
}

export enum ESwitchView {
  PRIMARY = 'primary',
  BASKET = 'basket',
  QUICK_BET = 'quickBet'
}
