import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconProfile: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.861 3.9524C9.861 5.63556 8.58004 7.00002 6.9999 7.00002C5.41976 7.00002 4.1388 5.63556 4.1388 3.9524C4.1388 2.26925 5.41976 0.904785 6.9999 0.904785C8.58004 0.904785 9.861 2.26925 9.861 3.9524Z"
        fill="currentColor"
      />
      <path
        d="M1.85626 14.4655C-0.251374 13.9751 -0.661184 11.4389 1.12877 10.1561C2.72889 9.00944 4.77287 8.71434 6.9999 8.71434C9.22706 8.71434 11.2711 9.00951 12.8713 10.1563C14.6612 11.4391 14.2513 13.9753 12.1437 14.4656C9.08098 15.1782 4.91894 15.1782 1.85626 14.4655Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
