import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTime } from '../../EventStatisticTime.styled'

import { IEventStatisticTimeAddedProps } from './EventStatisticTimeAdded.types'

export const EventStatisticTimeAdded: FC<IEventStatisticTimeAddedProps> = ({
  value
}) => {
  const { t } = useTranslation()

  if (Number.parseInt(value, 10) === 0) {
    return null
  }
  return <StyledTime isPlain>{`+${value} ${t('min')}`}</StyledTime>
}
