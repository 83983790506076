import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'

import { ESportsMenuTabTypes } from 'widgets/sports-menu'

export const isActiveSport = (
  pathname: string,
  currentTabMenu: ESportsMenuTabTypes,
  sportId: number
) => {
  const [, currentPath, sport] = pathname.split('/')

  return currentPath === currentTabMenu && +sport === sportId
}

export const getMenuTabSportsCounterType = (
  currentMenuTab: ESportsMenuTabTypes
) => {
  return currentMenuTab === ESportsMenuTabTypes.LINE
    ? ESportsCounterType.LINE
    : ESportsCounterType.LIVE
}
