import styled from 'styled-components'

import { ETeamLogo } from './TeamLogo.types'
import { getTeamLogoStyles } from './utils'

export const StyledTeamLogo = styled.div<{ isPlace?: ETeamLogo }>`
  align-items: center;
  display: flex;
  ${({ isPlace }) => {
    const styles = getTeamLogoStyles(isPlace)
    return `
      height: ${styles.height};
      margin-right: ${styles.marginRight};
      width: ${styles.width};
    `
  }}
`
