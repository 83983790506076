import React, { createContext, FC, useContext, useCallback, memo } from 'react'

import { TabsItemProps, TabsProps } from './Tabs.types'
import { StyledTabs, StyledTabsItem } from './Tabs.styled'

const TabsContext = createContext<TabsProps>({ variant: 1 })

export const Tabs: FC<TabsProps> = (props) => {
  return (
    <TabsContext.Provider value={props}>
      <StyledTabs {...props} />
    </TabsContext.Provider>
  )
}

export const TabsItem: FC<TabsItemProps> = memo(
  ({ children, icon: Icon, iconProps, testData, ...props }) => {
    const { combined, variant } = useContext<TabsProps>(TabsContext)
    const { handleTabChange, type } = props

    const handleTabClick = useCallback(() => {
      if (handleTabChange) {
        handleTabChange(type)
      }
    }, [handleTabChange, type])

    return (
      <StyledTabsItem
        {...props}
        combined={combined}
        data-test-id={testData}
        variant={variant}
        onClick={handleTabClick}
      >
        {!!Icon && <Icon size={16} {...iconProps} />}
        {children}
      </StyledTabsItem>
    )
  }
)
