import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'

import { ERoutes } from 'shared/types/routes'

import { BasketQuickBetForm } from '../../../right-container/Basket/BasketQuickBet/components/BasketQuickBetForm/BasketQuickBetForm'

import {
  StyledLink,
  StyledLowerBalanceModalDescription,
  StyledLowerBalanceModalDescriptionWrapper
} from './LowerBalanceContent.styled'
import { LowerBalanceContentProps } from './LowerBalanceContent.types'

export const LowerBalanceContent: FC<LowerBalanceContentProps> = ({
  onClickClose
}) => {
  const { t } = useTranslation()
  const balance = useSelector(selectAccountBalance)
  const currency = useGetCurrencyIcon()

  return (
    <>
      <StyledLowerBalanceModalDescriptionWrapper>
        <StyledLowerBalanceModalDescription>
          {t('amount on your balance is lower')} <br />
          {t('change your bet')}
        </StyledLowerBalanceModalDescription>
        <StyledLowerBalanceModalDescription>
          {t('your balance', {
            balance,
            currency
          })}
          <StyledLink to={ERoutes.ProfileBalance} onClick={onClickClose}>
            {t('up balance')}
          </StyledLink>
        </StyledLowerBalanceModalDescription>
      </StyledLowerBalanceModalDescriptionWrapper>

      <BasketQuickBetForm />
    </>
  )
}
