import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'

import {
  ButtonProps,
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews,
  StyledButtonAdditionalTextProps
} from './types'

export const StyledButton = styled.button<ButtonProps>`
  ${(props) => css`
    align-items: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    outline: none;
    transition: background-color 0.2s;
    white-space: nowrap;
    width: fit-content;

    ${props.iconProps?.positionSide === EButtonIconPositionSides.Left &&
    css`
      ${StyledIcon} {
        margin-right: 8px;
      }
    `}

    ${props.size === EButtonSizes.M &&
    css`
      font-size: ${props.theme.fonts.size.m};
      height: 32px;
      line-height: 20px;
      padding: 6px 8px;
    `}

    ${props.size === EButtonSizes.S &&
    css`
      font-size: ${props.theme.fonts.size.s};
      height: 24px;
      line-height: 16px;
      padding: 4px 6px;
    `}
    
    ${props.view === EButtonViews.PRIMARY &&
    css`
      background-color: ${props.theme.colors.default.primary[90]};
      color: ${props.theme.colors.custom.primary[20]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[20]} !important;
      }

      &:hover {
        background-color: ${props.theme.colors.default.primary[80]};
      }

      &:active {
        background-color: ${props.theme.colors.custom.primary[23]};
      }

      &:disabled {
        background-color: ${props.theme.colors.custom.primary[24]};
        color: ${props.theme.colors.custom.primary[25]};
      }
    `}

    ${props.view === EButtonViews.SECONDARY &&
    css`
      background-color: ${props.theme.colors.custom.primary[4]};
      color: ${props.theme.colors.custom.primary[7]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[90]} !important;
      }

      &:hover {
        background-color: ${props.theme.colors.default.primary[10]};
      }

      &:active {
        background-color: ${props.theme.colors.default.primary[15]};
      }

      &:disabled {
        background-color: ${props.theme.colors.custom.primary[4]};
        color: ${props.theme.colors.custom.primary[26]};
      }
    `}

    ${props.view === EButtonViews.SELL_BET &&
    css`
      background-color: ${props.theme.colors.custom.primary[4]};
      color: ${props.theme.colors.custom.primary[7]};

      &:disabled {
        background-color: ${props.theme.colors.custom.primary[4]};
        color: ${props.theme.colors.custom.primary[26]};
      }
    `}

    ${props.view === EButtonViews.NONE &&
    css`
      background-color: transparent;
      padding: 0;
    `}

    ${props.view === EButtonViews.REGISTRATION &&
    css`
      background: linear-gradient(180deg, #6c7 0%, #40bf55 100%);
      color: ${(props) => props.theme.colors.fixed.primary[0]};

      &:hover {
        background: linear-gradient(180deg, #40bf55 0%, #394 100%);
      }

      &:active {
        background: linear-gradient(180deg, #394 0%, #267333 100%);
      }
    `}

    ${props.round &&
    css`
      border-radius: 32px;
    `}
  `}
`

export const StyledButtonAdditionalText = styled.span<StyledButtonAdditionalTextProps>`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[50]};
    margin-left: ${props.icon ? '10px' : '0'};
  `}
`

export const StyledIconWrap = styled.span`
  align-items: center;
  display: inline-flex;
  margin-left: 5px;
`
