import styled from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { EColorsNames } from 'shared/types/theme'

export const StyledLiveIndicator = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.fixed[EColorsNames.Red][0]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.fixed[EColorsNames.Primary][0]};
  display: flex;
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  height: 24px;
  padding: 0 8px;
  text-transform: uppercase;

  ${StyledIcon} {
    margin-right: 4px;
  }
`
