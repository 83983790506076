import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { MenuMainHeader } from 'widgets/header/Menu'
import { EMenuView } from 'widgets/header/Menu/Menu.types'
import { ERoutes } from 'shared/types/routes'
import { filterRoutes } from 'shared/lib/routes'

import { Logo, PersonalData } from './components'
import { MAIN_HEADER_ROUTES_LIST } from './routes'
import { StyledMainHeader, StyledMainHeaderContent } from './MainHeader.styled'

export const MainHeader = memo(() => {
  const [t] = useTranslation()
  const casinoMatches = useRouteMatch(ERoutes.Casino)

  const menuItems = useMemo(
    () =>
      filterRoutes({
        routeList: MAIN_HEADER_ROUTES_LIST,
        t
      }),
    [t]
  )

  return (
    <StyledMainHeader>
      <Logo />

      <StyledMainHeaderContent>
        {!casinoMatches && (
          <MenuMainHeader list={menuItems} view={EMenuView.LIGHT} />
        )}
      </StyledMainHeaderContent>

      <PersonalData />
    </StyledMainHeader>
  )
})
