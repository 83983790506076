import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { EPostMessageIdEvent } from 'astra-core/containers/AuthMessageProvider/AuthMessageProvider.types'

import { ADVERT_INFO_COOKIE } from 'constants/cookies.constants'
import { ERoutes } from 'shared/types/routes'
import { LoaderPoints } from 'shared/ui/LoaderPoints'

import {
  StyledSuccessPage,
  StyledSuccessPageWrapper,
  GlobalStyleHideLiveChatPatch
} from './SuccessPage.styled'
import { SuccessAuthComponents } from './components'
import { SUCCESS_AUTH_TEXT } from './constants'

export const SuccessPages = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    Cookies.remove(ADVERT_INFO_COOKIE)
  }, [])

  const isPageSignUp = useMemo(
    () =>
      location.pathname === ERoutes.SuccessFastSignUp ||
      location.pathname === ERoutes.SuccessFullSignUp,
    [location.pathname]
  )

  const isLoginPage = useMemo(
    () => location.pathname === ERoutes.SuccessLoginPage,
    [location.pathname]
  )

  // inside the iframe calls a modal window about successful registration
  useEffect(() => {
    if (isPageSignUp) {
      window.parent.postMessage(
        {
          event_id: EPostMessageIdEvent.pageChange,
          event_payload: { isSuccessSignUp: true }
        },
        '*'
      )
    }
  }, [isPageSignUp, location.pathname])

  useEffect(() => {
    if (isLoginPage) {
      window.parent.postMessage(
        {
          event_id: EPostMessageIdEvent.pageChange,
          event_payload: { isSuccess: true }
        },
        '*'
      )
    }
  }, [dispatch, isLoginPage])

  return (
    <StyledSuccessPageWrapper>
      <GlobalStyleHideLiveChatPatch />
      <StyledSuccessPage>
        {isPageSignUp ? (
          <LoaderPoints />
        ) : (
          <SuccessAuthComponents text={SUCCESS_AUTH_TEXT} />
        )}
      </StyledSuccessPage>
    </StyledSuccessPageWrapper>
  )
}
