import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { EColorsTypes } from 'shared/types/theme'

import {
  StyledQuickBetSubmitButton,
  StyledQuickBetSubmitButtonText
} from './BasketQuickBetSubmitButton.styled'
import { BasketQuickBetSubmitButtonProps } from './BasketQuickBetSubmitButton.types'

export const BasketQuickBetSubmitButton: FC<BasketQuickBetSubmitButtonProps> = (
  props
) => {
  const { title, handleSubmit, isLoading, disabled } = props

  const { t } = useTranslation()
  const { colors } = useTheme()

  const handleClick = () => {
    if (isLoading) {
      return
    }
    handleSubmit()
  }

  return (
    <StyledQuickBetSubmitButton disabled={disabled} onClick={handleClick}>
      {isLoading ? (
        <LoaderPoints
          colors={{
            start: colors[EColorsTypes.DEFAULT].primary[0],
            end: colors[EColorsTypes.DEFAULT].primary[60]
          }}
          settingsAnimation={{
            timeAfterEnd: 2.4,
            scaleActivePoint: 1
          }}
          count={3}
          margin={2}
          size={4}
        />
      ) : (
        <StyledQuickBetSubmitButtonText>
          {t(title)}
        </StyledQuickBetSubmitButtonText>
      )}
    </StyledQuickBetSubmitButton>
  )
}
