import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  ECashoutStatusView,
  selectCashoutError,
  selectCashoutSoldBet,
  selectCashoutStatusView,
  selectCurrentCashout
} from 'astra-core/containers/BetsHistoryProvider'

import { ETestData } from 'shared/lib/testData'
import { useTimer } from 'hooks'
import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'

import {
  TOASTIFY_CASHOUT_ID,
  TToastifyCashoutNotificationProps
} from './WithdrawalFormMethods.types'
import {
  StyledDeleteHistoryHint,
  StyledIconDollarInCircle,
  StyledToastifyCashoutNotificationSold,
  StyledToastifyCashoutNotificationText,
  StyledToastifyCashoutNotificationTextWrapper
} from './ToastifyCashoutNotification.styled'
import { TOASTIFY_CASHOUT_NOTIFICATION_TIMER } from './constants'

export const ToastifyCashoutNotification: FC<
  TToastifyCashoutNotificationProps
> = ({ toastProps }) => {
  const seconds = useTimer(TOASTIFY_CASHOUT_NOTIFICATION_TIMER)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!seconds) {
      toast.update(toastProps.toastId, {
        closeButton: false,
        containerId: ENotificationContainerIds.SYSTEM,
        autoClose: 10,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: false,
        toastId: TOASTIFY_CASHOUT_ID
      })
    }
  }, [dispatch, seconds, toastProps.toastId])

  return (
    <StyledDeleteHistoryHint>
      <ToastifyCashoutNotificationText />
    </StyledDeleteHistoryHint>
  )
}

const ToastifyCashoutNotificationText = () => {
  const [t] = useTranslation()
  const cashoutStatusView = useSelector(selectCashoutStatusView)
  const soldBet = useSelector(selectCashoutSoldBet)
  const cashoutError = useSelector(selectCashoutError)

  if (ECashoutStatusView.SUCCESS === cashoutStatusView && soldBet) {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <StyledIconDollarInCircle />

        <StyledToastifyCashoutNotificationText>
          {`${t('just bet')} ${t(soldBet.betType.toLowerCase())}`}
        </StyledToastifyCashoutNotificationText>

        <StyledToastifyCashoutNotificationSold
          data-test-id={ETestData.TestCashoutNotificationSold}
        >
          {t('sold')}
        </StyledToastifyCashoutNotificationSold>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
  } else if (ECashoutStatusView.ERROR === cashoutStatusView && cashoutError) {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <StyledToastifyCashoutNotificationText>
          {t(cashoutError.title)}
        </StyledToastifyCashoutNotificationText>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
  } else {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <StyledToastifyCashoutNotificationText>
          {t('something went wrong')}
        </StyledToastifyCashoutNotificationText>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
  }
}

export const ToastifyCashoutModal = () => {
  const cashoutStatusView = useSelector(selectCashoutStatusView)
  const currentCashout = useSelector(selectCurrentCashout)

  useEffect(() => {
    if (cashoutStatusView !== ECashoutStatusView.NONE && !currentCashout) {
      setToastifyNotifyCashout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashoutStatusView])

  const setToastifyNotifyCashout = usePopUpNotification({
    content: {
      children: (props) => <ToastifyCashoutNotification {...props} />
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: false,
      toastId: TOASTIFY_CASHOUT_ID
    }
  })

  return null
}
