import React, { FC, memo } from 'react'

import { ResultsMenu } from 'widgets/results-menu'
import { Auth } from 'widgets/Auth/Auth'
import { RightBasketContainer } from 'containers/BasketContainer'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { Header } from 'widgets/header'
import { FooterOfPage } from 'widgets/footer-of-page'
import { HeaderResults } from 'pages/page-results/Results/Header'
import { useLayoutContext } from 'pages/page-event-completed/EventCompleted.context'

import { LayoutProps } from '../../Layout.types'

import { StyledContent, StyledLayout } from './Results.styled'

export const LayoutResults: FC<LayoutProps> = memo(({ children }) => {
  const { hideHeaderResults } = useLayoutContext()

  return (
    <StyledLayout>
      <Auth />

      <Header />

      {hideHeaderResults ? null : <HeaderResults />}

      <ResultsMenu />

      <NotificationsPanel />

      <StyledContent>
        {children}
        <FooterOfPage />
      </StyledContent>

      <RightBasketContainer />
    </StyledLayout>
  )
})
