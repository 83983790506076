import React from 'react'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { BasketHistoryBody } from 'widgets/right-container/Basket/BasketHistoryBody/BasketHistoryBody'

import { reducer, sliceKey } from './slice'
import { betsHistoryBasketContainerSaga } from './saga'

export const BetsHistoryBasketContainer = () => {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: betsHistoryBasketContainerSaga })

  return <BasketHistoryBody />
}
