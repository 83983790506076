import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import { ContainerState } from './types'

const REDUCER_KEY = 'liveMatchTrackerContainer'

export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  currentMatch: null
})

const liveMatchTrackerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setCurrentMatch(state, action: PayloadAction<{ eventSourceId: string }>) {
      state.currentMatch = action.payload.eventSourceId
    },
    clearCurrentMatch(state) {
      state.currentMatch = null
    }
  }
})

export const { actions, reducer, name: sliceKey } = liveMatchTrackerSlice
export const liveMatchTrackerActions = actions
