import React from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from '../../../../components'

export const OnboardingContentHeaderLiveLine = () => {
  const [t] = useTranslation()

  return (
    <OnboardingContent title={t('sections live and line')}>
      {t('onboarding live and line')}
    </OnboardingContent>
  )
}
