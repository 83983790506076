import { ELineHeaderTypes } from './LineHeader.types'
import {
  LineHeaderFilters,
  LiveHeaderFilters,
  TournamentsHeaderFilters
} from './components'

export const LINE_HEADER_FILTERS = {
  [ELineHeaderTypes.LIVE]: LiveHeaderFilters,
  [ELineHeaderTypes.LINE]: LineHeaderFilters,
  [ELineHeaderTypes.TOURNAMENTS]: TournamentsHeaderFilters
}
