import { EColorsTypes, TFixedColorsTheme } from 'shared/types/theme'

export const FIXED_COLORS: TFixedColorsTheme[EColorsTypes.FIXED] = {
  primary: {
    100: '#000000',
    95: '#0D0D0D',
    90: '#1A1A1A',
    85: '#262626',
    80: '#333333',
    70: '#4D4D4D',
    60: '#666666',
    50: '#808080',
    40: '#999999',
    30: '#B3B3B3',
    20: '#CCCCCC',
    15: '#D9D9D9',
    10: '#E6E6E6',
    7: '#EFF2F5',
    5: '#F2F2F2',
    4: '#6F6F6F',
    3: '#F7F7F7',
    1: '#F6F6F6',
    0: '#FFFFFF'
  },
  green: {
    0: '#B0E8B0',
    1: '#4DCB4D',
    2: '#27AE60',
    3: '#197719',
    4: '#43B243',
    5: '#ECF8EE'
  },
  red: {
    0: '#E03A3A',
    1: '#F4BDBD',
    2: '#F2200D'
  },
  yellow: {
    90: '#A77140',
    80: '#FE7F00',
    70: '#FFA217',
    50: '#FFC400',
    49: '#FFD240',
    40: '#F2C94C',
    30: '#FFDB66',
    10: '#FFF3C8',
    5: '#F7F1DC'
  },
  blue: {
    0: '#E8E8FC',
    1: '#3D7AF5'
  }
}
