import React, { FC } from 'react'

import {
  EventStatisticTimeAdded,
  EventStatisticTimeCurrent,
  EventStatisticTimeRemaining
} from './components'
import {
  EEventStatisticTimeTypes,
  TEventStatisticTimeProps
} from './EventStatisticTime.types'

export const EventStatisticTime: FC<TEventStatisticTimeProps> = (props) => {
  switch (props.type) {
    case EEventStatisticTimeTypes.ADDED:
      return <EventStatisticTimeAdded {...props} />
    case EEventStatisticTimeTypes.REMAINING:
      return <EventStatisticTimeRemaining {...props} />
    case EEventStatisticTimeTypes.CURRENT:
      return <EventStatisticTimeCurrent {...props} />

    default:
      return null
  }
}
