import styled from 'styled-components'

export const STYLED_MAIN_HEADER_HEIGHT = 40

export const StyledMainHeader = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[3]};
  border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  display: grid;
  grid-template-columns: minmax(240px, auto) 1fr 0fr;
  height: ${STYLED_MAIN_HEADER_HEIGHT}px;
  padding: 0 17px;
`

export const StyledMainHeaderContent = styled.div``
