import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconSetting: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3523 7.68542C12.3823 7.46667 12.3973 7.24062 12.3973 7C12.3973 6.76667 12.3823 6.53333 12.3448 6.31458L13.8666 5.1625C14.0015 5.06042 14.039 4.86354 13.9565 4.71771L12.5172 2.29688C12.4273 2.13646 12.2399 2.08542 12.075 2.13646L10.2834 2.83646C9.90855 2.55937 9.51124 2.32604 9.06896 2.15104L8.7991 0.298958C8.76911 0.123958 8.61919 0 8.43928 0H5.56072C5.38081 0 5.23838 0.123958 5.2084 0.298958L4.93853 2.15104C4.49625 2.32604 4.09145 2.56667 3.72414 2.83646L1.93253 2.13646C1.76762 2.07812 1.58021 2.13646 1.49026 2.29688L0.058471 4.71771C-0.031484 4.87083 -0.00149909 5.06042 0.148426 5.1625L1.67016 6.31458C1.63268 6.53333 1.6027 6.77396 1.6027 7C1.6027 7.22604 1.61769 7.46667 1.65517 7.68542L0.133433 8.8375C-0.00149906 8.93958 -0.0389802 9.13646 0.0434785 9.28229L1.48276 11.7031C1.57271 11.8635 1.76012 11.9146 1.92504 11.8635L3.71664 11.1635C4.09145 11.4406 4.48876 11.674 4.93103 11.849L5.2009 13.701C5.23838 13.876 5.38081 14 5.56072 14H8.43928C8.61919 14 8.76912 13.876 8.7916 13.701L9.06147 11.849C9.50375 11.674 9.90855 11.4406 10.2759 11.1635L12.0675 11.8635C12.2324 11.9219 12.4198 11.8635 12.5097 11.7031L13.949 9.28229C14.039 9.12187 14.0015 8.93958 13.8591 8.8375L12.3523 7.68542ZM7 8.625C5.51574 8.625 5.30135 8.44375 5.30135 7C5.30135 5.55625 5.51574 5.375 7 5.375C8.48426 5.375 8.69865 5.55625 8.69865 7C8.69865 8.44375 8.48426 8.625 7 8.625Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
