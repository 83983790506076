import styled from 'styled-components'

export const StyledButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`
export const StyledTitle = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[30]};

  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 16px;
  white-space: nowrap;
`

export const StyledButtonLink = styled.a`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[4]};
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 32px;
  padding: 6px 8px;
  transition: background-color 0.2s ease;
  width: 88px;
  &:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[81]};
  }
`
export const StyledText = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 20px;
`
