import { useCallback, useState } from 'react'

export const useToggleState = (
  toggledValue?: boolean
): [boolean, () => void] => {
  const [value, setValue] = useState(!!toggledValue)

  const toggleValue = useCallback(
    () => setValue((stateValue) => !stateValue),
    []
  )

  return [value, toggleValue]
}
