import styled from 'styled-components'

import { StyledContent, StyledLayout } from '../Sport/Sport.styled'

export const StyledLayoutAbout = styled(StyledLayout)`
  grid-template-areas:
    'header header'
    '. content';
  grid-template-columns: 240px 1fr;
`

export const StyledContentAbout = styled(StyledContent)`
  grid-template-rows: auto 1fr;
  width: 100%;
`
