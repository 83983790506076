import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconClearResultSearch: FC<IconProps> = (props) => {
  const theme = useTheme()

  let colorsIcon = {
    background: theme.colors.fixed.yellow[10],
    element: theme.colors.fixed.yellow[30],
    element2: theme.colors.fixed.yellow[50],
    element3: theme.colors.fixed.primary[70]
  }

  if (theme.typeLayoutTheme === LayoutTheme.Dark) {
    colorsIcon = {
      background: theme.colors.fixed.primary[80],
      element: theme.colors.fixed.primary[4],
      element2: theme.colors.fixed.primary[30],
      element3: theme.colors.fixed.primary[0]
    }
  }

  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="64"
        viewBox="0 0 64 64"
        width="64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z"
          fill={colorsIcon.background}
        />
        <path
          d="M16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11Z"
          fill={colorsIcon.element}
        />
        <path
          d="M16 25C16 27.2091 14.2091 29 12 29C9.79086 29 8 27.2091 8 25C8 22.7909 9.79086 21 12 21C14.2091 21 16 22.7909 16 25Z"
          fill={colorsIcon.element}
        />
        <path
          d="M16 39C16 41.2091 14.2091 43 12 43C9.79086 43 8 41.2091 8 39C8 36.7909 9.79086 35 12 35C14.2091 35 16 36.7909 16 39Z"
          fill={colorsIcon.element}
        />
        <path
          d="M16 53C16 55.2091 14.2091 57 12 57C9.79086 57 8 55.2091 8 53C8 50.7909 9.79086 49 12 49C14.2091 49 16 50.7909 16 53Z"
          fill={colorsIcon.element}
        />
        <path
          d="M19 11C19 10.4477 19.4477 10 20 10H52C52.5523 10 53 10.4477 53 11V12C53 12.5523 52.5523 13 52 13H20C19.4477 13 19 12.5523 19 12V11Z"
          fill={colorsIcon.element}
        />
        <path
          d="M19 25C19 24.4477 19.4477 24 20 24H56C56.5523 24 57 24.4477 57 25V26C57 26.5523 56.5523 27 56 27H20C19.4477 27 19 26.5523 19 26V25Z"
          fill={colorsIcon.element}
        />
        <path
          d="M19 39C19 38.4477 19.4477 38 20 38H48C48.5523 38 49 38.4477 49 39V40C49 40.5523 48.5523 41 48 41H20C19.4477 41 19 40.5523 19 40V39Z"
          fill={colorsIcon.element}
        />
        <path
          d="M19 53C19 52.4477 19.4477 52 20 52H52C52.5523 52 53 52.4477 53 53V54C53 54.5523 52.5523 55 52 55H20C19.4477 55 19 54.5523 19 54V53Z"
          fill={colorsIcon.element}
        />
        <path
          clipRule="evenodd"
          d="M39.7304 43.1446C37.0007 45.5446 33.4203 47 29.5 47C20.9396 47 14 40.0604 14 31.5C14 22.9396 20.9396 16 29.5 16C38.0604 16 45 22.9396 45 31.5C45 35.4203 43.5446 39.0007 41.1446 41.7304L49.7071 50.2929C50.0976 50.6834 50.0976 51.3166 49.7071 51.7071C49.3166 52.0976 48.6834 52.0976 48.2929 51.7071L39.7304 43.1446ZM43 31.5C43 38.9558 36.9558 45 29.5 45C22.0442 45 16 38.9558 16 31.5C16 24.0442 22.0442 18 29.5 18C36.9558 18 43 24.0442 43 31.5Z"
          fill={colorsIcon.element3}
          fillRule="evenodd"
        />
        <path
          d="M26.4992 20.9718C26.4992 25.5897 22.7556 29.3333 18.1377 29.3333C17.6431 29.3333 17.1585 29.2904 16.6875 29.208C17.5552 24.1714 21.3396 20.1327 26.2389 18.8936C26.4089 19.558 26.4992 20.2544 26.4992 20.9718Z"
          fill={colorsIcon.element2}
        />
        <path
          d="M26.4983 44.0023C26.4317 39.4418 22.714 35.7653 18.1376 35.7653C17.8433 35.7653 17.5524 35.7805 17.2659 35.8102C18.7411 39.8888 22.2114 43.0124 26.4983 44.0023Z"
          fill={colorsIcon.element2}
        />
      </svg>
    </Icon>
  )
}
