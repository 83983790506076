import { useCallback, useEffect, useRef, useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'

export const useHandleMarqueeWidth = () => {
  const stringRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [marqueeWidth, setMarqueeWidth] = useState<number>(0)
  const windowWidth = useWindowWidth()

  const getMarqueeWidth = useCallback(() => {
    if (stringRef.current && wrapperRef.current && windowWidth) {
      const stringRect = stringRef.current?.getBoundingClientRect()
      const { width: wrapperWidth } =
        wrapperRef.current?.getBoundingClientRect()

      if (stringRect.width > wrapperWidth) {
        setMarqueeWidth(stringRect.width - wrapperWidth + 10)
      } else {
        setMarqueeWidth(0)
      }
    }
  }, [windowWidth])

  useEffect(() => {
    getMarqueeWidth()
  }, [getMarqueeWidth])

  return { wrapperRef, marqueeWidth, stringRef }
}
