import React, { FC, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import iframeResize from 'iframe-resizer/js/iframeResizer.min'
import { accountProviderActions } from 'astra-core/containers/AccountProvider'
import {
  useGetPostAuthServiceMessage,
  EPostMessageIdEvent
} from 'astra-core/containers/AuthMessageProvider'

import { selectIsAuthShowModal } from 'containers/LayoutContainer/selectors'
import { useAuthTypeModal } from 'hooks/useAuthTypeModal'

import { IIFrame } from './Auth.types'
import { LoaderSkeleton } from './components'
import {
  AuthWindow,
  IframeVisibilityControl,
  IframeWrapper
} from './UnauthorizedModal.styled'
import { useGetIframeData } from './hooks'

export const UnauthorizedModal: FC = () => {
  const dispatch = useDispatch()
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const isAuthShowModal = useSelector(selectIsAuthShowModal)
  const setAuthTypeModal = useAuthTypeModal()

  const iframeData = useGetIframeData()

  const hideModalWindow = useCallback(() => {
    dispatch(accountProviderActions.setTypeAuthModal(null))
    setAuthTypeModal(false)
  }, [dispatch, setAuthTypeModal])

  const setSuccessfulLoading = useCallback(() => {
    setIsLoading(false)
    iframeResize(
      {
        log: false,
        checkOrigin: false
      },
      `#${iframeData.id}`
    )
  }, [iframeData])

  const backDropHandler = useCallback(
    (e) => {
      if (modalWrapperRef?.current === e.target) {
        hideModalWindow()
      }
    },
    [hideModalWindow]
  )

  useGetPostAuthServiceMessage(EPostMessageIdEvent.close, hideModalWindow)

  return (
    <AuthWindow
      isAuthShowModal={isAuthShowModal}
      ref={modalWrapperRef}
      onClick={backDropHandler}
    >
      <IframeWrapper>
        {isLoading && <LoaderSkeleton />}
        <IframeVisibilityControl isLoading={isLoading}>
          <IFrame setSuccessfulLoading={setSuccessfulLoading} />
        </IframeVisibilityControl>
      </IframeWrapper>
    </AuthWindow>
  )
}

const IFrame: FC<IIFrame> = ({ setSuccessfulLoading }) => {
  const { id, src, styles } = useGetIframeData()

  const handleAuthIFrameLoad = useCallback(() => {
    setSuccessfulLoading()
  }, [setSuccessfulLoading])

  return (
    <iframe
      frameBorder="0"
      id={id}
      src={src}
      style={styles}
      title="iframeAuth"
      onLoad={handleAuthIFrameLoad}
    />
  )
}
