import styled from 'styled-components'

export const StyledDynamicInputWrapper = styled.div`
  display: inline-flex;
  position: relative;
`

export const StyledDynamicInput = styled.input`
  box-sizing: content-box;
`

export const StyledHiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`
