import { useDispatch, useSelector } from 'react-redux'
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react'
import {
  eventsProviderActions,
  selectEventStatistics
} from 'astra-core/containers/EventsProvider'

import { LayoutEventContext } from 'widgets/Layout/components/Event/context'
import { getEventHomeAwayCompetitors } from 'shared/lib/events'
import { useInterval } from 'hooks'
import { RootState } from 'shared/types/store'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { EVENT_STATISTICS_POLLING_INTERVAL } from 'pages/page-live-events/Line/components/Event/components/EventBanner/constants'
import { getBannerScore } from 'pages/page-live-events/Line/components/Event/components/EventBanner/utils'

export const useEventBannerData = () => {
  const dispatch = useDispatch()
  const { event } = useContext(EventContext) as IEventContext
  const containerRef = useRef(null)
  const { getCoordsEventBanner } = useContext(LayoutEventContext) || {}

  useLayoutEffect(() => {
    getCoordsEventBanner?.(containerRef)
  }, [getCoordsEventBanner])

  const competitors = useMemo(
    () => getEventHomeAwayCompetitors(event!),
    [event]
  )

  const fetchEventStatistics = useCallback(() => {
    if (event.live) {
      dispatch(
        eventsProviderActions.fetchEventStatistics({ eventId: event.id })
      )
    }
  }, [dispatch, event.id, event.live])

  useInterval(
    fetchEventStatistics,
    event.live ? EVENT_STATISTICS_POLLING_INTERVAL : null,
    true
  )

  const eventStatistics = useSelector((state: RootState) =>
    selectEventStatistics(state, event.id)
  )

  const bannerScore = useMemo(
    () => getBannerScore(eventStatistics, event.live),
    [event.live, eventStatistics]
  )

  const currentServer = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.CurrentServer
  )

  const matchPhase = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.MatchPhase
  )

  return { bannerScore, competitors, containerRef, currentServer, matchPhase }
}
