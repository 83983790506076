import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { rowGapPrefixes } from 'shared/lib/styled'
import { STYLED_FIXED_HEADER_HEIGHT } from 'widgets/header/FixedHeader/FixedHeader.styled'

export const SettingsWindow = styled.div`
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
`

export const StyledSettingsList = styled.div`
  ${(props) => css`
    background-color: ${props.theme.colors.custom.primary[10]};
    border-radius: 8px;
    box-shadow: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
      ? `0px 2px 4px rgb(0 0 0 / 60%)`
      : `0px 2px 8px rgb(0 0 0 / 8%)`};
    padding: 26px 32px;
    position: absolute;
    right: 72px;
    top: ${STYLED_FIXED_HEADER_HEIGHT + 4}px;
    width: 400px;
    z-index: 1000;
    ${rowGapPrefixes('24px')}
  `}
`

export const StyledSettingsListTitle = styled.h3`
  ${(props) =>
    css`
      align-items: flex-start;
      color: ${props.theme.colors.default.primary[90]};
      display: flex;
      font-size: ${props.theme.fonts.size.l};
      font-weight: ${props.theme.fonts.weight.bold};
      justify-content: space-between;
      line-height: 20px;
      margin-bottom: 18px;
    `}
`

export const StyledSettingsListItems = styled.div`
  display: flex;
  flex-direction: column;
  ${rowGapPrefixes('24px')}
`
