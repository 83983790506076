import { EColorsNames, TComponentColorProps } from 'shared/types/theme'
import { IconProps } from 'shared/ui/Icon'

export const SELECTED_DATE = 'DD.MM.YYYY'

export const ICON_CALENDAR_COLOR_PROPS: TComponentColorProps = {
  name: EColorsNames.Primary,
  value: 90
}

export const ICON_CLOSE_PROPS: IconProps = {
  size: 10
}
