import styled, { css } from 'styled-components'

import { Select } from 'shared/ui/Select'

export const StyledInputWrapper = styled.div`
  margin-bottom: 4px;
  padding: 4px 16px;
  position: relative;
`

export const StyledSystemCalculatorButton = styled.button`
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 4px;
  margin-left: 16px;
  margin-top: 4px;
  padding: 4px 8px;
  ${({ theme }) =>
    `
      background: ${theme.colors.default.primary[5]};
      border: 1px solid ${theme.colors.default.primary[5]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[70]};
    `}
`

export const StyledSelect = styled(Select)`
  line-height: 16px;
  z-index: 3;

  ${({ theme }) =>
    css`
      font-weight: ${theme.fonts.weight.regular};
    `}
`
