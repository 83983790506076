import { useDispatch } from 'react-redux'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'

export const useAuthTypeModal = () => {
  const dispatch = useDispatch()

  const setAuthTypeModal = (types: boolean) => {
    dispatch(layoutContainerActions.setIsAuthTypeModal(types))
  }

  return setAuthTypeModal
}
