import React, { FC, memo } from 'react'

import { FixedHeader } from 'widgets/header/FixedHeader'
import { MainHeader } from 'widgets/header/MainHeader'

import { StyledHeader } from './Header.styled'

export const Header: FC = memo(() => (
  <StyledHeader>
    <FixedHeader />
    <MainHeader />
  </StyledHeader>
))
