import styled from 'styled-components'

import { StyledTag } from 'shared/ui/Tag'

export const StyledTeamWrapper = styled.div`
  display: flex;
  ${StyledTag} {
    margin-left: 4px;
  }
`
