import React, { FC } from 'react'

import { useOnboardingItemContext } from '../../Onboarding.context'

export const OnboardingMask: FC = () => {
  const { itemNodeRectProps } = useOnboardingItemContext()

  return (
    <svg height="100%" role="none" width="100%">
      <defs>
        <mask id="hole">
          <rect fill="white" height="100%" width="100%" />

          {itemNodeRectProps &&
            itemNodeRectProps.map((itemNodeRectProps) => (
              <rect
                fill="black"
                height={itemNodeRectProps.height}
                rx="4"
                ry="4"
                width={itemNodeRectProps.width}
                x={itemNodeRectProps.leftTopX}
                y={itemNodeRectProps.leftTopY}
              />
            ))}
        </mask>
      </defs>

      <rect
        fill="rgba(0, 0, 0, 0.65)"
        height="100%"
        mask="url(#hole)"
        width="100%"
      />
    </svg>
  )
}
