import styled, { css } from 'styled-components'

export const StyledBasketWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  width: 280px;
`

export const BetStatusWaitWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const BetStatusWaitText = styled.div`
  line-height: 16px;
  margin-top: 19px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const BetStatusSuccessWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const BetStatusSuccessTitle = styled.div<{ isAppliedCoupon: boolean }>`
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 19px;
  padding: 0 35.5px;
  text-align: center;

  ${({ theme, isAppliedCoupon }) =>
    css`
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.bold};

      ${isAppliedCoupon
        ? css`
            color: ${theme.colors.custom.blue[24]};
          `
        : css`
            color: ${theme.colors.default.green[50]};
          `}
    `}
`

export const BetStatusSuccessText = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const BetStatusSuccessLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`
