import { Statistics } from 'betweb-openapi-axios'

import { MAX_LENGTH_NAME_COMPETITORS } from './constants'

export const getBannerScore = (
  eventStatistics: Statistics,
  isLive?: boolean
) => {
  if (isLive) {
    // TODO find out what should be shown while fetching score
    return `${eventStatistics?.homeStatistics?.score ?? ''} : ${
      eventStatistics?.awayStatistics?.score ?? ''
    }`
  } else {
    return '-'
  }
}

export const isHideLongNameCompetitors = (competitorName: string) => {
  return competitorName?.trim()?.length >= MAX_LENGTH_NAME_COMPETITORS
}
