import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { EventCoefficientTotal } from 'entities/event'
import { RootState } from 'shared/types/store'

import { useGetTotalValue } from '../../lib'

export const EventRowCoefficientTotal: FC<
  Pick<Parameters<typeof useGetTotalValue>[0], 'event'> &
    Pick<Parameters<typeof useGetTotalValue>[0], 'totalOutcomeType'>
> = ({ event, totalOutcomeType }) => {
  const value = useGetTotalValue({ event, totalOutcomeType })
  const { code: sportCode } =
    useSelector((state: RootState) =>
      selectSport(state, event.tournament.sportId)
    ) || {}

  return (
    <EventCoefficientTotal
      sportCode={sportCode as ESportsCodes}
      value={value}
    />
  )
}
