import React, { FC, ReactElement } from 'react'

import { StyledMatchActions } from './actions.styled'

export const EventRowActions: FC<{
  liveComponents: ReactElement
  lineComponents: ReactElement
  isLive?: boolean
}> = ({ isLive, liveComponents, lineComponents }) => {
  return (
    <StyledMatchActions>
      {isLive ? liveComponents : lineComponents}
    </StyledMatchActions>
  )
}
