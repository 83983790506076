/* eslint-disable consistent-return */
import { useEffect, useState } from 'react'

export function useTimer(start: number) {
  const [count, setCount] = useState<number>(start)

  useEffect(() => {
    if (start) {
      const secondsLeft = setInterval(() => {
        setCount((c) => c - 1)
      }, 1000)

      if (!count) return clearInterval(secondsLeft)

      return () => clearInterval(secondsLeft)
    }
  }, [count, start])

  return count
}
