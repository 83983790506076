import { useCallback, useEffect, useRef, useState } from 'react'

import { useGetOnboardingNodeItemClientRect } from '../../hooks'
import { IGetFormattedOnboardingAttr } from '../../Onboarding.types'

import { stepGenerator } from './stepGenerator'
import { TUseStepGeneratorOtherPropertiesState } from './stepGenerator.types'

export const useStepGenerator = <
  TArrayOnboarding extends IGetFormattedOnboardingAttr[]
>(
  onboardingList: TArrayOnboarding
) => {
  const [otherProperties, setOtherProperties] =
    useState<TUseStepGeneratorOtherPropertiesState<TArrayOnboarding>>()
  const selectNextExistingElement = useSelectNextExistingElement(onboardingList)

  const onNextOnboardingItem = useCallback(() => {
    const {
      nodeItemRectProps: itemNodeRectProps,
      generatorData: {
        value: { item },
        done
      },
      isHaveNext
    } = selectNextExistingElement()

    if (itemNodeRectProps || done) {
      setOtherProperties({
        ...(!!itemNodeRectProps && { itemNodeRectProps, item }), // don't display itemNodeRectProps and item when itemNodeRectProps is negative. When generator will return last item, its dom node may not be
        isEndedGenerator: typeof done === 'undefined' ? !!done : done,
        isHaveNext
      })
    }
  }, [selectNextExistingElement])

  useEffect(() => {
    // Key is Enter can activate next step
    const func = (e) => {
      if (e.key === 'Enter') {
        onNextOnboardingItem()
      } else {
        e.preventDefault() // block other buttons
      }
    }

    window.addEventListener('keypress', func)

    return () => {
      window.removeEventListener('keypress', func)
    }
  }, [onNextOnboardingItem])

  return {
    onNextOnboardingItem,
    onStartOnboarding: onNextOnboardingItem,
    ...otherProperties
  }
}

const useSelectNextExistingElement = <
  TArrayOnboarding extends IGetFormattedOnboardingAttr[]
>(
  onboardingList: TArrayOnboarding
) => {
  const getNodeItemRectProps = useGetOnboardingNodeItemClientRect()
  const { current: generatorSteps } = useRef(stepGenerator(onboardingList))

  return useCallback(() => {
    let generatorData = generatorSteps.next() // Activate next step
    let nodeItemRectProps = getNodeItemRectProps(generatorData.value.item, true) // Get dom node with required custom attr of generatorData item

    if (!nodeItemRectProps && !generatorData.done) {
      // try to find dom node with required custom attr of generatorData item in next generator iterations if its is haven`t

      while (!nodeItemRectProps && !generatorData.done) {
        generatorData = generatorSteps.next()
        nodeItemRectProps = getNodeItemRectProps(generatorData.value.item)
      }
    }

    let isHaveNext: boolean

    if (generatorData.value.indexNum !== onboardingList.length - 1) {
      isHaveNext = !!onboardingList
        .slice(generatorData.value.indexNum + 1)
        .find((onboardingItem) => !!getNodeItemRectProps(onboardingItem))
    } else {
      isHaveNext = false
    }

    return { nodeItemRectProps, generatorData, isHaveNext }
  }, [generatorSteps, getNodeItemRectProps, onboardingList])
}
