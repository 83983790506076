import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBonusCouponBasketLight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.25057 2.75V3.77083C6.25057 4.20039 6.59879 4.54861 7.02835 4.54861C7.4579 4.54861 7.80613 4.20039 7.80613 3.77083V2.75H12.6504C13.948 2.75 15 3.5335 15 4.5V11.5C15 12.4665 13.948 13.25 12.6504 13.25H7.80613V12.2785C7.80613 11.849 7.4579 11.5008 7.02835 11.5008C6.59879 11.5008 6.25057 11.849 6.25057 12.2785V13.25H3.34965C2.05197 13.25 1 12.4665 1 11.5V10.1583C1 9.83617 1.36172 9.58515 1.76685 9.47225C2.57775 9.24626 3.15385 8.67233 3.15385 8C3.15385 7.32767 2.57775 6.75374 1.76685 6.52775C1.36172 6.41485 1 6.16383 1 5.84167V4.5C1 3.5335 2.05197 2.75 3.34965 2.75H6.25057ZM6.25 7.02854C6.25 6.59898 6.59822 6.25076 7.02778 6.25076C7.45733 6.25076 7.80556 6.59898 7.80556 7.02854L7.80613 8.97298C7.80613 9.40254 7.4579 9.75076 7.02835 9.75076C6.59879 9.75076 6.25057 9.40254 6.25057 8.97298L6.25 7.02854Z"
        fill="url(#paint0_linear_37167_4004)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_37167_4004"
          x1="1"
          x2="12.7541"
          y1="1.89971"
          y2="23.6344"
        >
          <stop offset="0.0001" stopColor="#875EFF" />
          <stop offset="1" stopColor="#492CC1" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)

export const IconBonusCouponBasketDark: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.25057 2.75V3.77083C6.25057 4.20039 6.59879 4.54861 7.02835 4.54861C7.4579 4.54861 7.80613 4.20039 7.80613 3.77083V2.75H12.6504C13.948 2.75 15 3.5335 15 4.5V11.5C15 12.4665 13.948 13.25 12.6504 13.25H7.80613V12.2785C7.80613 11.849 7.4579 11.5008 7.02835 11.5008C6.59879 11.5008 6.25057 11.849 6.25057 12.2785V13.25H3.34965C2.05197 13.25 1 12.4665 1 11.5V10.1583C1 9.83617 1.36172 9.58515 1.76685 9.47225C2.57775 9.24626 3.15385 8.67233 3.15385 8C3.15385 7.32767 2.57775 6.75374 1.76685 6.52775C1.36172 6.41485 1 6.16383 1 5.84167V4.5C1 3.5335 2.05197 2.75 3.34965 2.75H6.25057ZM6.25 7.02854C6.25 6.59898 6.59822 6.25076 7.02778 6.25076C7.45733 6.25076 7.80556 6.59898 7.80556 7.02854L7.80613 8.97298C7.80613 9.40254 7.4579 9.75076 7.02835 9.75076C6.59879 9.75076 6.25057 9.40254 6.25057 8.97298L6.25 7.02854Z"
        fill="url(#paint0_linear_37167_26349)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_37167_26349"
          x1="0.999999"
          x2="11.5315"
          y1="1.89971"
          y2="22.8043"
        >
          <stop offset="0.0001" stopColor="#A184F9" />
          <stop offset="0.841126" stopColor="#805AC6" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
