import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLine: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg fill="none" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447716 2 0 1.55228 0 1ZM0 5C0 4.44772 0.447715 4 1 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H1C0.447715 6 0 5.55228 0 5ZM1 8C0.447715 8 0 8.44771 0 9C0 9.55229 0.447715 10 1 10H8C8.55229 10 9 9.55229 9 9C9 8.44771 8.55229 8 8 8H1Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
