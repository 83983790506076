import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { QuickBetOutcome } from 'astra-core/containers/BasketProvider'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { setLocalStorageItem } from 'og-web-core/utils/storage/local-storage'
import { StorageKeys } from 'og-web-core/utils/storage/constants'

import { Modal, ViewComponentType } from 'shared/ui/modal'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { Button, EButtonViews } from 'shared/ui/Button'
import { Checkbox } from 'shared/ui/checkbox'
import { BasketQuickBetSubmitButton } from 'widgets/right-container/Basket/BasketQuickBet/components/BasketQuickBetSubmitButton/BasketQuickBetSubmitButton'
import { useHandleMakeQuickBet } from 'features/event'

import {
  StyledButtonWrapper,
  StyledFirstQuickBetModalDescription,
  StyledFirstQuickBetModalTitle,
  StyledFirstQuickBetModalTitleWrapper,
  StyledFirstQuickBetModalWrapper
} from './confirm-quick-bet-modal.styled'

const FirstQuickBetModalDescription: FC<{
  outcome: QuickBetOutcome
}> = ({ outcome }) => {
  const { competitorsNames, basketOutcomeName } = useOutcomeInfo(
    outcome.outcomeCompositeId
  )

  const currencyIcon = useGetCurrencyIcon()

  const { quickBetValue } = useContext(BasketQuickBetContext)
  return (
    <StyledFirstQuickBetModalDescription>
      <div>
        Вы действительно хотите сделать ставку
        <strong>{` ${competitorsNames} ${basketOutcomeName} ${outcome.coefficient} `}</strong>
        на сумму <strong>{`${quickBetValue} ${currencyIcon} ?`}</strong>
      </div>
    </StyledFirstQuickBetModalDescription>
  )
}

export const ConfirmQuickBetModal = () => {
  const { t } = useTranslation()

  const {
    outcome,
    confirmQuickBetModalVisible,
    setConfirmQuickBetModalVisible
  } = useContext(BasketQuickBetContext)

  const { handleMakeQuickBet, isLoading } = useHandleMakeQuickBet()

  const [isChecked, setIsChecked] = useState(true)

  const onClickClose = () => {
    setConfirmQuickBetModalVisible(false)
  }

  const handleCheckBox = () => {
    setIsChecked(!isChecked)
  }

  const handleSubmit = useCallback(() => {
    setLocalStorageItem(StorageKeys.shouldConfirmQuickBet, !isChecked)
    if (outcome) {
      handleMakeQuickBet(outcome)
    }
  }, [handleMakeQuickBet, isChecked, outcome])

  return (
    <Modal isOpen={confirmQuickBetModalVisible} view={ViewComponentType.FIRST}>
      <StyledFirstQuickBetModalWrapper>
        <StyledFirstQuickBetModalTitleWrapper>
          <StyledFirstQuickBetModalTitle>
            {t('one click bet')}
          </StyledFirstQuickBetModalTitle>
          <IconClose
            colorProps={{ name: EColorsNames.Primary, value: 30 }}
            size={10}
            onClick={onClickClose}
          />
        </StyledFirstQuickBetModalTitleWrapper>
        {outcome && <FirstQuickBetModalDescription outcome={outcome} />}
        <Checkbox
          checked={isChecked}
          label={t('not show message')}
          onChange={handleCheckBox}
        />
        <StyledButtonWrapper>
          <BasketQuickBetSubmitButton
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            title={t('confirm')}
          />
          <Button view={EButtonViews.SECONDARY} onClick={onClickClose}>
            {t('сancel')}
          </Button>
        </StyledButtonWrapper>
      </StyledFirstQuickBetModalWrapper>
    </Modal>
  )
}
