import styled, { css } from 'styled-components'

export const StyledMatchStatisticsWrapper = styled.div`
  color: ${(props) => props.theme.colors.default.primary[50]};
  display: flex;
  grid-area: statistics;
`

export const StyledLiveScoreOfMatch = styled.span`
  ${(props) => css`
    background: ${props.theme.colors.default.primary[5]};
    border-radius: 2px;
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.xs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    padding: 0 4px;
  `}
`

export const StyledMiniStatisticsTime = styled.span`
  color: ${(props) => props.theme.colors.default.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  margin-right: 6px;
`

export const StyledLiveExtraTimeOfMatch = styled.span`
  ${(props) => css`
    background-color: ${props.theme.colors.default.primary[5]};
    color: ${props.theme.colors.default.primary[50]};
    font-size: ${props.theme.fonts.size.xs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    padding: 0 2px;
  `}
`
