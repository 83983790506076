import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'

export const StyledTableRow = styled.div<{
  isSuspended?: boolean
}>`
  display: flex;
  position: relative;
`

export const StyledTableRowOutcomes = styled(StyledTableRow)``

export const StyledEventRowInfoCell = styled.div`
  display: grid;
  grid-template-areas:
    'teams teams teams teams'
    'statistics statistics actions fav';
  grid-template-columns: repeat(3, 1fr) 16px;
  min-height: 40px;
  ${customMedia.medium} {
    padding: 4px 8px;
  }
  padding: 3px 8px;
  width: 100%;
`

export const StyledLineTableRowWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};

  &:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[37]};
  }
`

export const EventRowOutcomesValues = styled.div<{ hasMessage: boolean }>`
  ${(props) =>
    props.hasMessage &&
    css`
      & > *:not(:nth-child(1)) {
        opacity: 0.3;
      }
    `}
  display: flex;
  position: relative;
`

export const EventRowActionsStyled = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
`
