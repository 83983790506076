import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { IconWithAdditionalCssProps } from '../types'
import { Icon } from '../Icon'

const StyledIconStar = styled(Icon)<IconWithAdditionalCssProps>`
  ${(props) => css`
    ${props.withHover &&
    css`
      &:hover {
        color: ${props.theme.colors.default.yellow[30]} !important;
      }
    `}

    ${props.withActive &&
    css`
      &:active {
        color: ${props.theme.colors.default.yellow[50]} !important;
      }
    `}

    ${props.$isActiveState &&
    css`
      color: ${props.theme.colors.default.yellow[50]} !important;
    `}
  `}
`

export const IconStar: FC<IconWithAdditionalCssProps> = (props) => (
  <StyledIconStar {...props}>
    <svg
      fill="none"
      height="17"
      viewBox="0 0 18 17"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10317 0.816956C8.46998 0.07371 9.52983 0.0737101 9.89664 0.816956L11.7062 4.48344C11.8518 4.77859 12.1334 4.98316 12.4591 5.03048L16.5053 5.61843C17.3255 5.73762 17.653 6.74559 17.0595 7.32413L14.1317 10.1781C13.896 10.4078 13.7884 10.7388 13.8441 11.0632L14.5352 15.0931C14.6753 15.91 13.8179 16.5329 13.0843 16.1473L9.46524 14.2446C9.17392 14.0915 8.82589 14.0915 8.53456 14.2446L4.91552 16.1473C4.1819 16.5329 3.32446 15.91 3.46457 15.0931L4.15575 11.0632C4.21139 10.7388 4.10384 10.4078 3.86815 10.1781L0.940288 7.32413C0.346771 6.74559 0.674281 5.73762 1.4945 5.61843L5.54071 5.03048C5.86642 4.98316 6.14799 4.77859 6.29365 4.48344L8.10317 0.816956Z"
        fill="currentColor"
      />
    </svg>
  </StyledIconStar>
)
