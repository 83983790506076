import React, { FC } from 'react'

import {
  SportsMenuListItemBottomOneSkeleton,
  SportsMenuListItemBottomSkeleton,
  SportsMenuListItemSquareSkeleton,
  SportsMenuListItemTopOneSkeleton,
  SportsMenuListItemTopSkeleton,
  SportsMenuSkeletonWrapper
} from './SportsMenuListSkeleton.styled'

export const SportsMenuListSkeleton: FC = () => {
  return (
    <SportsMenuSkeletonWrapper>
      <SportsMenuListItemTopSkeleton>
        <SportsMenuListItemTopOneSkeleton />
        <SportsMenuListItemSquareSkeleton />
      </SportsMenuListItemTopSkeleton>
      <SportsMenuListItemBottomSkeleton>
        <SportsMenuListItemBottomOneSkeleton />
        <SportsMenuListItemSquareSkeleton />
      </SportsMenuListItemBottomSkeleton>
    </SportsMenuSkeletonWrapper>
  )
}
