import React from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { getIsDisplayNotificationPanel } from 'containers/LayoutContainer/selectors'
import './styles.css'

export const AnimationNotificationPanel = ({ children }) => {
  const isDisplayNotificationPanel = useSelector(getIsDisplayNotificationPanel)

  return (
    <CSSTransition
      classNames="notification-panel-node"
      in={isDisplayNotificationPanel}
      timeout={250}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  )
}
