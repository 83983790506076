import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconPerson: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M16.3481 17.7271C18.5779 15.893 20 13.1126 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 13.1131 1.42256 15.894 3.6531 17.728C5.24754 16.0479 7.50195 15.0002 10.0011 15.0002C12.4997 15.0002 14.7537 16.0475 16.3481 17.7271ZM13.7512 8.75022C13.7512 10.8213 12.0722 12.5002 10.0012 12.5002C7.93011 12.5002 6.25118 10.8213 6.25118 8.75022C6.25118 6.67915 7.93011 5.00022 10.0012 5.00022C12.0722 5.00022 13.7512 6.67915 13.7512 8.75022Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
