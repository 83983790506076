import { EMakeBetStatus } from 'astra-core/containers/BasketProvider'

export type BasketBodyTypes = {
  [EBasketHeaderTypes.Coupon]: JSX.Element
  [EBasketHeaderTypes.History]: JSX.Element
}

export enum EBasketHeaderTypes {
  Coupon = 'Coupon',
  History = 'History'
}

export type MakeBetStatusBodyType = { [key in EMakeBetStatus]: JSX.Element }
