import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'

import { RootState } from 'shared/types/store'

import {
  StyledMatchPhaseName,
  StyledMatchPhaseWrapper
} from './EventMatchPhase.styled'
import { IEventMatchPhaseProps } from './EventMatchPhase.types'

export const EventMatchPhase: FC<IEventMatchPhaseProps> = ({ value }) => {
  const matchPhase = useSelector((state: RootState) =>
    selectMatchPhase(state, Number(value))
  )

  return (
    <StyledMatchPhaseWrapper>
      <StyledMatchPhaseName>{matchPhase?.name}</StyledMatchPhaseName>
    </StyledMatchPhaseWrapper>
  )
}
