import { cssTransition } from 'react-toastify'

export enum EGetCustomTransitionTypes {
  SYSTEM_NOTIFICATION = 'system notification',
  EVENT_NOTIFICATION = 'event notification'
}

export const getCustomTransition = (type: EGetCustomTransitionTypes) => {
  const data = {
    [EGetCustomTransitionTypes.SYSTEM_NOTIFICATION]: {
      collapse: false,
      collapseDuration: 100,
      enter: 'animate__animated animate__slideInUp',
      exit: 'animate__animated animate__slideOutUp'
    },
    [EGetCustomTransitionTypes.EVENT_NOTIFICATION]: {
      collapse: false,
      collapseDuration: 300,
      enter: 'animate__animated animate__slideInUpEvent',
      exit: 'animate__animated animate__slideOutUpEvent'
    }
  }

  return cssTransition(data[type])
}

export const getCustomTransitionOfType = (type: EGetCustomTransitionTypes) => {
  const CustomTransition = getCustomTransition(type)
  return CustomTransition
}
