import { TFunction } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { OptionType } from 'astra-core/utils/line/types'

import { ESelectOrder } from './Filters.types'

export const OPTIONS_SELECT_ORDER = (
  t?: TFunction<'translation'>
): OptionType[] => [
  {
    label: t?.('by popularity') || '',
    value: ESelectOrder.Popularity,
    id: '1'
  },
  { label: t?.('by alphabet') || '', value: ESelectOrder.Alphabet, id: '2' }
]

export const OPTIONS_SELECT_PERIOD = (
  t?: TFunction<'translation'>
): OptionType[] => [
  {
    label: t?.('all time') || '',
    value: '',
    id: '1'
  },
  {
    label: t?.('by day') || '',
    value: dayjs().add(1, 'day').format(),
    id: '2'
  },
  {
    label: t?.('by 2 days') || '',
    value: dayjs().add(2, 'day').format(),
    id: '3'
  },
  {
    label: t?.('by 3 days') || '',
    value: dayjs().add(3, 'day').format(),
    id: '4'
  },
  {
    label: t?.('by week') || '',
    value: dayjs().add(1, 'week').format(),
    id: '5'
  }
]
