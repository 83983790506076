import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { OptionType } from 'astra-core/utils/line/types'
import { getSelectedPeriodOptionValue } from 'astra-core'

import { ETestData } from 'shared/lib/testData'
import { Switch } from 'shared/ui/Switch'
import { ESelectView } from 'shared/ui/Select'
import {
  selectFilterIsTop,
  selectFilterPeriod
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'

import {
  getScheduleTime,
  OPTIONS_SELECT_PERIOD
} from '../LineHeaderFilters/constants'
import { SearchInput } from '../SearchInput'

import {
  StyledInputWrapper,
  StyledSelectPeriod,
  StyledTournamentsHeaderFilters
} from './TournamentsHeaderFilters.styled'

export const TournamentsHeaderFilters: FC = memo(() => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const isTop = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(selectFilterPeriod)

  const toggleTop = useCallback(() => {
    dispatch(lineTournamentsContainerActions.toggleFilterIsTop())
  }, [dispatch])

  // Do not use useMemo for OPTIONS_SELECT_PERIOD because we need an updated Date
  const selectPeriodOptions = OPTIONS_SELECT_PERIOD(t)

  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(selectPeriodOptions, filterPeriod),
    [filterPeriod, selectPeriodOptions]
  )

  const selectPeriodHandle = useCallback(
    (option: OptionType) => {
      if (option.id !== selectedPeriodValue?.id) {
        dispatch(
          lineTournamentsContainerActions.setFilterPeriod({
            ...getScheduleTime(option)
          })
        )
      }
    },
    [dispatch, selectedPeriodValue]
  )

  return (
    <StyledTournamentsHeaderFilters>
      <Switch
        active={isTop}
        label={t('top')}
        testData={ETestData.TestLineTopButton}
        onClick={toggleTop}
      />

      <StyledSelectPeriod
        isSearchable={false}
        options={selectPeriodOptions}
        placeholder={t('select')}
        testData={ETestData.TestLineSelectPeriod}
        value={selectedPeriodValue}
        view={ESelectView.Mini}
        onChange={selectPeriodHandle}
      />
      <StyledInputWrapper>
        <SearchInput isTournaments />
      </StyledInputWrapper>
    </StyledTournamentsHeaderFilters>
  )
})
