import { selectCurrencyMinBet } from 'astra-core/containers/CommonDataProvider/selectors'
import { formatAmount, formatAmountLocaleString } from 'astra-core/utils/format'
import React, { useMemo, FC } from 'react'
import { useSelector } from 'react-redux'

import {
  StyledMinMaxPopupText,
  StyledMinMaxPopupTriangle,
  StyledMinMaxPopupWrapper
} from './MinMaxHintPopup.styled'

interface HoverMaxBetTextProps {
  maxBet: number
}

export const MinMaxHintPopup: FC<HoverMaxBetTextProps> = ({ maxBet }) => {
  const minBet = useSelector(selectCurrencyMinBet)

  const minMaxBet = useMemo(
    () =>
      `${formatAmountLocaleString({
        value: minBet
      })} - ${formatAmount({
        value: maxBet
      })}`,
    [minBet, maxBet]
  )

  return (
    <StyledMinMaxPopupWrapper>
      <StyledMinMaxPopupText>{minMaxBet}</StyledMinMaxPopupText>
      <StyledMinMaxPopupTriangle />
    </StyledMinMaxPopupWrapper>
  )
}
