import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { LayoutTheme } from 'betweb-openapi-axios'

export const DEFAULT_SPORT_SYMBOL_ID = 'AllSports'

// TODO: OL-554 Move to astrabet-core

type SportIconsColorMap = Record<ESportsCodes, string>
const SPORTS_ICON_MAP_LIGHT: SportIconsColorMap = {
  [ESportsCodes.AGE_OF_EMPIRES]: 'AgeOfEmpires',
  [ESportsCodes.ALPINE_SKIING]: 'Skiing',
  [ESportsCodes.AMERICAN_FOOTBALL]: 'AmericanFootball',
  [ESportsCodes.APEX_LEGENDS]: 'ApexLegends',
  [ESportsCodes.ARCHERY]: 'Bow',
  [ESportsCodes.ARENA_OF_VALOR]: 'ArenaOfValor',
  [ESportsCodes.ARTIFACT]: 'Artifact',
  [ESportsCodes.ATHLETICS]: 'Running',
  [ESportsCodes.AUSTRALIAN_FOOTBALL]: 'AustralianFootball',
  [ESportsCodes.BADMINTON]: 'Badminton',
  [ESportsCodes.BALLS]: 'Balls',
  [ESportsCodes.BASEBALL]: 'Baseball',
  [ESportsCodes.BASKETBALL_3X3]: 'Basketball3x3',
  [ESportsCodes.BASKETBALL]: 'Basketball',
  [ESportsCodes.BEACH_HANDBALL]: 'BeachHandball',
  [ESportsCodes.BEACH_SOCCER]: 'BeachFootball',
  [ESportsCodes.BEACH_VOLLEYBALL]: 'BeachVolleyball',
  [ESportsCodes.BIATHLON]: 'Biathlon',
  [ESportsCodes.BILLIARDS]: 'Billiard',
  [ESportsCodes.BOBSLED]: 'Bobsleigh',
  [ESportsCodes.BOWLING]: 'Bowling',
  [ESportsCodes.BOX]: 'Box',
  [ESportsCodes.BRAWL_STARS]: 'BrawlStars',
  [ESportsCodes.BULLET_SHOOTING]: 'BulletShooting',
  [ESportsCodes.CALL_OF_DUTY]: 'CallOfDuty',
  [ESportsCodes.CHESS]: 'Chess',
  [ESportsCodes.CLASH_ROYALE]: 'ClashRoyale',
  [ESportsCodes.CRICKET]: 'Cricket',
  [ESportsCodes.CROSSFIRE]: 'CrossFire',
  [ESportsCodes.CS_GO]: 'CSGO',
  [ESportsCodes.CS2]: 'CS2',
  [ESportsCodes.CURLING]: 'Curling',
  [ESportsCodes.CYBER_BASKETBALL]: 'Cyberbasketball',
  [ESportsCodes.CYBER_FOOTBALL]: 'Cyberfootball',
  [ESportsCodes.CYBER_HOCKEY]: 'Cyberhockey',
  [ESportsCodes.CYBER_SPORT]: 'Cybersport',
  [ESportsCodes.CYBER_TENNIS]: 'Cybertennis',
  [ESportsCodes.CYCLING]: 'Velo',
  [ESportsCodes.DARTS]: 'Darts',
  [ESportsCodes.DIVING]: 'Diving',
  [ESportsCodes.DOTA_2]: 'Dota2',
  [ESportsCodes.DTM]: 'Car',
  [ESportsCodes.DUATHLON]: 'Biplane',
  [ESportsCodes.FIELD_HOCKEY]: 'FieldHockey',
  [ESportsCodes.FIGURE_SKATING]: 'FigureSkating',
  [ESportsCodes.FISHING]: 'Fishing',
  [ESportsCodes.FLOORBALL]: 'Floorball',
  [ESportsCodes.FOOTBALL]: 'Football',
  [ESportsCodes.FORMULA_1]: 'Formula1',
  [ESportsCodes.FORTNITE]: 'Fortnite',
  [ESportsCodes.FREE_FIRE]: 'FreeFire',
  [ESportsCodes.FREESTYLE_WRESTLING]: 'Wrestling',
  [ESportsCodes.FREESTYLE]: 'Freestyle',
  [ESportsCodes.FUTSAL]: 'MiniFootball',
  [ESportsCodes.GAELIC_FOOTBALL]: 'GaelicFootball',
  [ESportsCodes.GEARS_OF_WAR]: 'GearsOfWar',
  [ESportsCodes.GOLF]: 'Golf',
  [ESportsCodes.GRECOROMAN_WRESTLING]: 'Wrestling',
  [ESportsCodes.GWENT]: 'Gwent',
  [ESportsCodes.GYMNASTICS]: 'Athletics',
  [ESportsCodes.HALO]: 'Halo',
  [ESportsCodes.HANDBALL]: 'Handball',
  [ESportsCodes.HEARTHSTONE]: 'Hearthstone',
  [ESportsCodes.HEROES_OF_NEWERTH]: 'HeroesOfNewerth',
  [ESportsCodes.HEROES_OF_THE_STORM]: 'HeroesOfTheStorm',
  [ESportsCodes.HOCKEY_WITH_BALL]: 'FieldHockey',
  [ESportsCodes.HORSE_RACING]: 'HorseRacing',
  [ESportsCodes.HORSEBACK_RIDING]: 'HorseRacing',
  [ESportsCodes.ICE_HOCKEY]: 'Hockey',
  [ESportsCodes.KABBADI]: 'Kabaddi',
  [ESportsCodes.KING_OF_GLORY]: 'KingOfGlory',
  [ESportsCodes.LACROSSE]: 'Lacrosse',
  [ESportsCodes.LEAGUE_OF_LEGENDS_WILD_RIFT]: 'LeagueOfLegendsWildRift',
  [ESportsCodes.LEAGUE_OF_LEGENDS]: 'LeagueofLegends',
  [ESportsCodes.MINI_FOOTBALL]: 'MiniFootball',
  [ESportsCodes.MIXED_MARTIAL_ARTS]: 'MixFight',
  [ESportsCodes.MMA]: 'MMA',
  [ESportsCodes.MOBILE_LEGENDS]: 'MobileLegends',
  [ESportsCodes.MODERN_PENTATHLON]: 'ModernPentathlon',
  [ESportsCodes.MORTAL_KOMBAT_XL]: 'MortalKombatXL',
  [ESportsCodes.MOTORCYCLE_RACING]: 'Moto',
  [ESportsCodes.NASCAR]: 'Car',
  [ESportsCodes.NETBALL]: 'Netball',
  [ESportsCodes.NORDIC_COMBINED]: 'Biplane',
  [ESportsCodes.OVERWATCH_2]: 'Overwatch2',
  [ESportsCodes.OVERWATCH]: 'Overwatch',
  [ESportsCodes.PESAPALLO]: 'Pesapallo',
  [ESportsCodes.PISTOL_SHOOTING]: 'StartGun',
  [ESportsCodes.PUBG_MOBILE]: 'PUBGMobile',
  [ESportsCodes.PUBG]: 'PUBG',
  [ESportsCodes.QUAKE]: 'Quake',
  [ESportsCodes.RAINBOW_SIX]: 'RainbowSix',
  [ESportsCodes.RALLY]: 'Car',
  [ESportsCodes.ROCKET_LEAGUE]: 'RocketLeague',
  [ESportsCodes.ROWING_ACADEMIC]: 'RowingAcademic',
  [ESportsCodes.ROWING_ON_KAYAKS_AND_CANOES]: 'Rowing',
  [ESportsCodes.RUGBY_7]: 'Rugby',
  [ESportsCodes.RUGBY_LEAGUE]: 'Rugby',
  [ESportsCodes.RUGBY_UNION]: 'Rugby',
  [ESportsCodes.RUGBY]: 'Rugby',
  [ESportsCodes.SAILING]: 'Windsurf',
  [ESportsCodes.SEPAK_TAKRAW]: 'Sepaktakrau',
  [ESportsCodes.SHORT_HOCKEY]: 'ShortHockey',
  [ESportsCodes.SHORT_TRACK]: 'ShortTrack',
  [ESportsCodes.SKATEBOARDING]: 'Skateboarding',
  [ESportsCodes.SKATING]: 'Skating',
  [ESportsCodes.SKELETON]: 'Skeleton',
  [ESportsCodes.SKI_JUMPING]: 'SkiJumping',
  [ESportsCodes.SKIING]: 'Skiing',
  [ESportsCodes.SLED]: 'Sleigh',
  [ESportsCodes.SNOWBOARDING]: 'Board',
  [ESportsCodes.SOFTBALL]: 'Softball',
  [ESportsCodes.SPEEDWAY]: 'Speedway',
  [ESportsCodes.SQUASH]: 'Squash',
  [ESportsCodes.STARCRAFT_2]: 'StarCraft2',
  [ESportsCodes.STARCRAFT]: 'StarCraft',
  [ESportsCodes.STREET_FIGHTER_V]: 'StreetFighterV',
  [ESportsCodes.STREET_FIGHTER]: 'StreetFighter',
  [ESportsCodes.SWIMMING]: 'Swimming',
  [ESportsCodes.SYNCHRONIZED_SWIMMING]: 'SynchronisedSwimming',
  [ESportsCodes.TABLE_TENNIS]: 'TableTennis',
  [ESportsCodes.TEKKEN_7]: 'Tekken7',
  [ESportsCodes.TENNIS]: 'Tennis',
  [ESportsCodes.TEQBOL]: 'Teqball',
  [ESportsCodes.TRIATHLON]: 'Triathlon',
  [ESportsCodes.VAINGLORY]: 'VainGlory',
  [ESportsCodes.VALORANT]: 'Valorant',
  [ESportsCodes.VOLLEYBALL]: 'Volleyball',
  [ESportsCodes.WARCRAFT_III]: 'WarCraft3',
  [ESportsCodes.WATER_POLO]: 'WaterPolo',
  [ESportsCodes.WEIGHTLIFTING]: 'Weightlifting',
  [ESportsCodes.WORLD_OF_TANKS]: 'WorldOfTanks',
  [ESportsCodes.WORLD_OF_WARCRAFT]: 'WorldOfWarcraft'
}

const SPORTS_ICON_MAP_DARK: SportIconsColorMap = {
  ...SPORTS_ICON_MAP_LIGHT,
  [ESportsCodes.GEARS_OF_WAR]: 'GearsOfWarDark',
  [ESportsCodes.HALO]: 'HaloDark',
  [ESportsCodes.HEROES_OF_THE_STORM]: 'HeroesOfTheStormDark',
  [ESportsCodes.MODERN_PENTATHLON]: 'ModernPentathlonDark'
}

const getColorMap = (theme: LayoutTheme): SportIconsColorMap => {
  return theme === LayoutTheme.Light
    ? SPORTS_ICON_MAP_LIGHT
    : SPORTS_ICON_MAP_DARK
}

export const getSportSymbolId = (
  sport?: ESportsCodes,
  theme: LayoutTheme = LayoutTheme.Light
): string => {
  if (!sport) {
    return DEFAULT_SPORT_SYMBOL_ID
  }

  const colorMap = getColorMap(theme)

  return colorMap[sport] || DEFAULT_SPORT_SYMBOL_ID
}
