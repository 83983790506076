import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resultsProviderActions,
  selectResultCountersList
} from 'astra-core/containers/ResultsProvider'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useResultsActiveItem } from 'astra-core/hooks/useResultsActiveItem'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { ERoutes } from 'shared/types/routes'

import { ResultsMenuItemProps } from './ResultsMenuList.types.ts'
import {
  StyledSportsMenuItemCount,
  StyledSportsMenuItemLink,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './ResultsMenuList.styled'

export const ResultsMenuList = () => {
  const resultsCountersList = useSelector(selectResultCountersList)
  useResultsActiveItem()

  return (
    <StyledSportsMenuList>
      {resultsCountersList.map((sport) => (
        <ResultsMenuItem key={sport.id} sport={sport as Required<typeof sport>}>
          {sport.name}
        </ResultsMenuItem>
      ))}
    </StyledSportsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(({ sport }) => {
  const dispatch = useDispatch()

  const changeCurrentSportId = useCallback(() => {
    dispatch(
      resultsProviderActions.setCurrentSportIdFilter({ sportId: sport.id })
    )
  }, [dispatch, sport.id])

  return (
    <StyledSportsMenuItemLink
      // @ts-ignore
      $active={sport.isActive}
      to={`${ERoutes.Results}`}
      onClick={changeCurrentSportId}
    >
      <SportIcon
        isActive={sport.top}
        sportCode={sport.code as ESportsCodes}
        withBackground
      />
      <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>
      <StyledSportsMenuItemCount>{sport.count}</StyledSportsMenuItemCount>
    </StyledSportsMenuItemLink>
  )
})
