import React, { useEffect, useState } from 'react'
import CurrencyInput, {
  CurrencyInputOnChangeValues,
  formatValue
} from 'react-currency-input-field'

import { DynamicInput } from './DynamicInput'
import { DynamicInputProps } from './DynamicInput.types'

type TCurrencyDynamicInput = DynamicInputProps & {
  onAmoutChange: (amount: string) => void
}

const format = (value: string) => {
  return formatValue({
    value,
    decimalSeparator: ',',
    groupSeparator: ' '
  })
}

export const CurrencyDynamicInput = React.forwardRef<
  HTMLInputElement,
  TCurrencyDynamicInput
>((props: TCurrencyDynamicInput, ref) => {
  const [inputValue, setInputValue] = useState<string>(
    format(props.inputValue ?? '')
  )

  useEffect(() => {
    setInputValue(format(props.inputValue ?? ''))
  }, [props.inputValue])

  const onChangeValues = (
    value: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues
  ) => {
    setInputValue(values?.formatted ?? '')
    props.onAmoutChange(value ?? '')
  }

  return (
    <CurrencyInput
      transformRawValue={(raw) => {
        return raw.replace('.', ',')
      }}
      allowNegativeValue={false}
      customInput={DynamicInput}
      decimalSeparator=","
      groupSeparator=" "
      inputMode="decimal"
      // @ts-ignore CurrencyInput cant type custom input
      inputValue={inputValue}
      placeholder={props.placeholder}
      ref={ref}
      disableAbbreviations
      onValueChange={onChangeValues}
    />
  )
})
