import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconChevronUp: FC<IconProps> = (props) => {
  const theme = useTheme()
  const color = theme.colors.default.primary[90]
  return (
    <Icon {...props}>
      <svg fill="none" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 7L7 1L1 7" stroke={color} />
      </svg>
    </Icon>
  )
}
