import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getLiveMatchTrackerContainer = (state: RootState) =>
  state.liveMatchTrackerContainer ?? initialState

const getCurrentMatch = (state: RootState) =>
  getLiveMatchTrackerContainer(state).currentMatch

export const selectCurrentMatch = createSelector(
  [getCurrentMatch],
  (currentMatch) => currentMatch
)
