import styled, { css } from 'styled-components'

import { Icon } from '../Icon'

import { ISportIconProps } from './SportIcon.types'

interface IStyledIconProps extends ISportIconProps {
  $color: string
  $backgroundColor: string
}

export const StyledSportIcon = styled(Icon)<IStyledIconProps>`
  ${({ isActive, theme, $color }) => css`
    color: ${isActive ? $color : theme.colors.default.primary[70]} !important;
  `}

  & use {
    ${({ isActive, theme }) => {
      if (!isActive) {
        return css`
          --discolor: ${theme.colors.default.primary[70]};
        `
      }
    }}
  }

  ${({ withBackground, $backgroundColor, isActive, theme }) => {
    if (withBackground) {
      return css`
        background-color: ${isActive
          ? $backgroundColor
          : theme.colors.custom.primary[4]};
        border-radius: 4px;
        padding: 4px;
      `
    }
  }}
`
