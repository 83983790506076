import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconRoundWarning: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#FFC400" height="40" rx="20" width="40" />
      <path
        clipRule="evenodd"
        d="M29.6878 24.5822L22.0288 11.1783C21.1318 9.60725 18.8678 9.60725 17.9708 11.1783L10.3118 24.5822C9.42076 26.1402 10.5458 28.0793 12.3408 28.0793H27.6588C29.4528 28.0793 30.5778 26.1402 29.6878 24.5822ZM20 15.6689C20.4142 15.6689 20.75 16.0047 20.75 16.4189V20.1589C20.75 20.5732 20.4142 20.9089 20 20.9089C19.5858 20.9089 19.25 20.5732 19.25 20.1589V16.4189C19.25 16.0047 19.5858 15.6689 20 15.6689ZM19.996 22.0391C19.444 22.0391 18.996 22.4871 19 23.0391C19 23.5911 19.448 24.0391 20 24.0391C20.552 24.0391 21 23.5911 21 23.0391C21 22.4871 20.552 22.0391 19.996 22.0391Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
