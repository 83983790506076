import keyBy from 'lodash/keyBy'

import { routes } from 'pages/model'
import { IRoute } from 'shared/types/routes'

import { ICustomRoute, IFilterRoutes } from './filterRoutes.types'

export const filterRoutes = ({ routeList, t }: IFilterRoutes): ICustomRoute[] =>
  routeList.map((item) => ({
    ...keyBy(routes, 'path')[item.path],
    translatedName: t(item.locale),
    icon: item.icon
  }))

export const isActiveCurrentPath = (
  path: string,
  itemPath: IRoute['path'] | string
) => typeof itemPath === 'string' && `${path}/`.includes(`${itemPath}/`)
