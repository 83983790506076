import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventLiveTracker: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9 5.17071C10.1652 5.58254 11 6.69378 11 8C11 9.30622 10.1652 10.4175 9 10.8293V13H12C13.1046 13 14 12.1046 14 11V5C14 3.89543 13.1046 3 12 3H9V5.17071ZM7 3V5.17071C5.83481 5.58254 5 6.69378 5 8C5 9.30622 5.83481 10.4175 7 10.8293V13H4C2.89543 13 2 12.1046 2 11V5C2 3.89543 2.89543 3 4 3H7ZM9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
