import { Props as SelectProps } from 'react-select'
import styled from 'styled-components'

import { Input } from 'shared/ui/Input'
import { Select } from 'shared/ui/Select'
import { IconResults } from 'shared/ui/Icon/General/IconResults'
import { customMedia } from 'shared/lib/styled'

export const StyledResultsWrapper = styled.div``

export const StyledSelect = styled(Select)<SelectProps>`
  margin-right: 8px;
  width: 140px;
`

export const StyledSelectWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`

export const StyledHeaderWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: flex;
`

export const StyledHeaderResults = styled.div`
  grid-area: page-header;
  margin-top: 8px;
`

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  margin: 0 16px;
`

export const StyledCheckboxText = styled.span`
  line-height: 14px;
  margin-left: 8px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
      color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledSearchInput = styled(Input)`
  margin-right: 8px;
  width: 122px;
`
export const StyledInputWrapper = styled.div`
  ${customMedia.small} {
    order: -1;
  }
`

// export const StyledCollapseAllText = styled.div`
//   line-height: 14px;
//   margin-left: 16px;
//   cursor: pointer;
//   ${({ theme }) =>
//     `
//       font-size: ${theme.fonts.size.s};
//       font-weight: ${theme.fonts.weight.regular};
//       color: ${theme.colors.default.primary[50]};
//     `}
// `

export const StyledLive = styled.span`
  ${({ theme }) => `
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.fixed.red[0]};
    text-transform: uppercase;
    margin-left: 4px;
  `}
`

export const StyledIconResults = styled(IconResults)`
  background-color: ${(props) => props.theme.colors.custom.green[4]};
  border-radius: 4px;
  padding: 5px;
`
