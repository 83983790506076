import React from 'react'
import { toast } from 'react-toastify'
import { css } from 'styled-components'

import { STYLED_HEADER_HEIGHT } from 'widgets/header/Header.styled'

import { EGetCustomTransitionTypes, getCustomTransitionOfType } from './utils'
import { StyledToastContainerDefault } from './Toastify.styled'
import { ENotificationContainerIds } from './Toastify.types'

export const Toastify = () => (
  <>
    <StyledToastContainerDefault
      containerPositionStyles={css`
        bottom: 10px;
        left: 16px;
      `}
      transition={getCustomTransitionOfType(
        EGetCustomTransitionTypes.SYSTEM_NOTIFICATION
      )}
      autoClose={false}
      containerId={ENotificationContainerIds.SYSTEM}
      position={toast.POSITION.BOTTOM_LEFT}
      enableMultiContainer
    />

    <StyledToastContainerDefault
      containerPositionStyles={css`
        right: 16px;
        top: ${STYLED_HEADER_HEIGHT + 20}px;
      `}
      transition={getCustomTransitionOfType(
        EGetCustomTransitionTypes.EVENT_NOTIFICATION
      )}
      autoClose={false}
      containerId={ENotificationContainerIds.EVENT}
      position={toast.POSITION.TOP_RIGHT}
      enableMultiContainer
      newestOnTop
    />
  </>
)
