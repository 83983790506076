import React, { FC, useCallback } from 'react'
import { CompetitorStatistics, Event } from 'betweb-openapi-axios'
import { selectEventStatistics } from 'astra-core/containers/EventsProvider'
import { useSelector } from 'react-redux'

import { RootState } from 'shared/types/store'
import {
  IconStatFootballCorner,
  IconStatFootballFouls,
  IconStatFootballShot,
  IconStatFootballShotOnGoal,
  IconStatFootballYellowCard
} from 'shared/ui/Icon/SportStatsIcon/Football'

import {
  StyledBannerStats,
  StyledBannerStatsItem,
  StyledBannerStatsItemScore,
  StyledBannerStatsItemText
} from '../../Stats.styled'

import { StatisticsMeasureItems } from './StatsFootball.types'
import { StyledStatsIconWrapper } from './StatsFootball.styled'

import type { StatisticsItems } from '../../Stats.types'

const statisticsItems: StatisticsItems<CompetitorStatistics> = [
  {
    field: StatisticsMeasureItems.FreeKicks,
    Icon: IconStatFootballFouls
  },
  {
    field: StatisticsMeasureItems.YellowCards,
    Icon: IconStatFootballYellowCard
  },
  {
    Icon: IconStatFootballShotOnGoal
  },
  {
    field: StatisticsMeasureItems.Corners,
    Icon: IconStatFootballCorner
  },
  {
    field: StatisticsMeasureItems.ShotsOnTarget,
    Icon: IconStatFootballShot
  }
]

export const StatsFootball: FC<{ eventId: Event['id'] }> = ({ eventId }) => {
  const eventStatistics = useSelector((state: RootState) =>
    selectEventStatistics(state, eventId)
  )

  const getStatisticsItem = useCallback(
    (field?: keyof CompetitorStatistics) => {
      if (eventStatistics.homeStatistics && eventStatistics.awayStatistics) {
        return (
          <>
            <StyledBannerStatsItemScore>
              {(field && eventStatistics.homeStatistics?.[field]) ?? 0}
            </StyledBannerStatsItemScore>
            <StyledBannerStatsItemScore>
              {(field && eventStatistics.awayStatistics?.[field]) ?? 0}
            </StyledBannerStatsItemScore>
          </>
        )
      }
    },
    [eventStatistics]
  )

  if (!eventStatistics) {
    return null
  }

  return (
    <StyledBannerStats>
      {statisticsItems.map(({ field, Icon }) => (
        <StyledBannerStatsItem
          isCard={
            field === StatisticsMeasureItems.YellowCards ||
            field === StatisticsMeasureItems.RedCards
          }
          key={field}
        >
          <StyledStatsIconWrapper>
            <Icon />
          </StyledStatsIconWrapper>

          <StyledBannerStatsItemText>
            {getStatisticsItem(field)}
          </StyledBannerStatsItemText>
        </StyledBannerStatsItem>
      ))}
    </StyledBannerStats>
  )
}
