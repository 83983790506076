import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { EColorsNames } from 'shared/types/theme'
import { IconProps } from 'shared/ui/Icon'

export const EVENT_STATISTICS_POLLING_INTERVAL = 2000

export const ICON_ACTION_PROPS: IconProps = {
  size: 24,
  colorProps: { name: EColorsNames.Primary, value: 70 }
}

export const SPORT_CERTAIN_DATA: Partial<
  Record<ESportsCodes | 'default', any>
> = {
  [ESportsCodes.FOOTBALL]: {
    backgroundColor:
      'linear-gradient(162.76deg, #109D55 -6.01%, #055657 99.96%)',
    fixedColor: '#086050'
  },
  [ESportsCodes.BASKETBALL]: {
    backgroundColor:
      'linear-gradient(156.79deg, #EF5107 1.06%, #971102 103.97%)',
    fixedColor: '#971102'
  },
  [ESportsCodes.VOLLEYBALL]: {
    backgroundColor:
      'linear-gradient(163.48deg, #FCDB30 -7.97%, #BB0000 124.96%)',
    fixedColor: '#BB0000'
  },
  [ESportsCodes.ICE_HOCKEY]: {
    backgroundColor:
      'linear-gradient(164.18deg, #195FF0 4.18%, #091E85 93.74%)',
    fixedColor: '#091E85'
  },
  [ESportsCodes.TENNIS]: {
    backgroundColor: 'linear-gradient(154.76deg, #0495D9 6.6%, #273D99 91.26%)',
    fixedColor: '#273D99'
  },
  [ESportsCodes.TABLE_TENNIS]: {
    backgroundColor:
      'linear-gradient(156.79deg, #DA3C40 1.06%, #8C002E 103.97%)',
    fixedColor: '#8C002E'
  },
  [ESportsCodes.BOX]: {
    backgroundColor:
      'linear-gradient(170.52deg, #EA3434 -7.39%, #800B19 115.56%)',
    fixedColor: '#800B19'
  },
  [ESportsCodes.MMA]: {
    backgroundColor:
      'linear-gradient(170.52deg, #EA3434 -7.39%, #800B19 115.56%)',
    fixedColor: '#800B19'
  },
  default: {
    backgroundColor:
      'linear-gradient(165.16deg, #A2A2A2 -20.16%, #5E5E5E 106.66%)',
    fixedColor: '#5E5E5E'
  }
}

export enum SPORT_NAME_PERIOD {
  FOOTBALL = 'Т'
}
