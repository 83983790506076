import React, { FC } from 'react'

import { IEventStatisticScoreDefaultProps } from './EventStatisticScoreDefault.types'
import {
  StyledMainScore,
  StyledMainScoreWrapper,
  StyledScoreDivider
} from './EventStatisticScoreDefault.styled'

export const EventStatisticScoreDefault: FC<
  IEventStatisticScoreDefaultProps
> = ({ value, withDivider, ...props }) => (
  <StyledMainScoreWrapper {...props}>
    <StyledMainScore>{value}</StyledMainScore>

    {withDivider && <StyledScoreDivider />}
  </StyledMainScoreWrapper>
)
