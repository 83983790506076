import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'

import { CalendarMenuList } from './components'
import {
  StyledCalendarMenuTitle,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './calendar-menu.styled'

export const CalendarMenu = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  useEffect(() => {
    dispatch(calendarEventsContainerActions.fetchScheduledEventsCounters())
  }, [dispatch])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <StyledCalendarMenuTitle>
            {t('kind of sport')}
          </StyledCalendarMenuTitle>
        </StyledTabsWrapper>
        <CalendarMenuList />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
