import styled, { keyframes } from 'styled-components'

const speed = 1.5

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 150;
    stroke-dashoffset: -24;
  }
  100% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: -124;
  }
`

interface SvgProps {
  small?: boolean
}

export const StyledLoadingIndicatorSVG = styled.svg<SvgProps>`
  animation: ${rotate} ${speed * 1.75}s linear infinite;
  height: ${(p) => (p.small ? '1.25rem' : '3rem')};
  transform-origin: center;
  width: ${(p) => (p.small ? '1.25rem' : '3rem')};
`

export const StyledLoadingIndicatorSVGCircle = styled.circle`
  animation: ${dash} ${speed}s ease-in-out infinite;
  stroke: ${(props) => props.theme.colors.custom.primary[7]};
  stroke-linecap: round;
`

export const StyledLoadingIndicatorFullContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`
