import React, { FC, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentLanguage,
  selectLanguagesList,
  useChangeLanguage
} from 'astra-core/containers/LanguagesProvider'
import { Language } from 'betweb-openapi-axios'
import { languagesList } from 'astra-core/constants'
import { includes, remove } from 'lodash'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { EButtonViews } from 'shared/ui/Button'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { Flag } from 'shared/ui/Flag'

import {
  LanguagesWindow,
  StyledIconCheckMark,
  StyledLanguagesList,
  StyledLanguagesListButton,
  StyledLanguagesListItems,
  StyledLanguagesListItemsName
} from './LanguagesList.styled'

const LanguageItem = (item: Language) => {
  const { name, code } = item
  const { changeLanguage } = useChangeLanguage()
  const currentLanguage = useSelector(selectCurrentLanguage)

  const onChangeLanguage = () => changeLanguage(code)

  const Icon = () => <Flag country={code} />

  return (
    <StyledLanguagesListButton
      icon={Icon}
      view={EButtonViews.NONE}
      onClick={onChangeLanguage}
    >
      <StyledLanguagesListItems>
        <StyledLanguagesListItemsName>{name}</StyledLanguagesListItemsName>
        {currentLanguage?.code === code && (
          <StyledIconCheckMark
            colorProps={{
              name: EColorsNames.Green,
              value: 50,
              type: EColorsTypes.DEFAULT
            }}
          />
        )}
      </StyledLanguagesListItems>
    </StyledLanguagesListButton>
  )
}

export const LanguagesList: FC = () => {
  const dispatch = useDispatch()
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const languageList = useSelector(selectLanguagesList)

  remove(languageList, (item: Language) => !includes(languagesList, item.code))

  const handleModalClose = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalChangeLanguage(false))
  }, [dispatch])

  const backDropHandler = useCallback(
    (e) => {
      if (modalWrapperRef?.current === e.target) {
        handleModalClose()
      }
    },
    [handleModalClose]
  )

  return (
    <LanguagesWindow ref={modalWrapperRef} onClick={backDropHandler}>
      <StyledLanguagesList>
        {languageList.map((item: Language) => (
          <LanguageItem key={item?.id} {...item} />
        ))}
      </StyledLanguagesList>
    </LanguagesWindow>
  )
}
