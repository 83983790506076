import React, { FC, useMemo } from 'react'
import { Event } from 'betweb-openapi-axios'

import { getEventBetradarSourceId } from 'shared/lib/events'
import { IconEventLiveTracker } from 'shared/ui/Icon/Event'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { useHandleClickLiveTracker } from '../lib'

export const OpenActionMatchLiveTracker: FC<{ event: Event }> = ({ event }) => {
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useHandleClickLiveTracker({ eventSourceId })

  return eventSourceId ? (
    <IconEventLiveTracker
      colorProps={{
        name: EColorsNames.Green,
        type: EColorsTypes.CUSTOM,
        value: 1
      }}
      size={16}
      onClick={handleClick}
    />
  ) : null
}
