import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  margin-top: 8px;
`

export const Title = styled.div`
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const Bonus = styled.span`
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.default.primary[90]};
    `}
`
