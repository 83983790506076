import styled, { css, keyframes } from 'styled-components'

import { CssButtonReset } from 'constants/styles'
import { EColorsTypes } from 'shared/types/theme'

import { EIconButtonShape, IconProps, IStyledIconButtonProps } from './types'

export const StyledIcon = styled.span<IconProps>`
  ${(props) => {
    const iconColor = props.colorProps
      ? props.theme.colors[props.colorProps.type || EColorsTypes.DEFAULT][
          props.colorProps.name
        ][props.colorProps.value]
      : ''

    return css`
      align-items: center;
      display: inline-flex;
      flex-shrink: 0;
      justify-content: center;
      overflow: hidden;

      svg {
        display: block;
        height: 100%;
        width: 100%;
      }

      ${props.colorProps &&
      iconColor &&
      css`
        color: ${iconColor} !important;
      `}

      ${typeof props.size === 'number' &&
      css`
        height: ${props.size}px;
        width: ${props.size}px;
      `}

    ${typeof props.size === 'object' &&
      css`
        height: ${props.size.height}px;
        width: ${props.size.width}px;
      `}
    
    ${props.twist &&
      css`
        transform: rotate(${props.twist}deg);
      `}
    
    ${props.onClick &&
      css`
        cursor: pointer;
        ${CssButtonReset}
      `}
    `
  }}
`

const keyframeIconPreloaderSpin = keyframes`
  100% {
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
`

export const StyledIconPreloader = styled.div`
  -moz-animation: ${keyframeIconPreloaderSpin} 1s linear infinite;
  -webkit-animation: ${keyframeIconPreloaderSpin} 1s linear infinite;
  animation: ${keyframeIconPreloaderSpin} 1s linear infinite;
  height: 40px;
  transform-origin: 50% 50%;
  width: 40px;
`

export const StyledIconButton = styled.button<IStyledIconButtonProps>`
  ${(props) => css`
    align-items: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: ${props.size};
    justify-content: center;
    outline: none;
    padding: 4px;
    width: ${props.size};

    ${props.shape === EIconButtonShape.Circle &&
    css`
      border-radius: 50%;
    `}

    ${props.isActiveState
      ? css`
          background: ${props.withBackground
            ? props.theme.colors.default.primary[90]
            : 'transparent'};

          ${StyledIcon} {
            color: ${props.theme.colors.default.primary[30]} !important;
          }

          &:hover {
            background: ${props.theme.colors.default.primary[80]};
          }

          &:active {
            background: ${props.theme.colors.default.primary[70]};
          }
        `
      : css`
          background: ${props.withBackground
            ? props.theme.colors.custom.primary[4]
            : 'transparent'};

          ${StyledIcon} {
            color: ${props.theme.colors.default.primary[17]} !important;
          }

          &:hover {
            background: ${props.theme.colors.default.primary[10]};

            ${StyledIcon} {
              color: ${props.theme.colors.custom.primary[30]} !important;
            }
          }

          &:active {
            background: ${props.theme.colors.default.primary[8]};

            ${StyledIcon} {
              color: ${props.theme.colors.default.primary[90]} !important;
            }
          }
        `}
  `}
`
