import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledErrorPageText,
  StyledErrorPageWrapper,
  StyledErrorPageTitle
} from './page-error-unknow.styled'

export const PageErrorUnknown = () => {
  const [t] = useTranslation()

  return (
    <StyledErrorPageWrapper>
      <StyledErrorPageTitle>
        <span aria-label="Crying Face" role="img">
          😢
        </span>
      </StyledErrorPageTitle>
      <StyledErrorPageText>{t('something went wrong')}</StyledErrorPageText>
    </StyledErrorPageWrapper>
  )
}
