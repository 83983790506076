import React, { FC, useMemo } from 'react'
import { MainOutcomeTypes } from 'astra-core/containers/CommonDataProvider'
import { ParameterType } from 'betweb-openapi-axios'
import { getOutcomeParameterValue } from 'astra-core/utils/outcomes'

import { getEventRowTestDataId } from 'shared/lib/testData'
import { useEventMode } from 'hooks'
import { EventOddProps } from 'pages/page-live-events/Line/Line.types'
import {
  CoefficientTableCellStyled,
  EventCoefficientTable as EventCoefficientTableEntity
} from 'entities/event'
import { useHandleAddCoefficientToBasket } from 'features/event'

import { useGetEventProbability } from '../../lib/coefficient/get-event-probability'
import { EventRowCoefficientTotal } from '../coefficient-total'

import { IEventRowCoefficientProps } from './coefficient.types'

export const EventRowCoefficient: FC<IEventRowCoefficientProps> = ({
  mainOutcomeType,
  outcomeTypesName,
  event,
  ...props
}) => {
  const { eventProbability } = useGetEventProbability({
    event,
    outcomeTypesName
  })

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket()

  // @ts-ignore
  const outcomeMode = useEventMode({
    eventProbability: {
      ...eventProbability,
      eventId: event.id
    } as EventOddProps['eventProbability']
  })
  const eventTestDataId = getEventRowTestDataId(event, mainOutcomeType)

  const parameterValue = useMemo(
    () =>
      getOutcomeParameterValue({
        eventProbability,
        parameterType: ParameterType.Value
      }),
    [eventProbability]
  )

  const isMainOutcomeTotal =
    mainOutcomeType === MainOutcomeTypes.TO ||
    mainOutcomeType === MainOutcomeTypes.TOO

  return (
    <>
      {isMainOutcomeTotal && (
        <EventRowCoefficientTotal
          event={event}
          totalOutcomeType={mainOutcomeType}
        />
      )}

      <CoefficientTableCellStyled
        data-test-id={eventTestDataId}
        withoutBorder={isMainOutcomeTotal}
        withParam={props.withParam}
        onClick={() =>
          handleAddOutcomeToBasket({
            eventId: event.id,
            eventStatus: event.status,
            eventProbability,
            live: event.live
          })
        }
      >
        <EventCoefficientTableEntity
          outcomeMode={outcomeMode}
          parameterValue={props.withParam && parameterValue}
          value={eventProbability?.odd}
        />
      </CoefficientTableCellStyled>
    </>
  )
}
