import { all, put, takeLatest } from 'redux-saga/effects'
import {
  RemoveItemPayload,
  SetItemPayload,
  storageProviderActions
} from 'astra-core/containers/StorageProvider'
import { PayloadAction } from '@reduxjs/toolkit'

export function* setStorageSaga() {
  yield put(storageProviderActions.setInitialValues({ storage: localStorage }))
}

function setItem({ payload: { key, value } }: PayloadAction<SetItemPayload>) {
  localStorage.setItem(key, value)
}

function removeItem({ payload: { key } }: PayloadAction<RemoveItemPayload>) {
  localStorage.removeItem(key)
}

function clearStorage() {
  localStorage.clear()
}

export function* localStorageProviderSaga() {
  yield all([
    takeLatest(storageProviderActions.setStorage, setStorageSaga),
    takeLatest(storageProviderActions.setItem, setItem),
    takeLatest(storageProviderActions.removeItem, removeItem),
    takeLatest(storageProviderActions.clear, clearStorage)
  ])
}
