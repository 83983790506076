import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

import { IStyledFilterButtonProps } from './PickerPeriod.types'

export const StyledFilterButton = styled(Button)<IStyledFilterButtonProps>`
  background-color: ${(theme) =>
    theme.isActive ? theme.theme.colors.custom.primary[68] : 'transparent'};
  border: 1px solid ${(theme) => theme.theme.colors.default.primary[5]};
  color: ${(theme) =>
    theme.isActive
      ? theme.theme.colors.fixed.primary[0]
      : theme.theme.colors.default.primary[70]};

  &:hover {
    color: ${(p) => p.theme.colors.default.primary[70]};
  }
`
