import styled from 'styled-components'

import { Menu } from 'widgets/header/Menu'

export const STYLED_FIXED_HEADER_HEIGHT = 32

export const StyledMenu = styled(Menu)`
  height: ${STYLED_FIXED_HEADER_HEIGHT}px;
  justify-content: center;
  position: relative;
  width: 100%;
`

export const StyledFixedHeader = styled.div`
  align-items: center;
  background: ${(props) => props?.theme.colors.custom.primary[5]};
  display: flex;
  height: 32px;
  padding: 0 17px;

  & > :first-child,
  & > :last-child {
    flex-shrink: 0;
    width: 30%;
  }
`
