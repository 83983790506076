import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledLowerBalanceModalWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 20px 16px;
  width: 400px;
`

export const StyledLowerBalanceModalTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledLowerBalanceModalTitle = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.l};
    font-weight: ${props.theme.fonts.weight.bold};
    text-align: left;
  `}
`
export const StyledLowerBalanceModalDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const StyledLowerBalanceModalDescription = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
  `}
`

export const StyledLink = styled(Link)`
  border-radius: 2px;
  margin-left: 10px;
  padding: 0 4px;
  text-decoration: none;
  ${({ theme }) => css`
    background-color: ${theme.colors.default.primary[5]};
    color: ${theme.colors.default.primary[80]};
    font-weight: ${theme.fonts.weight.normal};
  `};
  text-transform: capitalize;
`
