import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { selectAccount } from 'astra-core/containers/AccountProvider'
import { useRouteMatch } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { ComponentOfUnauthorized } from 'features/ComponentOfUnauthorized'

import { ComponentOfAuthorized } from './components'
import { CasinoComponentOfAuthorized } from './components/CasinoComponentOfAuthorized'

export const PersonalData = memo(() => {
  const account = useSelector(selectAccount)
  const casinoMatches = useRouteMatch(ERoutes.Casino)

  return account ? (
    casinoMatches ? (
      <CasinoComponentOfAuthorized />
    ) : (
      <ComponentOfAuthorized />
    )
  ) : (
    <ComponentOfUnauthorized />
  )
})
