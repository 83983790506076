import { createContext } from 'react'
import { Event } from 'betweb-openapi-axios'

interface ITableBetsRowContext {
  event: Event
}

export const TableBetsRowContext = createContext<ITableBetsRowContext | null>(
  null
)
