import React, { Fragment } from 'react'

import { StyledFooterBottomSecurity } from 'entities/footer-of-page'
import { OpenCuracaoLicence } from 'features/footer-of-page'
import { IconSecurity } from 'shared/ui/Icon/General/IconSecurity'

export const FooterOfPageLogoItems = () => (
  <>
    <OpenCuracaoLicence />

    <StyledFooterBottomSecurity>
      <IconSecurity />
    </StyledFooterBottomSecurity>
  </>
)
