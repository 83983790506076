import React from 'react'

import { StyledIconPreloader } from '../styled'

export const IconPreloader = ({ color }) => (
  <StyledIconPreloader>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 20C38 23.5388 36.9569 26.9991 35.0011 29.9483C33.0452 32.8975 30.2635 35.2047 27.0035 36.5816C23.7436 37.9585 20.1501 38.3439 16.6723 37.6897C13.1945 37.0355 9.98675 35.3707 7.44996 32.9033C4.91317 30.436 3.15994 27.2757 2.40944 23.8174C1.65895 20.3591 1.94449 16.7563 3.23037 13.4594C4.51625 10.1625 6.74542 7.31776 9.6392 5.28084C12.533 3.24391 15.963 2.10516 19.5004 2.00693"
        stroke="url(#paint0_angular)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <defs>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(20 20) rotate(-8.36588) scale(17.1828)"
          gradientUnits="userSpaceOnUse"
          id="paint0_angular"
          r="1"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="1" />
        </radialGradient>
      </defs>
    </svg>
  </StyledIconPreloader>
)
