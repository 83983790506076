import React, { FC } from 'react'

import {
  ButtonProps,
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/Button'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

import { CoefficientTableCellButtonExpandStyled } from './expand-button.styled'

export const EventRowExpandButton: FC<
  Pick<ButtonProps, 'children' | 'onClick'> & {
    isExpanded?: boolean
  }
> = ({ children, onClick, isExpanded }) => {
  return children ? (
    <CoefficientTableCellButtonExpandStyled
      iconProps={{
        positionSide: EButtonIconPositionSides.Right,
        twist: isExpanded ? 180 : 0,
        size: 10
      }}
      icon={IconChevronDown}
      size={EButtonSizes.S}
      view={EButtonViews.SECONDARY}
      onClick={onClick}
    >
      {children}
    </CoefficientTableCellButtonExpandStyled>
  ) : null
}
