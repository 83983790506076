import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconClose: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70519 5.00004L9.35164 1.35359L8.64453 0.646484L4.99808 4.29293L1.35164 0.646484L0.644531 1.35359L4.29098 5.00004L0.644531 8.64648L1.35164 9.35359L4.99809 5.70715L8.64453 9.35359L9.35164 8.64649L5.70519 5.00004Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
