import { EDatePeriods } from 'astra-core'

export const BETS_HISTORY_PERIODS = [
  { key: EDatePeriods.Day, label: 'today' },
  { key: EDatePeriods.Week, label: 'per week' },
  { key: EDatePeriods.Month, label: 'per month' },
  { key: EDatePeriods.ThreeMonths, label: 'per 3 months' }
]

export const CURRENT_DATE = new Date()
