import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import loadable from '@loadable/component'

import { selectIsShowBasketOnSmallWindow } from 'containers/BasketContainer/selectors'

import { Basket } from './Basket/Basket'
import { StyledRightContainerWrapper } from './right-container.styled'
import { hiddenRightContainerRoutesList } from './constants'

const LiveMatchTracker = loadable(() => import('widgets/live-match-tracker'), {
  resolveComponent: (c) => c.LiveMatchTracker,
  fallback: <></>
})

const LiveMatchBroadcast = loadable(
  () => import('widgets/live-match-broadcast'),
  {
    resolveComponent: (c) => c.LiveMatchBroadcast,
    fallback: <></>
  }
)

export const RightContainer: FC = () => {
  const { pathname } = useLocation()
  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)
  const isRouteWithPermanentHiddenBasket =
    hiddenRightContainerRoutesList.includes(pathname)

  return (
    <StyledRightContainerWrapper
      isRouteWithPermanentHiddenBasket={isRouteWithPermanentHiddenBasket}
      isShowBasketOnSmallWindow={isShowBasketOnSmallWindow}
    >
      <LiveMatchTracker />
      <LiveMatchBroadcast />
      <Basket />
    </StyledRightContainerWrapper>
  )
}
