import { ERoutes } from 'shared/types/routes'

export type HeaderRouteItem = {
  path: ERoutes
  locale: string
}

export const FIXED_HEADER_ROUTES_LIST: HeaderRouteItem[] = [
  { path: ERoutes.Casino, locale: 'casino' },
  { path: ERoutes.CyberbetGames, locale: 'cyberbet' },
  { path: ERoutes.BetGames, locale: 'betgames' },
  { path: ERoutes.LiveGames, locale: 'livegames' },
  { path: ERoutes.SportGamesLobby, locale: 'sport games' },
  { path: ERoutes.TurboGames, locale: 'fast games' },
  { path: ERoutes.TwainGames, locale: 'twain' },
  { path: ERoutes.VsportGames, locale: 'v-sport' },
  { path: ERoutes.Frigg, locale: 'frigg' }
]
