import { all, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  betsHistoryProviderActions,
  FetchBetsHistoryPayload
} from 'astra-core/containers/BetsHistoryProvider'

import { betsHistoryProviderSideEffects } from './model'
import { actions } from './slice'

const SOURCE_KEY = 'betsHistoryBasket'

export function* fetchBetsHistoryBasketSaga(
  action: PayloadAction<FetchBetsHistoryPayload>
) {
  yield put(
    betsHistoryProviderActions.fetchBets({
      reqData: action.payload,
      sideEffects: betsHistoryProviderSideEffects[SOURCE_KEY]
    })
  )
}

export function* betsHistoryBasketContainerSaga() {
  yield all([
    takeLatest(actions.fetchHistoryBetsBasket.type, fetchBetsHistoryBasketSaga)
  ])
}
