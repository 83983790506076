/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'

import { InjectedReducersType } from 'shared/lib/@reduxjs'
import { RootState } from 'shared/types/store'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: RootState) => state
  } else {
    // @ts-ignore
    return combineReducers({
      ...injectedReducers
      //  Statically injected reducers should be placed here
    })
  }
}
