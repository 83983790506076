import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconWarning: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.029 4.13894L21.688 17.5429C22.578 19.1009 21.453 21.0399 19.659 21.0399H4.34101C2.54601 21.0399 1.42101 19.1009 2.31201 17.5429L9.97101 4.13894C10.868 2.56794 13.132 2.56794 14.029 4.13894Z"
        fill="#FFD240"
      />
      <path d="M12 13.1199V9.37988V13.1199Z" fill="#FFC400" />
      <path
        d="M12 13.1199V9.37988"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.996 15C11.444 15 10.996 15.448 11 16C11 16.552 11.448 17 12 17C12.552 17 13 16.552 13 16C13 15.448 12.552 15 11.996 15Z"
        fill="white"
      />
    </svg>
  </Icon>
)
