import styled, { css } from 'styled-components'
import {
  ESportsCodes,
  MainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'

import { OddModes } from 'pages/page-live-events/Line/Line.types'
import { EColorsTypes } from 'shared/types/theme'

export const StyledOddWrapper = styled.div<{
  $mode?: OddModes
  isPlain?: boolean
}>`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.default.primary[80]};
    cursor: pointer;
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    height: ${props.isPlain ? '100%' : '24px'};
    justify-content: center;
    line-height: 16px;
    margin: auto;
    transition: 0.5s ease background-color, color;
    width: ${props.isPlain ? '100%' : '40px'};

    ${!props.isPlain &&
    css`
      background-color: ${({ theme }) => theme.colors.default.primary[5]};
      border-radius: 0.25rem;
    `}

    ${props.$mode === OddModes.Increase &&
    css`
      background-color: ${props.theme.colors.default.green[5]};
      transition: none;
    `}

    ${props.$mode === OddModes.Decrease &&
    css`
      background-color: ${props.theme.colors.default.red[5]};
      transition: none;
    `}

    ${props.$mode === OddModes.Active &&
    css`
      background-color: ${props.theme.colors.custom.primary[48]};
      color: ${props.theme.colors.custom.primary[43]};
      transition: none;
      ${StyledEventOdd} {
        color: ${props.theme.colors.custom.primary[43]};
      }
      ${StyledEventOddInfo} {
        color: ${props.theme.colors.custom.primary[6]};
        background-color: ${props.theme.colors.custom.primary[47]};
      }

      &:hover {
        background-color: ${props.theme.colors.custom.primary[48]};
        color: ${props.theme.colors.custom.primary[6]};
        ${StyledEventOdd} {
          color: ${props.theme.colors.custom.primary[43]};
        }
        ${StyledEventOddInfo} {
          background-color: ${props.theme.colors[EColorsTypes.CUSTOM]
            .primary[47]};
        }
      }
    `}

    &:hover {
      ${props.isPlain
        ? css`
            background-color: ${props.theme.colors.custom.primary[8]};
          `
        : css`
            background-color: ${props.theme.colors.default.primary[10]};
          `}

      ${props.$mode === OddModes.Increase &&
      css`
        background-color: ${props.theme.colors.fixed.green[0]};
      `}

      ${props.$mode === OddModes.Decrease &&
      css`
        background-color: ${props.theme.colors.fixed.red[1]};
      `}

      ${props.$mode === OddModes.Active &&
      css`
        background-color: ${props.theme.colors.custom.primary[48]};
      `}
    }
  `}
`

export const StyledEventOdd = styled.div<{
  $mode?: OddModes
}>`
  color: ${({ theme, $mode }) =>
    $mode !== OddModes.Active
      ? theme.colors.default.primary[80]
      : theme.colors.custom.primary[46]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
`

export const StyledEventOddInfo = styled.div<{ $mode?: OddModes }>`
  align-self: center;
  background-color: ${(props) => props.theme.colors.default.primary[5]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.default.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  padding: 4px;

  & + ${StyledEventOdd} {
    margin-left: 8px;
  }
  ${(props) => css`
    ${props.$mode === OddModes.Active &&
    css`
      background-color: ${props.theme.colors.custom.primary[45]};
      color: ${(props) => props.theme.colors.default.primary[50]};
      transition: none;
    `}
  `}
`

export const CoefficientTableCellStyled = styled.div<{
  withoutBorder?: boolean
  withParam?: boolean
  withExpandButton?: boolean
  viewType?: MainOutcomeTypes | 'total'
  sportCode?: ESportsCodes
}>`
  ${(props) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    grid-column-gap: 4px;
    justify-content: center;

    ${props.withParam
      ? css`
          width: 80px;
        `
      : css`
          width: 40px;
        `}

    ${!props.withoutBorder &&
    css`
      border-left: 1px solid ${props.theme.colors.custom.primary[4]};
    `}
    
    ${props.withExpandButton &&
    css`
      width: 70px;
    `}
    
    ${props.sportCode === ESportsCodes.BASKETBALL &&
    css`
      ${props.viewType === 'total' &&
      css`
        justify-content: flex-start;
        padding-left: 8px;
        width: 62px;
      `}
    `}
  `}
`

export const StyledTableCellFirst = styled(CoefficientTableCellStyled)`
  flex-grow: 1;
  min-width: 300px;
`
