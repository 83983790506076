import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconLightning: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="currentColor"
      height={24}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.959.056c.279.12.445.406.407.703l-.59 4.644h5.395c.267 0 .509.151.621.388a.667.667 0 0 1-.094.72l-6.857 8.103a.693.693 0 0 1-.8.187.674.674 0 0 1-.407-.703l.59-4.643H1.828a.687.687 0 0 1-.621-.39.667.667 0 0 1 .094-.718L8.159.243a.693.693 0 0 1 .8-.187ZM15.665.335c.447.446.447 1.17 0 1.616l-1.143 1.143a1.143 1.143 0 1 1-1.616-1.616L14.05.335a1.143 1.143 0 0 1 1.616 0ZM15.665 15.665a1.143 1.143 0 0 1-1.616 0l-1.143-1.143a1.143 1.143 0 0 1 1.616-1.616l1.143 1.143c.447.446.447 1.17 0 1.616ZM.335 15.665a1.143 1.143 0 0 1 0-1.616l1.143-1.143a1.143 1.143 0 1 1 1.616 1.616L1.95 15.665a1.143 1.143 0 0 1-1.616 0ZM.335.335a1.143 1.143 0 0 1 1.616 0l1.143 1.143a1.143 1.143 0 0 1-1.616 1.616L.335 1.95a1.143 1.143 0 0 1 0-1.616Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
