import {
  EEventStatisticsCodes,
  TGetRenderedStatisticsStatistics
} from './EventStatistics.types'

export const getRenderedStatistics = (
  statistics: TGetRenderedStatisticsStatistics,
  included: EEventStatisticsCodes[]
) =>
  statistics.filter(
    (item) =>
      included.includes(item.code as EEventStatisticsCodes) && !!item.value
  )
