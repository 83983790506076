import styled from 'styled-components'

export const StyledLayout = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  overflow: hidden;
`

export const StyledContent = styled.div`
  display: grid;
  grid-area: content;
  grid-template-rows: 1fr auto;
`
