import { useCallback, useEffect } from 'react'
import {
  balanceProviderActions,
  PaymentModalType,
  PAYMENT_QUERY_PARAM,
  EPaymentModalTypes,
  selectPaymentModalType
} from 'astra-core/containers/BalanceProvider'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { enumIncludes } from 'astra-core'

import { useQuery } from './useQuery'
import { usePrevious } from './usePrevious'

// TODO: use this func from Astra-Core
export const useShowPaymentModal = () => {
  const togglePaymentModal = useTogglePaymentModal()
  const query = useQuery()
  const payment = query.get(PAYMENT_QUERY_PARAM)
  const paymentModalType = useSelector(selectPaymentModalType)
  const prevPaymentModalType = usePrevious(paymentModalType)
  const history = useHistory()

  useEffect(() => {
    if (!paymentModalType && !!prevPaymentModalType) {
      history.replace({
        search: ''
      })
    }
  }, [history, paymentModalType, prevPaymentModalType])

  useEffect(() => {
    if (enumIncludes(EPaymentModalTypes, payment)) {
      togglePaymentModal(payment as EPaymentModalTypes)
    } else {
      togglePaymentModal(null)
    }
  }, [payment, togglePaymentModal])
}

export const useTogglePaymentModal = () => {
  const dispatch = useDispatch()

  const togglePaymentModal = useCallback(
    (paymentModalType: PaymentModalType) => {
      dispatch(balanceProviderActions.togglePaymentModal({ paymentModalType }))
    },
    [dispatch]
  )

  return togglePaymentModal
}
