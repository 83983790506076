import styled from 'styled-components'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

export const StyledDataListDefault = styled.div`
  padding: 0 8px;
  width: 100%;
`

export const StyledLoader = styled(LoaderSpinner)`
  height: 20px;
  margin: 10px auto 0;
  width: 20px;
`
