import React, { FC, useContext, useMemo, useState } from 'react'
import ReactSelect, { components } from 'react-select'
import { ThemeContext } from 'styled-components'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

import { StyledIndicatorsContainer } from './styled'
import { CustomReactSelectProps, ESelectView } from './types'
import { SELECT_STYLES } from './constants'
import { CustomOption } from './components'

export const Select: FC<CustomReactSelectProps> = ({
  view = ESelectView.Default,
  testData,
  ...props
}) => {
  const [isMenuShown, setIsMenuShown] = useState(false)
  const themeContext = useContext(ThemeContext) // TODO: to rework
  const styles = useMemo(
    () => SELECT_STYLES[view](themeContext),
    [themeContext, view]
  )

  const onMenuOpen = () => setIsMenuShown(true)
  const onMenuClose = () => setIsMenuShown(false)
  const handleKeyDown = (event) => event.preventDefault()

  return (
    <ReactSelect
      {...props}
      components={{
        IndicatorsContainer: () => (
          <StyledIndicatorsContainer>
            <IconChevronDown size={11} twist={isMenuShown ? 180 : 0} />
          </StyledIndicatorsContainer>
        ),
        Option: (optionProps) => {
          let testId: undefined | string
          if (optionProps?.data?.testId) {
            testId = `${testData ? `${testData}--` : ''}${
              optionProps.data.testId
            }`
          }
          return (
            <CustomOption
              {...optionProps}
              innerProps={{
                ...optionProps.innerProps,
                'data-test-id': testId
              }}
            />
          )
        },
        Menu: (menuProps) => (
          <components.Menu
            {...menuProps}
            innerProps={{ ...menuProps.innerProps, 'data-test-id': testData }}
          />
        )
      }}
      styles={styles}
      onKeyDown={handleKeyDown}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
    />
  )
}
