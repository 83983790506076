import styled, { css } from 'styled-components'

import { ERatioView, RadioProps } from './types'

export const StyledRadioWrapper = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`

export const StyledRadio = styled.div<RadioProps>`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 7px;
  height: 14px;
  padding: 2px;
  width: 14px;
  &:after {
    border-radius: 4px;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
  }

  ${({ checked, disabled, theme, view }) =>
    css`
      ${view === ERatioView.DEFAULT &&
      css`
        border: 1px solid
          ${checked
            ? theme.colors.default.primary[90]
            : theme.colors.default.primary[30]};

        &:after {
          background-color: ${checked
            ? disabled
              ? theme.colors.default.primary[30]
              : theme.colors.default.primary[90]
            : theme.colors.default.primary[0]};
        }
      `}

      ${view === ERatioView.BASKET &&
      css`
        border: 1px solid
          ${checked
            ? theme.colors.custom.blue[18]
            : theme.colors.custom.blue[18]};

        &:after {
          background-color: ${checked
            ? disabled
              ? theme.colors.default.primary[30]
              : theme.colors.custom.blue[18]
            : theme.colors.default.primary[0]};
        }
      `}
    `}
`

export const StyledRadioInput = styled.input`
  display: none;
`

export const StyledRadioLabel = styled.div`
  color: ${({ theme }) => theme.colors.custom.primary[55]};
  font-size: ${({ theme }) => theme.fonts.size.s};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 16px;
  margin-left: 8px;
`
