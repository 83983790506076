import React, { FC } from 'react'

import {
  EEventStatisticScoreTypes,
  EventStatisticScore
} from 'widgets/event-statistics'

import { IBasketBetCardHistoryStatisticProps } from './BasketBetCardHistoryStatistic.types'
import { StyledBasketBetCardHistoryStatistic } from './BasketBetCardHistoryStatistic.styled'

export const BasketBetCardHistoryStatistic: FC<
  IBasketBetCardHistoryStatisticProps
> = ({ currentScore, scoresByPeriod }) => (
  <StyledBasketBetCardHistoryStatistic>
    {currentScore && (
      <EventStatisticScore
        type={EEventStatisticScoreTypes.DEFAULT}
        value={currentScore}
      />
    )}

    {scoresByPeriod && (
      <EventStatisticScore
        type={EEventStatisticScoreTypes.BY_PERIOD}
        value={scoresByPeriod}
        asDots
      />
    )}
  </StyledBasketBetCardHistoryStatistic>
)
