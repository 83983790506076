import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCheckCircleFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.5693 7.73803C13.9769 7.36536 14.0052 6.73283 13.6326 6.32523C13.2599 5.91763 12.6274 5.88931 12.2198 6.26197L8.51953 9.64504L6.5693 7.86197C6.1617 7.48931 5.52917 7.51763 5.1565 7.92523C4.78384 8.33283 4.81216 8.96536 5.21976 9.33803L7.84476 11.738C8.2268 12.0873 8.81226 12.0873 9.1943 11.738L13.5693 7.73803Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
