import styled from 'styled-components'

import { CssButtonReset } from 'constants/styles'
import { StyledIcon } from 'shared/ui/Icon'
import { columnGapPrefixes } from 'shared/lib/styled'
import { UserAccountBalance } from 'widgets/user-account-balance'

export const StyledComponentOfAuthorized = styled.div`
  align-items: center;
  display: flex;
  ${columnGapPrefixes('8px')}
  position: relative;
`

export const StyledButtonBalance = styled(UserAccountBalance)`
  align-items: center;
  color: ${(props) => props.theme.colors.default.primary[90]};
  ${columnGapPrefixes('4px')}
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  ${CssButtonReset}

  &:hover {
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.default.primary[70]} !important;
    }
  }

  &:active {
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.default.primary[50]} !important;
    }
  }
`

export const StyledPadding = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 -4px;
  padding: 0 4px;
  position: relative;
  width: 100%;
`
