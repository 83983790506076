import styled, { css } from 'styled-components'

export const StyledSettingItem = styled.div``

export const StyledSettingItemTitle = styled.h2`
  ${(props) =>
    css`
      color: ${props.theme.colors.default.primary[90]};
      font-size: ${props.theme.fonts.size.s};
      font-weight: ${props.theme.fonts.weight.normal};
      line-height: 16px;
      margin-bottom: 8px;
    `}
`

export const StyledSettingItemContentRadio = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 36px;
`

export const StyledSettingItemIcon = styled.div<{ $src: string }>`
  ${(props) => css`
    -moz-outline-radius: 4px;
    background-image: url(${props.$src});
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px;
    height: 80px;
    margin-bottom: 8px;
    outline: 2px solid ${props.theme.colors.default.primary[10]};
    outline-offset: -2px;
    width: 118px;
  `}
`

export const StyledSettingItemLabel = styled.label`
  cursor: pointer;
`
