import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import values from 'lodash/values'
import { getEvents } from 'astra-core/containers/EventsProvider/selectors'
import { selectSports } from 'astra-core/containers/CommonDataProvider/selectors'
import { getEventsBySearch } from 'astra-core/utils/search'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'

import { initialState } from './slice'

export const selectLayoutSelectInputs = (state: RootState) =>
  state.calendarEventsContainer.layout.selectInputs ||
  initialState.layout.selectInputs

export const selectFilterSelectInputPeriod = (state: RootState) =>
  state.calendarEventsContainer.filters.selectInputs.period ||
  initialState.filters.selectInputs.period

export const selectFilterStream = (state: RootState) =>
  state.calendarEventsContainer.filters.isStream ??
  initialState.filters.isStream

export const selectFilterInputSearch = (state: RootState) =>
  state.calendarEventsContainer.filters.inputSearch ||
  initialState.filters.inputSearch

export const getCalendarEventsIds = (state: RootState) =>
  state.calendarEventsContainer.data.eventsIds || initialState.data.eventsIds

export const selectCalendarEventsFetchData = (state: RootState) =>
  state.calendarEventsContainer.data.fetchItems || initialState.data.fetchItems

export const getFilterCurrentSportId = (state: RootState) =>
  state.calendarEventsContainer.filters.currentSportId ||
  initialState.filters.currentSportId

export const selectCalendarEventsHasMore = (state: RootState) =>
  state.calendarEventsContainer.data.hasMore ?? initialState.data.hasMore

export const selectCalendarEventsPagination = (state: RootState) =>
  state.calendarEventsContainer.pagination || initialState.pagination

export const selectCalendarEventsFetchItems = (state: RootState) =>
  state.calendarEventsContainer.data.fetchItems || initialState.data.fetchItems

/// TODO check to empty object
export const getCalendarCounters = (state: RootState) =>
  state.calendarEventsContainer.data.eventsCountersBySport ||
  initialState.data.eventsCountersBySport

export const getCalendarFilterSportId = (state: RootState) =>
  state.calendarEventsContainer.filters.currentSportId ||
  initialState.filters.currentSportId

export const selectCalendarCountersList = createSelector(
  [selectSports, getCalendarCounters, getCalendarFilterSportId],
  (sports, calendarCounters, currentSportId) =>
    values(calendarCounters)
      .filter(({ count }) => count)
      .map((sport) => ({
        ...(sports[sport.id] || {}),
        ...sport,
        isActive: sport.id === currentSportId
      }))
)

export const selectCalendarEventsDataEntries = createSelector(
  [
    getCalendarEventsIds,
    getEvents,
    getFilterCurrentSportId,
    selectFilterInputSearch
  ],
  (eventsIds, events, currentSportId, searchText) => {
    if (eventsIds[currentSportId]) {
      return getEventsBySearch({
        events: getValues(eventsIds[currentSportId], events),
        searchText
      })
    }

    return []
  }
)

export const selectCalendarEventsIsEmpty = createSelector(
  [selectCalendarEventsDataEntries],
  (calendarEvents) => isEmpty(calendarEvents)
)
