import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToggleEventFavourite } from 'astra-core'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { IconLiveTracker } from 'shared/ui/Icon/General/IconLiveTracker'
import { IconH2H } from 'shared/ui/Icon/General/IconH2H'
import { getEventBetradarSourceId } from 'shared/lib/events'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { matchTrackerModel } from 'entities/match-tracker'
import { IconStar } from 'shared/ui/Icon/General/IconStar'

import { ICON_ACTION_PROPS } from './constants'
import {
  ActionItemProps,
  ActionLiveTrackerProps,
  ActionStatisticsProps
} from './Actions.types'
import {
  StyledActionFavourite,
  StyledActions,
  StyledActionsItem
} from './Actions.styled'

export const ActionsList = memo(() => {
  const { event } = useContext(EventContext) as IEventContext

  const { isEventFavourite, toggleEventFavourite } = useToggleEventFavourite(
    event.id
  )

  const user = useSelector(selectAccountUser)

  const handleClickFavorite = useCallback(() => {
    if (user) {
      toggleEventFavourite()
    }
  }, [toggleEventFavourite, user])

  return (
    <StyledActions isLive={event.live}>
      {event.live ? (
        <ActionLiveTracker event={event} />
      ) : (
        <ActionH2H event={event} />
      )}

      {/* <ActionItem Icon={IconStatistics} /> */}

      <StyledActionFavourite
        $isActive={isEventFavourite}
        onClick={handleClickFavorite}
      >
        <IconStar {...ICON_ACTION_PROPS} />
      </StyledActionFavourite>
    </StyledActions>
  )
})

const ActionItem: FC<ActionItemProps> = ({ Icon, ...props }) => (
  <StyledActionsItem {...props}>
    <Icon {...ICON_ACTION_PROPS} />
  </StyledActionsItem>
)

const ActionH2H: FC<ActionStatisticsProps> = ({ event }) => {
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useCallback(
    () => window.open(`/headToHead/${eventSourceId}`, 'head2head'),
    [eventSourceId]
  )

  if (!eventSourceId) {
    return null
  }

  return <ActionItem Icon={IconH2H} onClick={handleClick} />
}

const ActionLiveTracker: FC<ActionLiveTrackerProps> = ({ event }) => {
  const dispatch = useDispatch()
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useCallback(() => {
    if (eventSourceId) {
      dispatch(
        matchTrackerModel.liveMatchTrackerActions.setCurrentMatch({
          eventSourceId
        })
      )
    }
  }, [dispatch, eventSourceId])

  if (!eventSourceId) {
    return null
  }

  return <ActionItem Icon={IconLiveTracker} onClick={handleClick} />
}
