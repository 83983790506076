import styled from 'styled-components'

export const StyledStatsIconWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  height: 24px;
  justify-content: center;
  margin-bottom: 8px;
  width: 45px;
`
