import styled from 'styled-components'

import { STYLED_FIXED_HEADER_HEIGHT } from 'widgets/header/FixedHeader/FixedHeader.styled'

import { STYLED_MAIN_HEADER_HEIGHT } from './MainHeader/MainHeader.styled'

export const STYLED_HEADER_HEIGHT =
  STYLED_MAIN_HEADER_HEIGHT + STYLED_FIXED_HEADER_HEIGHT

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  grid-area: header;
  height: ${STYLED_HEADER_HEIGHT}px;
  z-index: 51;
`
