import React, { FC, ReactElement } from 'react'

import { StyledMatchStatisticsWrapper } from './mini-statistics.styled'

export const MiniStatistics: FC<{
  liveStatisticsComponents?: ReactElement | false
  lineStatisticsComponents?: ReactElement | false
}> = ({ liveStatisticsComponents, lineStatisticsComponents }) => {
  return (
    <StyledMatchStatisticsWrapper>
      {liveStatisticsComponents}
      {lineStatisticsComponents}
    </StyledMatchStatisticsWrapper>
  )
}
