import styled from 'styled-components'

import { StyledSuccessPageSubtitle } from '../../SuccessPage.styled'

export const StyledSuccessSignUpComponentsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const StyledSuccessSignUp = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 56px;
`

export const StyledSuccessSignUpSubtitle = styled(StyledSuccessPageSubtitle)`
  margin: 0;
  width: 80%;
`
