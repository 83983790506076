import React, { FC, memo } from 'react'

import { LayoutProps } from 'widgets/Layout/Layout.types'
import { Header } from 'widgets/header'
import { FooterOfPage } from 'widgets/footer-of-page'

import { StyledContentError, StyledLayoutError } from './Error404.styled'

export const LayoutError: FC<LayoutProps> = memo(({ children }) => {
  return (
    <StyledLayoutError>
      <Header />

      <StyledContentError>
        {children}
        <FooterOfPage />
      </StyledContentError>
    </StyledLayoutError>
  )
})
