import { formatAmount as formatAmountCore } from 'astra-core'

export const getFormattedBonusCouponAmount = (
  text: string,
  value: number,
  currency?: string
) =>
  `${text} ${formatAmountCore({
    value,
    currency
  })}`
