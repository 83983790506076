import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCalendar: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 2C12 1.44772 11.5523 1 11 1C10.4477 1 10 1.44772 10 2V3H6V2C6 1.44772 5.55228 1 5 1C4.44772 1 4 1.44772 4 2V3H3C1.89543 3 1 3.89543 1 5V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V5C15 3.89543 14.1046 3 13 3H12V2ZM4 5C3.44772 5 3 5.44772 3 6V7C3 7.55228 3.44772 8 4 8C4.55228 8 5 7.55228 5 7V6C5 5.44772 4.55228 5 4 5ZM4 9C3.44772 9 3 9.44772 3 10V11C3 11.5523 3.44772 12 4 12C4.55228 12 5 11.5523 5 11V10C5 9.44772 4.55228 9 4 9ZM7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM8 9C7.44772 9 7 9.44772 7 10V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V10C9 9.44772 8.55228 9 8 9ZM11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7V6Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
