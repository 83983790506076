import React from 'react'

import { IconInstagram } from 'shared/ui/Icon/General/IconInstagram'

import { openInstagramConstants } from '../lib'

export const OpenInstagram = () => (
  <a
    href={openInstagramConstants.LINK_INSTAGRAM}
    rel="noreferrer"
    target="_blank"
  >
    <IconInstagram size={32} />
  </a>
)
