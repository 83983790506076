import { EOnboardingContentPositions } from 'widgets/onboarding/Onboarding.types'
import { TOnboardingMaskProps } from 'widgets/onboarding/components/OnboardingMask/OnboardingMask.types'

export const getPositionData = ({
  position,
  itemNodeRectProps,
  onboardingContentBlockProps
}: {
  position: EOnboardingContentPositions
  itemNodeRectProps: TOnboardingMaskProps
  onboardingContentBlockProps: HTMLDivElement
}): { left: string; top: string } => {
  const data = {
    [EOnboardingContentPositions.BOTTOM_RIGHT]: {
      left: `${
        itemNodeRectProps.leftTopX +
        itemNodeRectProps.width -
        onboardingContentBlockProps.offsetWidth
      }px`,
      top: `${itemNodeRectProps.leftTopY + itemNodeRectProps.height + 8}px`
    },
    [EOnboardingContentPositions.BOTTOM_LEFT]: {
      left: `${itemNodeRectProps.leftTopX}px`,
      top: `${itemNodeRectProps.leftTopY + itemNodeRectProps.height + 8}px`
    },
    [EOnboardingContentPositions.TOP_LEFT]: {
      left: `${itemNodeRectProps.leftTopX}px`,
      top: `${
        itemNodeRectProps.leftTopY -
        onboardingContentBlockProps.offsetHeight -
        8
      }px`
    },
    [EOnboardingContentPositions.TOP_RIGHT]: {
      left: `${
        itemNodeRectProps.leftTopX +
        itemNodeRectProps.width -
        onboardingContentBlockProps.offsetWidth
      }px`,
      top: `${
        itemNodeRectProps.leftTopY -
        onboardingContentBlockProps.offsetHeight -
        8
      }px`
    }
  }

  return data[position]
}
