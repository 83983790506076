import { Tournament } from 'betweb-openapi-axios'

export interface ITableHeadProps {
  tournament?: Tournament
  isShowOdds?: boolean
  isShowCount?: number
  isOutcomesOpened?: boolean
  setIsOutcomesOpened?: (arg1: boolean) => void
  variant?: ETableHeadVariant
  isSearch?: boolean
}

export enum ETableHeadVariant {
  ONE = 1,
  TWO = 2
}
