import React, { Component, memo } from 'react'
import * as Sentry from '@sentry/react'

import { PageOfflineLazy } from 'pages/page-offline'
import { PageErrorUnknown } from 'pages/page-error-unknown'

import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types'

class ErrorBoundaryClass extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false, isOnline: true }

  componentDidMount() {
    window.addEventListener('online', this.handleOnline)
    window.addEventListener('offline', this.handleOffline)
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })

    // Send error and errorInfo to Sentry
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline)
    window.removeEventListener('offline', this.handleOffline)
  }

  handleOnline = () => {
    this.setState({ isOnline: true })
  }

  handleOffline = () => {
    this.setState({ isOnline: false })
  }

  render() {
    const { isOnline, hasError } = this.state

    if (hasError) {
      return <PageErrorUnknown />
    } else if (!isOnline) {
      return <PageOfflineLazy />
    } else {
      return this.props.children
    }
  }
}

export const ErrorBoundary = memo(ErrorBoundaryClass)
