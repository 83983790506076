import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSearch: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="4" stroke="#808080" strokeWidth="2" />
      <path
        d="M9.57129 9.57141L12.9999 13"
        stroke="#808080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </Icon>
)
