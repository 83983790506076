import React from 'react'
import { selectBonusOnExpress } from 'astra-core/containers/BasketProvider/selectors'
import { useSelector } from 'react-redux'
import { bonusOnExpressToPercent } from 'astra-core/utils/bets'

import { Bonus, Title, Wrapper } from './BonusOnExpress.styled'

const BonusOnExpress = () => {
  const bonus = useSelector(selectBonusOnExpress)
  const bonusAsPercent = bonusOnExpressToPercent(bonus)
  return (
    <Wrapper>
      <Title>Bonus</Title>
      <Bonus>
        {bonus} ({bonusAsPercent}%)
      </Bonus>
    </Wrapper>
  )
}

export default BonusOnExpress
