import React from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from '../../../../components'

export const OnboardingContentSiteSettings = () => {
  const [t] = useTranslation()

  return (
    <OnboardingContent title={t('site settings')}>
      {t('onboarding site settings')}
    </OnboardingContent>
  )
}
