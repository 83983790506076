import { ESelectView } from './types'
import {
  selectStylesDefault,
  selectStylesMini,
  selectStylesBasket,
  selectStylesResult,
  selectStylesFullRegistration
} from './styled'

export const SELECT_STYLES = {
  [ESelectView.Default]: selectStylesDefault,
  [ESelectView.Mini]: selectStylesMini,
  [ESelectView.Basket]: selectStylesBasket,
  [ESelectView.Results]: selectStylesResult,
  [ESelectView.Registration]: selectStylesFullRegistration
}
