import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { css, keyframes } from 'styled-components'

const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const SportsMenuSkeletonWrapper = styled.div`
  &:nth-child(2) {
    border-bottom: 1px solid ${(p) => p.theme.colors.custom.primary[4]};
  }
`
export const SportsMenuListItemTopSkeleton = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
`

export const SportsMenuListItemTopOneSkeleton = styled.div`
  background: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 184px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`

export const SportsMenuListItemSquareSkeleton = styled.div`
  background: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`

export const SportsMenuListItemBottomSkeleton = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;
  width: 100%;
`

export const SportsMenuListItemBottomOneSkeleton = styled.div`
  background: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 146px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`
