import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { StyledSportsMenuItemProps } from 'widgets/sports-menu'

export const StyledSportsMenuItem = styled(Link)<StyledSportsMenuItemProps>`
  ${(props) => css`
    align-items: center;
    border-radius: 4px;
    color: ${props.theme.colors.default.primary[70]};
    display: flex;
    font-size: ${props.theme.fonts.size.m};
    line-height: 20px;
    margin-bottom: 4px;
    padding: 4px 8px 4px 4px;
    text-decoration: none;

    &:hover {
      background-color: ${props.theme.colors.custom.primary[61]};
      color: ${props.theme.colors.default.primary[70]};
    }

    ${props.$active &&
    css`
      background-color: ${props.theme.colors.custom.primary[4]};
      color: ${props.theme.colors.default.primary[90]};
      font-weight: ${props.theme.fonts.weight.normal};

      &:hover {
        background-color: ${props.theme.colors.custom.primary[4]};
        color: ${props.theme.colors.default.primary[90]};
      }
    `}

    ${StyledIcon} {
      margin-right: 8px;
    }
  `}
`

export const StyledSportsMenuItemCount = styled.span<StyledSportsMenuItemProps>`
  ${(props) => css`
    font-size: ${(props) => props.theme.fonts.size.s};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    margin-left: 4px;
    ${props.$active
      ? css`
          color: ${props.theme.colors.fixed.primary[50]};
        `
      : css`
          color: ${props.theme.colors.custom.primary[9]};
        `}
  `}
`

export const StyledSportsMenuItemText = styled.span`
  flex: 1;
`

export const StyledSportsMenuList = styled.div`
  overflow-y: auto;
  overflow-y: overlay;
  padding: 0 13px 0 12px;
`

export const StyledSportMenuListSeparateTopLine = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[8]};
  height: 1px;
  margin: 4px 0;
  width: 100%;
`
