import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledTableRow = styled.div<{
  isSuspended?: boolean
}>`
  display: grid;
  grid-template-columns:
    1fr repeat(6, 40px) repeat(2, 80px) repeat(3, minmax(max-content, 45px))
    68px;
  position: relative;
`

export const CoefficientTableCellButtonExpandStyled = styled(Button)`
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;

  * {
    color: ${(props) => props.theme.colors.default.primary[50]} !important;
  }
`
