import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectTypeAuthModal } from 'astra-core/containers/AccountProvider'

import { ELoaderSkeletonBlockView } from './LoaderSkeleton.types'
import {
  StyledLoaderSkeleton,
  StyledLoaderSkeletonBlock,
  StyledLoaderSkeletonTitle
} from './LoaderSkeleton.styled'

export const LoaderSkeleton = () => {
  const [t] = useTranslation()
  const typeAuthModal = useSelector(selectTypeAuthModal)

  return (
    <StyledLoaderSkeleton>
      <StyledLoaderSkeletonTitle>
        {t(`${typeAuthModal}`)}
      </StyledLoaderSkeletonTitle>

      <StyledLoaderSkeletonBlock
        marginBottom={4}
        view={ELoaderSkeletonBlockView.Text}
        width={150}
      />

      <StyledLoaderSkeletonBlock
        marginBottom={10}
        view={ELoaderSkeletonBlockView.Input}
      />

      <StyledLoaderSkeletonBlock
        marginBottom={4}
        view={ELoaderSkeletonBlockView.Text}
      />

      <StyledLoaderSkeletonBlock
        marginBottom={10}
        view={ELoaderSkeletonBlockView.Input}
      />

      <StyledLoaderSkeletonBlock
        marginBottom={8}
        view={ELoaderSkeletonBlockView.Text}
        width={95}
      />

      <StyledLoaderSkeletonBlock
        marginBottom={20}
        view={ELoaderSkeletonBlockView.Text}
        width={125}
      />

      <StyledLoaderSkeletonBlock view={ELoaderSkeletonBlockView.Input} />
    </StyledLoaderSkeleton>
  )
}
