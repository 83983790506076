import { Event, EventProbability, Tournament } from 'betweb-openapi-axios'

export interface EventOddProps {
  eventProbability?: EventProbability & { eventId: Event['id'] }
  withParam?: boolean
}

export enum OddModes {
  Increase,
  Decrease,
  Default,
  Active,
  Disabled
}

export type UseOddHighlightType = {
  mode: OddModes
  prevOdd?: number
}

export interface ILineUseLocationState {
  maintainLineFilter?: boolean
}

export interface TournamentIdContextParams {
  tournamentId: Tournament['id']
}
