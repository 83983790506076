import * as Sentry from '@sentry/react'
import { ENV, getEnv } from 'astra-core'

export const initSentryClient = () => {
  if (process.env.NODE_ENV === 'production' && getEnv() === ENV.PROD) {
    Sentry.init({
      dsn: window.__PRELOADED_STATE__?.configProvider?.config?.SENTRY_DSN,
      environment: ENV.PROD,
      beforeSend(event) {
        event.tags = event.tags || {}
        event.tags.source = 'client' // Tagging the error as originating from the server
        return event
      }
    })
  }
}
