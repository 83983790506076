import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconResults: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 1C5 0.447716 5.44772 0 6 0H8C8.55229 0 9 0.447715 9 1V13C9 13.5523 8.55229 14 8 14H6C5.44772 14 5 13.5523 5 13V1Z"
        fill="currentColor"
      />
      <path
        d="M0 3C0 2.44772 0.447715 2 1 2H3C3.55228 2 4 2.44772 4 3V13C4 13.5523 3.55228 14 3 14H1C0.447715 14 0 13.5523 0 13V3Z"
        fill="currentColor"
      />
      <path
        d="M11 2C10.4477 2 10 2.44772 10 3V13C10 13.5523 10.4477 14 11 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H11Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
