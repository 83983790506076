import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getIsHiddenWelcomeScreen = (state: RootState) =>
  state.layoutContainer.isHiddenWelcomeScreen ??
  initialState.isHiddenWelcomeScreen

export const getIsDisplayNotificationPanel = (state: RootState) =>
  state.layoutContainer.isDisplayNotificationPanel ??
  initialState.isDisplayNotificationPanel

export const getIsDisplayModalSettings = (state: RootState) =>
  state.layoutContainer.isDisplayModalSettings ??
  initialState.isDisplayModalSettings

export const getIsDisplayModalChangeLanguage = (state: RootState) =>
  state.layoutContainer.isDisplayModalChangeLanguage ??
  initialState.isDisplayModalChangeLanguage

export const getOnboardingIsDisplay = (state: RootState) =>
  state.layoutContainer.onboarding.isDisplay ??
  initialState.onboarding.isDisplay

export const getOnboardingType = (state: RootState) =>
  state.layoutContainer.onboarding.type || initialState.onboarding.type

export const getIsAuthShowModal = (state: RootState) =>
  state.layoutContainer.isAuthShowModal ?? initialState.isAuthShowModal

export const selectIsHiddenWelcomeScreen = createSelector(
  [getIsHiddenWelcomeScreen],
  (isHiddenWelcomeScreen) => isHiddenWelcomeScreen
)

export const selectIsDisplayModalSettings = createSelector(
  [getIsDisplayModalSettings],
  (isDisplayModalSettings) => isDisplayModalSettings
)

export const selectIsDisplayModalChangeLanguage = createSelector(
  [getIsDisplayModalChangeLanguage],
  (isDisplayModalChangeLanguage) => isDisplayModalChangeLanguage
)

export const selectIsAuthShowModal = createSelector(
  [getIsAuthShowModal],
  (isAuthShowModal) => isAuthShowModal
)
