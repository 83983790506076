import React, { FC, useContext } from 'react'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { useSelector } from 'react-redux'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'

import { ETestData } from 'shared/lib/testData'
import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'
import { BasketQuickBetButton } from 'widgets/right-container/Basket/BasketQuickBetButton/BasketQuickBetButton'

import { TitleLinePageProps } from './TitleLinePage.types'
import {
  StyledHeaderWrapper,
  StyledTitle,
  StyledTitleTextWrapper,
  StyledTitleWrapper
} from './TitleLinePage.styled'

// TODO: Transfer to widget or entities
export const TitleLinePage: FC<TitleLinePageProps> = ({
  iconComponent,
  title,
  children,
  testData
}) => {
  const { isQuickBetActive } = useContext(BasketQuickBetContext)
  const account = useSelector(selectAccount)

  const isQuickBetButtonVisible = isQuickBetActive && account

  return (
    <StyledHeaderWrapper data-test-id={ETestData.TestHeaderWrapper}>
      <StyledTitleWrapper>
        <StyledTitleTextWrapper>
          {iconComponent}

          <StyledTitle data-test-id={testData}>{title}</StyledTitle>
        </StyledTitleTextWrapper>

        {isQuickBetButtonVisible ? (
          <BasketQuickBetButton />
        ) : (
          <BasketButtonSmallWindow />
        )}
      </StyledTitleWrapper>

      {children}
    </StyledHeaderWrapper>
  )
}
