import React, { FC, useCallback, useState } from 'react'

import { BasketQuickBetsAmountProps } from './BasketQuickBetsAmount.types'
import {
  BasketQuickBetsAmountItemWrapper,
  BasketQuickBetsAmountWrapper,
  StyledBasketQuickBetsAmountItem
} from './BasketQuickBetsAmount.styled'

export const BasketQuickBetsAmount: FC<BasketQuickBetsAmountProps> = (
  props
) => {
  // TODO need to get from astra-core
  const amounts = ['100', '200', '500', '1000', '2000']

  const { onChange } = props
  const [activeAmount, setActiveAmount] = useState<string>()

  const handleClickAmount = useCallback(
    (item: string) => {
      setActiveAmount(item)
      onChange(item)
    },
    [onChange]
  )

  return (
    <BasketQuickBetsAmountWrapper>
      {amounts.map((item) => {
        return (
          <BasketQuickBetsAmountItemWrapper
            active={activeAmount === item}
            key={item}
            onClick={() => handleClickAmount(item)}
          >
            <StyledBasketQuickBetsAmountItem active={activeAmount === item}>
              {item}
            </StyledBasketQuickBetsAmountItem>
          </BasketQuickBetsAmountItemWrapper>
        )
      })}
    </BasketQuickBetsAmountWrapper>
  )
}
