import React, { FC } from 'react'
import range from 'lodash/range'

import { useLoaderPointsColors } from './hooks'
import { StyledLoaderPoints, StyledLoaderPointsItem } from './styled'
import { ILoaderPointsProps } from './types'

const DEFAULT_SETTINGS_ANIMATION: Required<
  ILoaderPointsProps['settingsAnimation']
> = {
  timeStep: 0.2,
  timeAfterEnd: 1.1,
  scaleActivePoint: 1.3
}

export const LoaderPoints: FC<ILoaderPointsProps> = ({
  size = 6,
  margin = 4,
  count = 5,
  colors,
  settingsAnimation
}) => {
  const selectedColors = useLoaderPointsColors(colors)
  const defaultSettingsAnimation = {
    ...DEFAULT_SETTINGS_ANIMATION,
    ...settingsAnimation
  }

  let delay = 0

  return (
    <StyledLoaderPoints count={count} margin={margin} size={size}>
      {range(count).map((num) => {
        delay += defaultSettingsAnimation.timeStep

        return (
          <StyledLoaderPointsItem
            colors={selectedColors}
            count={count}
            delayPoint={delay}
            key={num}
            margin={margin}
            orderNum={num}
            settingsAnimation={defaultSettingsAnimation}
            size={size}
          />
        )
      })}
    </StyledLoaderPoints>
  )
}
