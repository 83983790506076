import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledQuickBetSubmitButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledQuickBetSubmitButtonText = styled.div`
  text-align: center;
  width: 100%;
`
