/**
 *
 * LiveMatchTrackerContainer
 *
 */
import { useInjectReducer } from 'shared/lib/@reduxjs'

import { reducer, sliceKey } from './slice'

export const useLiveMatchTracker = () => {
  useInjectReducer({ key: sliceKey, reducer })
}
