import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'astra-core'

import { getLastYearEvent } from 'shared/lib/events'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import { getEventStatisticsByCode } from 'shared/lib/statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { IconLivePointWhite } from 'shared/ui/Icon/General/IconLivePointWhite'

import {
  StyledAdditionalTime,
  StyledEventDateTime,
  StyledEventDateTimeAdditional,
  StyledEventLivePointer
} from './EventDateTime.styled'

export const EventDateTime: FC = () => {
  const { t } = useTranslation()
  const { event } = useContext(EventContext) as IEventContext

  const lastYear = getLastYearEvent(event.eventDate)

  const currentTime = getEventStatisticsByCode(
    event,
    EEventStatisticsCodes.CurrentTime
  )
  const remainingTime = getEventStatisticsByCode(
    event,
    EEventStatisticsCodes.RemainingTime
  )
  const additionalTime = getEventStatisticsByCode(
    event,
    EEventStatisticsCodes.AddTime
  )

  const hasTime =
    event.live &&
    (!!currentTime?.value || !!remainingTime?.value || !!additionalTime?.value)

  return (
    <StyledEventDateTime isLive={event.live}>
      {hasTime ? (
        <>
          {event.live && (
            <StyledEventLivePointer>
              <IconLivePointWhite />
            </StyledEventLivePointer>
          )}
          {currentTime?.value && <Timer actualTime={currentTime.value} />}
          {remainingTime?.value && <Timer actualTime={remainingTime.value} />}
          {additionalTime?.value && !!Number(additionalTime.value) && (
            <StyledAdditionalTime>
              + {additionalTime.value} {t('min')}
            </StyledAdditionalTime>
          )}
        </>
      ) : (
        <>
          {lastYear && (
            <StyledEventDateTimeAdditional>
              {lastYear}
            </StyledEventDateTimeAdditional>
          )}
          {formatDate(event.eventDate)}
        </>
      )}
    </StyledEventDateTime>
  )
}

const Timer: FC<{
  actualTime: string
}> = ({ actualTime }) => {
  return <>{actualTime}</>
}
