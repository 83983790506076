import styled from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { columnGapPrefixes } from 'shared/lib/styled'
import { CssButtonReset } from 'constants/styles'

export const StyledButtonFavourite = styled.button`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[30]};
  ${columnGapPrefixes('3px')}
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.light};
  ${CssButtonReset}

  &:hover {
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.default.yellow[30]} !important;
    }
  }
`
