import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCustomInfiniteScroll } from 'hooks/useCustomInfiniteScroll'

import {
  UsePaginationInfiniteScrollProps,
  UsePaginationProps,
  UsePaginationWithReduxInfiniteScrollProps
} from './usePagination.types'

const usePagination = ({ onSetPagination, pagination }: UsePaginationProps) => {
  return useCallback(() => {
    onSetPagination({
      limit: pagination.limit,
      offset: pagination.offset + pagination.limit
    })
  }, [onSetPagination, pagination])
}

export const usePaginationInfiniteScroll = ({
  isLoading,
  hasMore,
  ...paginationData
}: UsePaginationInfiniteScrollProps) => {
  const onLoadMore = usePagination(paginationData)

  return useCustomInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore
  })
}

export const usePaginationWithReduxInfiniteScroll = (
  {
    selectorPagination,
    setPaginationAction,
    selectorRequestStatus,
    selectorHasMore
  }: UsePaginationWithReduxInfiniteScrollProps,
  isHidePreloader?: boolean
) => {
  const dispatch = useDispatch()
  const pagination = useSelector(selectorPagination)
  const hasMore = useSelector(selectorHasMore)
  const { loading: isLoading } = useSelector(selectorRequestStatus)

  const onLoadMore = usePagination({
    pagination,
    onSetPagination: (newPagination) =>
      dispatch((setPaginationAction as any)(newPagination))
  })

  const infiniteScrollData = useCustomInfiniteScroll({
    loading: !!isLoading,
    hasNextPage: hasMore,
    onLoadMore
  })

  const isInitLoading = isLoading && !pagination.offset && !isHidePreloader

  return {
    ...infiniteScrollData,
    isInitLoading,
    isPaginationLoading: hasMore
  }
}
