import React, { FC, memo } from 'react'

import { Auth } from 'widgets/Auth/Auth'
import { Header } from 'widgets/header'

import { LayoutProps } from '../../Layout.types'

import { StyledContent, StyledLayout } from './Default.styled'

export const LayoutDefault: FC<LayoutProps> = memo(({ children, ...props }) => (
  <StyledLayout {...props}>
    <Auth />

    <Header />

    <StyledContent>{children}</StyledContent>
  </StyledLayout>
))
