import styled, { css } from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'

import {
  ELoaderPointsMiniTypesSizes,
  StyledLoaderPointsMiniProps
} from './types'

export const StyledLoaderPointsMini = styled.div<StyledLoaderPointsMiniProps>`
  ${(props) => css`
    align-items: center;
    background-color: ${props.theme.colors.custom.primary[4]};
    border-radius: 24px;
    display: flex;
    height: fit-content;
    line-height: 16px;
    width: fit-content;
    ${columnGapPrefixes('8px')};

    ${props.type === ELoaderPointsMiniTypesSizes.S &&
    (props.text
      ? css`
          padding: 0 8px;
        `
      : css`
          padding: 7px 8px;
        `)}

    ${props.type === ELoaderPointsMiniTypesSizes.M &&
    (props.text
      ? css`
          padding: 4px 8px;
        `
      : css`
          padding: 11px 8px;
        `)}
  `}
`

export const StyledLoaderPointsMiniText = styled.span`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.regular};
  `}
`
