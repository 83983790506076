import React, { FC } from 'react'

import { EventMatchPhase } from '../EventStateStatus/EventMatchPhase'

import {
  EventStatisticScoreDefault,
  EventStatisticScoreGame,
  EventStatisticScoresByPeriod,
  EventStatisticScoreInfo
} from './components'
import {
  EEventStatisticScoreTypes,
  TEventStatisticScoreProps
} from './EventStatisticScore.types'

export const EventStatisticScore: FC<TEventStatisticScoreProps> = (props) => {
  switch (props.type) {
    case EEventStatisticScoreTypes.DEFAULT:
      return <EventStatisticScoreDefault {...props} />
    case EEventStatisticScoreTypes.BY_PERIOD:
      return <EventStatisticScoresByPeriod {...props} />
    case EEventStatisticScoreTypes.GAME:
      return <EventStatisticScoreGame {...props} />
    case EEventStatisticScoreTypes.INFO:
      return <EventStatisticScoreInfo {...props} />
    case EEventStatisticScoreTypes.MATCH_PHASE:
      return <EventMatchPhase {...props} />

    default:
      return null
  }
}
