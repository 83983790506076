import React, { FC, useMemo } from 'react'
import { range } from 'lodash'

import { useIsMedium, useIsSmall } from 'shared/lib/styled'
import { Tooltip } from 'shared/ui/Tooltip'

import { StyledScore } from '../../EventStatisticsScore.styled'

import { WRAP_SYMBOLS } from './constants'
import { IEventStatisticScoresByPeriodProps } from './EventStatisticScoresByPeriod.types'
import {
  StyledScoreByPeriods,
  StyledScoreByPeriodsDot,
  StyledScoreByPeriodsDotsIcon,
  StyledScoreWrapper
} from './EventStatisticScoresByPeriod.styled'

export const EventStatisticScoresByPeriod: FC<
  IEventStatisticScoresByPeriodProps
> = ({ asDots, value, isAdaptive, separator, wrapSymbol, ...props }) => {
  const selectedWrapSymbol = wrapSymbol ?? WRAP_SYMBOLS
  const valueString = value.join(separator ?? ', ')
  const valueStringWithWrapSymbols = `${selectedWrapSymbol.start}${valueString}${selectedWrapSymbol.end}`

  const isSmall = useIsSmall()
  const isLarge = useIsMedium()

  const formattedStringValue = useMemo(() => {
    if ((isSmall || isLarge) && value.length > 1 && isAdaptive) {
      return `..., ${value[value.length - 1]}`
    }

    return valueString ? valueStringWithWrapSymbols : ''
  }, [
    isAdaptive,
    isLarge,
    isSmall,
    value,
    valueString,
    valueStringWithWrapSymbols
  ])

  return asDots ? (
    <Tooltip title={valueStringWithWrapSymbols}>
      <StyledScoreByPeriodsDotsIcon>
        {range(0, 3).map((key) => (
          <StyledScoreByPeriodsDot key={key} />
        ))}
      </StyledScoreByPeriodsDotsIcon>
    </Tooltip>
  ) : (
    <Tooltip title={valueStringWithWrapSymbols}>
      <StyledScoreWrapper {...props}>
        <StyledScoreByPeriods>
          <StyledScore isAdaptive={isAdaptive}>
            {formattedStringValue}
          </StyledScore>
        </StyledScoreByPeriods>
      </StyledScoreWrapper>
    </Tooltip>
  )
}
