import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { languagesList } from 'astra-core/constants'

const options = {
  fallbackLng: languagesList[0],
  supportedLngs: languagesList,
  load: 'languageOnly',
  ns: ['translations'],
  defaultNS: 'translations',
  saveMissing: true,
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase()
      return value
    }
  },
  detection: {
    caches: ['cookie', 'localStorage']
  },
  useSuspense: process && !process.release
}

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next).use(LanguageDetector)
}

// initialize if not already initialized

if (!i18n.isInitialized) {
  console.log('------ i18n RE-INIT')
  i18n.init(options as any)
}

// eslint-disable-next-line import/no-default-export
export default i18n
