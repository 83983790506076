import React, { FC, memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useToggleEventFavourite } from 'astra-core/hooks'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { IconStarWithoutBackground } from 'shared/ui/Icon/General/IconStarWithoutBackground'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { EventFavouritesStarButtonProps } from './event-favourites-star-button.types'

export const EventFavouritesStarButton: FC<EventFavouritesStarButtonProps> =
  memo(({ eventId, withStopPropagation, ...props }) => {
    const { isEventFavourite, toggleEventFavourite } =
      useToggleEventFavourite(eventId)
    const user = useSelector(selectAccountUser)

    const handleClick = useCallback(
      (e) => {
        if (withStopPropagation) {
          e.stopPropagation()
        }

        if (user) {
          // TODO: if favourite functional is haven't for unauthorized users than move this condition useToggleEventFavourite in astra-core
          toggleEventFavourite()
        }
      },
      [toggleEventFavourite, user, withStopPropagation]
    )

    return (
      <IconStarWithoutBackground
        colorProps={{
          name: EColorsNames.Primary,
          type: EColorsTypes.FIXED,
          value: 50
        }}
        {...props}
        isActive={isEventFavourite}
        onClick={handleClick}
      />
    )
  })
