import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconStream: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M16 4C16 2.89543 15.1046 2 14 2H2C0.895431 2 0 2.89543 0 4V12C0 13.1046 0.89543 14 2 14L14 14C15.1046 14 16 13.1046 16 12V4ZM11.75 8L5.74999 5V11L11.75 8Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
