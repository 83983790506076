import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledQuickBetErrorWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 4px 8px 4px 5px;
  ${({ theme }) => css`
    background-color: ${theme.colors.custom.yellow[5]};
  `}
`

export const StyledQuickBetErrorText = styled.div`
  margin-left: 5px;
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[70]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`

export const StyledIconWrapper = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[80]};
    font-weight: ${theme.fonts.weight.normal};
  `}
`
