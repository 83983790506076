import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledCurrentCashoutWrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  bottom: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  left: 0px;
  padding: 8px;
  position: absolute;
  right: 0px;
  z-index: 1001;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[10]};
      border-color: ${theme.colors.custom.primary[8]};
      box-shadow: 0px 2px 4px 0px ${theme.colors.default.shadow[0]};
      box-shadow: 0 0 0 ${({ theme }) => theme.colors.default.shadow[0]};
    `}
`

export const StyledCurrentCashoutTextLoading = styled.div`
  line-height: 16px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[17]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledCurrentCashoutText = styled.div`
  line-height: 16px;
  margin-right: 12px;
  text-align: center;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.bold};
    `}
`

export const StyledCurrentCashoutButton = styled(Button)<{
  isConfirm?: boolean
}>`
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  line-height: 16px;
  padding: 5px;
  width: 100%;

  ${({ theme, isConfirm }) =>
    css`
      background-color: ${isConfirm
        ? theme.colors.default.primary[90]
        : theme.colors.custom.primary[4]};
      color: ${isConfirm
        ? theme.colors.custom.primary[20]
        : theme.colors.custom.primary[7]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledCurrentCashoutButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const StyledMakeCashoutLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[4]};
      border-color: ${theme.colors.custom.primary[4]};
      border-radius: 24px;
      box-shadow: 0 0 0 ${({ theme }) => theme.colors.default.shadow[0]};
      gap: 8px;
      height: 24px;
    `}
`
