import { EventStatisticPropsFromType } from '../../EventStatistics.types'

import { IEventStatisticTimeRemainingProps } from './components/EventStatisticTimeRemaining/EventStatisticTimeRemaining.types'
import { IEventStatisticTimeAddedProps } from './components/EventStatisticTimeAdded/EventStatisticTimeAdded.types'
import { IEventStatisticTimeCurrentProps } from './components/EventStatisticTimeCurrent/EventStatisticTimeCurrent.types'

export enum EEventStatisticTimeTypes {
  CURRENT = 'current',
  ADDED = 'added',
  REMAINING = 'remaining'
}

export type TEventStatisticTimeProps =
  | EventStatisticPropsFromType<
      EEventStatisticTimeTypes.CURRENT,
      IEventStatisticTimeCurrentProps
    >
  | EventStatisticPropsFromType<
      EEventStatisticTimeTypes.ADDED,
      IEventStatisticTimeAddedProps
    >
  | EventStatisticPropsFromType<
      EEventStatisticTimeTypes.REMAINING,
      IEventStatisticTimeRemainingProps
    >

export interface StyledTimeProps {
  isPlain?: boolean
}
