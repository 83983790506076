import React from 'react'
import { components } from 'react-select'

import {
  StyledCustomOptionAdditionalLabel,
  StyledCustomOptionBorderDash,
  StyledCustomOptionBorderWrapper
} from './CustomOption.styled'

export const CustomOption = (props) => {
  const {
    data: { styles, additionalLabel },
    children
  } = props

  return (
    <>
      <components.Option {...props}>
        {children}
        <StyledCustomOptionAdditionalLabel>
          {additionalLabel}
        </StyledCustomOptionAdditionalLabel>
      </components.Option>
      {styles?.isBottomBorder && (
        <>
          <StyledCustomOptionBorderWrapper>
            <StyledCustomOptionBorderDash />
          </StyledCustomOptionBorderWrapper>
        </>
      )}
    </>
  )
}
