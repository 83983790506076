import { Event } from 'betweb-openapi-axios'

import { EEventStatisticsCodes } from 'widgets/event-statistics'

export const useGetIsActivePlayer = ({
  eventStatistics
}: {
  eventStatistics: Event['statistics']
}) => {
  const currentServer = eventStatistics.find(
    (stat) => stat.code === EEventStatisticsCodes.CurrentServer
  )

  return {
    isActiveHomeTeam: currentServer?.value === '1',
    isActiveAwayTeam: currentServer?.value === '2'
  }
}
