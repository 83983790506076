import styled, { css } from 'styled-components'

import { ITagProps, ETagTypes } from './types'

export const StyledTag = styled.span<ITagProps>`
  ${(props) => css`
    display: inline-flex;
    height: 14px;
    width: 24px;

    ${props.type === ETagTypes.TOP &&
    css`
      background-color: ${props.theme.colors.default.blue[70]};
      border-radius: 4px;
      color: ${props.theme.colors.fixed.primary[0]};
      font-size: ${props.theme.fonts.size.xxxxs};
      font-weight: ${props.theme.fonts.weight.bold};
      letter-spacing: 0.6px;
      line-height: 15px;
      padding: 0 3px;
      text-transform: uppercase;
    `}

    ${props.type === ETagTypes.GOAL &&
    css`
      color: ${props.theme.colors.default.green[50]};
      font-size: ${props.theme.fonts.size.xxs};
      font-weight: ${props.theme.fonts.weight.normal};
      line-height: 16px;
      padding: 0 3px;
      text-transform: uppercase;
    `}
    
    ${props.type === ETagTypes.VAR &&
    css`
      color: ${props.theme.colors.default.red[50]};
      font-size: ${props.theme.fonts.size.xs};
      font-weight: ${props.theme.fonts.weight.bold};
      line-height: 16px;
      padding: 0 3px;
      text-transform: uppercase;
    `}
      
    ${props.type === ETagTypes.BONUS &&
    css`
      background-color: ${props.theme.colors.default.yellow[30]};
      color: ${props.theme.colors.default.primary[90]};
      font-size: 9px;
      line-height: 16px;
      padding: 0 3px;
      text-transform: uppercase;
    `}
  `}
`
