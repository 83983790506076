import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getBetsHistoryBasketPagination = (state: RootState) =>
  state.betsHistoryBasketContainer.pagination || initialState.pagination

export const getBetsHistoryBasketHasMore = (state: RootState) =>
  state.betsHistoryBasketContainer.bets.hasMore || initialState.bets.hasMore

export const getBetsHistoryBasketFetchData = (state: RootState) =>
  state.betsHistoryBasketContainer.bets.fetchItems ||
  initialState.bets.fetchItems

export const getBetsHistoryBasketSortedIds = (state: RootState) =>
  state.betsHistoryBasketContainer.bets.sortedIds ||
  initialState.bets.sortedIds ||
  []

export const selectBetsHistoryBasketsPagination = createSelector(
  getBetsHistoryBasketPagination,
  (pagination) => pagination
)

export const selectBetsHistoryBasketHasMore = createSelector(
  getBetsHistoryBasketHasMore,
  (hasMore) => hasMore
)

export const selectBetsHistoryBasketFetchData = createSelector(
  [getBetsHistoryBasketFetchData],
  (fetchData) => fetchData
)
