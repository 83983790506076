import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconH2H: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6.7749 8.82267C6.45148 9.53933 5.73818 10 4.95193 10H1.5C0.947714 10 0.5 9.55228 0.5 9V1C0.5 0.447716 0.947715 0 1.5 0H4.95193C5.73818 0 6.45149 0.460675 6.7749 1.17733L8.5 5L6.7749 8.82267ZM10.2251 3.17733C10.5485 2.46067 11.2618 2 12.0481 2H15.5C16.0523 2 16.5 2.44772 16.5 3V11C16.5 11.5523 16.0523 12 15.5 12H12.0481C11.2618 12 10.5485 11.5393 10.2251 10.8227L8.5 7L10.2251 3.17733ZM3.14597 2.33888C3.51109 2.14328 3.96565 2.2807 4.16125 2.64582L5.16125 4.51249C5.27308 4.72124 5.27975 4.97055 5.17923 5.18498L4.17923 7.31831C4.00343 7.69337 3.55687 7.85489 3.18181 7.67908C2.80676 7.50328 2.64524 7.05672 2.82105 6.68166L3.66132 4.88909L2.83903 3.35415C2.64343 2.98903 2.78085 2.53448 3.14597 2.33888ZM13.8541 9.66107C13.489 9.85667 13.0345 9.71925 12.8389 9.35413L11.8389 7.48746C11.727 7.27871 11.7204 7.0294 11.8209 6.81497L12.8209 4.68164C12.9967 4.30658 13.4432 4.14506 13.8183 4.32087C14.1933 4.49667 14.3549 4.94323 14.1791 5.31829L13.3388 7.11086L14.1611 8.6458C14.3567 9.01092 14.2192 9.46547 13.8541 9.66107Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
