import { Event } from 'betweb-openapi-axios'

export interface EventStatisticsProps {
  event: Event
  showLiveIcon?: boolean
  adaptiveScoreByPeriods?: boolean
}

export enum EEventStatisticsCodes {
  Score = 'score',
  ScoresByPeriods = 'scores_by_periods',
  CurrentTime = 'current_time',
  GameScore = 'game_score',
  RemainingTime = 'remaining_time',
  AddTime = 'add_minutes',
  ScoresInfo = 'scores_info',
  MatchPhase = 'match_phase',
  CurrentServer = 'current_server'
}

export type EventStatisticPropsFromType<EType, ExtendedInterface> = {
  type: EType
} & ExtendedInterface

export type TGetRenderedStatisticsStatistics = Array<{
  value: string
  code: EEventStatisticsCodes
}>
