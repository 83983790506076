import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { matchTrackerModel } from 'entities/match-tracker'

export const useHandleClickLiveTracker = ({
  eventSourceId
}: Partial<
  Pick<
    Parameters<
      typeof matchTrackerModel.liveMatchTrackerActions.setCurrentMatch
    >[0],
    'eventSourceId'
  >
>) => {
  const dispatch = useDispatch()
  const { liveMatchTrackerActions } = matchTrackerModel

  return useCallback(() => {
    if (eventSourceId) {
      dispatch(
        liveMatchTrackerActions.setCurrentMatch({
          eventSourceId
        })
      )
    }
  }, [dispatch, eventSourceId, liveMatchTrackerActions])
}
