import styled from 'styled-components'

import { StyledContent, StyledLayout } from '../Sport/Sport.styled'

export const StyledLayoutError = styled(StyledLayout)`
  grid-template-areas:
    'header'
    'content'
    'footer';
  grid-template-columns: 1fr;
`

export const StyledContentError = styled(StyledContent)`
  width: 101%;
`
