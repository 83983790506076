import { useDispatch, useSelector } from 'react-redux'
import { Event, EventProbability } from 'betweb-openapi-axios'
import { selectAccountId } from 'astra-core/containers/AccountProvider/selectors'
import { useHandleAddToBasket } from 'astra-core/containers/BasketProvider/add-to-basket'
import { useCallback } from 'react'
import { selectBasketOutcomes } from 'astra-core/containers/BasketProvider'
import { useHandleMakeQuickBet } from 'og-web-core/providers/basket/quick-bet/hooks/useHandleMakeQuickBet'
import { getLocalStorageItem } from 'og-web-core/utils/storage'
import { StorageKeys } from 'og-web-core/utils/storage/constants'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { selectIsShowBasketOnSmallWindow } from 'containers/BasketContainer/selectors'
import { useNotifyMakeQuickBet } from 'hooks/useNotifyMakeQuickBet'

import { useHandleMakeQuickBet as useHandleMakeQuickBetDeprecated } from './make-quick-bet'

export interface IAddToBasketProps {
  eventStatus: Event['status']
  eventId: Event['id']
  eventProbability: EventProbability | undefined
  live: Event['live']
}

// TODO move to astra core
export const useHandleAddCoefficientToBasket = () => {
  const dispatch = useDispatch()
  const accountId = useSelector(selectAccountId)
  const isShowBasket = useSelector(selectIsShowBasketOnSmallWindow)
  const handleAddToBasket = useHandleAddToBasket()
  const notifyCallback = useNotifyMakeQuickBet()
  const quickBetsImprovementsEnabled = useFeatureFlag(
    EFeatureFlags.BASKET_QUICK_BETS_IMPROVEMENTS_ENABLED
  )
  const { handleMakeQuickBet } = useHandleMakeQuickBet(notifyCallback)
  const { handleMakeQuickBet: deprecatedHandleMakeQuickBet } =
    useHandleMakeQuickBetDeprecated()

  const outcomes = useSelector(selectBasketOutcomes)

  const isQuickBetActive = getLocalStorageItem<boolean>(
    StorageKeys.isQuickBetActive,
    false
  )

  const callback = useCallback(() => {
    if (!accountId) {
      dispatch(
        rightBasketContainerState.toggleBasketOnSmallWindow({
          isShow: !isShowBasket
        })
      )
    }
  }, [accountId, dispatch, isShowBasket])

  return useCallback(
    ({ eventId, eventStatus, eventProbability, live }: IAddToBasketProps) => {
      const shouldMakeQuickBet =
        eventProbability && isQuickBetActive && !outcomes.length

      if (shouldMakeQuickBet) {
        const bet = {
          coefficient: eventProbability.odd,
          outcomeCompositeId: {
            eventId,
            live,
            outcomeTypeId: eventProbability.outcomeTypeId,
            parameters: eventProbability.parameters
          }
        }

        if (!quickBetsImprovementsEnabled) {
          deprecatedHandleMakeQuickBet(bet)
          return
        }

        handleMakeQuickBet(bet)
        return
      }

      handleAddToBasket({
        eventId,
        eventStatus,
        eventProbability,
        callback
      })
    },
    [
      callback,
      deprecatedHandleMakeQuickBet,
      handleAddToBasket,
      handleMakeQuickBet,
      isQuickBetActive,
      outcomes.length,
      quickBetsImprovementsEnabled
    ]
  )
}
