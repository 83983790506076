import styled from 'styled-components'

export const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: auto 1fr;
  height: 100%;
`

export const StyledTabsWrapper = styled.div`
  padding: 0 8px;

  & > div {
    & > div {
      margin: 0;
    }
  }
`

export const StyledSportsMenu = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  border-right: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  grid-area: sports-menu;
  overflow: hidden;
  padding-top: 8px;
`
