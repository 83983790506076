import styled, { css, keyframes } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { IconSvgFromServer } from 'shared/ui/Icon/IconSvgFromServer'

export const IconLogoStyled = styled(IconSvgFromServer)`
  max-width: 240px;
  overflow: hidden;
`
const SkeletonShine = keyframes`
  0%, 25% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const StyledLogoSkeleton = styled.div`
  background: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 86px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 60%;
  }
`
