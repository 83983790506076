import { useCallback, useState } from 'react'

export const useExpandEventRow = ({
  isSuspended
}: {
  isSuspended: boolean
}) => {
  const [isOutcomesOpened, setIsOutcomesOpened] = useState(false)

  const toggleOutcomesOpen = useCallback(() => {
    if (!isSuspended || (isSuspended && isOutcomesOpened)) {
      setIsOutcomesOpened((isOutcomesOpened) => !isOutcomesOpened)
    }
  }, [isOutcomesOpened, isSuspended, setIsOutcomesOpened])

  return { toggleOutcomesOpen, isOutcomesOpened, setIsOutcomesOpened }
}
