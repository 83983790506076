import styled from 'styled-components'

export const StyledMatchPhaseWrapper = styled.div`
  display: flex;
`
export const StyledMatchPhaseName = styled.div`
  color: ${(props) => props.theme.colors.custom.blue[7]};
  display: flex;
  white-space: nowrap;
`
