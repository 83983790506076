import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import { ContainerState } from './types'

const REDUCER_KEY = 'liveMatchBroadcastContainer'

export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  currentBroadcast: null
})

const liveMatchBroadcastSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setCurrentBroadcast(state, action: PayloadAction<{ eventId: number }>) {
      state.currentBroadcast = action.payload.eventId
    },
    clearCurrentBroadcast(state) {
      state.currentBroadcast = null
    }
  }
})

export const { actions, reducer, name: sliceKey } = liveMatchBroadcastSlice
export const liveMatchBroadcastActions = actions
