import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { messagesProviderActions } from 'astra-core/containers/MessagesProvider/slice'
import { ENotificationLayouts } from 'astra-core/containers/MessagesProvider/types'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { ERoutes } from 'shared/types/routes'

import { AnimationNotificationPanel } from './AnimationNotificationPanel'
import { NotificationsList } from './components/NotificationsList'
import {
  StyledLastTitle,
  StyledModalTitle,
  StyledNotifications,
  StyledNotificationsHeader
} from './NotificationsPanel.styled'

export const NotificationsPanel = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const routeRedirect = useRouteRedirect()

  useEffect(() => {
    return () => {
      dispatch(layoutContainerActions.toggleIsDisplayNotificationPanel(false))
    }
  }, [dispatch])

  const onClose = useCallback(() => {
    dispatch(layoutContainerActions.toggleIsDisplayNotificationPanel(false))
  }, [dispatch])

  const handleClickAllNotifications = useCallback(() => {
    routeRedirect({ route: ERoutes.Notifications })
    dispatch(
      messagesProviderActions.clearMessages({
        layoutType: ENotificationLayouts.NOTIFICATIONS_PANEL
      })
    )
  }, [routeRedirect])

  return (
    <AnimationNotificationPanel>
      <StyledNotifications>
        <StyledNotificationsHeader>
          <StyledModalTitle>{t('notifications')}</StyledModalTitle>
          <StyledLastTitle onClick={handleClickAllNotifications}>
            {t('all notifications')}
          </StyledLastTitle>
          <IconClose size={10} onClick={onClose} />
        </StyledNotificationsHeader>

        <NotificationsList />
      </StyledNotifications>
    </AnimationNotificationPanel>
  )
}
