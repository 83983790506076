import { TThemeFonts } from 'shared/types/theme'

export const FONTS_STYLES: TThemeFonts = {
  size: {
    xxxxs: '8px',
    xxxs: '9px',
    xxs: '10px',
    xs: '11px',
    s: '12px',
    m: '14px',
    l: '16px',
    xl: '20px',
    xxl: '24px'
  },
  weight: {
    light: '300',
    regular: '400',
    normal: '500',
    semiBold: '600',
    bold: '700'
  }
}
