import React, { FC, memo } from 'react'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'
import { useMakeQuickBet } from 'astra-core/containers/BasketProvider/useMakeBet'
import { ErrorCode, OutcomeIdentifier } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'
import { getErrorOrUnknown } from 'og-core/shared/errors'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { TCustomToastContentProps } from 'shared/lib/Toastify'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { IconClockAnimated } from 'shared/ui/Icon/IconAnimated'
import { IconWarning2 } from 'shared/ui/Icon/General/IconWarning2'

import { StyledToastNotificationItem } from './BasketQuickBetNotification.styled'

export const BasketQuickBetNotificationWrapper: FC<
  TCustomToastContentProps<{ key: string }>
> = memo(({ data, closeToast }) => {
  const [, result] = useMakeQuickBet({
    fixedCacheKey: data?.key
  })

  const { t } = useTranslation()

  const handleCloseToast = () =>
    setTimeout(() => closeToast && closeToast(), 5000)
  const betResponse = result.data

  if (result?.isLoading) {
    return (
      <StyledToastNotificationItem>
        <IconClockAnimated />
        <span>{t('the bet is accepting')}</span>
      </StyledToastNotificationItem>
    )
  }

  handleCloseToast()
  if (result?.isError) {
    const errorInfo = getErrorOrUnknown(result.error)
    const isBetsPayMaxExceeded = errorInfo.code === ErrorCode.BetsPayMaxExceeded
    const message = isBetsPayMaxExceeded
      ? 'bet limit exceeded'
      : 'bet not accepted'

    return (
      <StyledToastNotificationItem>
        <IconWarning2
          colorProps={{
            name: EColorsNames.Yellow,
            value: 10,
            type: EColorsTypes.CUSTOM
          }}
          size={18}
        />
        <span>{t(message)}</span>
      </StyledToastNotificationItem>
    )
  }

  if (betResponse?.items[0].outcomes) {
    const outcomes = betResponse?.items[0].outcomes
    const outcomeCompositeId = outcomes[0]?.outcomeCompositeId
    return outcomeCompositeId ? (
      <BasketQuickBetNotification outcomeCompositeId={outcomeCompositeId} />
    ) : null
  }

  return null
})

export const BasketQuickBetNotification: FC<{
  outcomeCompositeId: OutcomeIdentifier
}> = ({ outcomeCompositeId }) => {
  const { competitorsNames, basketOutcomeName } =
    useOutcomeInfo(outcomeCompositeId)

  const { t } = useTranslation()

  return (
    <StyledToastNotificationItem>
      <IconCheckCircleFilled
        colorProps={{
          name: EColorsNames.Green,
          type: EColorsTypes.FIXED,
          value: 4
        }}
      />
      <span>{`${competitorsNames} ${basketOutcomeName}`}</span>
      <span>{t('bet accepted')}</span>
    </StyledToastNotificationItem>
  )
}
