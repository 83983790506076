import { TBaseColorsTheme } from 'shared/types/theme'

export const LIGHT_THEME_COLORS: TBaseColorsTheme = {
  custom: {
    // colors for few color cases
    primary: {
      0: '#000000',
      1: '#1A1A1A',
      2: '#FFFFFF', // mostly background
      3: '#FFFFFF',
      4: '#F2F2F2', // mostly border
      5: '#1A1A1A',
      6: '#CCCCCC',
      7: '#1A1A1A',
      8: '#E6E6E6',
      9: '#B3B3B3',
      10: '#FFFFFF',
      11: '#333333',
      12: '#333333',
      13: '#4D4D4D',
      14: '#999999',
      15: '#FFFFFF',
      16: '#333333',
      17: '#808080',
      18: '#E6E6E6',
      19: '#CCCCCC', // mostly hover border input, select, etc.
      20: '#FFFFFF',
      21: '#F2F2F2',
      22: '#E6E6E6',
      23: '#4D4D4D',
      24: '#B3B3B3',
      25: '#E6E6E6',
      26: '#B3B3B3',
      27: '#EFF2F5', // transfer to blue
      28: '#4D4D4D',
      29: '#0D0D0D',
      30: '#4D4D4D',
      31: '#F7F7F7',
      32: '#333333',
      33: '#CCCCCC',
      34: '#B3B3B3',
      35: '#CCCCCC',
      36: '#F2F2F2',
      37: '#F6F6F6',
      38: '#4D4D4D',
      39: '#4D4D4D',
      40: '#808080',
      41: '#4D4D4D',
      42: '#4D4D4D',
      43: '#FFFFFF',
      44: '#333333',
      45: '#D9D9D9',
      46: '#333333',
      47: '#333333',
      48: '#4D4D4D',
      49: '#1A1A1A',
      50: '#333333',
      51: '#E6E6E6',
      52: '#1A1A1A',
      53: '#CCCCCC',
      54: '#B3B3B3',
      55: '#333333',
      56: '#808080',
      57: '#666666',
      58: '#E6E6E6',
      59: '#F2F2F2',
      60: '#1A1A1A',
      61: '#F7F7F7',
      62: '#06132C',
      63: '#B3B3B3',
      64: '#FAF2EA',
      65: '#F7F7F7',
      66: '#0D0D0D',
      67: '#4D4D4D',
      68: '#1A1A1A',
      69: '#333333',
      70: '#999999',
      71: '#1A1A1A',
      72: '#F6F6F6',
      73: '#B3B3B3',
      74: '#FFFFFF',
      75: '#808080',
      76: '#F6F6F6',
      77: '#CCCCCC',
      78: '#4D4D4D',
      79: '#E5F4FF',
      80: '#0D0D0D',
      81: '#E6E6E6'
    },
    blue: {
      0: '#003399',
      1: '#002266',
      2: '#98BCE5',
      3: '#E6F4FF',
      4: '#E5F4FF',
      5: '#EDF1F7',
      6: '#E5F4FF',
      7: '#8592AD',
      8: '#003399',
      9: '#002266',
      10: '#8592AD',
      11: '#EDF1F7',
      12: '#E2E7EF',
      13: '#8592AD',
      14: '#515F7B',
      15: '#4D4D4D',
      16: '#F4F0FF',
      17: '#5E1AFF',
      18: '#7033FF',
      19: '#EDE5FF',
      20: '#EDE5FF',
      21: '#A680FF',
      22: '#DBCCFF',
      23: '#724DEA',
      24: '#7A4EFE',
      25: '#7033FF',
      26: '#5E1AFF',
      27: '#BAE2FF'
    },
    yellow: {
      0: '#F8EDE2',
      1: '#F8EDE2',
      2: '#F8F3E2',
      3: '#EBCD69',
      4: '#FFF3C8',
      5: '#FFF3C8',
      6: '#F0D9C1',
      7: '#F8EDE2',
      8: '#F8F3E2',
      9: '#F7F1DC',
      10: '#FFC400',
      11: '#F8F3E2'
    },
    red: {
      0: '#F2200D',
      1: '#F8D3D3'
    },
    brown: {
      0: '#E7AE85'
    },
    green: {
      0: '#40BF40',
      1: '#9EC89D',
      2: '#D9F2DD',
      3: '#3AAC3A',
      4: '#ECF8EE',
      5: '#40BF40',
      6: '#40BF40',
      7: '#3AAC3A'
    }
  },
  default: {
    // colors for one color case

    primary: {
      100: '#000000',
      90: '#1A1A1A',
      80: '#333333',
      70: '#4D4D4D',
      60: '#666666',
      50: '#808080',
      40: '#999999',
      30: '#B3B3B3',
      20: '#CCCCCC',
      15: '#D9D9D9',
      10: '#E6E6E6',
      7: '#EFF2F5',
      5: '#F2F2F2',
      3: '#F7F7F7',
      1: '#F6F6F6',
      0: '#FFFFFF'
    },
    green: {
      50: '#40BF40',
      11: '#BCE8C3',
      10: '#D9F2DD',
      5: '#ECF8EE'
    },
    red: {
      100: '#FF3333',
      60: '#DA1D0B',
      50: '#F2200D',
      40: '#F54D3D',
      10: '#F8D3D3',
      5: '#FBE9E9'
    },
    yellow: {
      50: '#FFC400',
      31: '#FFDB66',
      30: '#FFDB66'
    },
    blue: {
      71: '#F5F8FF',
      70: '#003399',
      51: '#0D59F2',
      50: '#0D59F2',
      1: '#3D7AF5',
      0: '#3D7AF5'
    },
    shadow: {
      0: 'rgba(0, 0, 0, 0.15)',
      1: 'rgba(26, 26, 26, 0.05)'
    }
  }
}
