import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconDependentOutcomes: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M14.5565 1.82843C12.9944 0.26633 10.4617 0.26633 8.8996 1.82843L6.07117 4.65685C4.50908 6.21895 4.50908 8.75161 6.07117 10.3137C6.4617 10.7042 7.09486 10.7042 7.48539 10.3137C7.87591 9.92318 7.87591 9.29002 7.48539 8.89949C6.70434 8.11845 6.70434 6.85212 7.48539 6.07107L10.3138 3.24264C11.0949 2.46159 12.3612 2.46159 13.1422 3.24264C13.9233 4.02369 13.9233 5.29002 13.1422 6.07107C12.7517 6.46159 12.7517 7.09476 13.1422 7.48528C13.5328 7.87581 14.1659 7.87581 14.5565 7.48528C16.1186 5.92318 16.1186 3.39052 14.5565 1.82843ZM10.3138 6.07107C9.92329 5.68054 9.29012 5.68054 8.8996 6.07107C8.50908 6.46159 8.50908 7.09476 8.8996 7.48528C9.68065 8.26633 9.68065 9.53266 8.8996 10.3137L6.07117 13.1421C5.29012 13.9232 4.02379 13.9232 3.24275 13.1421C2.4617 12.3611 2.4617 11.0948 3.24275 10.3137L3.47845 10.078C3.86897 9.68748 3.86897 9.05432 3.47845 8.66379C3.08792 8.27327 2.45476 8.27327 2.06423 8.66379L1.82853 8.89949C0.266434 10.4616 0.266436 12.9943 1.82853 14.5563C3.39063 16.1184 5.92329 16.1184 7.48539 14.5563L10.3138 11.7279C11.8759 10.1658 11.8759 7.63316 10.3138 6.07107Z"
        fill="#FF3333"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
