import styled, { css } from 'styled-components'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { EColorsNames } from 'shared/types/theme'

import { SPORT_CERTAIN_DATA } from '../../EventBannerLargeFeatureFlag/constants'

export const STYLED_EVENT_BANNER_MINI_HEIGHT = 40

export const StyledEventBannerMini = styled.div<{ sportCode: ESportsCodes }>`
  align-items: center;
  background: ${(p) =>
    p.sportCode
      ? (SPORT_CERTAIN_DATA[p.sportCode] || SPORT_CERTAIN_DATA.default)
          .backgroundColor
      : 'none'};
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto auto;
  height: ${STYLED_EVENT_BANNER_MINI_HEIGHT}px;
  justify-content: space-between;
  margin: 0 8px 8px;
  padding: 8px 8px 8px 14px;
  position: sticky;
  top: 2px;
  width: calc(100% - 8px);
  z-index: 1000;
`

export const StyledEventBannerMiniColumn = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  width: 100%;
`

export const StyledEventBannerMiniCompetitors = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.l};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  gap: 8px;
`

export const StyledBannerMiniCompetitorWrapper = styled.div<{
  reverse?: boolean
}>`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: ${(p) => (p.reverse ? 'flex-start' : 'flex-end')};
  line-height: 1;
`

export const CurrentServerFlag = styled.div<{ $isActive?: boolean }>`
  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.fixed.primary[0] : 'transparent'};
  border-radius: 50%;
  height: 5px;
  opacity: 0.5;
  width: 5px;
`

export const StyledEventBannerMiniCompetitor = styled.h3<{
  isHideLongName?: boolean
  sportCode: ESportsCodes
}>`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #fff 91.67%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: inline-block;
  font-size: ${(props) => props.theme.fonts.size.l};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  max-width: ${(p) => (p.isHideLongName ? '325px' : '100%')};
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`
export const StyledEventBannerMiniLiveWrapper = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.default[EColorsNames.Red][100]};
  border-radius: 4px;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
`

export const StyledEventBannerWhiteSpaceWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

export const StyledEventBannerWhiteSpace = styled.div<{ isLive: boolean }>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: ${(props) => (props.isLive ? '1' : '0')};
  justify-content: space-between;
  margin: 0 16px 0 8px;
  margin-left: 0;
  overflow: hidden;
  width: 100%;
`

export const StyledEventBannerScore = styled.span`
  color: ${(props) => props.theme.colors.fixed.primary[100]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 15px;
  position: relative;
  white-space: nowrap;
`

export const StyledEventBannerScoreByPeriods = styled.span<{
  isShowButtonMoreScores?: boolean
  sportCode: ESportsCodes
}>`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  opacity: 0.5;
  white-space: nowrap;

  &::after {
    background: ${(p) =>
      p.isShowButtonMoreScores
        ? (SPORT_CERTAIN_DATA[p.sportCode] || SPORT_CERTAIN_DATA.default)
            .backgroundColor
        : 'none'};
    background-attachment: fixed;
    content: '';
    display: flex;
    height: 13px;
    opacity: 0.65;
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
  }
`

export const StyledScoresWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 8px;
`

export const StyledMainScoresWrapper = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.fixed.primary[0]};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-left: 4px;
  padding: 4px;
`

export const StyledButtonMore = styled.button`
  background: none;
  border: none;
  height: 12px;
  margin-left: 6px;
  padding: 0px;
  &:hover {
    cursor: pointer;
  }
`

export const StyledMatchPhase = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  padding-left: 16px;
`

export const StyledMatchStatisticsActionsWrapper = styled.div`
  display: flex;
`

export const StyledDropDownMenuScores = styled.div`
  background: ${(props) => props.theme.colors.fixed.primary[0]};
  border: 1px solid ${(props) => props.theme.colors.fixed.primary[10]};
  border-radius: 4px;
  display: flex;
  padding: 4px 6px 4px 6px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 22;

  ${({ theme }) => css`
    box-shadow: 0 2px 4px ${theme.colors.default.shadow[0]};
  `}
`

export const StyledStyledDropDownMenuScoresItem = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[80]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
`
