import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isBrowser } from 'astra-core'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { selectIsHiddenWelcomeScreen } from 'containers/LayoutContainer/selectors'

export const useDisplayWelcomeScreen = () => {
  const dispatch = useDispatch()
  const isHiddenWelcomeScreen = useSelector(selectIsHiddenWelcomeScreen)
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(!isHiddenWelcomeScreen)

  useEffect(() => {
    let timer

    if (!isHiddenWelcomeScreen && isBrowser) {
      timer = setTimeout(() => {
        dispatch(layoutContainerActions.setHiddenWelcomeScreen(true))
        setIsLoaderDisplay(false)
      }, 1000)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [dispatch, isHiddenWelcomeScreen, isLoaderDisplay])

  return [isLoaderDisplay]
}
