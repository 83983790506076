import { useCallback } from 'react'

import { ERoutes } from 'shared/types/routes'
import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'

export const useOnboardingAttrs = () => {
  const { onboardingAttr: onboardingAttrFavourite } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.FAVOURITES_EVENTS,
    EOnboardingType.MAIN_PAGE_WELCOME
  )
  const { onboardingAttr: onboardingAttrPersonalAccount } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.PERSONAL_ACCOUNT,
    EOnboardingType.MAIN_PAGE_WELCOME
  )
  const { onboardingAttr: onboardingAttrBalance } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.BALANCE,
    EOnboardingType.MAIN_PAGE_WELCOME
  )
  const { onboardingAttr: onboardingAttrBonus } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.BONUS,
    EOnboardingType.MAIN_PAGE_WELCOME
  )
  const { onboardingAttr: onboardingAttrMessages } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.MESSAGES,
    EOnboardingType.MAIN_PAGE_WELCOME
  )

  return {
    onboardingAttrFavourite,
    onboardingAttrPersonalAccount,
    onboardingAttrBalance,
    onboardingAttrBonus,
    onboardingAttrMessages
  }
}

export const useHandleRedirectList = () => {
  const routeRedirect = useRouteRedirect()

  return useCallback(
    (route: ERoutes) => () => routeRedirect({ route }),
    [routeRedirect]
  )
}
