import styled, { css } from 'styled-components'

import { customMedia, columnGapPrefixes } from 'shared/lib/styled'

export const StyledMainScoreWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;
`

export const StyledScoreWrapper = styled(StyledMainScoreWrapper)`
  min-width: 0;
`

export const StyledScore = styled.div<{ isAdaptive?: boolean }>`
  color: ${(props) => props.theme.colors.default.primary[90]};
  ${(props) =>
    !props.isAdaptive &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  white-space: nowrap;
`

export const StyledScoreByPeriods = styled.span`
  color: ${(p) => p.theme.colors.default.primary[50]};
  display: flex;
  max-width: 150px;
  min-width: 0;

  ${customMedia.tablet} {
    display: none;
  }
`

export const StyledScoreByPeriodsDotsIcon = styled.span`
  background-color: ${(p) => p.theme.colors.custom.blue[5]};
  border-radius: 2px;
  display: flex;
  ${columnGapPrefixes('2px')}
  height: fit-content;
  padding: 5px 3px;
`

export const StyledScoreByPeriodsDot = styled.div`
  background-color: ${(p) => p.theme.colors.custom.blue[13]};
  border-radius: 50%;
  height: 2px;
  width: 2px;
`
