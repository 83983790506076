import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBonusCoupons: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 14 18"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M11.5 -9.83506e-08C12.7426 -4.40331e-08 13.75 1.00736 13.75 2.25L13.75 15.75C13.75 16.9926 12.7426 18 11.5 18L8.99994 18C8.99994 16.8954 8.10451 15.9995 6.99994 15.9995C5.89537 15.9995 4.99994 16.8949 4.99994 17.9995L2.5 18C1.25736 18 0.249999 16.9926 0.249999 15.75L0.25 2.25C0.25 1.00736 1.25736 -5.46071e-07 2.5 -4.91753e-07L5 -3.82475e-07C5 1.10457 5.89543 2 7 2C8.10457 2 9 1.10457 9 -2.07629e-07L11.5 -9.83506e-08ZM10.375 7.875C9.75368 7.875 9.25 7.37132 9.25 6.75C9.25 6.12868 9.75368 5.625 10.375 5.625C10.9963 5.625 11.5 6.12868 11.5 6.75C11.5 7.37132 10.9963 7.875 10.375 7.875ZM7 7.875C6.37868 7.875 5.875 7.37132 5.875 6.75C5.875 6.12868 6.37868 5.625 7 5.625C7.62132 5.625 8.125 6.12868 8.125 6.75C8.125 7.37132 7.62132 7.875 7 7.875ZM2.5 6.75C2.5 7.37132 3.00368 7.875 3.625 7.875C4.24632 7.875 4.75 7.37132 4.75 6.75C4.75 6.12868 4.24632 5.625 3.625 5.625C3.00368 5.625 2.5 6.12868 2.5 6.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
