import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconClockAnimated: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" fill="#666" r="10" />
      <line
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2px"
        x1="10"
        x2="10"
        y1="10.01"
        y2="4.01"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="3s"
          from="0 10 10.1"
          repeatCount="indefinite"
          to="360 10 10.1"
          type="rotate"
        />
      </line>
      <line
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2px"
        x1="10"
        x2="10"
        y1="10.01"
        y2="6.01"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="6s"
          from="0 10 10.1"
          repeatCount="indefinite"
          to="360 10 10.1"
          type="rotate"
        />
      </line>
    </svg>
  </Icon>
)
