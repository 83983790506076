import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { useOnboardingItemContext } from 'widgets/onboarding/Onboarding.context'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'

import { useOnboardingContentCoords } from './hooks'
import {
  StyledOnboardingContent,
  StyledOnboardingContentButtonNext,
  StyledOnboardingContentButtonSkip,
  StyledOnboardingContentButtonWrapper,
  StyledOnboardingContentHeader,
  StyledOnboardingContentText
} from './OnboardingContent.styled'
import { IOnboardingContentProps } from './OnboardingContent.types'

export const OnboardingContent: FC<IOnboardingContentProps> = ({
  children,
  title,
  ...props
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { coords, onboardingContentRef } = useOnboardingContentCoords()
  const { onNextOnboardingItem, onCloseOnboarding, isHaveNext } =
    useOnboardingItemContext()

  const onRepeatOnboarding = useCallback(() => {
    dispatch(layoutContainerActions.resetOnboarding())

    setTimeout(() => {
      dispatch(
        layoutContainerActions.setOnboarding({
          isDisplay: true,
          type: EOnboardingType.MAIN_PAGE_WELCOME
        })
      )
    }, 300)
  }, [dispatch])

  return (
    <StyledOnboardingContent
      {...props}
      positionCoords={coords}
      ref={onboardingContentRef}
    >
      <StyledOnboardingContentHeader>{title}</StyledOnboardingContentHeader>

      <StyledOnboardingContentText>{children}</StyledOnboardingContentText>

      <StyledOnboardingContentButtonWrapper>
        {isHaveNext ? (
          <>
            <StyledOnboardingContentButtonNext onClick={onNextOnboardingItem}>
              {t('next')}
            </StyledOnboardingContentButtonNext>
            <StyledOnboardingContentButtonSkip onClick={onCloseOnboarding}>
              {t('skip')}
            </StyledOnboardingContentButtonSkip>
          </>
        ) : (
          <>
            <StyledOnboardingContentButtonNext onClick={onCloseOnboarding}>
              {t('got it')}
            </StyledOnboardingContentButtonNext>
            <StyledOnboardingContentButtonSkip onClick={onRepeatOnboarding}>
              {t('repeat')}
            </StyledOnboardingContentButtonSkip>
          </>
        )}
      </StyledOnboardingContentButtonWrapper>
    </StyledOnboardingContent>
  )
}
