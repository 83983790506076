import { createSelector } from '@reduxjs/toolkit'
import {
  getBetsHistoryPageBetsTypes,
  getHistoryBets
} from 'astra-core/containers/BetsHistoryProvider'
import { getValuesBets } from 'astra-core/utils'
import { getEnrichBetsHistoryBasketWithPlayerNames } from 'astra-core/utils/bets'
import map from 'lodash/map'
import { selectPlayers } from 'astra-core/containers/PlayersProvider'

import { getBetsHistoryBasketSortedIds } from 'containers/BetsHistoryBasketContainer/selectors'

export const selectBetsHistoryBasketValues = createSelector(
  [
    getBetsHistoryBasketSortedIds,
    getHistoryBets,
    getBetsHistoryPageBetsTypes,
    selectPlayers
  ],
  (betsIds, bets, betTypes, players) => {
    const filteredBets = getValuesBets(betsIds, bets, players).filter((bet) => {
      if (betTypes?.length) {
        return map(betTypes, 'value').includes(bet.betType)
      } else {
        return bet
      }
    })
    return getEnrichBetsHistoryBasketWithPlayerNames(filteredBets, players)
  }
)
