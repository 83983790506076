import React, { FC } from 'react'
import {
  ThemeProvider as StyledThemeProvider,
  ThemeProviderProps
} from 'styled-components'

import { ThemeContext } from 'shared/types/theme'

import { useSelectDefaultTheme } from './use-select-default-theme'

export const ThemeProvider: FC<Partial<ThemeProviderProps<ThemeContext>>> = (
  props
) => {
  const [defaultTheme] = useSelectDefaultTheme()

  return <StyledThemeProvider {...props} theme={defaultTheme} />
}
