import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { selectIsDisplayModalSettings } from 'containers/LayoutContainer/selectors'

import { SettingsList } from './components'

export const Settings: FC = () => {
  const isDisplayModal = useSelector(selectIsDisplayModalSettings)

  return isDisplayModal ? <SettingsList /> : null
}
