import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EButtonSizes, EButtonViews } from 'shared/ui/Button'
import { getIsTodayValue } from 'shared/lib/todayDate'

import {
  ICON_CALENDAR_COLOR_PROPS,
  ICON_CLOSE_PROPS,
  SELECTED_DATE
} from './constants'
import { ICustomButtonRange } from './CustomButtonRange.types'
import {
  StyledButtonResetRange,
  StyledCustomButtonRange,
  StyledCustomButtonRangeWrapper,
  StyledIconCalendar
} from './CustomButtonRange.styled'

export const CustomButtonRange: FC<ICustomButtonRange> = memo(
  ({
    onClick,
    startDate,
    endDate,
    onReset,
    isSingleDate,
    singleDateFormat
  }) => {
    const [t] = useTranslation()

    const dateValue = useMemo(
      () =>
        startDate && endDate
          ? `${dayjs(startDate).format(SELECTED_DATE)} - ${dayjs(
              endDate
            ).format(SELECTED_DATE)}`
          : '',
      [startDate, endDate]
    )

    const singleDate = useMemo(
      () =>
        isSingleDate &&
        startDate &&
        `${getIsTodayValue({ date: startDate, t, addText: ',' })} ${dayjs(
          startDate
        ).format(singleDateFormat)}`,
      [isSingleDate, startDate, t, singleDateFormat]
    )

    const handleOnReset = useCallback(
      (e) => {
        e.stopPropagation()
        if (onReset) {
          // @ts-ignore
          onReset([null, null], e)
        }
      },
      [onReset]
    )

    return (
      <StyledCustomButtonRange
        isSelected={singleDate && !!dateValue}
        size={EButtonSizes.S}
        view={EButtonViews.SECONDARY}
        onClick={onClick}
      >
        <StyledCustomButtonRangeWrapper>
          <StyledIconCalendar
            colorProps={ICON_CALENDAR_COLOR_PROPS}
            size={14}
          />

          {singleDate || dateValue || t('per period')}

          {!!dateValue ||
            (!getIsTodayValue({ date: startDate ?? new Date(), t }) && (
              <StyledButtonResetRange
                icon={IconClose}
                iconProps={ICON_CLOSE_PROPS}
                view={EButtonViews.NONE}
                onClick={handleOnReset}
              />
            ))}
        </StyledCustomButtonRangeWrapper>
      </StyledCustomButtonRange>
    )
  }
)
