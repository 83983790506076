import styled from 'styled-components'

import { IconCalendar } from 'shared/ui/Icon/General/IconCalendar'

export const StyledCalendarTitle = styled.div`
  grid-area: page-header;
  margin-top: 8px;
`
export const StyledIconCalendar = styled(IconCalendar)`
  background: ${(props) => props.theme.colors.custom.blue[6]};
  border-radius: 4px;
  padding: 5px;
`
