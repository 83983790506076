import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { IconBasketClose } from 'shared/ui/Icon/General/IconBasketClose'
import { customMedia } from 'shared/lib/styled'

import { StyledBasketHeaderItemWrapperProps } from './BasketHeader.types'

export const StyledBasketHeaderWrapper = styled.div`
  ${(props) => css`
    align-items: center;
    border-bottom: 1px solid ${props.theme.colors.custom.primary[4]};
    display: grid;
    grid-template-columns: minmax(85px, max-content) minmax(76px, max-content) auto;
    height: 49px;
    padding: 0 16px;
    position: relative;
    width: 280px;
  `}
`

export const StyledSettingRatesWrapper = styled.div<{ top: number }>`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  position: absolute;
  right: 0;
  top: ${(p) => p.top}px;
  width: 400px;
  ${({ theme }) => css`
    background-color: ${theme.colors.custom.primary[10]};
    box-shadow: 0 2px 4px ${theme.colors.default.shadow[0]};
  `}
  z-index: 100;
`

export const StyledSettingRatesHeader = styled.div`
  align-items: center;
  display: flex;
  height: min-content;
  justify-content: space-between;
  padding: 0 32px;
  padding-top: 24px;
  width: inherit;
`

export const StyledSettingRatesTitle = styled.div`
  line-height: 20px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.l};
      font-weight: ${theme.fonts.weight.bold};
    `}
`

export const StyledSettingRatesIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`

export const StyledSettingRatesCoefficientChange = styled.div`
  line-height: 14px;
  margin-bottom: 8px;
  margin-top: 23px;
  padding: 0 32px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `};
`

export const StyledInputRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-between;
  margin-bottom: 23px;
  padding: 0 32px;
`

export const labelStyle = (theme) => ({
  color: theme.colors.default.primary[80]
})

export const StyledBasketHeaderItemWrapper = styled.div<StyledBasketHeaderItemWrapperProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  margin-bottom: 2px;
  padding: 0 8px;
  ${({ theme, active }) =>
    active &&
    css`
      box-shadow: 0px 3px 0px -1px ${theme.colors.default.primary[90]};
    `}
`

export const BasketHeaderItemTitle = styled.span<StyledBasketHeaderItemWrapperProps>`
  ${(props) => css`
    color: ${props.active
      ? props.theme.colors.default.primary[90]
      : props.theme.colors.fixed.primary[50]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
    text-transform: uppercase;
  `}
`

export const BasketHeaderItemCounter = styled.span`
  ${(props) => css`
    align-items: center;
    background-color: ${props.theme.colors.default.primary[10]};
    border-radius: 4px;
    clip-path: polygon(
      11% 44%,
      0 32%,
      0 0,
      100% 0,
      100% 32%,
      90% 43%,
      90% 57%,
      100% 67%,
      100% 100%,
      0 100%,
      0 67%,
      11% 59%
    );
    color: ${props.theme.colors.default.primary[90]};
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.semiBold};
    height: 20px;
    justify-content: center;
    line-height: 16px;
    margin-left: 4px;
    width: 24px;
  `}
`

export const StyledCloseButtonWrapper = styled.div<{
  isRouteWithPermanentHiddenBasket: boolean
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.default.primary[5]};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  justify-self: end;
  width: 24px;
  z-index: 111111;

  ${({ isRouteWithPermanentHiddenBasket }) =>
    !isRouteWithPermanentHiddenBasket
      ? css`
          display: none;
        `
      : ''}

  ${customMedia.small} {
    display: flex;
  }
`

export const StyledIconClose = styled(IconBasketClose)`
  height: 11px;
  width: 11px;
  ${({ theme }) => `
  ${
    theme.typeLayoutTheme === LayoutTheme.Light &&
    `
    & svg path {
      fill: #808080;
    }
    `
  }
  `};
`
