import React, { FC } from 'react'

import { LoaderSpinnerProps } from './types'
import {
  StyledLoadingIndicatorFullContainer,
  StyledLoadingIndicatorSVG,
  StyledLoadingIndicatorSVGCircle
} from './styled'

export const LoaderSpinner: FC<LoaderSpinnerProps> = ({ small }) => (
  <StyledLoadingIndicatorFullContainer>
    <StyledLoadingIndicatorSVG small={small} viewBox="-24 -24 48 48">
      <StyledLoadingIndicatorSVGCircle
        cx="0"
        cy="0"
        fill="none"
        r="20"
        strokeWidth="4"
      />
    </StyledLoadingIndicatorSVG>
  </StyledLoadingIndicatorFullContainer>
)
