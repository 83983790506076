import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBonusCouponHeader: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 28 18"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 0.8C26.215 0.8 27.2 1.78497 27.2 3V15C27.2 16.215 26.215 17.2 25 17.2H3C1.78497 17.2 0.8 16.215 0.8 15V12.7C0.8 12.6601 0.813997 12.5975 0.891111 12.5116C0.972436 12.4209 1.10266 12.3318 1.25946 12.2731L0.979103 11.5239L1.25946 12.2731C2.59009 11.7752 3.55 10.5043 3.55 9C3.55 7.4957 2.59009 6.22477 1.25946 5.72688C1.10266 5.66821 0.972436 5.57909 0.891111 5.48843C0.813997 5.40245 0.8 5.33991 0.8 5.3V3C0.8 1.78497 1.78497 0.8 3 0.8H25Z"
        fill="url(#paint0_linear_1805_79197)"
        stroke="#9470FE"
        strokeWidth="1.6"
      />
      <path
        d="M9 0H11V3C11 3.55228 10.5523 4 10 4C9.44771 4 9 3.55228 9 3V0Z"
        fill="#9470FE"
      />
      <path
        d="M9 7C9 6.44772 9.44771 6 10 6C10.5523 6 11 6.44772 11 7V11C11 11.5523 10.5523 12 10 12C9.44771 12 9 11.5523 9 11V7Z"
        fill="#9470FE"
      />
      <path
        d="M10 14C9.44771 14 9 14.4477 9 15V18H11V15C11 14.4477 10.5523 14 10 14Z"
        fill="#9470FE"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1805_79197"
          x1="-3.96485e-07"
          x2="22.8956"
          y1="-1.45764"
          y2="30.0742"
        >
          <stop stopColor="#875EFF" />
          <stop offset="1" stopColor="#492CC1" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
