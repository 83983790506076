import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { STYLED_FIXED_HEADER_HEIGHT } from 'widgets/header/FixedHeader/FixedHeader.styled'
import { Button } from 'shared/ui/Button'
import { IconCheckMark } from 'shared/ui/Icon/General/IconCheckMark'

export const LanguagesWindow = styled.div`
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
`

export const StyledLanguagesList = styled.div`
  ${(props) => css`
    background-color: ${props.theme.colors.custom.primary[10]};
    border-radius: 8px;
    box-shadow: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
      ? `0px 2px 4px  rgb(0 0 0 / 60%)`
      : `0px 2px 8px  rgb(0 0 0 / 8%)`};
    max-height: 256px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    right: 95px;
    top: ${STYLED_FIXED_HEADER_HEIGHT + 4}px;
    width: 200px;
    z-index: 1000;
  `}
`

export const StyledLanguagesListItems = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
`
export const StyledLanguagesListItemsName = styled.span`
  color: ${(props) => props.theme.colors.default.primary[90]};
  padding-left: 8px;
`

export const StyledLanguagesListButton = styled(Button)`
  font-size: 12px;
  line-height: 16px;
  padding: 8px 0 8px 16px;
  width: 100%;
`

export const StyledIconCheckMark = styled(IconCheckMark)`
  position: absolute;
  right: 8px;
`
