import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconStatFootballYellowCard: FC<IconProps> = (props) => (
  <Icon size={16} {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 3.5C4.5 2.67157 5.21634 2 6.1 2H10.9C11.7837 2 12.5 2.67157 12.5 3.5V12.5C12.5 13.3284 11.7837 14 10.9 14H6.1C5.21634 14 4.5 13.3284 4.5 12.5V3.5Z"
        fill="#FFD047"
      />
    </svg>
  </Icon>
)
