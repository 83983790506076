import styled, { css } from 'styled-components'

export const StyledQuickBetForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
export const StyledQuickBetInputWrapper = styled.div``

export const StyledQuickBetInputLabel = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  margin-bottom: 4px;
  text-align: center;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[39]};
    `}
`

export const StyledQuickBetInput = styled.div<{
  error: string | null
}>`
  ${({ theme, error }) => css`
    align-items: center;
    background: ${theme.colors.default.primary[0]};
    border: 1px solid ${theme.colors.default.primary[20]};
    border-radius: 4px;
    &:hover {
      border-color: ${theme.colors.custom.primary[14]};
    }

    &:focus {
      border-color: ${error
        ? theme.colors.fixed.red[2]
        : theme.colors.default.primary[90]};
    }
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    overflow: hidden;
    padding: 5px 6px 5px 8px;
    position: relative;

    &:disabled {
      border: 1px solid ${theme.colors.default.primary[5]};

      &::placeholder {
        color: ${theme.colors.custom.primary[34]};
      }
    }

    &:focus-within {
      border: 1px solid
        ${error ? theme.colors.fixed.red[2] : theme.colors.default.primary[90]};
    }
  `}
`

export const StyledQuickBetInputDescription = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.xxs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
  `}
`

export const StyledQuickBetInputError = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.red[50]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
  `}
`
