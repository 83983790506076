import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import {
  ENotificationContainerIds,
  ToastifyCloseButton,
  usePopUpNotification
} from 'shared/lib/Toastify'

import { NotificationClearHistoryProgress } from '../NotificationClearHistory'
import { StyledFilterTextButton } from '../../Filter.styled'

export const ButtonClearHistory: FC = () => {
  const [t] = useTranslation()

  const notifyClearHistory = usePopUpNotification({
    content: {
      children: (props) => <NotificationClearHistoryProgress {...props} />
    },
    options: {
      closeButton: (props) => (
        <ToastifyCloseButton closeToast={props.closeToast} />
      ),
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: false
    }
  })

  return (
    <StyledFilterTextButton
      data-test-id={ETestData.TestBetsHistoryClearHistoryButton}
      onClick={notifyClearHistory}
    >
      {t('to clear history')}
    </StyledFilterTextButton>
  )
}
