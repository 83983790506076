export interface ITagProps {
  type: ETagTypes
}

export enum ETagTypes {
  TOP = 'TOP',
  BONUS = 'BONUS',
  GOAL = 'GOAL',
  VAR = 'VAR'
}
