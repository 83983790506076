import React, { FC } from 'react'

import {
  EventStatisticBlockLiveTime,
  EventStatisticBlockOtherInfo
} from './components'
import { EventStatisticsProps } from './EventStatistics.types'
import { StyledEventStatistics } from './EventStatistics.styled'

export const EventStatistics: FC<EventStatisticsProps> = (props) => (
  <StyledEventStatistics>
    <EventStatisticBlockLiveTime {...props} />
    <EventStatisticBlockOtherInfo {...props} />
  </StyledEventStatistics>
)
