import React, { FC, useContext, useMemo } from 'react'
import { Competitor } from 'betweb-openapi-axios'
import {
  selectSport,
  ESportsCodes,
  selectMatchPhase
} from 'astra-core/containers/CommonDataProvider'
import { useSelector } from 'react-redux'

import { RootState } from 'shared/types/store'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { IconMoreRow } from 'shared/ui/Icon/General/IconMoreRow'

import { useEventBannerData } from '../../../hooks'
import {
  MAX_SCORES_STATISTICS,
  MIN_SCORES_STATISTICS
} from '../../../constants'
import { isHideLongNameCompetitors } from '../../../utils'

import { ActionsList } from './components'
import {
  CurrentServerFlag,
  StyledBannerMiniCompetitorWrapper,
  StyledButtonMore,
  StyledDropDownMenuScores,
  StyledEventBannerMini,
  StyledEventBannerMiniColumn,
  StyledEventBannerMiniCompetitor,
  StyledEventBannerMiniCompetitors,
  StyledEventBannerScore,
  StyledEventBannerScoreByPeriods,
  StyledEventBannerWhiteSpace,
  StyledEventBannerWhiteSpaceWrapper,
  StyledMainScoresWrapper,
  StyledMatchPhase,
  StyledMatchStatisticsActionsWrapper,
  StyledScoresWrapper,
  StyledStyledDropDownMenuScoresItem
} from './EventBannerMiniFeatureFlag.styled'
import { EventDateTime } from './components/EventDateTime'
import { EventBannerMiniProps } from './components/EventBannerMiniFeatureFlag.types'

export const EventBannerMiniFeatureFlag: FC<EventBannerMiniProps> = ({
  isShowDropDownMenuScores,
  setIsShowDropDownMenuScores
}) => {
  const { event } = useContext(EventContext) as IEventContext
  const { competitors, currentServer, bannerScore, matchPhase } =
    useEventBannerData()

  const sport = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const currentMatchPhase = useSelector((state: RootState) =>
    selectMatchPhase(state, Number(matchPhase?.value))
  )

  const isLive = useMemo(() => event.live, [event.live])

  const scoresByPeriods = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.ScoresByPeriods
  )

  const isShowButtonMoreScores =
    scoresByPeriods && scoresByPeriods?.value.length >= MAX_SCORES_STATISTICS

  const isShowMainScore = bannerScore.trim()?.length >= MIN_SCORES_STATISTICS

  const gameScore = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.GameScore
  )

  const gameScoreValue = useMemo(() => {
    if (!gameScore?.value) {
      return null
    }
    let formattedGameScoreValue = gameScore?.value
    if (sport?.code === ESportsCodes.TENNIS) {
      formattedGameScoreValue = formattedGameScoreValue.replace(/50/g, 'A')
    }
    return formattedGameScoreValue
  }, [gameScore?.value, sport?.code])

  const handleClickMore = () => {
    if (setIsShowDropDownMenuScores) {
      setIsShowDropDownMenuScores(!isShowDropDownMenuScores)
    }
  }

  return (
    <StyledEventBannerMini sportCode={sport?.code as ESportsCodes}>
      <StyledEventBannerMiniColumn>
        <EventCompetitorsMiniBanner
          bannerScore={isShowMainScore ? bannerScore : null}
          competitors={competitors}
          currentServer={currentServer}
          isLive={isLive}
          sportCode={sport?.code as ESportsCodes}
        />
        <StyledEventBannerWhiteSpaceWrapper>
          <StyledEventBannerWhiteSpace isLive={isLive}>
            {isLive && (
              <StyledScoresWrapper>
                <StyledEventBannerScoreByPeriods
                  isShowButtonMoreScores={isShowButtonMoreScores}
                  sportCode={sport?.code as ESportsCodes}
                >
                  {scoresByPeriods?.value && `(${scoresByPeriods?.value})`}
                  {!!gameScoreValue && `${gameScoreValue}`}
                </StyledEventBannerScoreByPeriods>
                {isShowButtonMoreScores && (
                  <StyledButtonMore onClick={handleClickMore}>
                    <IconMoreRow />
                  </StyledButtonMore>
                )}
              </StyledScoresWrapper>
            )}
            {isShowDropDownMenuScores && (
              <StyledDropDownMenuScores>
                <StyledStyledDropDownMenuScoresItem>
                  {scoresByPeriods?.value && `(${scoresByPeriods?.value})`}
                  {!!gameScoreValue && `${gameScoreValue}`}
                </StyledStyledDropDownMenuScoresItem>
              </StyledDropDownMenuScores>
            )}
          </StyledEventBannerWhiteSpace>
        </StyledEventBannerWhiteSpaceWrapper>
      </StyledEventBannerMiniColumn>

      <StyledMatchStatisticsActionsWrapper>
        <EventDateTime />
        {currentMatchPhase && (
          <StyledMatchPhase>{currentMatchPhase?.name}</StyledMatchPhase>
        )}
        <ActionsList />
      </StyledMatchStatisticsActionsWrapper>
    </StyledEventBannerMini>
  )
}

const EventCompetitor: FC<{
  competitorName: string
  sportCode: ESportsCodes
  isCurrentServer: boolean
}> = ({ competitorName, sportCode, isCurrentServer }) => {
  return (
    <>
      {isCurrentServer && <CurrentServerFlag $isActive={isCurrentServer} />}
      <StyledEventBannerMiniCompetitor
        isHideLongName={isHideLongNameCompetitors(competitorName)}
        sportCode={sportCode}
      >
        {competitorName}
      </StyledEventBannerMiniCompetitor>
    </>
  )
}

const EventCompetitorsMiniBanner: FC<{
  competitors: {
    homeCompetitors: Competitor[]
    awayCompetitors: Competitor[]
  }
  currentServer?: any
  isLive?: boolean
  bannerScore?: string | null
  sportCode: ESportsCodes
}> = ({ competitors, currentServer, isLive, bannerScore, sportCode }) => {
  return (
    <StyledEventBannerMiniCompetitors>
      <StyledBannerMiniCompetitorWrapper>
        <EventCompetitor
          competitorName={competitors.homeCompetitors[0]?.name}
          isCurrentServer={currentServer?.value === '1'}
          sportCode={sportCode}
        />
      </StyledBannerMiniCompetitorWrapper>
      {' — '}
      <StyledBannerMiniCompetitorWrapper>
        <EventCompetitor
          competitorName={competitors.awayCompetitors[0]?.name}
          isCurrentServer={currentServer?.value === '2'}
          sportCode={sportCode}
        />
        {isLive && bannerScore && (
          <StyledMainScoresWrapper>
            <StyledEventBannerScore>{bannerScore}</StyledEventBannerScore>
          </StyledMainScoresWrapper>
        )}
      </StyledBannerMiniCompetitorWrapper>
    </StyledEventBannerMiniCompetitors>
  )
}
