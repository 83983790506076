import { EBetTypes } from 'astra-core/containers/BasketProvider/types'
import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { css } from 'styled-components'

export const StyledMinMaxPopupWrapper = styled.div<{
  betTypeTab?: EBetTypes
}>`
  ${({ theme }) => css`
    background-color: ${theme.colors.custom.primary[41]};
    border-radius: 4px;
    left: 12px;
    padding: 2px 4px;
    position: absolute;
    top: 26px;
    z-index: 2;

    ${theme.typeLayoutTheme === LayoutTheme.Dark &&
    css`
      box-shadow: 0px 2px 4px ${theme.colors.default.shadow[0]};
    `}
  `}
`

export const StyledMinMaxPopupText = styled.div`
  line-height: 16px;

  ${({ theme }) =>
    `
        font-size: ${theme.fonts.size.s};
        font-weight: ${theme.fonts.weight.normal};
        color: ${theme.colors.custom.primary[51]};
      `}
`

export const StyledMinMaxPopupTriangle = styled.div`
  bottom: 19px;
  height: 0;
  left: 3px;
  position: absolute;
  width: 0;
  z-index: 100;

  ${({ theme }) =>
    `
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 6px solid ${theme.colors.custom.primary[41]};
      `}
`
