import { PayloadAction } from '@reduxjs/toolkit'
import { MarketGroup, Event } from 'betweb-openapi-axios'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'

import { ContainerState } from './types'

const REDUCER_KEY = 'outcomesContainer'

export const initialState: ContainerState = {
  selectedOutcomesGroup: {}
}

const outcomesContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setSelectedOutcomesGroup(
      state,
      {
        payload: { marketGroup, eventId }
      }: PayloadAction<{
        marketGroup: MarketGroup | null
        eventId: Event['id']
      }>
    ) {
      state.selectedOutcomesGroup[eventId] = marketGroup
    }
  }
})

export const {
  actions: outcomesContainerActions,
  reducer,
  name: sliceKey
} = outcomesContainerSlice
