import styled from 'styled-components'

import { customMedia } from 'shared/lib/styled'

import { IStyledRightContainerWrapper } from './right-container.types'

export const RIGHT_CONTAINER_WIDTH = 281
const RIGHT_CONTAINER_TOP_POSITION = 70
// TODO use here RIGHT_CONTAINER_TOP_POSITION

export const StyledRightContainerWrapper = styled.div<IStyledRightContainerWrapper>`
  background-color: ${(p) => p.theme.colors.custom.primary[2]};
  border-left: 1px solid ${(p) => p.theme.colors.custom.primary[4]};
  display: flex;
  flex-direction: column;
  grid-area: right-container;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;

  ${({ isRouteWithPermanentHiddenBasket, isShowBasketOnSmallWindow }) =>
    isRouteWithPermanentHiddenBasket &&
    `
          visibility: ${!isShowBasketOnSmallWindow ? 'hidden' : 'visible'};
          pointer-events: ${!isShowBasketOnSmallWindow ? 'none' : 'auto'};
          position: fixed;
          right: 0;
          top: ${RIGHT_CONTAINER_TOP_POSITION}px;
          height: calc(100% - ${RIGHT_CONTAINER_TOP_POSITION}px);
          z-index: 49;
          transform: ${
            !isShowBasketOnSmallWindow
              ? `translateX(${RIGHT_CONTAINER_WIDTH}px)`
              : 'translateX(0)'
          };
        `}

  ${customMedia.small} {
    background-color: ${({ theme }) => theme.colors.custom.primary[2]};
    display: flex;
    height: calc(100% - ${RIGHT_CONTAINER_TOP_POSITION}px);
    position: absolute;
    right: 0;
    top: ${RIGHT_CONTAINER_TOP_POSITION}px;
    transform: ${({ isShowBasketOnSmallWindow }) =>
      !isShowBasketOnSmallWindow
        ? `translateX(${RIGHT_CONTAINER_WIDTH}px)`
        : 'translateX(0)'};
    z-index: 1000;
  }
`
