import styled from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

export const StyledLoaderWelcomeScreen = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.theme.colors.fixed[EColorsNames.Primary][0]};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
`
