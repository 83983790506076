import React, { FC, Suspense } from 'react'
// eslint-disable-next-line react/no-deprecated
import { hydrateRoot } from 'react-dom/client'
// TODO: Switch to hydrate func fo React v18
import { loadableReady } from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { useSSR } from 'react-i18next'
import './app/lib/i18n'
import axios from 'axios'
import ApiBuilder from 'astra-core/config/ApiBuilder'
import CoreConfiguration from 'astra-core/config/CoreConfiguration'
import { addLogoutInterceptor } from 'og-web-core/shared/axios/interceptors'

import { configureAppStore } from 'app/lib/store'
import { routes, virtualGamesRoutes } from 'pages/model'

import App from './app/app'
import { initSentryClient } from './shared/lib/sentry/client'

/*
Redux Store Init
Statically injected reducers are pre-filled with initial data here,
while initial states of dynamically injected reducers are pre-filled
on behalf of each particular slice.
 */
const store = configureAppStore({
  // @ts-ignore
  preloadedState: window.__PRELOADED_STATE__
})

const axiosInstance = axios.create()
// @ts-ignore
addLogoutInterceptor(axiosInstance, store, virtualGamesRoutes)

// Init core configuration
CoreConfiguration.init({
  apiBuilder: ApiBuilder.create(axiosInstance)
})

// Init Sentry Client
initSentryClient()

// Log APP_VERSION
const appVersion = window.__PRELOADED_STATE__.configProvider.config.APP_VERSION
if (appVersion) {
  console.log('APP_VERSION', appVersion)
}

const AppContainer: FC = () => {
  useSSR(window.initialI18nStore, window.initialLanguage)

  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <Provider store={store}>
        <BrowserRouter>
          <App routes={routes} />
        </BrowserRouter>
      </Provider>
    </Suspense>
  )
}

// Load all components needed before rendering
loadableReady().then(() => {
  hydrateRoot(document.getElementById('root'), <AppContainer />)
})

if (module.hot) {
  module.hot.accept()
}
