import styled from 'styled-components'

import { ArrowPositionType } from './lib/useTooltipOptions'

export const TooltipWrapperStyled = styled.div``

export const TooltipStyled = styled.div`
  inset: 0 auto auto 0px;
  pointer-events: none;
  position: absolute;
  z-index: 1500;
`
export const TooltipTitleStyled = styled.div`
  background: ${(p) => p.theme.colors.default.primary[70]};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.default.primary[10]};
  display: inline-block;
  font-size: ${(p) => p.theme.fonts.size.s};
  font-weight: ${(p) => p.theme.fonts.weight.normal};
  line-height: 16px;
  margin-top: 8px;
  max-width: 300px;
  overflow-wrap: break-word;
  padding: 2px 4px;
  transform-origin: center top;
  word-wrap: break-word;
`
export const TooltipArrowStyled = styled.div<{
  position: ArrowPositionType
}>`
  border: 6px solid transparent;
  border-bottom-color: ${(p) => p.theme.colors.default.primary[70]};
  bottom: 100%;
  position: absolute;
  z-index: 1000;
  ${({ position }) => position === 'right' && 'transform: rotate(90deg)'}
  ${({ position }) => position === 'bottom' && 'transform: rotate(180deg)'}
`
