import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBonusCouponHistoryLight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.49976 3.5V4.375C6.49976 4.74319 6.79823 5.04167 7.16642 5.04167C7.53461 5.04167 7.83309 4.74319 7.83309 4.375V3.5H11.986C13.0983 3.5 14 4.17157 14 5V11C14 11.8284 13.0983 12.5 11.986 12.5H7.83309V11.6673C7.83309 11.2991 7.53461 11.0007 7.16642 11.0007C6.79823 11.0007 6.49976 11.2991 6.49976 11.6673V12.5H4.01399C2.90169 12.5 2 11.8284 2 11V9.85C2 9.57386 2.31005 9.3587 2.6573 9.26193C3.35236 9.06823 3.84615 8.57628 3.84615 8C3.84615 7.42372 3.35236 6.93178 2.6573 6.73807C2.31005 6.6413 2 6.42614 2 6.15V5C2 4.17157 2.90169 3.5 4.01399 3.5H6.49976ZM6.49927 7.16732C6.49927 6.79913 6.79774 6.50065 7.16593 6.50065C7.53412 6.50065 7.8326 6.79913 7.8326 7.16732L7.83309 8.83398C7.83309 9.20217 7.53461 9.50065 7.16642 9.50065C6.79823 9.50065 6.49976 9.20217 6.49976 8.83398L6.49927 7.16732Z"
        fill="url(#paint0_linear_37167_116739)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_37167_116739"
          x1="2"
          x2="12.075"
          y1="2.77118"
          y2="21.4009"
        >
          <stop offset="0.0001" stopColor="#875EFF" />
          <stop offset="1" stopColor="#492CC1" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)

export const IconBonusCouponHistoryDark: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.49976 3.5V4.375C6.49976 4.74319 6.79823 5.04167 7.16642 5.04167C7.53461 5.04167 7.83309 4.74319 7.83309 4.375V3.5H11.986C13.0983 3.5 14 4.17157 14 5V11C14 11.8284 13.0983 12.5 11.986 12.5H7.83309V11.6673C7.83309 11.2991 7.53461 11.0007 7.16642 11.0007C6.79823 11.0007 6.49976 11.2991 6.49976 11.6673V12.5H4.01399C2.90169 12.5 2 11.8284 2 11V9.85C2 9.57386 2.31005 9.3587 2.6573 9.26193C3.35236 9.06823 3.84615 8.57628 3.84615 8C3.84615 7.42372 3.35236 6.93178 2.6573 6.73807C2.31005 6.6413 2 6.42614 2 6.15V5C2 4.17157 2.90169 3.5 4.01399 3.5H6.49976ZM6.49927 7.16732C6.49927 6.79913 6.79774 6.50065 7.16593 6.50065C7.53412 6.50065 7.8326 6.79913 7.8326 7.16732L7.83309 8.83398C7.83309 9.20217 7.53461 9.50065 7.16642 9.50065C6.79823 9.50065 6.49976 9.20217 6.49976 8.83398L6.49927 7.16732Z"
        fill="url(#paint0_linear_37167_144249)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_37167_144249"
          x1="2"
          x2="11.027"
          y1="2.77118"
          y2="20.6894"
        >
          <stop offset="0.0001" stopColor="#A184F9" />
          <stop offset="0.841126" stopColor="#805AC6" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)
