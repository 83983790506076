import { useCallback } from 'react'
import { useConfigProvider } from 'astra-core/containers/ConfigProvider'
import { useAccountProvider } from 'astra-core/containers/AccountProvider'
import { useBalanceProvider } from 'astra-core/containers/BalanceProvider'
import { useBasketProvider } from 'astra-core/containers/BasketProvider'
import { useBetsHistoryProvider } from 'astra-core/containers/BetsHistoryProvider'
import { useCommonDataProvider } from 'astra-core/containers/CommonDataProvider'
import { useEventsProvider } from 'astra-core/containers/EventsProvider'
import { useSportCountersProvider } from 'astra-core/containers/SportCountersProvider'
import { useFavouritesProvider } from 'astra-core/containers/FavouritesProvider'
import { useLanguagesProvider } from 'astra-core/containers/LanguagesProvider'
import { useResultsProvider } from 'astra-core/containers/ResultsProvider'
import { useSearchProvider } from 'astra-core/containers/SearchProvider'
import {
  useSettingsApi,
  useSettingsProvider
} from 'astra-core/containers/SettingsProvider'
import { useMessagesProvider } from 'astra-core/containers/MessagesProvider'
import { usePlayersProvider } from 'astra-core/containers/PlayersProvider'
import { useGameVendorSettingsProvider } from 'astra-core/containers/GameVendorSettingsProvider'
import { useLogosProvider } from 'astra-core/containers/LogosProvider/useLogosProvider'
import { useBroadcastProvider } from 'astra-core/containers/BroadcastProvider/useBroadcastProvider'
import { useVirtualGamesProvider } from 'astra-core/containers/VirtualGamesProvider/useVirtualGamesProvider'
import { useVerificationProvider } from 'og-core/providers/verification/useVerificationProvider'
import { useBannersProvider } from 'astra-core/containers/BannersProvider'
import { useMainPageProvider as ogCoreUseMainPageProvider } from 'og-core/providers/main-page/useMainPageProvider'
import { useMainPageProvider as astraCoreUseMainPageProvider } from 'astra-core/containers/MainPageProvider/useMainPageProvider'
import { useBasketApi } from 'astra-core/containers/BasketProvider/useBasketApi'

import { useLayoutContainer } from 'containers/LayoutContainer'
import { useLocalStorageProvider } from 'containers/StorageProvider'
import { useOutcomesContainer } from 'containers/OutcomesContainer'
import { matchTrackerModel } from 'entities/match-tracker'
import { useRightBasketContainer } from 'containers/BasketContainer'
import { matchBroadcastModel } from 'entities/match-broadcast'

export const useProviders = () => {
  /**
   * It is required to memoize the function passed to the provider
   * to avoid unnecessary re-creations of the EventSource
   */
  const getEventSource = useCallback(
    (url, options: EventSourceInit) => new EventSource(url, options),
    []
  ) as Parameters<typeof useAccountProvider>[0]['getEventSource']

  useLocalStorageProvider()
  useConfigProvider()
  useLanguagesProvider()
  useCommonDataProvider()
  useSportCountersProvider()
  useEventsProvider()
  useAccountProvider({ getEventSource })
  useBasketProvider()
  useBetsHistoryProvider()
  useBalanceProvider()
  useFavouritesProvider()
  useResultsProvider()
  matchTrackerModel.useLiveMatchTracker()
  // todo combine matchTrackerModel and matchBroadcastModel
  matchBroadcastModel.useLiveMatchBroadcast()
  useLayoutContainer()
  useOutcomesContainer()
  useSearchProvider()
  useSettingsProvider()
  useMessagesProvider()
  usePlayersProvider()
  useGameVendorSettingsProvider()
  useLogosProvider()
  useRightBasketContainer()
  useBroadcastProvider()
  useVirtualGamesProvider()
  useBannersProvider()

  // TODO: Left it for compatibility, remove after PLAT-1871 release
  astraCoreUseMainPageProvider()

  /* OG related providers */
  useVerificationProvider()
  ogCoreUseMainPageProvider()
  useSettingsApi()
  useBasketApi()
}
