import React, {
  createRef,
  FC,
  forwardRef,
  memo,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'react-fast-compare'
import * as Templates from 'astrabet-templates-kit'
import {
  clearPerformanceMeasurements,
  EPerformanceEvent,
  markEvent
} from 'astra-core'
import {
  CategoryMarketInfoTradingStatus,
  selectEventCompetitors,
  selectEventProbabilityByMarketFiltered,
  selectEventStatusById,
  selectMarketCategoriesInfosByEventFiltered
} from 'astra-core/containers/EventsProvider'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { selectInputEventsSearch } from 'astra-core/containers/SearchProvider/selectors'
import { selectMarkets } from 'astra-core/containers/CommonDataProvider/selectors'
import { EventProbabilityTradingStatus, MarketType } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { ProbabilityWithOutcome } from 'astra-core/utils/outcomes'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { useEventMode, useTitleMergedColumns } from 'hooks/events'
import { RootState } from 'shared/types/store'
import { getSelectedOutcomesGroupId } from 'containers/OutcomesContainer/selectors'
import { Masonry } from 'shared/ui/Masonry'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { useFontOptions } from 'hooks/useFontOptions'
import { useHandleAddCoefficientToBasket } from 'features/event'

import {
  StyledEventOdd,
  StyledEventOddLabel,
  StyledEventOddTitle,
  StyledOddWrapper
} from '../ui/TableBets/TableBets.styled'
import { ClearResultSearch } from '../ui'
import { OddModes } from '../../Line.types'

import {
  MemoizedMasonryProps,
  OutcomeCategoriesHandle,
  OutcomeOddProps,
  OutcomesCategoriesProps,
  OutcomesCategoryProps,
  OutcomesTableProps,
  PanelHandle,
  PanelProps,
  TemplatedOutcomeProps
} from './Outcomes.types'
import {
  StyledClearResultSearch,
  StyledFavoriteButton,
  StyledPanel,
  StyledPanelHead,
  StyledPanelTitle,
  StyledTableRadius
} from './Outcomes.styled'
import { OddsTable, StyledHeaderCell, StyledOddsTableHeader } from './OddsTable'

const TABLES_GAP = 8
const MERGED_TEMPLATES = ['G2Template']

export const OutcomesCategories = memo(
  forwardRef<OutcomeCategoriesHandle, OutcomesCategoriesProps>(
    ({ eventId, ...props }, forwardedRef) => {
      const isShowAllEventProbs = useFeatureFlag(
        EFeatureFlags.SHOW_ALL_EVENT_PROBS
      )

      const valueSearch =
        useSelector((state: RootState) =>
          selectInputEventsSearch(state, eventId)
        ) || ''

      const selectedMarketGroupId = useSelector((state: RootState) =>
        getSelectedOutcomesGroupId(state, eventId)
      )
      const eventMarketCategoriesInfos = useSelector((state: RootState) =>
        selectMarketCategoriesInfosByEventFiltered(
          state,
          eventId,
          selectedMarketGroupId,
          valueSearch,
          isShowAllEventProbs
            ? undefined
            : CategoryMarketInfoTradingStatus.Trading
        )
      )

      if (valueSearch.length && !eventMarketCategoriesInfos.length) {
        return (
          <StyledClearResultSearch>
            <ClearResultSearch />
          </StyledClearResultSearch>
        )
      } else if (!eventMarketCategoriesInfos.length) {
        return null
      } else {
        // console.log('\n-----------------')
        // console.log('OutcomesCategories render')
        // console.log('eventMarketCategoriesInfos', eventMarketCategoriesInfos)

        return (
          <MemoizedMasonry
            eventId={eventId}
            eventMarketCategoriesInfos={eventMarketCategoriesInfos}
            ref={forwardedRef}
            {...props}
          />
        )
      }
    }
  ),
  isEqual
)

/**
 * This was necessary to prevent rerendering of child components
 * Closed panels used to open on each probability update
 * Memoizing array of filtered marketCategories, without reacting to probabilities update,
 * isolates changes inside each table, not the whole Masonry layout.
 * Filtering marketCategories was impossible without looking up into
 * event probabilities
 */
const MemoizedMasonry = memo(
  forwardRef<OutcomeCategoriesHandle, MemoizedMasonryProps>(
    (
      { eventMarketCategoriesInfos, eventId, columnsCount = 2 },
      forwardedRef
    ) => {
      const [panelRefs, setPanelRefs] = useState<
        Record<string, RefObject<React.ElementRef<typeof OutcomesCategory>>>
      >({})

      const MasonryRender = useCallback(
        ({
          data: { marketCategoryId, marketCategoryName, categoryMarketInfos }
        }) => (
          <OutcomesCategory
            categoryMarketInfos={categoryMarketInfos}
            eventId={eventId}
            marketCategoryId={marketCategoryId}
            marketCategoryName={marketCategoryName}
            ref={panelRefs[marketCategoryId]}
          />
        ),
        [eventId, panelRefs]
      )

      const MasonryItemKey = useCallback(
        ({ marketCategoryId }) => marketCategoryId,
        []
      )

      useEffect(() => {
        setPanelRefs((elRefs) =>
          mapValues(
            keyBy(eventMarketCategoriesInfos, 'marketCategoryId'),
            (value) => elRefs[value.marketCategoryId] ?? createRef()
          )
        )
      }, [eventMarketCategoriesInfos])

      useImperativeHandle(
        forwardedRef,
        () => ({
          openAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.open()
            })
          },
          closeAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.close()
            })
          }
        }),
        [panelRefs]
      )

      return (
        <Masonry
          columnCount={columnsCount}
          columnGutter={TABLES_GAP}
          itemKey={MasonryItemKey}
          items={eventMarketCategoriesInfos}
          overscanBy={Infinity}
          render={MasonryRender}
          rowGutter={TABLES_GAP}
          tabIndex={-1}
        />
      )
    }
  ),
  isEqual
)

export const OutcomesCategory = memo(
  forwardRef<PanelHandle, OutcomesCategoryProps>(
    ({ eventId, marketCategoryName, categoryMarketInfos }, forwardedRef) => {
      // if (marketCategoryId === '3fd0429e-dd05-4ff6-a123-e1f548b6673a') {
      //   console.log('\n-----------------')
      //   console.log('OutcomesCategory render')
      //   console.log('marketCategoryName', marketCategoryName)
      //   console.log('categoryMarketInfos', categoryMarketInfos)
      // }

      const { t } = useTranslation()
      const marketsDictionary = useSelector(selectMarkets)

      let shouldShowCommonTableHeader = false
      let commonHeaderTitles: string[] = []
      if (categoryMarketInfos.length) {
        const marketType =
          marketsDictionary[categoryMarketInfos[0].marketId].type
        shouldShowCommonTableHeader = categoryMarketInfos.every(
          (market) =>
            market.templateId === 'G2Template' &&
            marketsDictionary[market.marketId].type === marketType
        )
        if (shouldShowCommonTableHeader) {
          if (marketType === MarketType.YesNo) {
            commonHeaderTitles = ['', 'yes', 'no']
          } else if (marketType === MarketType.Total) {
            commonHeaderTitles = ['', 'over', 'under']
          }
        }
      }

      const OutcomesTables = useMemo(
        () =>
          categoryMarketInfos.map((categoryMarketInfo, index, array) => (
            <OutcomesTable
              isLast={
                shouldShowCommonTableHeader ? false : index === array.length - 1
              }
              isMerged={
                shouldShowCommonTableHeader
                  ? true
                  : index > 0 &&
                    MERGED_TEMPLATES.includes(categoryMarketInfo.templateId) &&
                    MERGED_TEMPLATES.includes(array[index - 1].templateId)
              }
              categoryMarketInfo={categoryMarketInfo}
              eventId={eventId}
              isFirst={index === 0}
              key={categoryMarketInfo.id}
            />
          )),
        [categoryMarketInfos, eventId, shouldShowCommonTableHeader]
      )

      return (
        <Panel ref={forwardedRef} title={marketCategoryName}>
          <StyledTableRadius>
            {shouldShowCommonTableHeader && (
              <StyledOddsTableHeader columns={commonHeaderTitles as any}>
                {commonHeaderTitles.map((title, i) => (
                  <StyledHeaderCell isFirstInRow={i === 0}>
                    {t(title)}
                  </StyledHeaderCell>
                ))}
              </StyledOddsTableHeader>
            )}
            {OutcomesTables}
          </StyledTableRadius>
        </Panel>
      )
    }
  ),
  isEqual
)

const Panel = memo(
  forwardRef<PanelHandle, PropsWithChildren<PanelProps>>(
    ({ title = '', children }, forwardedRef) => {
      const [opened, setOpened] = useState<boolean>(true)

      const toggleOpened = useCallback(() => setOpened((opened) => !opened), [])
      const open = useCallback(() => setOpened(true), [])
      const close = useCallback(() => setOpened(false), [])

      useImperativeHandle(forwardedRef, () => ({ open, close }), [close, open])

      return (
        <StyledPanel opened={opened}>
          <StyledPanelHead onClick={toggleOpened}>
            <StyledFavoriteButton>{/* <IconStar /> */}</StyledFavoriteButton>
            <StyledPanelTitle
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <IconChevronDown
              colorProps={{
                name: EColorsNames.Primary,
                value: 50,
                type: EColorsTypes.FIXED
              }}
              size={8}
              twist={opened ? 180 : 0}
            />
          </StyledPanelHead>
          {opened && children}
        </StyledPanel>
      )
    }
  ),
  isEqual
)

const TemplatedOutcome: FC<TemplatedOutcomeProps> = memo((props) => {
  const Component = useMemo(
    () =>
      Templates[props.categoryMarketInfo.templateId ?? 'K1Template'] ??
      Templates.K1Template,
    [props.categoryMarketInfo.templateId]
  )

  const renderOdd = useCallback(
    (outcome: ProbabilityWithOutcome) => (
      <OutcomeOdd
        eventProbability={{
          ...outcome,
          eventId: props.eventId
        }}
      />
    ),
    [props.eventId]
  )

  return (
    <Component
      competitors={{
        homeCompetitors: props.homeCompetitors,
        awayCompetitors: props.awayCompetitors
      }}
      categoryMarket={props.categoryMarketInfo}
      isLast={props.isLast}
      OddsTableComponent={OddsTable}
      probabilities={props.outcomes}
      renderOdd={renderOdd}
      withoutDivider={props.isFirst || props.isMerged}
      withoutTitle={props.isMerged}
    />
  )
}, isEqual)

export const OutcomesTable: FC<OutcomesTableProps> = memo(
  ({ eventId, categoryMarketInfo, isFirst, isLast, isMerged }) => {
    const isShowAllEventProbs = useFeatureFlag(
      EFeatureFlags.SHOW_ALL_EVENT_PROBS
    )

    // if (categoryMarketInfo.id === '460c7772-f882-45b4-a14b-1bbfa09285ca') {
    //   console.log('\n-----------------')
    //   console.log('OutcomesTable render')
    //   console.log('categoryMarketInfo', categoryMarketInfo)
    // }

    const { homeCompetitors, awayCompetitors } = useSelector(
      (state: RootState) => selectEventCompetitors(state, eventId)
    )

    const outcomes = useSelector((state: RootState) =>
      selectEventProbabilityByMarketFiltered(
        state,
        eventId,
        categoryMarketInfo.marketId,
        categoryMarketInfo.id,
        isShowAllEventProbs ? undefined : EventProbabilityTradingStatus.Trading
      )
    )

    return (
      <TemplatedOutcome
        awayCompetitors={awayCompetitors}
        categoryMarketInfo={categoryMarketInfo}
        eventId={eventId}
        homeCompetitors={homeCompetitors}
        isFirst={isFirst}
        isLast={isLast}
        isMerged={isMerged}
        outcomes={outcomes}
      />
    )
  },
  isEqual
)

const OutcomeOdd: FC<OutcomeOddProps> = memo(({ eventProbability }) => {
  const { labelInButton, eventId } = eventProbability

  const { options, stringRef, containerRef } = useFontOptions()

  const eventStatus = useSelector((state: RootState) =>
    selectEventStatusById(state, eventId)
  )

  const event = useSelector((state: RootState) =>
    selectEventById(state, eventId)
  )

  const outcomeMode = useEventMode({ eventProbability, eventStatus })
  const title = useTitleMergedColumns(eventProbability)

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket()

  const onClickAddOutcomeToBasket = useCallback(() => {
    if (outcomeMode !== OddModes.Disabled) {
      clearPerformanceMeasurements() // To start a new measurement of Adding Outcome Flow
      markEvent(EPerformanceEvent.OutcomeClick) // Performance Tests

      handleAddOutcomeToBasket({
        eventProbability,
        eventId,
        eventStatus,
        live: event.live
      })
    }
  }, [
    outcomeMode,
    handleAddOutcomeToBasket,
    eventProbability,
    eventId,
    eventStatus,
    event.live
  ])

  return (
    <StyledOddWrapper
      $mode={outcomeMode}
      labelInButton={labelInButton}
      onClick={onClickAddOutcomeToBasket}
    >
      {!!labelInButton && (
        <StyledEventOddLabel ref={containerRef}>
          <StyledEventOddTitle
            style={{
              fontSize: options.fontSize,
              lineClamp: options.lineClamp,
              whiteSpace: options.whiteSpace
            }}
            ref={stringRef}
          >
            {title}
          </StyledEventOddTitle>
        </StyledEventOddLabel>
      )}

      <StyledEventOdd $mode={outcomeMode}>
        {eventProbability?.odd}
      </StyledEventOdd>
    </StyledOddWrapper>
  )
}, isEqual)
