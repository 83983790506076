import React, { FC } from 'react'

import { ERatioView, RadioProps } from './types'
import {
  StyledRadioWrapper,
  StyledRadioLabel,
  StyledRadio,
  StyledRadioInput
} from './styled'

export const Radio: FC<RadioProps> = ({
  checked = false,
  view = ERatioView.DEFAULT,
  label,
  labelStyle,
  disabled,
  className,
  testData,
  ...props
}) => {
  return (
    <StyledRadioWrapper className={className} data-test-id={testData}>
      <StyledRadio checked={checked} disabled={disabled} view={view}>
        <StyledRadioInput
          checked={checked}
          disabled={disabled}
          type="radio"
          {...props}
        />
      </StyledRadio>
      {!!label && (
        <StyledRadioLabel style={labelStyle}>{label}</StyledRadioLabel>
      )}
    </StyledRadioWrapper>
  )
}
