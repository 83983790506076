import styled, { css } from 'styled-components'

import { IconDollarInCircle } from 'shared/ui/Icon/General/IconDollarInCircle'

export const StyledDeleteHistoryHint = styled.div`
  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[67]};
    `}
`

export const StyledToastifyCashoutNotificationTextWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
`

export const StyledToastifyCashoutNotificationText = styled.div``

export const StyledIconDollarInCircle = styled(IconDollarInCircle)`
  margin-right: 12px;
`

export const StyledToastifyCashoutNotificationSold = styled.div``
