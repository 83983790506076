import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { IconWithAdditionalCssProps } from '../types'
import { Icon } from '../Icon'

const StyledIconFavorite = styled(Icon)<IconWithAdditionalCssProps>`
  ${(props) => css`
    ${props.withHover &&
    css`
      &:hover {
        color: ${props.theme.colors.fixed.primary[0]} !important;
        opacity: 0.7 !important;
      }
    `}

    ${props.withActive &&
    css`
      &:active {
        color: ${props.theme.colors.fixed.primary[0]} !important;
        opacity: 1 !important;
      }
    `}

    ${props.$isActiveState &&
    css`
      color: ${props.theme.colors.fixed.primary[0]} !important;
      opacity: 1 !important;
    `}
  `}
`

export const IconFavorite: FC<IconWithAdditionalCssProps> = (props) => (
  <StyledIconFavorite {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M11.1037 3.8172C11.4705 3.07395 12.5303 3.07395 12.8971 3.8172L14.7066 7.48369C14.8523 7.77883 15.1339 7.9834 15.4596 8.03073L19.5058 8.61868C20.326 8.73786 20.6535 9.74583 20.06 10.3244L17.1321 13.1783C16.8965 13.4081 16.7889 13.7391 16.8445 14.0635L17.5357 18.0933C17.6758 18.9102 16.8184 19.5332 16.0848 19.1475L12.4657 17.2449C12.1744 17.0917 11.8264 17.0917 11.5351 17.2449L7.91601 19.1475C7.18238 19.5332 6.32495 18.9102 6.46506 18.0933L7.15624 14.0635C7.21187 13.7391 7.10433 13.4081 6.86864 13.1783L3.94078 10.3244C3.34726 9.74583 3.67477 8.73786 4.49499 8.61868L8.5412 8.03073C8.86691 7.9834 9.14847 7.77883 9.29414 7.48369L11.1037 3.8172Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </StyledIconFavorite>
)
