import React, { FC, useCallback, useLayoutEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'astra-core'

import { ERoutes } from 'shared/types/routes'
import { useRouteRedirect } from 'hooks/useRouteRedirect'

import { INotificationListItem } from './NotificationListItem.types'
import { SIZE_ICON } from './constants'
import {
  useHandleTextBlockSizes,
  useHandleHeight,
  useNotificationDescriptionToggleExpand
} from './hooks'
import {
  StyledIconUnreadMessage,
  StyledMessageBlock,
  StyledMessageDate,
  StyledMessageTitle,
  StyledMessageTop,
  StyledMoreButton,
  StyledNotificationListItemDescription,
  StyledNotificationListItemDescriptionText,
  StyledToggleExpandButton
} from './NotificationListItem.styled'

import './styles.css'

export const NotificationListItem: FC<INotificationListItem> = ({
  notification
}) => {
  return (
    <StyledMessageBlock isRead={notification.isRead}>
      <StyledMessageTop>
        <StyledMessageTitle isRead={notification.isRead}>
          {notification.header}
        </StyledMessageTitle>
        {!notification.isRead && <StyledIconUnreadMessage size={SIZE_ICON} />}
      </StyledMessageTop>

      <NotificationListItemDescription
        isReadNotification={notification.isRead}
        notificationId={notification.id}
        text={notification.rendered_text}
      />
      <StyledMessageDate>
        {formatDate(notification.created_at)}
      </StyledMessageDate>
    </StyledMessageBlock>
  )
}

const NotificationListItemDescription = ({
  text,
  notificationId,
  isReadNotification
}) => {
  const [t] = useTranslation()
  const ref = useRef<HTMLParagraphElement | null>(null)
  const routeRedirect = useRouteRedirect()
  const { conditionalSizes } = useHandleTextBlockSizes<HTMLParagraphElement>({
    ref,
    text
  })
  const {
    isExpanded,
    onToggleExpandAndSetReadNotification,
    onSetReadNotification
  } = useNotificationDescriptionToggleExpand({
    notificationId,
    isRead: isReadNotification
  })
  const { onSetDefaultBlockHeight, onSetFullBlockHeight, blockHeight } =
    useHandleHeight({ ref, isExpanded })

  const handleClickMoreDescription = useCallback(
    () => routeRedirect({ route: ERoutes.Notifications }),
    [routeRedirect]
  )

  useLayoutEffect(() => {
    // Viewed notification as default when its size is Small (not isMiddle and not isLarge)
    const isSmallView =
      conditionalSizes.isMiddle !== null &&
      !isExpanded &&
      !(conditionalSizes.isMiddle || conditionalSizes.isLarge)

    if (isSmallView) {
      onSetReadNotification()
    }
  }, [conditionalSizes, isExpanded, onSetReadNotification, isReadNotification])

  return (
    <StyledNotificationListItemDescription isRead={isReadNotification}>
      <CSSTransition
        classNames="expandable-block"
        in={isExpanded}
        timeout={100}
        onEnter={onSetFullBlockHeight}
        onExited={onSetDefaultBlockHeight}
      >
        <StyledNotificationListItemDescriptionText
          ref={ref}
          style={{ height: blockHeight }}
        >
          {text}
        </StyledNotificationListItemDescriptionText>
      </CSSTransition>

      {conditionalSizes.isMiddle && (
        <StyledToggleExpandButton
          isExpanded
          onClick={onToggleExpandAndSetReadNotification}
        >
          {t(isExpanded ? 'curtail' : 'show text')}
        </StyledToggleExpandButton>
      )}

      {conditionalSizes.isLarge && (
        <StyledMoreButton onClick={handleClickMoreDescription}>
          {t('more')}
        </StyledMoreButton>
      )}
    </StyledNotificationListItemDescription>
  )
}
