import { OptionTypeBase, StylesConfig } from 'react-select'
import styled, { css } from 'styled-components'

import { CheckboxElement } from 'shared/ui/checkbox'

export const selectStyles = (
  themeContext
): StylesConfig<OptionTypeBase, boolean> => ({
  container: (base) => ({
    ...base,
    fontSize: 12,
    width: 110
  }),
  placeholder: () => ({
    color: themeContext.colors.default.primary[90]
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 24,
    height: 24,
    cursor: 'pointer',
    backgroundColor: themeContext.colors.custom.primary[10],
    color: themeContext.colors.default.primary[70],
    borderColor: 'none',
    border: `1px solid ${
      state.isFocused
        ? themeContext.colors.custom.primary[90]
        : themeContext.colors.custom.primary[8]
    }`,
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused
        ? themeContext.colors.custom.primary[90]
        : themeContext.colors.custom.primary[19]
    },

    zIndex: 2
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    paddingRight: 7,
    color: themeContext.colors.default.primary[50]
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: themeContext.fonts.size.s,
    color: themeContext.colors.default.primary[50],
    '&:hover': {
      backgroundColor: themeContext.colors.default.primary[5]
    },
    padding: '9px 8px',
    cursor: 'pointer'
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: themeContext.fonts.size.s,
    padding: '0 4px 0 8px',
    color: themeContext.colors.default.primary[90],
    height: '100%'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '4px',
    border: 'solid',
    borderWidth: '1px',
    borderColor: themeContext.colors.default.primary[10],
    backgroundColor: themeContext.colors.custom.primary[10],
    zIndex: 2
  })
})

export const StyledInputCheckbox = styled(CheckboxElement)`
  ${(props) => css`
    margin: 0 6px 0 0;
    min-width: 14px;
    ${!props.checked &&
    css`
      border-color: ${props.theme.colors.custom.primary[29]};
    `}
  `}
`
