import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'

export const BasketClearAllWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 42px 0 32px 0;
`
export const BasketClearAllWarning = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
`
export const BasketClearAllTitle = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[16]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
    margin-top: 16px;
  `}
`

export const BasketClearAllText = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[40]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.regular};
    line-height: 16px;
    padding: 0 20px;
    text-align: center;
  `}
`
export const BasketClearAllOutcomesWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  overflow-y: auto;
  width: 100%;
`

export const StyledBetCardCouponWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.custom.primary[4]};
  width: 100%;
`

export const BasketClearAllButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  width: 100%;
`

export const BasketClearAllConfirmButton = styled(Button)`
  width: 100%;
`

export const BasketClearAllCancelButton = styled(Button)`
  width: 100%;
`
