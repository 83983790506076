import React, { FC } from 'react'

import { Layout } from 'widgets/Layout'

import { ELayoutsList, LayoutProps } from '../../Layout.types'

import { useEventBannerScroll } from './hooks'
import { LayoutEventContext } from './context'

export const LayoutEvent: FC<LayoutProps> = ({ children }) => {
  const { setEventBannerRefData, isScrolled, containerScrolledRef } =
    useEventBannerScroll()

  return (
    <LayoutEventContext.Provider
      value={{
        getCoordsEventBanner: setEventBannerRefData,
        isEventBannerScrolled: !!isScrolled
      }}
    >
      <Layout refContent={containerScrolledRef} type={ELayoutsList.SPORT}>
        {children}
      </Layout>
    </LayoutEventContext.Provider>
  )
}
