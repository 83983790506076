import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EBasketCardEventStatusKey } from 'og-web-core/utils/basket/basket.types'
import { basketCardEventStatusTestData } from 'og-web-core/utils/test-data/constants'

import { ETestData } from 'shared/lib/testData'

import { LoaderEventPending } from '../../Common'

import { IBasketCardEventStatusProps } from './BasketCardEventStatus.types'
import {
  StyledBetCardEventPending,
  StyledBetCardEventStatus
} from './BasketCardEventStatus.styled'

export const BasketCardEventStatus: FC<IBasketCardEventStatusProps> = (
  props
) => {
  const { status } = props
  const { t } = useTranslation()

  switch (status) {
    case EBasketCardEventStatusKey.LOADING: {
      return (
        <StyledBetCardEventPending>
          <LoaderEventPending testData={ETestData.TestLoaderEventPending} />
        </StyledBetCardEventPending>
      )
    }

    case EBasketCardEventStatusKey.EVENT_CANCELED:
    case EBasketCardEventStatusKey.EVENT_POSTPONED:
    case EBasketCardEventStatusKey.EVENT_INTERRUPTED:
    case EBasketCardEventStatusKey.OUTCOME_NOT_AVAILABLE:
    case EBasketCardEventStatusKey.EVENT_ENDED:
    case EBasketCardEventStatusKey.EVENT_SUSPENDED: {
      return (
        <StyledBetCardEventStatus
          data-test-id={basketCardEventStatusTestData[status]}
        >
          {t(status)}
        </StyledBetCardEventStatus>
      )
    }

    default: {
      return null
    }
  }
}
