import styled from 'styled-components'

export const StyledErrorPageText = styled.p`
  color: black;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.625rem 0 1.5rem;
`

export const StyledErrorPageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  min-height: 320px;
`

export const StyledErrorPageTitle = styled.div`
  color: black;
  font-size: 3.375rem;
  font-weight: bold;
  margin-top: -8vh;

  span {
    font-size: 3.125rem;
  }
`
