import React, { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  EPaymentModalTypes,
  selectPaymentModalType
} from 'astra-core/containers/BalanceProvider'

import {
  useShowPaymentModal,
  useTogglePaymentModal
} from 'hooks/useShowPaymentModal'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { IconRoundWarning } from 'shared/ui/Icon/General/IconRoundWarning'
import { Modal, ViewComponentType } from 'shared/ui/modal'

import {
  StyledCircleChecked,
  StyledCloseButton,
  StyledCloseContainer,
  StyledCommonContainer,
  StyledContentContainer,
  StyledContinueTitle,
  StyledIconCloset,
  StyledModalContentSubtitle,
  StyledModalContentTitle
} from './PaymentModal.styled'
import { PaymentModalTypes } from './PaymentModal.types'

const PaymentSuccess = memo(() => {
  const toggleMobileModal = useTogglePaymentModal()
  const { t } = useTranslation()

  return (
    <StyledCommonContainer>
      <StyledCloseContainer>
        <StyledCloseButton onClick={() => toggleMobileModal(null)}>
          <StyledIconCloset
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 26
            }}
          />
        </StyledCloseButton>
      </StyledCloseContainer>
      <StyledContentContainer>
        <StyledCircleChecked type={EPaymentModalTypes.Success}>
          <IconSuccess size={40} />
        </StyledCircleChecked>
        <StyledModalContentTitle>
          {t('payment success')}
        </StyledModalContentTitle>
        <StyledContinueTitle onClick={() => toggleMobileModal(null)}>
          {t('continue')}
        </StyledContinueTitle>
      </StyledContentContainer>
    </StyledCommonContainer>
  )
})

const PaymentCanceled = memo(() => {
  const { t } = useTranslation()
  const toggleMobileModal = useTogglePaymentModal()

  return (
    <StyledCommonContainer>
      <StyledCloseContainer>
        <StyledCloseButton onClick={() => toggleMobileModal(null)}>
          <StyledIconCloset
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 26
            }}
          />
        </StyledCloseButton>
      </StyledCloseContainer>
      <StyledContentContainer>
        <StyledCircleChecked type={EPaymentModalTypes.Canceled}>
          <IconRoundWarning size={40} />
        </StyledCircleChecked>
        <StyledModalContentTitle>
          {t('payment canceled')}
        </StyledModalContentTitle>
        <StyledModalContentSubtitle>
          {t('payment technical reasons')}
        </StyledModalContentSubtitle>
        <StyledContinueTitle onClick={() => toggleMobileModal(null)}>
          {t('payment try again')}
        </StyledContinueTitle>
      </StyledContentContainer>
    </StyledCommonContainer>
  )
})

const ModalContents: PaymentModalTypes = {
  [EPaymentModalTypes.Success]: <PaymentSuccess />,
  [EPaymentModalTypes.Canceled]: <PaymentCanceled />
}

export const PaymentModal: FC = () => {
  const togglePaymentModal = useTogglePaymentModal()
  const paymentModal = useSelector(selectPaymentModalType)
  useShowPaymentModal()

  const renderModalContent = useMemo(() => {
    return paymentModal && ModalContents[paymentModal]
  }, [paymentModal])

  return (
    <Modal
      isOpen={!!paymentModal}
      showFooter={false}
      view={ViewComponentType.NOTIFICATION}
      onModalClose={() => togglePaymentModal(null)}
    >
      {renderModalContent}
    </Modal>
  )
}
