import React, { FC, memo } from 'react'

import { Auth } from 'widgets/Auth/Auth'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { SportsMenu } from 'widgets/sports-menu'
import { Header } from 'widgets/header'
import { FooterOfPage } from 'widgets/footer-of-page'

import { LayoutProps } from '../../Layout.types'

import { StyledContent, StyledLayout } from './Game.styled'

export const LayoutGame: FC<LayoutProps> = memo(({ children }) => (
  <StyledLayout>
    <Auth />

    <Header />

    <SportsMenu />

    <NotificationsPanel />

    <StyledContent>
      {children}
      <FooterOfPage />
    </StyledContent>
  </StyledLayout>
))
