import { ERoutes } from 'shared/types/routes'
import { IRouteTranslate } from 'shared/lib/routes'
import { IconRulesProfile } from 'shared/ui/Icon/General/IconRulesProfile'

export const PROFILE_MENU_ROUTES_LIST: IRouteTranslate[] = [
  {
    path: ERoutes.Rules,
    locale: 'rules',
    icon: IconRulesProfile
  }
]
