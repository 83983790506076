import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'

import {
  EOnboardingContentPositions,
  EOnboardingType,
  TOnboardingList
} from '../../Onboarding.types'

import {
  OnboardingContentBalance,
  OnboardingContentCouponSettings,
  OnboardingContentFavouritesEvents,
  OnboardingContentHeaderLiveLine,
  OnboardingContentInputFind,
  OnboardingContentNearestEvents,
  OnboardingContentNotifications,
  OnboardingContentPersonalAccount,
  OnboardingContentSiteSettings,
  OnboardingContentTopLine,
  OnboardingContentTopLive
} from './components'

export const ONBOARDING_WELCOME_LIST: TOnboardingList = [
  {
    attrType: EWelcomeOnboardingDataAttrs.HEADER_MENU_ITEM_IS_LINE,
    additionalAttrType: EWelcomeOnboardingDataAttrs.SPORTS_MENU_TABS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentHeaderLiveLine,
    positionContent: EOnboardingContentPositions.BOTTOM_LEFT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.INPUT_FIND,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentInputFind,
    positionContent: EOnboardingContentPositions.BOTTOM_LEFT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.TOP_LIVE_SECTION,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentTopLive,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.TOP_LINE_SECTION,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentTopLine,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.NEAREST_EVENTS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentNearestEvents,
    positionContent: EOnboardingContentPositions.TOP_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.SITE_SETTINGS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentSiteSettings,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.FAVOURITES_EVENTS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentFavouritesEvents,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.NOTIFICATIONS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentNotifications,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.PERSONAL_ACCOUNT,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentPersonalAccount,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.BALANCE,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentBalance,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.COUPON_SETTINGS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentCouponSettings,
    positionContent: EOnboardingContentPositions.BOTTOM_RIGHT
  }
]
