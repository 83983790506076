import { ETeamLogo } from './TeamLogo.types'

export const getTeamLogoStyles = (isPlace?: ETeamLogo) => {
  switch (isPlace) {
    case ETeamLogo.mainPage:
      return {
        height: '16px',
        marginRight: '0px',
        width: '16px'
      }
    case ETeamLogo.eventCompleted:
      return {
        height: '64px',
        marginRight: '0px',
        width: '64px'
      }
    default:
      return {
        height: '32px',
        marginRight: '8px',
        width: '32px'
      }
  }
}
