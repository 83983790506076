import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import React, { useCallback, useContext, useState } from 'react'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { useSelector } from 'react-redux'
import { selectBasketOutcomes } from 'astra-core/containers/BasketProvider'
import { setLocalStorageItem } from 'og-web-core/utils/storage/local-storage'
import { StorageKeys } from 'og-web-core/utils/storage/constants'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'

import { BasketBody, NotAuthorizedBasket } from '../BasketBody/BasketBody'
import { BasketQuickBet } from '../BasketQuickBet/BasketQuickBet'
import { BasketClearAll } from '../BasketClearAll/BasketClearAll'

export const BasketBodyWrapper = () => {
  const isBasketQuickBetEnabled = useFeatureFlag(
    EFeatureFlags.BASKET_QUICK_BETS_ENABLED
  )
  const outcomes = useSelector(selectBasketOutcomes)
  const account = useSelector(selectAccount)

  const { isQuickBetActive, setIsQuickBetActive } = useContext(
    BasketQuickBetContext
  )
  const [isActive, setIsActive] = useState<boolean>(isQuickBetActive)

  const basketBodyVisible = !isActive

  const handleSwitchQuickBet = useCallback(() => {
    setIsActive((isActive) => {
      if (isActive) {
        setIsQuickBetActive(false)
        setLocalStorageItem(StorageKeys.isQuickBetActive, false)
      }

      return !isActive
    })
  }, [setIsQuickBetActive])

  const basketQuickBet =
    outcomes.length && isActive ? (
      <BasketClearAll onClickCancel={handleSwitchQuickBet} />
    ) : (
      <BasketQuickBet
        handleSwitchQuickBet={handleSwitchQuickBet}
        isActive={isActive}
      />
    )

  if (!account) {
    return <NotAuthorizedBasket />
  }

  return (
    <>
      {isBasketQuickBetEnabled && basketQuickBet}
      {basketBodyVisible && <BasketBody />}
    </>
  )
}
