import { css } from 'styled-components'

/* stylelint-disable property-no-vendor-prefix */
export const columnGapPrefixes = (size: string) => css`
  column-gap: ${size};
`

export const rowGapPrefixes = (size: string) => css`
  -moz-row-gap: ${size};
  -webkit-row-gap: ${size};
  row-gap: ${size};
`
