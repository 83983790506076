import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectNotificationGeneralUnread } from 'astra-core/containers/MessagesProvider'

import { ETestData } from 'shared/lib/testData'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { getIsDisplayNotificationPanel } from 'containers/LayoutContainer/selectors'

import { StyledIconMessage } from './NotificationsButton.types'

export const NotificationsButton = () => {
  const dispatch = useDispatch()
  const isDisplayNotificationPanel = useSelector(getIsDisplayNotificationPanel)
  const generalUnread = useSelector(selectNotificationGeneralUnread)

  const onToggleNotificationPanel = () => {
    dispatch(
      layoutContainerActions.toggleIsDisplayNotificationPanel(
        !isDisplayNotificationPanel
      )
    )
  }

  return (
    <StyledIconMessage
      isNewNotifications={!!generalUnread}
      size={26}
      testData={ETestData.TestMainHeaderNotificationsButton}
      onClick={onToggleNotificationPanel}
    />
  )
}
