import React from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from '../../../../components'

export const OnboardingContentTopLive = () => {
  const { t } = useTranslation()

  return (
    <OnboardingContent title="Топ LIVE">
      {t('onboarding Top Live')}
    </OnboardingContent>
  )
}
