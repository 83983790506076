import React, { FC } from 'react'
import { Event } from 'betweb-openapi-axios'

import { IconEventH2H } from 'shared/ui/Icon/Event'
import { useHandleClickH2h } from 'features/event/row/open-action-h2h/lib'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

export const OpenActionH2h: FC<{ event: Event }> = ({ event }) => {
  const { onClickH2h, eventSourceId } = useHandleClickH2h(event)

  return eventSourceId ? (
    <IconEventH2H
      colorProps={{
        name: EColorsNames.Brown,
        type: EColorsTypes.CUSTOM,
        value: 0
      }}
      size={16}
      onClick={onClickH2h}
    />
  ) : null
}
