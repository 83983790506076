import { ERoutes } from 'shared/types/routes'

export const ABOUT_PAGE_BLOCKS = [
  { key: 'block.1', locale: 'about page blocks 1' },
  { key: 'block.2', locale: 'about page blocks 2' },
  { key: 'block.3', locale: 'about page blocks 3' },
  { key: 'block.4', locale: 'about page blocks 4' }
]

export const getAboutPageDocuments = () => {
  return [
    {
      to: ERoutes.AMLPolicy,
      locale: 'aml policy'
    },
    {
      link: `License.pdf`,
      locale: 'license'
    },
    {
      to: ERoutes.ResponsibleGambling,
      locale: 'responsible gambling'
    },
    {
      to: ERoutes.RefundPolicy,
      locale: 'refund policy'
    },
    {
      to: ERoutes.PrivacyPolicy,
      locale: 'privacy policy'
    },
    {
      to: ERoutes.TermsAndConditions,
      locale: 'terms and conditions'
    }
  ]
}
