import styled, { css } from 'styled-components'

export const StyledCustomOptionBorderWrapper = styled.div`
  padding: 4px 0;
  width: calc(100% - 32px);
`

export const StyledCustomOptionBorderDash = styled.div`
  height: 1px;
  margin-left: 16px;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.colors.custom.primary[8]};
  `}
`

export const StyledCustomOptionAdditionalLabel = styled.span`
  margin-left: 4px;

  ${({ theme }) => css`
    color: ${theme.colors.custom.primary[26]};
  `}
`
