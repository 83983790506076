import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCashoutByBetId,
  selectCurrentCashout,
  betsHistoryProviderActions,
  selectIsMakeCashoutLoading,
  selectCashoutError
} from 'astra-core/containers/BetsHistoryProvider'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { formatAmountLocaleString } from 'astra-core'

import { RootState } from 'shared/types/store'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { DISABLE_HOVER_ON_CHILD } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Table/components/Cell/CellEvent/components/constants'
import { EButtonViews } from 'shared/ui/Button'

import {
  StyledBasketHistoryCashoutButtonTextWrapper,
  StyledBasketHistoryCashoutModalWholeBackground,
  StyledBasketHistoryCashoutWrapper
} from './BasketHistoryCashout.styled'
import {
  TBasketHistoryCashoutProps,
  TBasketHistoryCashoutTextProps
} from './BasketHistoryCashout.types'
import { CurrentCashoutModal } from './components'
import { MakeCashoutLoading } from './components/CurrentCashoutModal/constants'

export const BasketHistoryCashout: FC<TBasketHistoryCashoutProps> = ({
  id
}) => {
  const dispatch = useDispatch()
  const cashout = useSelector((state: RootState) =>
    selectCashoutByBetId(state, id)
  )
  const currentCashout = useSelector(selectCurrentCashout)
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)
  const isShowCurrentCashout = !!(currentCashout && currentCashout.betId === id)
  const isCashoutExist = !!(cashout && cashout.amount)
  const cashoutError = useSelector(selectCashoutError)
  const isShowCurrentCashoutModal =
    isShowCurrentCashout && !isMakeCashoutLoading

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const setCurrentCashout = (event) => {
    event.stopPropagation()

    if (isCashoutExist && !isShowCurrentCashout) {
      dispatch(
        betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
      )
    }
  }

  return isCashoutExist || !!cashoutError ? (
    <StyledBasketHistoryCashoutWrapper onClick={setCurrentCashout}>
      {isMakeCashoutLoading && isShowCurrentCashout ? (
        <MakeCashoutLoading />
      ) : isCashoutExist ? (
        <BasketHistoryCashoutText {...cashout} />
      ) : null}

      {isShowCurrentCashoutModal && (
        <>
          <CurrentCashoutModal />
          <StyledBasketHistoryCashoutModalWholeBackground
            onClick={unsetCurrentCashout}
          />
        </>
      )}
    </StyledBasketHistoryCashoutWrapper>
  ) : null
}

const BasketHistoryCashoutText: FC<TBasketHistoryCashoutTextProps> = ({
  amount
}) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)

  const hintAttr = useMemo(
    () =>
      isMakeCashoutLoading &&
      getHintAttrNameFormatted(t('currently impossible to sell a bet')),
    [isMakeCashoutLoading, t]
  )

  const text = `${t('sell for')} ${formatAmountLocaleString({
    value: amount,
    currency: currencyIcon
  })}`

  return (
    <StyledBasketHistoryCashoutButtonTextWrapper
      className={DISABLE_HOVER_ON_CHILD}
      disabled={isMakeCashoutLoading}
      view={EButtonViews.SELL_BET}
      {...hintAttr}
    >
      {text}
    </StyledBasketHistoryCashoutButtonTextWrapper>
  )
}
