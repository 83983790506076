import styled, { css } from 'styled-components'

export const StyledOutcomes = styled.div`
  height: calc(100% - 171px);
`

export const StyledOutcomesHead = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.custom.primary[2]};
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
`
export const StyledOutcomesHeadRight = styled.div`
  display: flex;

  > * {
    margin-left: 16px;
  }
`

export const StyledOutcomesInput = styled.div`
  width: 137px;
`

export const StyledOutcomesTables = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[36]};
  height: max-content;
  padding: 0.5rem;

  &:empty {
    display: none;
  }
`

export const StyledOutcomesLoaderWrap = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.default.primary[5]};
  display: flex;
  height: calc(100% - 189px);
  justify-content: center;
  min-height: 50px;
  position: relative;
  width: 100%;
`

export const StyledOutcomesTable = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.default.primary[5]};
  font-size: ${({ theme }) => theme.fonts.size.m};
`

export const StyledOutcomesTableRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.primary[5]};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
`

export const StyledOutcomesTableRowColumn = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.default.primary[5]};
  display: grid;
  grid-template-columns: minmax(0, 1fr) repeat(auto-fit, 68px);
`

export const StyledOutcomesTableTitle = styled.div`
  color: ${({ theme }) => theme.colors.default.primary[80]};
  flex: 1;
  font-size: ${({ theme }) => theme.fonts.size.m};
  margin-bottom: 1px;
  padding: 4px 8px;
`

export const StyledOutcomesTableOutcomeName = styled.div`
  color: ${({ theme }) => theme.colors.default.primary[50]};
  font-size: ${({ theme }) => theme.fonts.size.s};
  padding: 8px;
`

export const StyledPanel = styled.div<{ opened: boolean }>`
  ${({ opened, theme }) =>
    !opened
      ? css`
          border-bottom: 1px solid ${theme.colors.custom.primary[8]};
        `
      : css`
          margin-bottom: 8px;
        `}
`

export const StyledPanelHead = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: 0 1fr 16px;
  min-height: 32px;
  padding: 6px 5px;
`

export const StyledPanelTitle = styled.span`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${({ theme }) => theme.fonts.size.m};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: 20px;

  span {
    background-color: ${({ theme }) => theme.colors.default.yellow[30]};
    color: ${(props) => props.theme.colors.fixed.primary[90]};
  }
`

export const StyledFavoriteButton = styled.div`
  color: ${({ theme }) => theme.colors.default.primary[10]};
  height: 16px;
`

export const StyledOutcomesToggleDisplay = styled.div`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.default.primary[50]};
    cursor: pointer;
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
  `}
`

export const StyledOutcomesFilterTabsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[2]};
`

export const StyledClearResultSearch = styled.div`
  height: calc(100% - 171px);
`

export const StyledTableRadius = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 8px;
  overflow: hidden;
`
