import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconAndroid: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.638 3.35006C4.84642 3.55848 5.17637 3.57343 5.42498 3.41508C6.16909 2.9411 7.05258 2.6665 8.0002 2.6665C8.94781 2.6665 9.8313 2.94111 10.5754 3.41508C10.824 3.57343 11.154 3.55849 11.3624 3.35007L11.3943 3.31814C11.6026 3.10986 11.9403 3.10986 12.1486 3.31814C12.3568 3.52642 12.3568 3.86411 12.1486 4.07239L12.1166 4.10431C11.9082 4.31273 11.8933 4.64268 12.0516 4.89129C12.5256 5.6354 12.8002 6.51889 12.8002 7.4665C12.8002 7.76106 12.5614 7.99984 12.2669 7.99984H3.73353C3.43898 7.99984 3.2002 7.76106 3.2002 7.4665C3.2002 6.51888 3.4748 5.6354 3.94877 4.89128C4.10712 4.64268 4.09218 4.31273 3.88375 4.10431L3.85184 4.07239C3.64356 3.86411 3.64356 3.52642 3.85184 3.31814C4.06011 3.10986 4.3978 3.10986 4.60608 3.31814L4.638 3.35006ZM3.2002 10.0665C3.2002 9.51422 3.64791 9.0665 4.2002 9.0665H11.8002C12.3525 9.0665 12.8002 9.51422 12.8002 10.0665V12.7998C12.8002 13.0944 12.5614 13.3332 12.2669 13.3332H3.73353C3.43898 13.3332 3.2002 13.0944 3.2002 12.7998V10.0665ZM6.4002 6.39984C6.69474 6.39984 6.93353 6.16105 6.93353 5.8665C6.93353 5.57195 6.69474 5.33317 6.4002 5.33317C6.10565 5.33317 5.86686 5.57195 5.86686 5.8665C5.86686 6.16105 6.10565 6.39984 6.4002 6.39984ZM9.6002 6.39984C9.89476 6.39984 10.1335 6.16105 10.1335 5.8665C10.1335 5.57195 9.89476 5.33317 9.6002 5.33317C9.30564 5.33317 9.06686 5.57195 9.06686 5.8665C9.06686 6.16105 9.30564 6.39984 9.6002 6.39984Z"
        fill="#4CC460"
      />
    </svg>
  </Icon>
)
