import styled from 'styled-components'

export const StyledEventStatistics = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(p) => p.theme.fonts.size.xs};
  font-weight: ${(p) => p.theme.fonts.weight.normal};
  gap: 4px;
  justify-content: flex-start;
  min-width: 0;
`
