import React, { CSSProperties } from 'react'

import { ETestData } from 'shared/lib/testData'

export interface RadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  label?: string
  labelStyle?: CSSProperties
  className?: string
  view?: ERatioView
  testData?: ETestData
}

export enum ERatioView {
  DEFAULT = 'default',
  BASKET = 'basket'
}
