import { isBrowser } from 'astra-core'
import { createPortal } from 'react-dom'
import { FC, ReactNode } from 'react'

export const useLoaderWelcomeCreatePortal: FC<{
  isLoading?: boolean
  component: ReactNode
}> = ({ isLoading, component }) =>
  isLoading && isBrowser
    ? createPortal(component, document.body as Element)
    : null

// On server, we can't use createPortal. That's why this hook might return null
