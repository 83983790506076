import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  eventsProviderActions,
  selectEventProbabilitiesChannelSSEById
} from 'astra-core/containers/EventsProvider'
import { useLocation } from 'react-router-dom'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { ETestData } from 'shared/lib/testData'
import { RootState } from 'shared/types/store'
import { useInterval } from 'hooks'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { useIsTablet } from 'shared/lib/styled'
import { ERoutes } from 'shared/types/routes'

import { OutcomesCategories } from './Table'
import {
  DESKTOP_COLUMNS_COUNT,
  EVENT_OUTCOMES_POLLING_INTERVAL,
  TABLET_COLUMNS_COUNT
} from './constants'
import { OutcomesComponentType, OutcomesProps } from './Outcomes.types'
import {
  StyledOutcomes,
  StyledOutcomesLoaderWrap,
  StyledOutcomesTables
} from './Outcomes.styled'

export const Outcomes: FC<OutcomesProps> = memo(
  ({ eventId, type, isLoading = false, outcomesRef, eventStatusType }) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const isMainPage = ERoutes.Root === pathname
    const isMainPageSseEnabled = useFeatureFlag(
      EFeatureFlags.MAIN_PAGE_SSE_ENABLED
    )
    const isMainPageSse = isMainPage && isMainPageSseEnabled

    const eventProbabilitiesChannelSSE = useSelector((state: RootState) =>
      selectEventProbabilitiesChannelSSEById(state, eventId)
    )

    useEffect(() => {
      if (isMainPageSse) {
        dispatch(
          eventsProviderActions.subscribeEventProbabilitiesSSE({ eventId })
        )

        return () => {
          dispatch(
            eventsProviderActions.unsubscribeEventProbabilitiesSSE({ eventId })
          )
        }
      }
    }, [dispatch, eventId, isMainPageSse])

    const fetchEvent = useCallback(() => {
      if (!eventProbabilitiesChannelSSE) {
        dispatch(
          eventsProviderActions.fetchEvent({
            reqData: { eventId }
          })
        )
      }
    }, [dispatch, eventId, eventProbabilitiesChannelSSE])

    /*
  Add polling event outcomes only for Events List,
  since polling full event data is already enabled on Event Page
   */
    useInterval(
      fetchEvent,
      type === OutcomesComponentType.EVENTS_LIST
        ? EVENT_OUTCOMES_POLLING_INTERVAL
        : null,
      true
    )

    const isTablet = useIsTablet()

    const eventHasStatusType = eventStatusType !== null

    if (eventHasStatusType) {
      return <StyledOutcomes />
    }

    return (
      <StyledOutcomes>
        {isLoading ? (
          <StyledOutcomesLoaderWrap>
            <LoaderPoints size={6} />
          </StyledOutcomesLoaderWrap>
        ) : (
          <StyledOutcomesTables data-test-id={ETestData.TestEventTable}>
            <OutcomesCategories
              columnsCount={
                isTablet ? TABLET_COLUMNS_COUNT : DESKTOP_COLUMNS_COUNT
              }
              eventId={eventId}
              ref={outcomesRef}
            />
          </StyledOutcomesTables>
        )}
      </StyledOutcomes>
    )
  }
)
