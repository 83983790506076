import { NavLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'
import { EMenuView, StyledMenuItemProps } from 'widgets/header/Menu/Menu.types'

export const ONBOARDING_ATTR_WRAPPER_STYLES = {
  display: 'flex',
  flexDirection: 'row'
}

export const StyledMenu = styled.nav<{ view: StyledMenuItemProps['view'] }>`
  display: flex;

  ${(props) =>
    props.view === EMenuView.DARK &&
    css`
      ${columnGapPrefixes('16px')}
    `}
`

export const StyledMenuItem = styled(NavLink)<StyledMenuItemProps>`
  ${(props) => {
    const keyframeShine = keyframes`
      0% {
        background-position: 200% center;
      }
      50% {
        background-position: 170% center;
      }
      90%,
      100% {
        background-position: 0 center;
      }
    `

    const lightStyles =
      props.view === EMenuView.LIGHT &&
      css`
        color: ${props.theme.colors.default.primary[70]};
        font-weight: ${props.theme.fonts.weight.semiBold};
        line-height: 20px;
        padding: 10px 6px;
        position: relative;

        &::after {
          background: ${props.theme.colors.custom.primary[0]};
          bottom: 0;
          content: '';
          display: ${props.$isActive ? 'block' : 'none'};
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%;
        }

        &:hover {
          color: ${props.theme.colors.default.primary[90]};
        }
      `

    const darkStyles =
      props.view === EMenuView.DARK &&
      css`
        color: ${props.$isParticular
          ? props.theme.colors.fixed.yellow[props.$isActive ? 35 : 70]
          : props.$isActive
          ? props.theme.colors.fixed.primary[0]
          : props.theme.colors.custom.primary[6]};

        &:hover {
          color: ${props.$isParticular
            ? props.theme.colors.fixed.yellow[40]
            : props.theme.colors.fixed.primary[0]};
        }
      `

    const particularStyles =
      props.$isParticular &&
      css`
        -webkit-text-fill-color: transparent;
        animation: ${keyframeShine} 3s linear infinite;
        background: linear-gradient(
          120deg,
          ${props.theme.colors.fixed.yellow[70]} 45%,
          ${props.theme.colors.fixed.yellow[10]} 50%,
          ${props.theme.colors.fixed.yellow[70]} 55%
        );
        background-size: 200% auto;
        color: #000;
        text-align: center;
        text-fill-color: transparent;

        && /**For correct order **/ {
          -webkit-background-clip: text;
          background-clip: text;
        }
      `

    return css`
      align-items: center;
      display: flex;
      font-size: ${props.theme.fonts.size.xs};
      font-weight: ${props.theme.fonts.weight.bold};
      text-transform: uppercase;
      white-space: nowrap;

      ${lightStyles}
      ${darkStyles}
      ${particularStyles}
    `
  }}
`
