import styled from 'styled-components'

import { columnGapPrefixes, customMedia } from 'shared/lib/styled'

export const StyledLiveHeaderFilters = styled.div`
  align-items: center;
  display: flex;
  ${columnGapPrefixes('16px')}
`

export const StyledInputWrapper = styled.div`
  ${customMedia.small} {
    order: -1;
  }
`
