import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from '../../../../components'

export const OnboardingContentTopLine: FC = (props) => {
  const { t } = useTranslation()

  return (
    <OnboardingContent title="Топ Линия" {...props}>
      {t('onboarding Top Line')}
    </OnboardingContent>
  )
}
