import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledBasketHistoryCashoutWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
  padding-top: 8px;
  width: 100%;
`

export const StyledBasketHistoryCashoutButtonTextWrapper = styled(Button)`
  display: flex;
  height: 24px;
  justify-content: center;
  line-height: 16px;
  width: 100%;

  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledBasketHistoryCashoutModalWholeBackground = styled.div`
  cursor: default;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
`
