import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { IconCalendar } from 'shared/ui/Icon/General/IconCalendar'

import { IStyledCustomButtonRange } from './CustomButtonRange.types'

export const StyledCustomButtonRange = styled(Button)<IStyledCustomButtonRange>`
  ${(props) => css`
    background: ${props.isSelected
      ? props.theme.colors.default.primary[10]
      : props.theme.colors.default.primary[5]};
    border: 1px solid ${props.theme.colors.default.primary[5]};
    color: ${props.theme.colors.custom.primary[30]};

    &:hover {
      background: ${(props) => props.theme.colors.default.primary[10]};
    }
  `}
`

export const StyledIconCalendar = styled(IconCalendar)`
  margin-right: 7px;
`

export const StyledButtonResetRange = styled(Button)`
  margin-left: 8px;
`

export const StyledCustomButtonRangeWrapper = styled.div`
  align-items: center;
  display: flex;
  line-height: 16px;

  ${({ theme }) => css`
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
