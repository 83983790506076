import {
  getMessagesPanelFetchItems,
  getMessagesPanelHasMore,
  getMessagesPanelPagination,
  messagesProviderActions
} from 'astra-core/containers/MessagesProvider'

import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS: DataListWithReduxPaginationProps['pagination'] =
  {
    setPaginationAction: messagesProviderActions.setPaginationPanel,
    selectorHasMore: getMessagesPanelHasMore,
    selectorPagination: getMessagesPanelPagination,
    selectorRequestStatus: getMessagesPanelFetchItems
  }
