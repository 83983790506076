import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BasketQuickBetContext,
  QuickBetModalsTypes
} from 'og-web-core/providers/basket/quick-bet'
import { QuickBetOutcome } from 'astra-core/containers/BasketProvider'

import { Modal, ViewComponentType } from 'shared/ui/modal'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'

import { modalsTitles } from './constants'
import {
  StyledContent,
  StyledQuickBetModalTitle,
  StyledQuickBetModalTitleWrapper,
  StyledQuickBetModalWrapper
} from './quick-bet-modal.styled'
import { ConfirmQuickContent } from './components/ConfirmQuickContent'
import { CoefficientChangedContent } from './components/CoefficientChangedContent'
import { LowerBalanceContent } from './components/LowerBalanceContent'

export const QuickBetModalContent: FC<{
  modal: { type: QuickBetModalsTypes; data?: QuickBetOutcome }
}> = ({ modal }) => {
  const { outcome, setModal, quickBetValue } = useContext(BasketQuickBetContext)
  const onClickClose = () => {
    setModal(null)
  }

  switch (modal.type) {
    case QuickBetModalsTypes.confirmQuickBet: {
      return modal.data ? (
        <ConfirmQuickContent
          outcome={modal.data}
          quickBetValue={quickBetValue}
          onClickClose={onClickClose}
        />
      ) : null
    }

    case QuickBetModalsTypes.coefficientChanged: {
      return outcome ? (
        <CoefficientChangedContent
          outcome={outcome}
          onClickClose={onClickClose}
        />
      ) : null
    }

    case QuickBetModalsTypes.lowerBalance: {
      return <LowerBalanceContent onClickClose={onClickClose} />
    }

    default: {
      return null
    }
  }
}

export const QuickBetModal: FC = () => {
  const { t } = useTranslation()

  const { modal, setModal } = useContext(BasketQuickBetContext)

  const onClickClose = () => {
    setModal(null)
  }

  if (!modal) return null

  return (
    <Modal isOpen={!!modal} view={ViewComponentType.FIRST}>
      <StyledQuickBetModalWrapper>
        <StyledQuickBetModalTitleWrapper>
          <StyledQuickBetModalTitle>
            {t(modalsTitles[modal.type])}
          </StyledQuickBetModalTitle>
          <IconClose
            colorProps={{ name: EColorsNames.Primary, value: 30 }}
            size={10}
            onClick={onClickClose}
          />
        </StyledQuickBetModalTitleWrapper>
        <StyledContent>
          <QuickBetModalContent modal={modal} />
        </StyledContent>
      </StyledQuickBetModalWrapper>
    </Modal>
  )
}
