import styled, { css } from 'styled-components'

export const StyledScore = styled.div<{ isAdaptive?: boolean }>`
  color: ${(props) => props.theme.colors.custom.blue[13]};
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  white-space: nowrap;

  ${(props) =>
    !props.isAdaptive &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`
