import React from 'react'
import { useSelector } from 'react-redux'
import { selectActiveLoyaltyLength } from 'astra-core/containers/AccountProvider'

import { ETestData } from 'shared/lib/testData'
import { ERoutes } from 'shared/types/routes'

import { useHandleRedirectList } from '../../hooks'

import {
  StyledActiveBonusCouponButtonValue,
  StyledIconBonusCouponButton
} from './BonusCouponButton.styled'

export const BonusCouponButton = () => {
  const handleRedirectList = useHandleRedirectList()
  const activeLoyaltyLength = useSelector(selectActiveLoyaltyLength)

  return (
    <>
      <StyledIconBonusCouponButton
        size={30}
        testData={ETestData.TestMainHeaderBonusCouponButton}
        onClick={handleRedirectList(ERoutes.PromotionsAndBonuses)}
      />
      <StyledActiveBonusCouponButtonValue
        activeLoyaltyLength={activeLoyaltyLength}
        onClick={handleRedirectList(ERoutes.PromotionsAndBonuses)}
      >
        {activeLoyaltyLength}
      </StyledActiveBonusCouponButtonValue>
    </>
  )
}
