import { useCallback, useMemo } from 'react'

import { getEventBetradarSourceId } from 'shared/lib/events'

export const useHandleClickH2h = (
  event: Parameters<typeof getEventBetradarSourceId>[0]
) => {
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const onClickH2h = useCallback(
    () => window.open(`/headToHead/${eventSourceId}`, 'head2head'),
    [eventSourceId]
  )

  return { onClickH2h, eventSourceId }
}
