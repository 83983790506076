import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { IconHomepageNotifications } from 'shared/ui/Icon/General/IconHomepageNotifications'

export const StyledIconMessage = styled(IconHomepageNotifications)`
  ${(props) => css`
    cursor: pointer;

    & svg circle {
      stroke: #0d0d0d;
      fill: ${props.theme.colors.default.red[40]};
    }

    ${({ theme }) => `
  ${
    theme.typeLayoutTheme === LayoutTheme.Light &&
    `
    & svg circle {
      stroke: #fff;
      fill: #F2200D;
    }
    & svg path {
      fill: #1A1A1A;
    }`
  }
  `};
    &:hover svg path {
      fill: ${(props) => props.theme.colors.default.primary[90]} !important;
    }
  `}
`
