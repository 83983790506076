import React from 'react'
import { useSelector } from 'react-redux'
import { selectFavouriteEventsCount } from 'astra-core/containers/FavouritesProvider'

import { ETestData } from 'shared/lib/testData'
import { ERoutes } from 'shared/types/routes'
import { IconStar } from 'shared/ui/Icon/General/IconStar'
import { EColorsNames } from 'shared/types/theme'

import { useHandleRedirectList } from '../../hooks'

import { StyledButtonFavourite } from './FavouritesButton.styled'

export const FavouritesButton = () => {
  const handleRedirectList = useHandleRedirectList()
  const favouriteEventsCount = useSelector(selectFavouriteEventsCount)

  return (
    <StyledButtonFavourite
      data-test-id={ETestData.TestMainHeaderFavouriteButton}
      onClick={handleRedirectList(ERoutes.FavouriteEvents)}
    >
      <IconStar
        colorProps={{ name: EColorsNames.Yellow, value: 50 }}
        size={19}
      />
      {favouriteEventsCount}
    </StyledButtonFavourite>
  )
}
