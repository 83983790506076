import React from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { getCurrentLng } from 'astra-core'
import { useLoadLocaleDayjs } from 'astra-core/hooks/useLocaleDayjs'
import { AuthMessageProvider } from 'astra-core/containers/AuthMessageProvider'
import {
  EFeatureFlags,
  selectConfig,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { BasketQuickBetProvider } from 'og-web-core/providers/basket/quick-bet'

import { useDisplayWelcomeScreen } from 'hooks/useDisplayWelcomeScreen'
import { useSetStorage } from 'hooks/useSetStorage'
import { PaymentModal } from 'pages/page-balance/Balance/components/Payment/components/PaymentModal'
import { OnboardingPortal } from 'widgets/onboarding' // Injecting static styles, such as fonts, etc
import { LoaderWelcome } from 'widgets/loader-welcome'
import { ToastifyCashoutModal } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Table/components/Cell/CellEvent/components'
import { LowerBalanceModal } from 'widgets/lower-balance-modal'
import { ConfirmQuickBetModal } from 'widgets/confirm-quick-bet-modal'
import { CoefficientChangedModal } from 'widgets/coefficient-changed-modal'
import { QuickBetModal } from 'widgets/quick-bet-modal'

import {
  ThemeProvider,
  GlobalStyle,
  ErrorBoundary,
  AppRoutes
} from './providers'
import { useProviders } from './lib/providers'

import './styles/index.css'

const App = ({ routes }) => {
  useProviders()
  useSetStorage()
  useLoadLocaleDayjs()
  const config = useSelector(selectConfig)

  const [isLoaderDisplay] = useDisplayWelcomeScreen()
  const quickBetsImprovementsEnabled = useFeatureFlag(
    EFeatureFlags.BASKET_QUICK_BETS_IMPROVEMENTS_ENABLED
  )

  return (
    <ThemeProvider>
      <GoogleReCaptchaProvider
        language={getCurrentLng()}
        reCaptchaKey={config.CAPTCHA_KEY}
      >
        <AuthMessageProvider>
          <LoaderWelcome isLoading={isLoaderDisplay} />
          <GlobalStyle />
          <PaymentModal />
          <ToastifyCashoutModal />
          <BasketQuickBetProvider>
            {quickBetsImprovementsEnabled ? (
              <QuickBetModal />
            ) : (
              <>
                <LowerBalanceModal />
                <ConfirmQuickBetModal />
                <CoefficientChangedModal />
              </>
            )}
            <Switch>
              <ErrorBoundary>
                <AppRoutes routes={routes} />
              </ErrorBoundary>
            </Switch>
          </BasketQuickBetProvider>

          <OnboardingPortal />
        </AuthMessageProvider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  )
}

// eslint-disable-next-line import/no-default-export
export default App
