import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectBasketOutcomesLength,
  selectDisabledButtonOrdinar,
  selectMakeBetErrorsBottom,
  selectOrdinarOutcomeErrors,
  selectOrdinarSingleAmount,
  selectOutcomeStakeAmount
} from 'astra-core/containers/BasketProvider'
import { activeEventStatus } from 'astra-core/containers/BasketProvider/constants'
import {
  getIsEventPending,
  validateBasketInputValue,
  formatAmount as formatAmountCore,
  markEvent,
  measureAddingOutcomeToBasket,
  EPerformanceEvent
} from 'astra-core'

import { ETestData } from 'shared/lib/testData'
import { RootState } from 'shared/types/store'

import {
  BasketOutcomeSingleError,
  BasketButton,
  BasketError,
  BetCardCoupon,
  CommonBlockClearAll
} from '../../BasketCommon/Common'
import { BasketBonusCoupons } from '../../BasketCommon/components'
import BasketInput from '../../BasketCommon/components/BasketInput'

import {
  StyledBetCardElementBorder,
  StyledBetCardElementWrapper,
  StyledSingleBetAmountInput,
  StyledSingleBetAmountWrapper
} from './Ordinar.styled'
import {
  BetCardElementProps,
  OrdinarProps,
  SingleBetAmountProps
} from './Ordinar.types'

export const Ordinar: FC<OrdinarProps> = ({ outcomes }) => {
  const disabledButton = useSelector(selectDisabledButtonOrdinar)
  const errorsBottom = useSelector(selectMakeBetErrorsBottom)
  const outcomesLength = useSelector(selectBasketOutcomesLength)

  useEffect(() => {
    console.log('Ordinar rendered')
    markEvent(EPerformanceEvent.BasketModalRendered) // Performance Tests
    measureAddingOutcomeToBasket() // Performance Tests Output
  }, [])

  return (
    <>
      {outcomes.map((outcome) => (
        <BetCardElement key={outcome.id} outcome={outcome} />
      ))}

      <StyledBetCardElementBorder />

      {outcomesLength > 1 && <CommonBlockClearAll />}

      <BasketBonusCoupons />

      {errorsBottom.map((error) => (
        <BasketError error={error} key={error.code} />
      ))}

      <BasketButton disabledButton={disabledButton} />
    </>
  )
}

const BetCardElement: FC<BetCardElementProps> = ({ outcome }) => {
  const {
    probability: { odd, tradingStatus },
    id: outcomeId,
    event: { status: eventStatus },
    status,
    maxBet
  } = outcome
  const dispatch = useDispatch()
  const outcomeErrors = useSelector(selectOrdinarOutcomeErrors)
  const stakeAmount = useSelector((state: RootState) =>
    selectOutcomeStakeAmount(state, outcomeId)
  )
  const isBonusWallet = useSelector(selectBasketBonusWallet)

  const isEventActive = useMemo(
    () => activeEventStatus.includes(eventStatus),
    [eventStatus]
  )

  const winSum = useMemo(
    () => (stakeAmount !== '' && +stakeAmount * (odd - +isBonusWallet)) || 0,
    [stakeAmount, odd, isBonusWallet]
  )

  const setOutcomeAmountNew = useCallback(
    (value: string) => {
      dispatch(
        basketProviderActions.setOrdinarAmount({
          outcomeId,
          amount: value.replace(',', '.'),
          maxBet
        })
      )
    },
    [dispatch, outcomeId, maxBet]
  )

  const isEventPending = useMemo(
    () => getIsEventPending(status, eventStatus, tradingStatus),
    [eventStatus, status, tradingStatus]
  )

  const outcomeError = useMemo(
    () => outcomeErrors[outcomeId]?.message,
    [outcomeErrors, outcomeId]
  )

  const showInput = isEventActive && !isEventPending

  return (
    <StyledBetCardElementWrapper>
      <BetCardCoupon outcome={outcome} />
      {showInput && (
        <>
          <BasketInput
            isBonusWallet={isBonusWallet}
            maxBet={maxBet}
            stakeAmount={stakeAmount}
            testData={ETestData.TestBasketBetCardInput}
            winSum={winSum}
            onAmountChange={setOutcomeAmountNew}
          />
          {outcomeError && <BasketOutcomeSingleError message={outcomeError} />}
        </>
      )}
    </StyledBetCardElementWrapper>
  )
}

export const SingleBetAmount: FC<SingleBetAmountProps> = memo(() => {
  const dispatch = useDispatch()
  const singleAmount = useSelector(selectOrdinarSingleAmount)

  const setSingleAmountForEachOutcome = useCallback(
    (e) => {
      dispatch(
        basketProviderActions.setSingleAmountForEachOutcome({
          amount: validateBasketInputValue(e)
        })
      )
    },
    [dispatch]
  )

  return (
    <StyledSingleBetAmountWrapper>
      <StyledSingleBetAmountInput
        data-test-id={ETestData.TestTotalBetSizeInput}
        value={formatAmountCore({ value: singleAmount })}
        onChange={setSingleAmountForEachOutcome}
      />
    </StyledSingleBetAmountWrapper>
  )
})
