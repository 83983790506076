import styled from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

export const StyledTabItemWrapper = styled.div`
  border: 1px solid
    ${(props) => props.theme.colors.custom[EColorsNames.Primary][4]};
  border-radius: 4px;
  margin-right: 6px;
`
