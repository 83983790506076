import React, { FC, memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ETestData, getCreateTestData } from 'shared/lib/testData'
import { ERoutes } from 'shared/types/routes'
import { isActiveCurrentPath } from 'shared/lib/routes'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import {
  ONBOARDING_ATTR_WRAPPER_STYLES,
  StyledMenu,
  StyledMenuItem
} from 'widgets/header/Menu/Menu.styled'

import { MenuItemProps, MenuProps } from './Menu.types'

export const Menu: FC<MenuProps> = ({ className, list, view }) => {
  const { pathname } = useLocation()

  return (
    <StyledMenu className={className} view={view}>
      {list.map((item) => (
        <MenuItem
          isActive={isActiveCurrentPath(pathname, item.path)}
          key={item.name}
          route={item}
          testData={getCreateTestData(ETestData.TestFixedHeaderMenu, item.name)}
          view={view}
        />
      ))}
    </StyledMenu>
  )
}

export const MenuMainHeader: FC<MenuProps> = ({ className, list, view }) => {
  const { pathname } = useLocation()
  const { OnboardingAttrWrapper } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.HEADER_MENU_ITEM_IS_LINE,
    EOnboardingType.MAIN_PAGE_WELCOME
  )

  const lineLiveList = list.slice(0, 2)
  const otherList = list.slice(2)

  return (
    <StyledMenu className={className} view={view}>
      <OnboardingAttrWrapper style={ONBOARDING_ATTR_WRAPPER_STYLES}>
        {lineLiveList.map((item) => (
          <MenuItem
            testData={getCreateTestData(
              ETestData.TestMainHeaderMenu,
              item.name
            )}
            isActive={isActiveCurrentPath(pathname, item.path)}
            key={item.name}
            route={item}
            view={view}
          />
        ))}
      </OnboardingAttrWrapper>

      {otherList.map((item) => (
        <MenuItem
          isActive={isActiveCurrentPath(pathname, item.path)}
          key={item.name}
          route={item}
          testData={getCreateTestData(ETestData.TestMainHeaderMenu, item.name)}
          view={view}
        />
      ))}
    </StyledMenu>
  )
}
const MenuItem: FC<MenuItemProps> = memo(
  ({ route, view, isActive, testData }) => {
    const isParticularItem = useMemo(
      () => route.path === ERoutes.Casino,
      [route.path]
    )
    return (
      <StyledMenuItem
        $isActive={isActive}
        $isParticular={isParticularItem}
        data-test-id={testData}
        exact={route.exact}
        key={route.name}
        to={route.path}
        view={view}
      >
        {route.translatedName || route.name}
      </StyledMenuItem>
    )
  }
)
