import styled from 'styled-components'

export const StyledDatePicker = styled.div`
  .react-datepicker__header {
    background: transparent;
    border-bottom: none;
  }

  .react-datepicker {
    background-color: ${(props) => props.theme.colors.custom.primary[10]};
    border: none;
    box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  }

  .react-datepicker__day:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[19]};
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker__day-name {
    color: ${(props) => props.theme.colors.custom.primary[35]};
  }

  .react-datepicker__week .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${(props) => props.theme.colors.default.primary[90]};
    font-size: 11px;
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${(props) => props.theme.colors.default.primary[90]};
    border-width: 1px 1px 0 0;
    height: 7px;
    top: 19px;
    width: 7px;
  }

  .react-datepicker__current-month {
    color: ${(props) => props.theme.colors.default.primary[90]};
    font-size: ${(props) => props.theme.fonts.size.s};
    font-weight: 500;
    padding: 12px 0 10px;
  }

  .react-datepicker__day--selected {
    background: ${(props) => props.theme.colors.default.primary[5]};
  } /* Selected specific date */

  .react-datepicker__day--today {
    background: ${(props) => props.theme.colors.custom.primary[19]};
    border-radius: 0.3rem !important;
  } /* Current date */

  .react-datepicker__day--today:hover {
    background: ${(props) => props.theme.colors.default.primary[50]};
  } /* Current date hover */

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 1.866rem !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0 0.166rem;
    width: 1.866rem !important;
  } /* styles for all items */

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--selecting-range-start
    ) {
    background: ${(props) => props.theme.colors.custom.primary[4]} !important;
    border-radius: 0 !important;
  } /* all selected and selected but don't clicked range items */

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-start:hover {
    background: ${(props) => props.theme.colors.custom.primary[19]} !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  } /* first selected range item */

  .react-datepicker__day--range-end,
  .react-datepicker__day--range-end:hover {
    background: ${(props) => props.theme.colors.custom.primary[19]} !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  } /* last selected range item */

  .react-datepicker__day--selecting-range-start {
    background: ${(props) => props.theme.colors.custom.primary[19]} !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  } /* first selected but don't clicked range item */

  .react-datepicker__day.react-datepicker__day--disabled[aria-disabled='true'] {
    color: ${(props) => props.theme.colors.fixed.primary[50]};
  }
  .react-datepicker__day.react-datepicker__day--disabled[aria-disabled='true']:hover {
    background: none;
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day:not(
      .react-datepicker__day--selecting-range-start
    ):hover {
    border-bottom-right-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  } /* imitation last selected but don't clicked range item */

  .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
    border-radius: 0.3rem !important;
  } /* specific selected item */

  .react-datepicker__day.react-datepicker__day--in-selecting-range:last-of-type,
  .react-datepicker__day.react-datepicker__day--in-range:last-of-type {
    border-bottom-right-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }

  .react-datepicker__day.react-datepicker__day--in-range:first-of-type,
  .react-datepicker__day.react-datepicker__day--in-selecting-range:first-of-type {
    border-bottom-left-radius: 0.3rem !important;
    border-top-left-radius: 0.3rem !important;
  }
`
