import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconCheckMark: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM10.7204 5.69359C11.1034 5.29575 11.0914 4.6627 10.6936 4.27963C10.2957 3.89657 9.6627 3.90856 9.27963 4.30641L6.04008 7.671L4.71883 6.30481C4.33489 5.90782 3.70181 5.89723 3.30481 6.28117C2.90782 6.66511 2.89723 7.29819 3.28117 7.69518L5.32284 9.8063C5.51151 10.0014 5.77137 10.1114 6.04277 10.1111C6.31417 10.1108 6.57379 10.0002 6.76203 9.80471L10.7204 5.69359Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
