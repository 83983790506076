import React, { FC, useMemo } from 'react'

import { IconLivePoint } from 'shared/ui/Icon/General/IconLivePoint'
import { EColorsNames } from 'shared/types/theme'

import { EventStatisticSelectRender } from '../EventStatisticSelectRender'
import { getRenderedStatistics } from '../../utils'
import {
  EEventStatisticsCodes,
  TGetRenderedStatisticsStatistics
} from '../../EventStatistics.types'

import { EVENT_STATISTIC_BLOCK_LIVE_TIME_LIST } from './constants'
import { IEventStatisticBlockLiveTimeProps } from './EventStatisticBlockLiveTime.types'
import { StyledEventStatisticBlockLiveTime } from './EventStatisticBlockLiveTime.styled'

export const EventStatisticBlockLiveTime: FC<
  IEventStatisticBlockLiveTimeProps
> = ({ event, showLiveIcon }) => {
  const renderedStatistics = useMemo(
    () =>
      getRenderedStatistics(
        event.statistics as TGetRenderedStatisticsStatistics,
        EVENT_STATISTIC_BLOCK_LIVE_TIME_LIST
      ),
    [event.statistics]
  )

  if (!renderedStatistics.length && !showLiveIcon) {
    return null
  } else {
    return (
      <StyledEventStatisticBlockLiveTime>
        {showLiveIcon && (
          <IconLivePoint
            colorProps={{ name: EColorsNames.Red, value: 50 }}
            size={8}
          />
        )}

        {renderedStatistics.map((item) => (
          <EventStatisticSelectRender
            code={item.code as EEventStatisticsCodes}
            key={item.code}
            sportId={event.tournament.sportId}
            value={item.value}
          />
        ))}
      </StyledEventStatisticBlockLiveTime>
    )
  }
}
