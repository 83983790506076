import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Event, MarketGroup } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'
import { selectMarketGroupsByEvent } from 'astra-core/containers/EventsProvider/selectors'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { RootState } from 'shared/types/store'
import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { getSelectedOutcomesGroupId } from 'containers/OutcomesContainer/selectors'
import { ETestData, getTruncateAfterDash } from 'shared/lib/testData'

import { StyledTabItemWrapper } from './OutcomesGroupsTabs.styled'

type OutcomesFilterTabsProps = {
  event: Event
}

export const OutcomesGroupsTabs: FC<OutcomesFilterTabsProps> = ({ event }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const selectedMarketGroupId = useSelector((state: RootState) =>
    getSelectedOutcomesGroupId(state, event.id)
  )
  const marketGroups = useSelector(
    (state) => selectMarketGroupsByEvent(state, event.id),
    isEqual
  )

  useEffect(() => {
    if (
      !!selectedMarketGroupId &&
      !marketGroups.find(
        (marketGroup) => marketGroup.id === selectedMarketGroupId
      )
    ) {
      // If the selected market group becomes empty, select 'all' market group
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup({
          marketGroup: null,
          eventId: event.id
        })
      )
    }
  }, [dispatch, marketGroups, selectedMarketGroupId, event.id])

  useEffect(() => {
    return () => {
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup({
          marketGroup: null,
          eventId: event.id
        })
      )
    }
  }, [dispatch, event.id])

  const handleSportTypeClick = useCallback(
    (marketGroup: MarketGroup | null) => () =>
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup({
          marketGroup,
          eventId: event.id
        })
      ),
    [dispatch, event.id]
  )

  return (
    <Tabs variant={2} combined>
      {!!marketGroups.length && (
        <StyledTabItemWrapper>
          <TabsItem
            active={!selectedMarketGroupId}
            handleTabChange={handleSportTypeClick(null)}
            testData={ETestData.TestEventTabAll}
          >
            {t('all')}
          </TabsItem>
        </StyledTabItemWrapper>
      )}

      {marketGroups.map((group) => (
        <StyledTabItemWrapper key={group.id}>
          <TabsItem
            testData={`${ETestData.TestEventTab}-${getTruncateAfterDash(
              group?.id
            )}`}
            active={group.id === selectedMarketGroupId}
            handleTabChange={handleSportTypeClick(group)}
          >
            {group?.name}
          </TabsItem>
        </StyledTabItemWrapper>
      ))}
    </Tabs>
  )
}
