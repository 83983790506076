import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'

export const StyledBasketQuickBetButton = styled.div<{
  isRouteWithPermanentHiddenBasket: boolean
}>`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 6px 8px;

  ${({ isRouteWithPermanentHiddenBasket }) =>
    !isRouteWithPermanentHiddenBasket
      ? css`
          display: none;
        `
      : ''}

  ${customMedia.small} {
    display: flex;
  }

  ${(props) => css`
    background-color: ${props.theme.colors.default.blue[50]};
    color: ${props.theme.colors.fixed.primary[0]};
    font-size: ${props.theme.fonts.size.m};

    &:hover {
      background-color: ${props.theme.colors.default.blue[1]};
    }

    &:active {
      background-color: ${props.theme.colors.default.blue[70]};
    }

    &:disabled {
      background-color: ${props.theme.colors.custom.blue[27]};
      color: ${props.theme.colors.custom.primary[26]};
    }
  `};
`

export const StyledBasketQuickBetButtonLine = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.fixed.primary[0]};
  height: 12px;
  margin: 0 8px;
  width: 0;
`

export const StyledBasketQuickBetButtonIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 4px;
`

export const StyledBasketQuickBetButtonText = styled.div``
export const StyledBasketQuickBetButtonLoaderWrapper = styled.div`
  padding: 0 14px;
`
