import styled, { css } from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'

export const StyledUserAccountBonus = styled.button`
  border: none;
  ${columnGapPrefixes('2px')};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  line-height: 16px;
  outline: none;
  padding: 2px 2px 2px 6px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.yellow[4]};
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.semiBold};
    `}
`
