import { ETestData } from 'shared/lib/testData'

export interface LoaderPointsMiniProps {
  text?: string
  type: ELoaderPointsMiniTypesSizes
  testData?: ETestData
}

export enum ELoaderPointsMiniTypesSizes {
  S = 's',
  M = 'm'
}

export type StyledLoaderPointsMiniProps = LoaderPointsMiniProps
