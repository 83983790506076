import { useEffect, useRef } from 'react'

export const useInterval = (
  callback: () => void,
  delay: number | null,
  isInitialCall?: boolean
) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isInitialCall) {
      savedCallback.current()
    }
    // Don't schedule if no delay is specified.
    if (delay !== null) {
      const tick = () => {
        savedCallback.current()
      }

      const intervalId = setInterval(tick, delay)

      return () => clearInterval(intervalId)
    }
  }, [delay, isInitialCall])
}
