import { useCallback, useEffect, useRef, useState } from 'react'
import { CSSProperties } from 'styled-components'

export const useFontOptions = () => {
  const stringRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [options, setOptions] = useState<CSSProperties>({
    fontSize: 12,
    lineClamp: 1,
    whiteSpace: 'normal'
  })

  const setTitleFontSize = useCallback(() => {
    if (stringRef.current && containerRef.current) {
      const stringRect = stringRef.current?.getBoundingClientRect()
      const containerRect = containerRef.current?.getBoundingClientRect()

      if (
        stringRect.width + 5 > containerRect.width ||
        stringRect.height > containerRect.height
      ) {
        setOptions((prev) => ({ ...prev, fontSize: 10 }))
      }

      if (stringRect.height > containerRect.height + 10) {
        setOptions((prev) => ({ ...prev, whiteSpace: 'nowrap' }))
      }
    }
  }, [])

  useEffect(() => {
    setTitleFontSize()
  }, [setTitleFontSize])

  return {
    options,
    stringRef,
    containerRef
  }
}
