import styled from 'styled-components'

export const StyledButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  left: 16px;
  position: absolute;
  top: 4px;
`

export const StyledButtonLink = styled.a`
  align-items: center;
  display: flex;
  gap: 4px;
  height: 24px;
  padding: 4px;
  width: 74px;
`
export const StyledText = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
`
