import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventVideo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14 4C14 3.44772 13.5523 3 13 3H3C2.44772 3 2 3.44772 2 4V12C2 12.5523 2.44771 13 3 13H13C13.5523 13 14 12.5523 14 12V4ZM11 8L6 5V11L11 8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
