import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLiveTracker: FC<IconProps> = (props) => (
  <Icon size={16} {...props}>
    <svg fill="none" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M7.27308 12V0H2C0.895431 0 0 0.895431 0 2V2.25007C0.000118334 2.25007 0.000236669 2.25007 0.000355005 2.25007C2.00866 2.25007 3.63672 3.929 3.63672 6.00007C3.63672 8.07114 2.00866 9.75007 0.000355005 9.75007C0.000236669 9.75007 0.000118334 9.75007 0 9.75007V10C0 11.1046 0.895431 12 2 12H7.27308ZM8.72763 12H14C15.1046 12 16 11.1046 16 10V9.75007C13.9919 9.74987 12.364 8.07101 12.364 6.00007C12.364 3.92912 13.9919 2.25027 16 2.25007V2C16 0.895431 15.1046 0 14 0H8.72763V12ZM16 4.25007C15.154 4.25027 14.364 4.97526 14.364 6.00007C14.364 7.02488 15.154 7.74987 16 7.75007V4.25007ZM0 7.75007C0.000118334 7.75007 0.000236669 7.75007 0.000355005 7.75007C0.846528 7.75007 1.63672 7.02503 1.63672 6.00007C1.63672 4.97511 0.846528 4.25007 0.000355005 4.25007C0.000236669 4.25007 0.000118334 4.25007 0 4.25007V7.75007Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
