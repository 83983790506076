import styled from 'styled-components'

import { columnGapPrefixes } from 'shared/lib/styled'

export const StyledUserAccountBalance = styled.button`
  border: none;
  ${columnGapPrefixes('5px')};
  display: flex;
  outline: none;
`
