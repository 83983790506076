import { Props as SelectProps } from 'react-select/src/Select'
import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { Input } from 'shared/ui/Input'
import { Select } from 'shared/ui/Select'

export const StyledContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0;
`
export const StyledContainerBody = styled.div`
  position: relative;
`

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 50%;
`
export const InputRange = styled(Input)`
  padding: 9px 8px;
  width: 90px;

  ${(props) =>
    props.theme.typeLayoutTheme === LayoutTheme.Dark &&
    css`
      background-color: ${props.theme.colors.fixed.primary[85]} !important;
    `}

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`

export const SelectRange = styled(Select)<SelectProps>`
  position: relative;
  width: 150px;
  z-index: 5;

  &.selectRange {
    div:nth-of-type(2) {
      min-width: 100%;
      top: 36px;
    }
  }
`

export const StyledDashWrapper = styled.div`
  padding: 0 4px;

  &::after {
    color: ${(props) => props.theme.colors.default.primary[90]};
    content: '-';
    display: block;
  }
`
