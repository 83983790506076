import { TFunction } from 'i18next'

type TodayValueParams = {
  date: Date
  addText?: string
  t: TFunction
}

export const getIsTodayValue = ({
  date,
  t,
  addText = ''
}: TodayValueParams): string => {
  const isToday = (date: Date) => {
    const today = new Date()

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  if (isToday(date)) {
    return `${t('today')}${addText}`
  }
  return ''
}
