import { createContext, useContext } from 'react'

export interface LayoutContextProps {
  hideHeaderResults?: boolean
}

export const LayoutResultsContext = createContext<LayoutContextProps>({
  hideHeaderResults: false
})

export const useLayoutContext = () => useContext(LayoutResultsContext)
