import { EBetTypes } from 'astra-core/containers/BasketProvider/types'
import styled, { css } from 'styled-components'

import {
  StyledDynamicInput,
  StyledDynamicInputWrapper,
  StyledHiddenSpan
} from 'shared/ui/DynamicInput/DynamicInput.styled'

const BASKET_INPUT_STYLE_VALUES = {
  [EBetTypes.Ordinar]: {
    height: '24px'
  },
  [EBetTypes.Express]: {
    height: '32px'
  },
  [EBetTypes.System]: {
    height: '32px'
  }
}

export const StyledBasketInputContainer = styled.div`
  margin: 4px 16px;
  position: relative;
  width: 248px;
`

export const StyledBetPlaceholder = styled.div``

export const StyledBasketInputWrapper = styled.div<{
  betTypeTab: EBetTypes
  isAppliedCoupon: boolean
}>`
  ${({ theme, isAppliedCoupon }) =>
    css`
      ${isAppliedCoupon
        ? css`
            background-color: ${theme.colors.custom.blue[16]};
            border-color: ${theme.colors.custom.blue[22]};
            color: ${theme.colors.custom.blue[17]};
            justify-content: end;
            ${StyledBetPlaceholder} {
              display: none;
            }
          `
        : css`
            background: ${theme.colors.default.primary[0]};
            border-color: ${theme.colors.default.primary[20]};
            cursor: pointer;
            justify-content: space-between;
            &:hover {
              border-color: ${theme.colors.custom.primary[14]};
            }

            &:focus {
              border-color: ${theme.colors.default.primary[90]};
            }
          `}
    `}

  ${({ theme, betTypeTab }) => css`
    ${BASKET_INPUT_STYLE_VALUES[betTypeTab]};
    align-items: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    font-size: 16px;
    overflow: hidden;
    padding: 5px 6px 5px 8px;
    position: relative;

    &:disabled {
      border: 1px solid ${theme.colors.default.primary[5]};

      &::placeholder {
        color: ${theme.colors.custom.primary[34]};
      }
    }

    &:focus-within {
      border: 1px solid ${theme.colors.default.primary[90]};
    }
  `}
`

export const StyledBetCurrency = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    line-height: 16px;
    margin-left: 2px;
  `}
`

export const StyledBetInputWrapper = styled.div`
  display: flex;

  ${StyledDynamicInputWrapper} {
    height: 16px;
  }

  ${StyledHiddenSpan} {
    ${({ theme }) => css`
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
    `}
  }

  ${StyledDynamicInput} {
    background: transparent;
    border: none;
    ${({ theme }) => css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      &::placeholder {
        color: ${theme.colors.default.primary[90]};
      }
    `}
    line-height: 16px;
    outline: none;
    text-align: end;
    width: 100%;
    &:focus::placeholder {
      color: transparent;
    }
  }
`
