import styled from 'styled-components'

import { Input } from 'shared/ui/Input'
import { StyledIcon } from 'shared/ui/Icon'
import { Select } from 'shared/ui/Select'
import { customMedia } from 'shared/lib/styled'

import { IStyledFilterStream } from './Filters.types'

export const StyledFilters = styled.div`
  align-items: center;
  column-gap: 15px;
  display: flex;
  position: relative;
`

export const StyledFilterStream = styled.button<IStyledFilterStream>`
  align-items: center;
  background: ${(props) => props.theme.colors.default.primary[5]};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  outline: none;
  padding: 5px;
  width: 24px;

  & path {
    color: ${(props) => props.theme.colors.default.primary[30]};
  }

  ${(props) =>
    props.$isActive
      ? `
        background: ${props.theme.colors.default.primary[90]};
        
        path {
          color: ${props.theme.colors.default.primary[0]};
        }
      `
      : ''}

  &:hover {
    background: ${(props) => props.theme.colors.default.primary[80]};

    path {
      fill: ${(props) => props.theme.colors.default.primary[0]};
    }
  }
`

export const StyledSelectOrder = styled(Select)`
  width: 140px;
`

export const StyledSelectPeriod = styled(Select)`
  width: 120px;
  z-index: 100;
`

export const StyledInputFindWrapper = styled.div`
  ${StyledIcon} {
    top: 5px;
    width: 15px;
  }
  ${customMedia.small} {
    order: -1;
  }
`

export const StyledInputFind = styled(Input)`
  height: 24px;
  width: 144px;
`

export const StyledIconRightButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  outline: none;
  padding: 0 8px;
  position: absolute;
  right: 0;
  top: 0;
`
