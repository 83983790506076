import React from 'react'

import { BasketQuickBetNotificationWrapper } from 'widgets/right-container/Basket/BasketQuickBet/components/BasketQuickBetNotification'

import {
  ENotificationContainerIds,
  TCustomToastContentProps,
  usePopUpNotification
} from '../shared/lib/Toastify'

export const useNotifyMakeQuickBet = () => {
  return usePopUpNotification({
    content: {
      children: (props: TCustomToastContentProps<{ key: string }>) => (
        <BasketQuickBetNotificationWrapper {...props} />
      )
    },
    options: {
      autoClose: false,
      containerId: ENotificationContainerIds.SYSTEM
    }
  })
}
