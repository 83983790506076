const getResource = async (url: string, options?: RequestInit) => {
  return await fetch(url, options)
}

export interface IParseFileFromServerProps {
  url: string
  contentTypeResponse: string
  options?: RequestInit
}

export const parseFileFromServer = ({
  url,
  contentTypeResponse,
  options
}: IParseFileFromServerProps): Promise<string> =>
  new Promise((resolve, reject) =>
    getResource(url, options)
      .then((response) => response.body)
      .then((response) => {
        const reader = response?.getReader()

        return new ReadableStream({
          start(controller) {
            function push() {
              reader?.read().then(({ done, value }) => {
                if (done) {
                  controller.close()

                  return
                }

                controller.enqueue(value)

                push()
              })
            }

            push()
          }
        })
      })
      .then((stream) =>
        // Respond with our stream
        new Response(stream, {
          headers: { 'Content-Type': contentTypeResponse }
        }).text()
      )
      .then(resolve)
      .catch(reject)
  )
