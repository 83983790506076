import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectCashoutError,
  selectIsMakeCashoutLoading
} from 'astra-core/containers/BetsHistoryProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { EButtonViews } from 'shared/ui/Button'

import {
  CASHOUT_BUTTOR_ERROR_TEXT,
  ECurrentCashoutModalContent,
  ICurrentCashoutModalContent,
  IGetCurrentCashoutModalContent
} from './CurrentCashoutModal.types'
import {
  StyledCurrentCashoutButton,
  StyledCurrentCashoutText,
  StyledCurrentCashoutTextLoading
} from './CurrentCashoutModal.styled'

export const getCurrentCashoutModalContent = ({
  cashoutError,
  cashout,
  isCashoutAmountHasChanged,
  setCurrentCashout,
  cashoutAmount
}: IGetCurrentCashoutModalContent) => {
  if (cashoutError) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.SELL_BET_ERROR}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else if (cashout?.outdated) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.SELL_NOT_POSSIBLE}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else if (isCashoutAmountHasChanged) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.ACTIVE_SELL}
        setCurrentCashout={setCurrentCashout}
      />
    )
  }
}

export const CurrentCashoutModalContent = ({
  setCurrentCashout,
  cashoutAmount,
  contentType
}: ICurrentCashoutModalContent) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const cashoutError = useSelector(selectCashoutError)!
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const makeCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.makeCashout())
  }

  if (contentType === ECurrentCashoutModalContent.ACTIVE_SELL) {
    return (
      <>
        {isMakeCashoutLoading ? (
          <MakeCashoutLoading />
        ) : (
          <>
            <StyledCurrentCashoutText>{cashoutAmount}</StyledCurrentCashoutText>

            <StyledCurrentCashoutButton isConfirm onClick={makeCashout}>
              {t('yes')}
            </StyledCurrentCashoutButton>
            <StyledCurrentCashoutButton
              view={EButtonViews.SECONDARY}
              onClick={unsetCurrentCashout}
            >
              {t('no')}
            </StyledCurrentCashoutButton>
          </>
        )}
      </>
    )
  } else if (contentType === ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED) {
    return (
      <>
        <StyledCurrentCashoutText>
          {t('bet amount has changed')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton isConfirm onClick={setCurrentCashout}>
          {t('update amount')}
        </StyledCurrentCashoutButton>
      </>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_NOT_POSSIBLE) {
    return (
      <>
        <StyledCurrentCashoutText>
          {t('sell bet is not possible')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton isConfirm onClick={unsetCurrentCashout}>
          {t('continue')}
        </StyledCurrentCashoutButton>
      </>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_BET_ERROR) {
    return (
      <>
        <StyledCurrentCashoutText>
          {t(cashoutError?.title)}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton isConfirm onClick={unsetCurrentCashout}>
          {t(CASHOUT_BUTTOR_ERROR_TEXT[cashoutError?.code] ?? 'continue')}
        </StyledCurrentCashoutButton>
      </>
    )
  } else {
    return null
  }
}

const MakeCashoutLoading = () => {
  const [t] = useTranslation()

  return (
    <>
      <LoaderPoints
        settingsAnimation={{
          timeStep: 0.3,
          timeAfterEnd: 1.7,
          scaleActivePoint: 1
        }}
        count={3}
        size={4}
      />
      <StyledCurrentCashoutTextLoading>
        {t('bet sale in progress')}
      </StyledCurrentCashoutTextLoading>
    </>
  )
}
