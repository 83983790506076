import React, { FC } from 'react'

import { IconEmblem } from 'shared/ui/Icon/General/IconEmblem'
import { IconEmblemMainPage } from 'shared/ui/Icon/General/IconEmblemMainPage'

import { ETeamLogo, TeamLogoProps } from './TeamLogo.types'
import { StyledTeamLogo } from './TeamLogo.styled'
import { IconTeamLogo } from './components/IconTeamLogo'

export const TeamLogo: FC<TeamLogoProps> = ({ logoUrl, isPlace }) => {
  return (
    <StyledTeamLogo isPlace={isPlace}>
      {logoUrl ? (
        <IconTeamLogo isPlace={isPlace} logoUrl={logoUrl} />
      ) : isPlace === ETeamLogo.mainPage ? (
        <IconEmblemMainPage size={16} />
      ) : isPlace === ETeamLogo.eventCompleted ? (
        <IconEmblem size={64} />
      ) : (
        <IconEmblem size={32} />
      )}
    </StyledTeamLogo>
  )
}
