import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  searchProviderActions,
  selectInputSearchLine
} from 'astra-core/containers/SearchProvider'
import { useParams } from 'react-router-dom'

import { ETestData } from 'shared/lib/testData'
import { EInputSize, EInputView } from 'shared/ui/Input'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { LiveParams } from 'pages/page-live-events/LiveContainer/types'

import {
  StyledIconRightButton,
  StyledInput,
  StyledSearch
} from './SearchInput.styled'
import { ISearchInputProps } from './SearchInput.type'

export const SearchInput: FC<ISearchInputProps> = memo(({ isTournaments }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const valueSearch = useSelector(selectInputSearchLine) || ''
  const { sportId } = useParams<LiveParams>()

  const clearInputSearch = useCallback(
    () => dispatch(searchProviderActions.setSearchLine({ valueSearch: '' })),
    [dispatch]
  )

  const handleInputSearch = useCallback(
    ({ target }) =>
      dispatch(
        searchProviderActions.setSearchLine({
          valueSearch: target.value,
          isTournaments,
          sportId: +sportId
        })
      ),
    [dispatch, isTournaments, sportId]
  )

  useEffect(() => {
    return () => {
      clearInputSearch()
    }
  }, [clearInputSearch])

  return (
    <StyledSearch>
      <StyledInput
        customSize={EInputSize.M}
        icon={IconSearch}
        placeholder={t('search')}
        rightPadding={28}
        testData={ETestData.TestTournamentsSearchInput}
        value={valueSearch}
        view={EInputView.SECONDARY}
        onChange={handleInputSearch}
      />
      <StyledIconRightButton onClick={clearInputSearch}>
        {!!valueSearch.length && (
          <IconClose
            colorProps={{ name: EColorsNames.Primary, value: 50 }}
            size={10}
          />
        )}
      </StyledIconRightButton>
    </StyledSearch>
  )
})
