import React from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { EColorsNames } from 'shared/types/theme'

import { Filters } from '../Filters'

import {
  StyledCalendarTitle,
  StyledIconCalendar
} from './HeaderCalendar.styled'

const IconCalendar = (
  <StyledIconCalendar
    colorProps={{ name: EColorsNames.Blue, value: 50 }}
    size={24}
  />
)

export const HeaderCalendar = () => {
  const [t] = useTranslation()

  return (
    <StyledCalendarTitle>
      <TitleLinePage
        iconComponent={IconCalendar}
        testData={ETestData.TestTitleUpcoming}
        title={t('mainPage upcoming')}
      >
        <Filters />
      </TitleLinePage>
    </StyledCalendarTitle>
  )
}
