import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { selectCalendarCountersList } from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'

import { ResultsMenuItemProps } from './CalendarMenuList.types.ts'
import {
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './CalendarMenuList.styled'

export const CalendarMenuList = () => {
  const calendarCountersList = useSelector(selectCalendarCountersList)

  return (
    <StyledSportsMenuList>
      {calendarCountersList.map((sport) => (
        // @ts-ignore
        <ResultsMenuItem key={sport.id} sport={sport}>
          {sport.name}
        </ResultsMenuItem>
      ))}
    </StyledSportsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(({ sport }) => {
  const dispatch = useDispatch()

  const changeCurrentSportId = useCallback(() => {
    dispatch(
      calendarEventsContainerActions.setCurrentSportIdFilter({
        sportId: sport.id
      })
    )
  }, [dispatch, sport.id])

  return (
    <StyledSportsMenuItem
      // @ts-ignore
      $active={sport.isActive}
      onClick={changeCurrentSportId}
    >
      <SportIcon
        isActive={sport.top}
        sportCode={sport.code as ESportsCodes}
        withBackground
      />

      <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>

      <StyledSportsMenuItemCount>{sport.count}</StyledSportsMenuItemCount>
    </StyledSportsMenuItem>
  )
})
