import React from 'react'

import { IconLogo } from 'features/logo'

import { StyledLoaderWelcomeScreen } from './loader-welcome.styled'

export const LoaderWelcome = () => (
  <StyledLoaderWelcomeScreen>
    <IconLogo />
  </StyledLoaderWelcomeScreen>
)
