import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { storageProviderActions } from 'astra-core/containers/StorageProvider'

// TODO: used in mobile too. Transfer to Astra-Core
export const useSetStorage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(storageProviderActions.setStorage())
  }, [dispatch])
}
