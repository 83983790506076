import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconProfileMenuBanner: FC<IconProps> = (props) => (
  <Icon {...props} size={32}>
    <svg
      fill="none"
      height="32"
      viewBox="0 0 33 32"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2677 5.7853C9.97097 6.13452 7.28699 8.99688 7.18894 12.3706C7.12153 14.6717 7.17056 16.9791 7.17056 19.2802C7.17056 19.5483 7.27473 19.7728 7.47082 19.9474C7.75883 20.2094 8.09586 20.2842 8.45127 20.1158C8.82507 19.9412 9.00277 19.6294 9.00277 19.2116C9.00277 17.0913 9.00277 14.9773 9.00277 12.857C9.00277 12.5265 9.02116 12.196 9.07631 11.8717C9.45623 9.60178 11.2149 7.90557 13.5557 7.63118C15.6208 7.38797 17.6859 8.52294 18.6296 10.5808C19.0034 11.3978 19.132 12.2646 19.0891 13.2374C19.6039 12.6699 20.1309 12.2459 20.7437 11.9403C20.8907 11.8655 20.8723 11.7782 20.8478 11.6597C20.2473 7.91804 16.9873 5.39866 13.2677 5.79154V5.7853Z"
        fill="#43B343"
      />
      <path
        d="M20.4985 4.24499C18.5928 2.7608 16.4358 2.02494 14.2175 2C13.427 2 12.8204 2.06236 12.2199 2.16837C7.22571 3.04766 3.53064 7.48775 3.5 12.6388C3.5 13.2312 3.86767 13.6615 4.39466 13.6677C4.92778 13.6802 5.31996 13.2624 5.33221 12.6762C5.33834 12.0151 5.418 11.3604 5.55894 10.7118C6.39845 6.87038 9.90969 3.94566 13.7763 3.87082C15.8659 3.82717 17.7349 4.44454 19.3955 5.73541C19.8612 6.0971 20.4189 6.03474 20.7375 5.60445C21.0562 5.17416 20.9581 4.61292 20.4985 4.25122V4.24499Z"
        fill="#43B343"
      />
      <path
        d="M17.2079 12.6824C16.9628 11.0236 15.5044 9.84499 13.8254 9.94477C12.2199 10.0383 10.8718 11.429 10.8472 13.0753C10.8227 15.0209 10.8166 16.9666 10.8472 18.906C10.8901 21.3817 13.6109 22.8784 15.6943 21.6062C15.8353 21.5189 15.8904 21.4316 15.8904 21.2633C15.8843 20.7893 15.872 20.3091 15.9088 19.8352C15.9701 19.1305 16.2704 18.5194 16.7483 17.9581C16.0375 17.7212 15.4615 18.0953 15.4247 18.7813C15.3757 19.6232 14.7936 20.2094 14.0214 20.1969C13.2432 20.1844 12.6733 19.5546 12.6733 18.694C12.6733 16.898 12.6733 15.0958 12.6733 13.2998C12.6733 13.1751 12.6733 13.0441 12.704 12.9256C12.8572 12.2085 13.4883 11.7345 14.193 11.8094C14.8916 11.8842 15.4186 12.4766 15.4247 13.2187C15.4308 14.1416 15.4247 15.0646 15.4247 15.9875C15.4247 16.5301 15.8353 16.9354 16.35 16.9354C16.8464 16.9354 17.2447 16.5301 17.2569 16C17.2631 15.657 17.2692 15.3203 17.2569 14.9773C17.2385 14.2102 17.3182 13.4432 17.2079 12.6762V12.6824Z"
        fill="#43B343"
      />
      <path
        d="M8.44514 26.0463C6.96834 24.7679 6.00627 23.1653 5.55894 21.2445C5.30158 20.1345 5.33834 19.0058 5.33834 17.8833C5.33834 17.2909 5.33834 16.6984 5.33834 16.106C5.33834 15.4886 4.96455 15.0646 4.4253 15.0646C3.87992 15.0646 3.50613 15.4824 3.50613 16.0998C3.50613 17.1225 3.50613 18.139 3.50613 19.1617C3.49387 21.2633 4.03312 23.2027 5.13 24.98C5.76729 26.0151 6.5639 26.9131 7.50759 27.6739C7.85687 27.9546 8.3042 27.9608 8.64736 27.6989C8.97826 27.4432 9.09469 26.9942 8.94149 26.6013C8.84345 26.3581 8.64123 26.2085 8.45127 26.0463H8.44514Z"
        fill="#43B343"
      />
      <path
        d="M15.6821 26.065C15.7924 26.0401 15.8904 26.0276 15.8843 25.853C15.872 25.2606 15.8843 24.6681 15.8843 24.057C15.3512 24.2503 14.8242 24.3875 14.2788 24.3938C12.2199 24.4187 10.6634 23.5332 9.62168 21.7247C9.31529 21.1947 8.78217 21.0138 8.31646 21.2944C7.85074 21.5688 7.73432 22.1238 8.03458 22.6601C8.86796 24.1506 10.0629 25.2107 11.6377 25.8218C12.9613 26.3332 14.3095 26.4018 15.6821 26.0713V26.065Z"
        fill="#43B343"
      />
      <path
        d="M16.1601 28.0419C16.0988 27.8922 16.0252 27.8984 15.8966 27.9296C14.1685 28.2976 12.4834 28.1666 10.8411 27.5056C10.6634 27.437 10.4857 27.3746 10.2835 27.3996C9.84841 27.4619 9.51751 27.7862 9.46848 28.2102C9.41333 28.6405 9.65845 29.0396 10.0935 29.2143C11.5336 29.8004 13.0226 30.0624 14.5729 29.9813C15.4982 29.9376 16.4052 29.7755 17.3305 29.4575C16.7912 29.0708 16.399 28.6156 16.1662 28.0356L16.1601 28.0419Z"
        fill="#43B343"
      />
      <path
        d="M24.5 11.3167C24.31 9.82628 23.8259 8.43563 23.0538 7.15724C22.8394 6.80178 22.4104 6.67082 22.0244 6.78931C21.6506 6.90779 21.3809 7.2882 21.3871 7.69978C21.3871 7.84944 21.4238 7.99287 21.4974 8.11759C22.055 9.07795 22.4411 10.1007 22.631 11.1982C22.6494 11.298 22.6617 11.3541 22.7842 11.3479C23.3112 11.3042 23.8321 11.3479 24.3468 11.4539C24.4632 11.4788 24.5123 11.4726 24.4939 11.3167H24.5Z"
        fill="#43B343"
      />
      <path
        d="M27.9329 18.3995C27.6861 18.3755 27.6553 18.3097 27.6491 18.0942C27.6491 17.4537 27.7108 16.8072 27.5689 16.1727C27.1247 14.1914 25.1319 12.6829 22.7689 13.06C21.1339 13.3174 19.6532 14.7241 19.4064 16.3044C19.3077 16.9269 19.3756 17.5495 19.3509 18.172C19.3509 18.2798 19.3817 18.3695 19.1905 18.3875C18.2095 18.4653 17.5 19.2255 17.5 20.1773C17.5 22.2664 17.5 24.3555 17.5 26.4446C17.5 27.4502 18.3082 28.2344 19.3324 28.2344C20.7144 28.2344 22.1026 28.2344 23.4846 28.2344C24.8666 28.2344 26.2918 28.2344 27.6985 28.2344C28.6794 28.2344 29.5 27.4442 29.5 26.4985C29.5 24.3734 29.5 22.2424 29.5 20.1174C29.5 19.2674 28.8028 18.4893 27.9267 18.3935L27.9329 18.3995ZM24.4224 24.2118C24.4162 24.7146 24.0028 25.0977 23.5031 25.0977C23.0033 25.0977 22.5961 24.7087 22.5838 24.2058C22.5776 23.9065 22.5838 23.6072 22.5838 23.3079C22.5838 23.0086 22.5838 22.7094 22.5838 22.4101C22.5961 21.9013 22.9848 21.5182 23.4907 21.5122C24.0028 21.5062 24.4162 21.8953 24.4285 22.416C24.4347 23.0146 24.4409 23.6132 24.4285 24.2058L24.4224 24.2118ZM25.6008 18.3815C24.8974 18.3815 24.2003 18.3815 23.4969 18.3815C22.7936 18.3815 22.1334 18.3695 21.4486 18.3875C21.2203 18.3875 21.1771 18.3276 21.1895 18.1241C21.2203 17.5495 21.1339 16.9688 21.3005 16.4062C21.5905 15.4364 22.5159 14.766 23.5956 14.8139C24.7247 14.8618 25.5884 15.64 25.7612 16.6935C25.8476 17.2143 25.7859 17.735 25.8105 18.2558C25.8167 18.4354 25.6871 18.3875 25.5946 18.3875L25.6008 18.3815Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
