import { createGlobalStyle, css } from 'styled-components'

import { HINT_ATTR } from 'constants/styles/components/hintAttr'

export const GlobalStyle = createGlobalStyle`${(props) => css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    color: ${(props) => props.theme.colors.default[70]};
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.custom[2]};
    margin: 0;
    padding: 0;
    font-feature-settings: 'liga', 'kern';
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
  }

  * {
    font-family: Inter, sans-serif;
    margin: 0;
    padding: 0;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  .masonry:focus-visible {
    outline: none;
  }

  #chat-widget-container {
    z-index: 50 !important;
  }

  ${HINT_ATTR(props)}
`}`
