import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLogo = styled(NavLink)`
  align-items: center;
  display: grid;
`

export const StyledLogoWrap = styled.div`
  align-items: center;
  display: grid;
  overflow: hidden;
  width: 240px;
`
