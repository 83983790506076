import styled from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { CssButtonReset } from 'constants/styles'
import { EColorsTypes } from 'shared/types/theme'
import { UserAccountBalance } from 'widgets/user-account-balance'
import { UserAccountBonus } from 'widgets/user-account-bonus'

export const StyledWrapper = styled.div`
  margin-bottom: 10px;
  padding: 0 16px;
`
export const StyledName = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.m};
  line-height: 20px;
  margin-bottom: 8px;
`

export const StyledBalanceTitle = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.xs};
  line-height: 16px;
`

export const StyledButtonBalanceCount = styled(UserAccountBalance)`
  align-items: center;
  color: ${(props) => props.theme.colors.default.primary[90]};
  cursor: pointer;
  font-size: ${(props) => props.theme.fonts.size.xl};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 28px;
  margin-bottom: 8px;
  white-space: nowrap;
  ${CssButtonReset}

  &:hover {
    ${StyledIcon} {
      color: ${(props) =>
        props.theme.colors[EColorsTypes.DEFAULT].primary[70]} !important;
    }
  }

  &:active {
    ${StyledIcon} {
      color: ${(props) =>
        props.theme.colors[EColorsTypes.DEFAULT].primary[50]} !important;
    }
  }
`

export const StyledButtonBonusCount = styled(UserAccountBonus)`
  align-items: center;
  color: ${(props) => props.theme.colors.default.primary[90]};
  cursor: pointer;
  font-size: ${(props) => props.theme.fonts.size.xl};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 28px;
  margin-bottom: 8px;
  padding: 0;
  white-space: nowrap;
  ${CssButtonReset}

  &:hover {
    ${StyledIcon} {
      color: ${(props) =>
        props.theme.colors[EColorsTypes.DEFAULT].primary[70]} !important;
    }
  }

  &:active {
    ${StyledIcon} {
      color: ${(props) =>
        props.theme.colors[EColorsTypes.DEFAULT].primary[50]} !important;
    }
  }
`

export const StyledCurrency = styled.span`
  color: ${(props) => props.theme.colors.default.primary[30]};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  margin-left: 4px;
`
