import React, { FC } from 'react'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { StyledDataListDefault, StyledLoader } from './styled'
import { DataListDefaultProps } from './types'

export const DataListDefault: FC<DataListDefaultProps> = ({
  children,
  isLoading,
  isPaginationLoading,
  containerRef,
  loadingRef,
  ...props
}) => {
  const isPaginationLoader = !isLoading && isPaginationLoading

  return (
    <StyledDataListDefault ref={containerRef} {...props}>
      {isLoading ? <LoaderSpinner /> : children}

      {isPaginationLoader && (
        <div ref={loadingRef}>
          <StyledLoader />
        </div>
      )}
    </StyledDataListDefault>
  )
}
