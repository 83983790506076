import React, { useCallback, useContext } from 'react'
import {
  BasketQuickBetContext,
  EBasketQuickBetError
} from 'og-web-core/providers/basket/quick-bet'
import { useSelector } from 'react-redux'
import {
  QuickBetOutcome,
  selectBasketOutcomes
} from 'astra-core/containers/BasketProvider'
import { useValidateQuickBetValue } from 'og-web-core/providers/basket/quick-bet/hooks/useValidateQuickBetValue'
import { useMakeQuickBet } from 'astra-core/containers/BasketProvider/useMakeBet'
import { CreateBetListResponse } from 'betweb-openapi-axios'

import {
  ENotificationContainerIds,
  TCustomToastContentProps,
  usePopUpNotification
} from 'shared/lib/Toastify'
import { BasketQuickBetNotification } from 'widgets/right-container/Basket/BasketQuickBet/components/BasketQuickBetNotification'

const BET_CHANGED_COEFFICIENT = 'BET_CHANGED_COEFFICIENT'

interface IUseHandleMakeQuickBetReturn {
  handleMakeQuickBet: (outcome: QuickBetOutcome) => Promise<void>
  isLoading: boolean
}
type UseHandleMakeQuickBet = () => IUseHandleMakeQuickBetReturn

export const useHandleMakeQuickBet: UseHandleMakeQuickBet = () => {
  const {
    setLowerBalanceModalVisible,
    setCoefficientChangedModalVisible,
    setConfirmQuickBetModalVisible,
    confirmQuickBetModalVisible,
    coefficientChangedModalVisible,
    shouldConfirmQuickBet,
    quickBetValue,
    betSaveAny,
    setOutcome
  } = useContext(BasketQuickBetContext)
  const outcomes = useSelector(selectBasketOutcomes)

  const notifyMakeBet = usePopUpNotification({
    content: {
      children: (
        props: TCustomToastContentProps<{ outcome: QuickBetOutcome }>
      ) =>
        props?.data?.outcome ? (
          <BasketQuickBetNotification
            outcomeCompositeId={props?.data?.outcome.outcomeCompositeId}
          />
        ) : null
    },
    options: {
      containerId: ENotificationContainerIds.SYSTEM
    }
  })

  const onSuccess = useCallback(
    ({ items }: CreateBetListResponse) => {
      const { outcomes } = items[0]

      if (outcomes) {
        notifyMakeBet({ outcome: outcomes[0] })
      }

      if (confirmQuickBetModalVisible) {
        setConfirmQuickBetModalVisible(false)
      }

      if (coefficientChangedModalVisible) {
        setCoefficientChangedModalVisible(false)
      }
    },
    [
      coefficientChangedModalVisible,
      confirmQuickBetModalVisible,
      notifyMakeBet,
      setCoefficientChangedModalVisible,
      setConfirmQuickBetModalVisible
    ]
  )

  const onError = useCallback(
    (error) => {
      const isChangedCoefficientError = error?.code === BET_CHANGED_COEFFICIENT

      if (confirmQuickBetModalVisible) {
        setConfirmQuickBetModalVisible(false)
      }

      if (isChangedCoefficientError) {
        setCoefficientChangedModalVisible(true)
      }
    },
    [
      confirmQuickBetModalVisible,
      setCoefficientChangedModalVisible,
      setConfirmQuickBetModalVisible
    ]
  )

  const [makeQuickBet, result] = useMakeQuickBet({
    onError,
    onSuccess
  })
  const error = useValidateQuickBetValue(quickBetValue)

  const shouldShowConfirmQuickBetModal =
    shouldConfirmQuickBet &&
    !confirmQuickBetModalVisible &&
    !coefficientChangedModalVisible

  const shouldShowLowerBalanceModal =
    error === EBasketQuickBetError.insufficientBalance

  const handleMakeQuickBet = useCallback(
    async (outcome) => {
      if (outcomes.length) {
        return
      }

      setOutcome(outcome)

      if (shouldShowLowerBalanceModal) {
        setLowerBalanceModalVisible(true)
        return
      }

      if (shouldShowConfirmQuickBetModal) {
        setConfirmQuickBetModalVisible(true)
        return
      }

      await makeQuickBet(outcome, quickBetValue, betSaveAny)
    },
    [
      betSaveAny,
      makeQuickBet,
      outcomes.length,
      quickBetValue,
      setConfirmQuickBetModalVisible,
      setLowerBalanceModalVisible,
      setOutcome,
      shouldShowConfirmQuickBetModal,
      shouldShowLowerBalanceModal
    ]
  )

  return {
    handleMakeQuickBet,
    isLoading: result.isLoading
  }
}
