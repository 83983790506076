import styled from 'styled-components'

import { ETeamLogo } from '../../TeamLogo.types'
import { getTeamLogoStyles } from '../../utils'

export const StyledIconTeamLogo = styled.img<{ isPlace?: ETeamLogo }>`
  ${({ isPlace }) => {
    const styles = getTeamLogoStyles(isPlace)
    return `
      height: ${styles.height};
      width: ${styles.width};
    `
  }}
  object-fit: contain;
`
