import { EventStatisticPropsFromType } from '../../EventStatistics.types'
import { IEventMatchPhaseProps } from '../EventStateStatus/EventMatchPhase.types'

import { IEventStatisticScoreDefaultProps } from './components/EventStatisticScoreDefault/EventStatisticScoreDefault.types'
import { IEventStatisticScoresByPeriodProps } from './components/EventStatisticScoresByPeriod/EventStatisticScoresByPeriod.types'
import { IEventStatisticScoreGameProps } from './components/EventStatisticScoreGame/EventStatisticScoreGame.types'
import { IEventStatisticScoreInfoProps } from './components/EventStatisticScoreInfo/EventStatisticScoreInfo.types'

export enum EEventStatisticScoreTypes {
  DEFAULT = 'default',
  GAME = 'game',
  BY_PERIOD = 'by_period',
  INFO = 'info',
  MATCH_PHASE = 'match_phase'
}

export type TEventStatisticScoreProps =
  | EventStatisticPropsFromType<
      EEventStatisticScoreTypes.DEFAULT,
      IEventStatisticScoreDefaultProps
    >
  | EventStatisticPropsFromType<
      EEventStatisticScoreTypes.BY_PERIOD,
      IEventStatisticScoresByPeriodProps
    >
  | EventStatisticPropsFromType<
      EEventStatisticScoreTypes.GAME,
      IEventStatisticScoreGameProps
    >
  | EventStatisticPropsFromType<
      EEventStatisticScoreTypes.INFO,
      IEventStatisticScoreInfoProps
    >
  | EventStatisticPropsFromType<
      EEventStatisticScoreTypes.MATCH_PHASE,
      IEventMatchPhaseProps
    >
