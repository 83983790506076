import React from 'react'

import { ETestData } from 'shared/lib/testData'
import { IconProps } from 'shared/ui/Icon'

export enum EButtonSizes {
  S = 's',
  M = 'm'
}

export enum EButtonViews {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  REGISTRATION = 'registration',
  SELL_BET = 'sell_bet',
  NONE = 'none'
}

export enum EButtonIconPositionSides {
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Partial<IButtonLink> {
  view?: EButtonViews // variants of theme
  size?: EButtonSizes
  icon?: React.FC<IconProps> // components with icon
  testData?: ETestData // it is needed for auto-testing purposes
  round?: boolean // corners of button is round
  additionalText?: string
  iconProps?: ButtonIconProps // props of Icon component
}

export interface IButtonLink {
  to: string // TODO: Consider discard this prop
}

export interface ButtonIconProps extends IconProps {
  positionSide: EButtonIconPositionSides // icon after content
}

export interface StyledButtonAdditionalTextProps {
  icon: React.FC<IconProps> | null
}
