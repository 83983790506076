import React from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { IconCuracaoLicence } from 'shared/ui/Icon/General/IconCuracaoLicence'

import { openCuracaoLicenceConstants } from '../lib'

export const OpenCuracaoLicence = () => {
  const config = useSelector(selectConfig)

  return (
    <a href={config.CURACAO_LICENCE_URL} rel="noreferrer" target="_blank">
      <IconCuracaoLicence size={openCuracaoLicenceConstants.CURACAO_SIZE} />
    </a>
  )
}
