import styled from 'styled-components'

export const StyledBetCardEventPending = styled.div`
  left: 250px;
  position: absolute;
  top: 42px;
  transform: translate(-50%, -50%);
`

export const StyledBetCardEventStatus = styled.div`
  border-radius: 24px;
  line-height: 16px;
  padding: 0 8px;
  position: absolute;
  right: 16px;
  top: 35px;
  width: fit-content;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.fixed.primary[50]};
      background-color: ${theme.colors.default.primary[5]}
    `};
`
