import styled, { css } from 'styled-components'

export const StyledCoefficientChangedModalWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 20px 16px;
  width: 400px;
`

export const StyledCoefficientChangedModalTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledCoefficientChangedModalTitle = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.l};
    font-weight: ${props.theme.fonts.weight.bold};
    text-align: left;
  `}
`

export const StyledCoefficientChangedModalDescription = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
  `}
`

export const StyledButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: start;
`

export const StyledCoefficientWrapper = styled.div`
  display: flex;
  gap: 24px;
`

export const StyledCoefficient = styled.div`
  align-items: start;

  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledCoefficientTitle = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[13]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
  `}
`

export const StyledCoefficientText = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
  `}
`
