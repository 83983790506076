import styled from 'styled-components'

import { SCROLLBAR_WIDTH, SCROLLBAR_HEIGHT } from './constants'

export const StyledLayout = styled.div`
  height: 100%;
  position: relative;

  * ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) => props.theme.colors.default.primary[10]};
    border: 3px solid transparent;
    border-radius: 6px;
  }

  * ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px;
  }

  * ::-webkit-scrollbar {
    background-color: ${(props) => props.theme.colors.custom.primary[2]};
    height: ${SCROLLBAR_HEIGHT};
    width: ${SCROLLBAR_WIDTH};
  }
`
