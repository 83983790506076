import React from 'react'

import { ETestData } from 'shared/lib/testData'
import { ERoutes } from 'shared/types/routes'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { useHandleRedirectList } from '../../hooks'

import { StyledIconPersonal } from './PersonalAccountButton.styled'

export const PersonalAccountButton = () => {
  const handleRedirectList = useHandleRedirectList()

  return (
    <StyledIconPersonal
      colorProps={{
        name: EColorsNames.Primary,
        type: EColorsTypes.CUSTOM,
        value: 7
      }}
      size={20}
      testData={ETestData.TestMainHeaderIconPersonal}
      onClick={handleRedirectList(ERoutes.Profile)}
    />
  )
}
