import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { selectIsDisplayModalChangeLanguage } from 'containers/LayoutContainer/selectors'

import { LanguagesList } from './components'

export const ChangeLanguage: FC = () => {
  const isDisplayModal = useSelector(selectIsDisplayModalChangeLanguage)

  return isDisplayModal ? <LanguagesList /> : null
}
