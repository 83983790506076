import React, { memo, useContext } from 'react'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { useLocation } from 'react-router-dom'

import { IconLightning } from 'shared/ui/Icon/General/IconLightning'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { rightBasketContainerState } from 'containers/BasketContainer/slice'

import { hiddenRightContainerRoutesList } from '../../constants'

import {
  StyledBasketQuickBetButton,
  StyledBasketQuickBetButtonIcon,
  StyledBasketQuickBetButtonLine,
  StyledBasketQuickBetButtonLoaderWrapper,
  StyledBasketQuickBetButtonText
} from './BasketQuickBetButton.styled'

export const BasketQuickBetButton = memo(() => {
  const currencyIcon = useGetCurrencyIcon()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { colors } = useTheme()
  const { pathname } = useLocation()
  const isRouteWithPermanentHiddenBasket =
    hiddenRightContainerRoutesList.includes(pathname)

  const { quickBetValue, isFetchLoading } = useContext(BasketQuickBetContext)

  const toggleShowBasket = () => {
    if (isFetchLoading) {
      return
    }

    dispatch(
      rightBasketContainerState.toggleBasketOnSmallWindow({ isShow: true })
    )
  }

  return (
    <StyledBasketQuickBetButton
      isRouteWithPermanentHiddenBasket={isRouteWithPermanentHiddenBasket}
      onClick={toggleShowBasket}
    >
      {isFetchLoading ? (
        <StyledBasketQuickBetButtonLoaderWrapper>
          <LoaderPoints
            colors={{
              start: colors[EColorsTypes.DEFAULT].primary[0],
              end: colors[EColorsTypes.DEFAULT].primary[60]
            }}
            settingsAnimation={{
              timeAfterEnd: 2.4,
              scaleActivePoint: 1
            }}
            count={3}
            margin={2}
            size={4}
          />
        </StyledBasketQuickBetButtonLoaderWrapper>
      ) : (
        <>
          <StyledBasketQuickBetButtonIcon>
            <IconLightning
              colorProps={{
                type: EColorsTypes.FIXED,
                name: EColorsNames.Primary,
                value: 0
              }}
            />
          </StyledBasketQuickBetButtonIcon>
          {`${quickBetValue} ${currencyIcon ?? ''}`}
          <StyledBasketQuickBetButtonLine />
          <StyledBasketQuickBetButtonText>
            {t('just coupon')}
          </StyledBasketQuickBetButtonText>
        </>
      )}
    </StyledBasketQuickBetButton>
  )
})
