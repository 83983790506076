import React, { FC, memo } from 'react'

import { ETestData } from 'shared/lib/testData'
import { IconLogo } from 'features/logo'

import { StyledLogo, StyledLogoWrap } from './Logo.styled'

export const Logo: FC = memo(() => (
  <StyledLogoWrap>
    <StyledLogo
      data-test-id={ETestData.TestMainHeaderLogo}
      key="Redirect to Live"
      to="/"
      exact
    >
      <IconLogo />
    </StyledLogo>
  </StyledLogoWrap>
))
