import styled from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { CssButtonReset } from 'constants/styles'
import { Button } from 'shared/ui/Button'
import { StyledIcon } from 'shared/ui/Icon'
import { columnGapPrefixes } from 'shared/lib/styled'
import { IconPerson } from 'shared/ui/Icon/General/IconPerson'
import { IconHomepageNotifications } from 'shared/ui/Icon/General/IconHomepageNotifications'

export const StyledCasinoComponentOfAuthorized = styled.div`
  align-items: center;
  display: flex;
  ${columnGapPrefixes('15px')}
  position: relative;
`

export const StyledBalanceButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.theme.colors.fixed.yellow[70]};
  border: none;
  border-radius: 4px;
  ${columnGapPrefixes('8px')}
  color: ${(props) => props.theme.colors.fixed.primary[90]};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  outline: none;
  padding: 4px 6px;
  transition: 0.5s ease background-color;
  white-space: nowrap;

  &:hover {
    background-color: ${(props) => props.theme.colors.fixed.yellow[80]};
  }
`

export const StyledButtonHistory = styled(Button)`
  font-weight: ${(props) => props.theme.fonts.weight.normal};
`

export const StyledButtonFavourite = styled.button`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[30]};
  ${columnGapPrefixes('3px')}
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.light};
  ${CssButtonReset}

  &:hover {
    ${StyledIcon} {
      color: ${(props) => props.theme.colors.default.yellow[30]} !important;
    }
  }
`

export const StyledIconMessage = styled(IconHomepageNotifications)`
  cursor: pointer;

  & svg circle {
    stroke: #0d0d0d;
    fill: #4e7fff;
  }

  ${({ theme }) => `
  ${
    theme.typeLayoutTheme === LayoutTheme.Light &&
    `
    & svg circle {
      stroke: #fff;
      fill: #F2200D;
    }
    & svg path {
      fill: #1A1A1A;
    }`
  }
  `};
  &:hover svg path {
    fill: ${(props) => props.theme.colors.default.primary[90]} !important;
  }
`
export const StyledPadding = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 -15px;
  padding: 0 15px;
  width: 100%;
`

export const StyledIconPersonal = styled(IconPerson)`
  &:hover {
    color: ${(props) => props.theme.colors.default.primary[70]} !important;
  }

  &:active {
    color: ${(props) => props.theme.colors.default.primary[50]} !important;
  }
`
