import styled from 'styled-components'

export const StyledMenuBannerWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[79]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 16px 2px;
  min-height: 280px;
  padding: 32px 16px 16px;
  width: 188px;
`
export const StyledMenuBannerText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fonts.size.s};
  gap: 4px;
  line-height: 16px;
  min-height: 116px;
  text-align: center;
`
export const StyledMenuBannerTitle = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  padding: 0 10px;
`
export const StyledMenuBannerDeskription = styled.div`
  color: ${(props) => props.theme.colors.custom.primary[78]};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
`
