import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBonus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" fill="#FFDB66" r="7" />
      <circle cx="7.99958" cy="7.99958" fill="#FFB133" r="5.63043" />
      <path
        d="M7.71003 4.0228C7.80113 3.7424 8.19782 3.7424 8.28893 4.0228L9.02443 6.28645C9.06518 6.41184 9.18203 6.49674 9.31388 6.49674H11.694C11.9889 6.49674 12.1114 6.87402 11.8729 7.04732L9.94734 8.44633C9.84067 8.52383 9.79604 8.6612 9.83678 8.7866L10.5723 11.0502C10.6634 11.3306 10.3425 11.5638 10.1039 11.3905L8.17837 9.9915C8.0717 9.914 7.92726 9.914 7.82059 9.9915L5.89501 11.3905C5.65649 11.5638 5.33556 11.3306 5.42667 11.0502L6.16217 8.7866C6.20292 8.6612 6.15828 8.52383 6.05161 8.44633L4.12604 7.04731C3.88752 6.87402 4.0101 6.49674 4.30493 6.49674H6.68507C6.81692 6.49674 6.93378 6.41184 6.97452 6.28645L7.71003 4.0228Z"
        fill="#FFF3C8"
      />
    </svg>
  </Icon>
)
