import React, { FC } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import {
  StyledEventOddInfo,
  CoefficientTableCellStyled
} from '../table/coefficient-table.styled'

export const EventCoefficientTotal: FC<{
  value: string | number | undefined
  sportCode: ESportsCodes
}> = ({ value, sportCode }) => {
  return (
    <CoefficientTableCellStyled sportCode={sportCode} viewType="total">
      {!!value && <StyledEventOddInfo>{value}</StyledEventOddInfo>}
    </CoefficientTableCellStyled>
  )
}
