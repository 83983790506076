export enum EFontsSize {
  XXXXS = 'xxxxs',
  XXXS = 'xxxs',
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl'
}

export enum EFontsWeight {
  LIGHT = 'light',
  REGULAR = 'regular',
  NORMAL = 'normal',
  SEMI_BOLD = 'semiBold',
  BOLD = 'bold'
}

export type TThemeFonts = {
  size: Record<EFontsSize, `${number}px`>
  weight: Record<EFontsWeight, `${number}`>
}
