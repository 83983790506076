import React, { FC, ReactElement } from 'react'

import {
  StyledCompetitor,
  StyledCurrentServerFlag,
  StyledEventTeams
} from './teams-names.styled'

type Team = { name: string; isActivePlayer?: boolean }

export const EventTeamsNames: FC<{
  tagComponent?: ReactElement | false
  teams: [Team, Team]
  teamsDivider?: string
  teamsWrapper: (ReactElement) => ReactElement
}> = ({ tagComponent, teams, teamsWrapper, teamsDivider = ' – ' }) => {
  const [homeTeam, awayTeam] = teams

  return (
    <StyledEventTeams>
      {tagComponent}

      {teamsWrapper(
        <>
          <CompetitorsName
            isActivePlayer={homeTeam.isActivePlayer}
            name={homeTeam.name}
          />

          {teamsDivider}
          <CompetitorsName
            isActivePlayer={awayTeam.isActivePlayer}
            name={awayTeam.name}
          />
        </>
      )}
    </StyledEventTeams>
  )
}

export const EventTeamsNamesCompactView: FC<{
  teams: [Team, Team]
  homeTeamWrapper: (ReactElement) => ReactElement
  awayTeamWrapper: (ReactElement) => ReactElement
}> = ({ teams, homeTeamWrapper, awayTeamWrapper }) => {
  const [homeTeam, awayTeam] = teams

  return (
    <StyledEventTeams>
      {homeTeamWrapper(
        <CompetitorsName
          isActivePlayer={homeTeam.isActivePlayer}
          name={homeTeam.name}
        />
      )}
      {awayTeamWrapper(
        <CompetitorsName
          isActivePlayer={awayTeam.isActivePlayer}
          name={awayTeam.name}
        />
      )}
    </StyledEventTeams>
  )
}

const CompetitorsName: FC<Team> = ({ name, isActivePlayer }) => {
  return (
    <StyledCompetitor>
      {isActivePlayer && <StyledCurrentServerFlag />} {` ${name}`}
    </StyledCompetitor>
  )
}
