import React, { FC } from 'react'

import { ToastContent } from './ToastifyContent.types'
import {
  StyledToastifyContent,
  StyledToastifyContentLeft,
  StyledToastifyContentMain,
  StyledToastifyContentRight
} from './ToastifyContent.styed'

export const ToastifyContent: FC<ToastContent> = ({
  children,
  rightContent,
  leftContent
}) => (
  <StyledToastifyContent>
    {!!leftContent && (
      <StyledToastifyContentLeft>{leftContent}</StyledToastifyContentLeft>
    )}

    <StyledToastifyContentMain>{children}</StyledToastifyContentMain>

    {!!rightContent && (
      <StyledToastifyContentRight>{rightContent}</StyledToastifyContentRight>
    )}
  </StyledToastifyContent>
)
