import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconWarning2: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#FFC400" height="32" rx="16" width="32" />
      <path
        clipRule="evenodd"
        d="M25.6878 20.5822L18.0288 7.17825C17.1318 5.60725 14.8678 5.60725 13.9708 7.17825L6.31176 20.5822C5.42076 22.1402 6.54576 24.0793 8.34076 24.0793H23.6588C25.4528 24.0793 26.5778 22.1402 25.6878 20.5822ZM16 11.6689C16.4142 11.6689 16.75 12.0047 16.75 12.4189V16.1589C16.75 16.5732 16.4142 16.9089 16 16.9089C15.5858 16.9089 15.25 16.5732 15.25 16.1589V12.4189C15.25 12.0047 15.5858 11.6689 16 11.6689ZM15.996 18.0391C15.444 18.0391 14.996 18.4871 15 19.0391C15 19.5911 15.448 20.0391 16 20.0391C16.552 20.0391 17 19.5911 17 19.0391C17 18.4871 16.552 18.0391 15.996 18.0391Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
