import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconSettingSmall: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 5C3.44772 5 3 4.46418 3 4L3 3.5L1 3.5C0.535824 3.5 0 3.05228 0 2.5C0 1.94771 0.535824 1.5 1 1.5L3 1.5L3 1C3 0.535824 3.44772 0 4 0C4.55228 0 5 0.535823 5 1L5 1.5L13 1.5C13.4642 1.5 14 1.94772 14 2.5C14 3.05228 13.4642 3.5 13 3.5L5 3.5L5 4C5 4.46418 4.55229 5 4 5Z"
        fill="currentColor"
      />
      <path
        d="M13 10.5C13.4642 10.5 14 10.0523 14 9.5C14 8.94772 13.4642 8.5 13 8.5L8 8.5L8 8C8 7.53582 7.55228 7 7 7C6.44772 7 6 7.53582 6 8V8.5L1 8.5C0.535824 8.5 0 8.94771 0 9.5C0 10.0523 0.535824 10.5 1 10.5L6 10.5L6 11C6 11.4642 6.44772 12 7 12C7.55229 12 8 11.4642 8 11L8 10.5L13 10.5Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
