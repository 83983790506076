import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledButtonExit = styled(Button)`
  color: ${(props) => props.theme.colors.custom.primary[17]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: auto;
  justify-content: flex-start;
  line-height: 20px;
  margin: 8px 0 0 16px;
  padding: 10px 4px;
  width: calc(100% - 32px);

  &:hover {
    background: ${(props) => props.theme.colors.default.primary[5]};
  }
`
