import React, { FC, MouseEvent, useRef, useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

import { ITooltipPrimitiveProps, ITooltipProps } from './Tooltip.types'
import {
  TooltipWrapperStyled,
  TooltipStyled,
  TooltipTitleStyled,
  TooltipArrowStyled
} from './Tooltip.styled'
import { TooltipOptionsType, useTooltipOptions } from './lib/useTooltipOptions'

export const Tooltip: FC<ITooltipProps> = ({ children, title, isCommon }) => {
  const [isShow, setIsShow] = useState<boolean>(false)
  const wrapperRect = useRef<DOMRect>()

  const handleOnMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    if (e?.currentTarget) {
      const rect: DOMRect = e.currentTarget.getBoundingClientRect()
      wrapperRect.current = rect
      setIsShow(true)
    }
  }

  const handleOnMouseLeave = () => {
    setIsShow(false)
  }

  const handleOnWheel = () => {
    setIsShow(false)
  }

  return isCommon ? (
    <>{children}</>
  ) : (
    <TooltipWrapperStyled
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onWheel={handleOnWheel}
    >
      {children}
      {isShow &&
        createPortal(
          <TooltipPrimitive wrapperRect={wrapperRect.current}>
            <TooltipTitleStyled>{title}</TooltipTitleStyled>
          </TooltipPrimitive>,
          document.body
        )}
    </TooltipWrapperStyled>
  )
}

const TooltipPrimitive: FC<ITooltipPrimitiveProps> = ({
  children,
  wrapperRect
}) => {
  const { getOptions, initOptions } = useTooltipOptions()
  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipOptionsType>(initOptions)
  const tooltipRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      if (wrapperRect) {
        setTooltipPosition(
          getOptions(
            {
              tooltipW: tooltipRef.current.clientWidth,
              tooltipH: tooltipRef.current.clientHeight
            },
            wrapperRect
          )
        )
      }
    }
  }, [getOptions, wrapperRect])

  return (
    <TooltipStyled
      style={{
        transform: `translate(${tooltipPosition.x}px, ${tooltipPosition.y}px)`
      }}
      ref={tooltipRef}
    >
      {children}
      <TooltipArrowStyled
        style={{
          left: `${tooltipPosition.arrow.x}px`,
          top: `${tooltipPosition.arrow.y}px`
        }}
        position={tooltipPosition.arrow.position}
      />
    </TooltipStyled>
  )
}
