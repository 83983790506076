import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconNotification: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V10C14 11.1046 13.1046 12 12 12H5L3.70711 13.2929C3.07714 13.9229 2 13.4767 2 12.5858V12C0.895431 12 0 11.1046 0 10V2ZM3 4C3 3.44772 3.44772 3 4 3H10C10.5523 3 11 3.44772 11 4C11 4.55228 10.5523 5 10 5H4C3.44772 5 3 4.55228 3 4ZM3 8C3 7.44771 3.44772 7 4 7H8C8.55228 7 9 7.44771 9 8C9 8.55229 8.55228 9 8 9H4C3.44772 9 3 8.55229 3 8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
