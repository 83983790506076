import styled, { css, CSSProp } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { LayoutTheme } from 'betweb-openapi-axios'

import 'react-toastify/dist/ReactToastify.css'
import { Button } from 'shared/ui/Button'

export const StyledButton = styled(Button)`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[0]};
    line-height: initial;
  `}
`

export const StyledToastContainerDefault = styled(ToastContainer)<{
  containerPositionStyles: CSSProp
}>`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[33]};

    &&&.Toastify__toast-container {
      ${props.containerPositionStyles}
      max-width: 400px;
      width: fit-content;
      z-index: 49;
    }

    .Toastify__toast {
      background-color: ${props.theme.colors.custom.primary[20]};
      box-shadow: ${props.theme.colors.default.shadow[0]};
      margin-bottom: 6px;
      min-height: 40px;
      padding: 10px 18px;
      width: 400px;
      z-index: 49;

      ${props.theme.typeLayoutTheme === LayoutTheme.Dark &&
      css`
        border: 1px solid ${props.theme.colors.fixed.primary[70]};
      `}

      & > button {
        font-size: ${props.theme.fonts.size.s};
        height: auto;
        line-height: 12px;
      }
    }

    .Toastify__toast-body {
      color: ${props.theme.colors.default.primary[90]};
      display: flex;
      flex-grow: 1;
      font-size: ${props.theme.fonts.size.m};
      font-weight: ${props.theme.fonts.weight.regular};
      line-height: 20px;
      padding: 0;
    }
  `}
`
