export enum ERoutes {
  About = '/about',
  RefundPolicy = '/refund-policy',
  AMLPolicy = '/aml-policy',
  ResponsibleGambling = '/responsible-gambling',
  TermsAndConditions = '/terms-and-conditions',
  Login = '/login',
  ProfileBalance = '/profile/balance/',
  BetsHistory = '/profile/bets-history',
  BetGames = '/betgames',
  TurboGames = '/turbo-games',
  TwainGames = '/twain-games',
  CyberbetGames = '/cyberbet',
  VsportGames = '/vsport',
  VsportGamesGame = '/vsport/:gameNameId',
  TurboGamesAviator = '/turbo-games/aviator',
  TurboGamesGame = '/turbo-games/:gameNameId',
  SportGamesLobby = '/sport-games/sport-games-lobby',
  Frigg = '/frigg',
  Calendar = '/calendar',
  Casino = '/casino',
  FavouriteEvents = '/favourite',
  Identification = '/profile/identification',
  Live = '/live',
  LiveEvents = '/live/:sportId',
  LiveEvent = '/live/:sportId/:eventId',
  Line = '/line/:sportId/:tournamentId',
  LineRedirect = '/line',
  LineTournament = '/line/:sportId',
  LineEvent = '/line/:sportId/:tournamentId/:eventId',
  LiveGames = '/livegames',
  Notifications = '/profile/notifications',
  FAQ = '/profile/FAQ',
  PromotionsAndBonuses = '/profile/promotions-and-bonuses',
  PromotionBonus = '/profile/promotions-and-bonuses/:id',
  PrivacyPolicy = '/privacy-policy',
  Profile = '/profile',
  Results = '/results',
  ResultsEvent = '/results/:sportId/:eventId',
  Rules = '/rules',
  Root = '/',
  SuccessLoginPage = '/auth/success',
  SuccessFastSignUp = '/auth/fast-sign-up-success',
  SuccessFullSignUp = '/auth/full-sign-up-success',
  Statistics = '/statistics',
  Support = '/support',
  SupportPage = '/profile/support',
  TopUpMethod = '/profile/top-up/:paymentMethodId',
  Training = '/training',
  TopUp = '/profile/top-up',
  VirtualGames = '/virtual-games',
  Withdraw = '/profile/withdraw',
  WithdrawMethod = '/profile/withdraw/:withdrawMethodId',
  Won = '/profile/won',
  User = '/profile/user',
  HeadToHead = '/headToHead/:sourceEventId',
  Search = '/search',
  Error404 = '/not-found'
}
