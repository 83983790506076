import { IconBalance } from 'shared/ui/Icon/General/IconBalance'
import { IconBetsHistory } from 'shared/ui/Icon/General/IconBetsHistory'
import { IconNotification } from 'shared/ui/Icon/General/IconNotification'
import { IconProfile } from 'shared/ui/Icon/General/IconProfile'
import { IconBonusCoupons } from 'shared/ui/Icon/General/IconBonusCoupons'
import { ERoutes } from 'shared/types/routes'
import { IRouteTranslate } from 'shared/lib/routes'

interface Options {
  hasBonuses: boolean
}

export const getProfileMenuRoutesList = ({
  hasBonuses
}: Options): IRouteTranslate[] => {
  const routes = [
    { path: ERoutes.Profile, locale: 'profile', icon: IconProfile },
    { path: ERoutes.ProfileBalance, locale: 'balance', icon: IconBalance },
    {
      path: ERoutes.BetsHistory,
      locale: 'history bets',
      icon: IconBetsHistory
    },
    {
      path: ERoutes.Notifications,
      locale: 'notifications',
      icon: IconNotification
    }
  ]
  if (hasBonuses) {
    routes.push({
      path: ERoutes.PromotionsAndBonuses,
      locale: 'bonus coupons',
      icon: IconBonusCoupons
    })
  }
  return routes
}
