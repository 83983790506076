import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'

export const StyledBannerStats = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
`

export const StyledBannerStatsItem = styled.div<{ isCard?: boolean }>`
  align-items: center;
  display: flex;
  flex-basis: 50px;
  flex-direction: column;
  position: relative;

  ${({ isCard }) => {
    if (!isCard) {
      return css`
        ${StyledIcon} {
          color: ${(props) => props.theme.colors.fixed.primary[0]} !important;
          opacity: 0.5;

          & path {
            fill: currentColor;
            fill-opacity: 1;
          }
        }
      `
    }
  }}

  &:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    padding-right: 0px;

    &::after {
      display: none;
    }
  }
`

export const StyledBannerStatsItemText = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fonts.size.l};
  /* padding-top: 9px; */
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  gap: 4px;
  line-height: 16px;
  white-space: nowrap;
`

export const StyledBannerStatsItemScore = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  height: 32px;
  justify-content: center;
  padding: 2px 4px;
  width: 45px;
`
